import axiosInstance from "../utils/axios";

export const GetAllDropdownValues = async () => {
  return await axiosInstance().get(
    "/getCommonContent?api_section=re_registration"
  );
};

export const GetAllDropdownValuesCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getCommonContent?api_section=re_registration`, {
    cancelToken: cancelToken
  });
};

export const setBackToCW = async (values) => {
  return await axiosInstance().post("/setBackToCW", values);
}

export const setToCancelled = async (values) => {
  return await axiosInstance().post("/setToCancelled", values);
}

export const setToTransferred = async (values) => {
  return await axiosInstance().post("/setToTransferred", values);
}

export const setBackToStudyKits = async (values) => {
  return await axiosInstance().post("/setBackToStudyKits", values);
}
export const GetRegisteredStudentList = async () => {
  return await axiosInstance().get("/getAllRegistrationsStudentList");
};

export const GetRegisteredStudentListCancelToken = async (cancelToken) => {
  return await axiosInstance().get("/getAllRegistrationsStudentList", {cancelToken: cancelToken});
};

export const GetSalesPersonsNames = async () => {
  return await axiosInstance().get("/intakes-list-edit");
};

export const GetSalesPersonsNamesCancelToken = async (cancelToken) => {
  return await axiosInstance().get("/intakes-list-edit", { cancelToken });
};

export const eBooksExportList = async (data) => {
  return await axiosInstance().post("/eBooksExportList", data);
};

export const eBooksExportListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/eBooksExportList", values, {
    cancelToken: cancelToken
  });
};

export const GetQualificationList = async (value, cancelToken) => {
  return await axiosInstance().get("/getQualificationList?id=" + value, {cancelToken});
};

export const AddOrUpdateReregistration = async (values) => {
  return await axiosInstance().post("/addOrUpdateReregistration", values);
};

export const reRegistrationDetails = async (values, cancelToken) => {
  return await axiosInstance().get(`/reRegistrationDetails?id=${values}`,{cancelToken});
};

export const reRegistrationList = async () => {
  return await axiosInstance().get(`/reRegistrationList`);
};

export const GetReRegistrationListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/reRegistrationList`, {
    cancelToken: cancelToken
  });
};

export const getStudentListBasedOnReregistration = async (value) => {
  return await axiosInstance().post(
    `/getStudentListBasedOnReregistration`, value
  );
};

export const GetStudentListBasedOnReregistrationCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getStudentListBasedOnReregistration`, values, {
    cancelToken: cancelToken
  });
};

export const reRegistrationDelete = async (value) => {
  return await axiosInstance().post(`/reRegistrationDelete?id=${value}`);
};

export const GetRegisteredStudentDetails = async (id) => {
  return await axiosInstance().get(
    "/getAllRegistrationsStudentDetails?id=" + id
  );
};

export const GetRegisteredStudentDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(
    "/getAllRegistrationsStudentDetails?id=" + id, {cancelToken}
  );
};

export const addStudentReRegistrations = async (values) => {
  return await axiosInstance().post("/addStudentReRegistrations", values);
};

export const getSubjects = async (id) => {
  return await axiosInstance().get("/getSubjects?id=" + id);
};

export const netSuitCreateLead = async (values) => {
  return await axiosInstance().post("/netSuitCreateLead", values);
};

export const deletePdfLetter = async (values) => {
  return await axiosInstance().post("/deletePdfLetter", values);
};

export const GetCourseAndPrograms = async (id = null, type = "assigned") => {
  // https://aiesuperadmin.devtechnosys.tech:17278/api/v1/getQualificationCourseList?id=5&type=assigned
  return await axiosInstance().get(`/getQualificationCourseList?student_id=${id}`);
};

export const GetAssignedCourseAndProgramme = async (studentId) => {
  return await axiosInstance().get(
    `/getAssignedQualificationCourseList?id=${studentId}`
  );
};

export const AssignCourses = async (params) => {
  return await axiosInstance().post("/assignProgramsToStudents", params);
};

export const updateSectionToSave = async (values) => {
  return await axiosInstance().post(`/updateSectionToSave`, values);
};

export const checkCheckboxRejectDetails = async (values) => {
  return await axiosInstance().post(`/checkboxsRejectDetails`, values);
};

export const StudentFileUploads = async (values) => {
  return await axiosInstance().post(`/studentFileUploads`, values);
};

export const StudentSendTrackingCommunication = async (values) => {
  return await axiosInstance().post(
    `/studentSendTrackingCommunication`,
    values
  );
};
export const StudentSendTrackingCommunicationLaptop = async (values) => {
  return await axiosInstance().post(
    `/studentSendTrackingCommunicationLaptop`,
    values
  );
};

export const StudentSendConfirmationCommunication = async (values) => {
  return await axiosInstance().post(
    `/studentSendConfirmationCommunication`,
    values
  );
};

export const StudentSendConfirmationCommunicationLaptop = async (values) => {
  return await axiosInstance().post(
    `/studentSendConfirmationCommunicationLaptop`,
    values
  );
};

export const CheckSubject = async (values) => {
  return await axiosInstance().post(`/checkSubject`, values);
};

export const SendLetter = async (values) => {
  return await axiosInstance().post(`/sendLetter`, values);
};

export const AllBrandList = async () => {
  return await axiosInstance().get(`/allBrandList`);
};

export const AllBrandListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/allBrandList`,{cancelToken: cancelToken});
};

export const ProfileMatch = async (value) => {
  return await axiosInstance().post(`/profileMatch`, value);
};

export const MergeMatch = async (value) => {
  return await axiosInstance().post(`/mergeMatch`, value);
};

export const SetStudentQualification = async (value) => {
  return await axiosInstance().post(`/setStudentQualification`, value);
};

export const GetNetsuiteCustomerDetails = async (value, email, contactID, bookingId) => {
  return await axiosInstance().get(
    `/getNetsuiteCustomerDetails?customerId=${value}&&email=${email}&&NScontactID=${contactID}&&bookingId=${bookingId}`
  );
};

export const GetStudentRegAuditTrail = async (type) => {
  return await axiosInstance().get(`/getRegAudit?resource_type=${type.resource_type}${type.action_id ? "&action_id="+type.action_id : ""}`);
}

export const GetStudentRegAuditTrailCancelToken = async (type, cancelToken) => {
  return await axiosInstance().get(`/getRegAudit?resource_type=${type.resource_type}${type.action_id ? "&action_id="+type.action_id : ""}`, {
    cancelToken: cancelToken
  });
};

export const getRegAuditData = async (type) => {
  return await axiosInstance().post(`/getRegAuditData`, type);
}

export const getRegAuditDataCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getRegAuditData`, values, {
    cancelToken: cancelToken
  });
};

export const GetQualificationOptions = async (id,cancelToken) => {
  // return await axiosInstance().get("/getNetsuiteProductList?itemtype=Service" + (id ? "&netsuite_department_id=" + id : ""), {cancelToken});
  return await axiosInstance().get("/getNetsuiteProductList?" + (id ? "netsuite_department_id=" + id : ""), {cancelToken});
  // return await axiosInstance().get("/getDropdownProgrammes");
}

export const GetQualificationOptionsCancelToken = async (id, cancelToken) => {
  // return await axiosInstance().get("/getNetsuiteProductList?itemtype=Service" + (id ? "netsuite_department_id=" + id : ""), {
  return await axiosInstance().get("/getNetsuiteProductList?" + (id ? "netsuite_department_id=" + id : ""), {
    cancelToken: cancelToken
  });
};

export const GetQualificationOptionsInactive = async (id,cancelToken) => {
  // return await axiosInstance().get("/getNetsuiteProductListInactive?itemtype=Service" + (id ? "&netsuite_department_id=" + id : ""), {cancelToken});
  return await axiosInstance().get("/getNetsuiteProductListInactive?" + (id ? "netsuite_department_id=" + id : ""), {cancelToken});
  // return await axiosInstance().get("/getDropdownProgrammes");
}

export const GetQualificationOptionsInactiveCancelToken = async (id, cancelToken) => {
  // return await axiosInstance().get("/getNetsuiteProductListInactive?itemtype=Service" + (id ? "netsuite_department_id=" + id : ""), {
  return await axiosInstance().get("/getNetsuiteProductListInactive?" + (id ? "netsuite_department_id=" + id : ""), {
    cancelToken: cancelToken
  });
};
export const GetAllRegistrationsStudentsList = async (values) => {
  return await axiosInstance().post("/getAllRegistrationsStudentsList", values);
}

export const GetAllRegistrationsStudentsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getAllRegistrationsStudentsList`, values, {
    cancelToken: cancelToken
  });
};
export const GetAllNewRegistrationsStudentsList = async (values, cancelToken) => {
  return await axiosInstance().post("/getAllNewRegistrationsStudentsList", values, {cancelToken});
}
export const GetAllSLPRegistrationsStudentsList = async (values, cancelToken) => {
  return await axiosInstance().post("/getAllSLPRegistrationsStudentsList", values, {cancelToken});
}

export const GetAllReRegistrationsStudentsList = async (values, cancelToken) => {
  return await axiosInstance().post("/getAllReRegistrationsStudentsList", values, {cancelToken});
}

export const GetsalesPersonFilterList = async (cancelToken) => {
  return await axiosInstance().get("/getsalesPersonFilterList",{cancelToken});
}

export const GetsalesPersonFilterListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getsalesPersonFilterList`, {
    cancelToken: cancelToken
  });
};

export const SaveAndLiveCourse = async (values) => {
  return await axiosInstance().post("/saveAndLiveCourse", values);
}

export const SpStudentStatusData = async (id) => {
  return await axiosInstance().get(`/spStudentStatusData?id=${id}`);
}

export const GetAllRegistrationsStudentsListFilters = async (cancelToken) => {
  return await axiosInstance().get("/getAllRegistrationsStudentsListFilters",{cancelToken});
}
export const GetAllNewRegistrationsStudentsListFilters = async (cancelToken) => {
  return await axiosInstance().get("/getAllNewRegistrationsStudentsListFilters",{cancelToken});
}
export const GetAllReRegistrationsStudentsListFilters = async (cancelToken) => {
  return await axiosInstance().get("/getAllReRegistrationsStudentsListFilters",{cancelToken});
}

export const GetAllSLPRegistrationsStudentsListFilters = async (cancelToken) => {
  return await axiosInstance().get("/GetAllNewRegistrationsStudentsListFilters", {cancelToken});
}

export const GetAllRegistrationsStudentsListFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getAllRegistrationsStudentsListFilters`, {
    cancelToken: cancelToken
  });
};

export const DeleteStudentRegistration = async (value) => {
  return await axiosInstance().post(`/deleteStudentRegistration`, value);
};

export const GetReRegStudentListFilters = async (id) => {
  return await axiosInstance().get(`/getReRegStudentListFilters?id=${id}`);
};

export const GetReRegStudentListFiltersCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getReRegStudentListFilters?id=${id}`, {
    cancelToken: cancelToken
  });
};

export const deleteStudentRegistration = async (param) => {
  return await axiosInstance().get(`/deleteStudentRegistration`, {params : param});
};

export const ReRegisterStudent = async (data) => {
  return await axiosInstance().post(`/reRegisterStudent`, data);
};

export const getNationlityList = async () => {
  return await axiosInstance().get(`/getNationlityList`);
}

export const getNationlityListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getNationlityList`, { cancelToken });
}
export const GetSchoolList = async () => {
  return await axiosInstance().get(`/getSchoolList`);
};
export const GetSchoolListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getSchoolList`, {cancelToken});
};
export const programmeReRegisterSubmitStatus = async (data) => {
  return await axiosInstance().post(`/programmeReRegisterSubmitStatus`, data);
};

export const getReRegisterationAuditFilters = async (param) => {
  return await axiosInstance().get(`/getReRegisterationAuditFilters`, {params : param});
};

export const getReRegisterationAuditFiltersCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getReRegisterationAuditFilters`, { params : params, cancelToken: cancelToken });
};
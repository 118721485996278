import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { InitialRender } from "../common/Helper";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../utils/Constants";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../utils/permissionGate";
import hasPermission from "../../utils/hasMultiplePermission";
import Tablefilter from "../common/Tablefilter";
import { BrandTemplateStatus, RenderProgrammeStudentStatus, RenderQuizTopic } from "../../utils/CommonStatusItems";
import { RenderBrandList, RenderSchool, MappingSchool, RenderBrandTemplate } from "../../utils/CommonGroupingItem";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";
import $ from "jquery";
import { BrandTemplateDropdown, DeleteBrandTemplate, GetBrandTemplateList, GetBrandTemplateListCancelToken } from "../../services/BrandTemplateServices";

function BrandTemplates() {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [brand, setBrand] = useState({ arr: [], checkObj: {} });
    const [linkedDomain, setLinkedDomain] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [updateData, setUpdateData] = useState(true);
    const [templateData, setTemplateData] = useState([]);
    const user = useSelector((state) => state.user);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("templateName");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [filterData, setFilterData] = useState({ status: [], brand: [], linkedDomain: [] });

    useEffect(() => {
        handleTableScroll()
    }, [loading])
    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })
    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateBrandTemplate"));
        if (storedFilters) {
            setStatus(storedFilters.status);
            setBrand(storedFilters.brand);
            setLinkedDomain(storedFilters.linkedDomain);
            setFilterData(storedFilters.filterData);
        }
    }, []);
    useEffect(() => {
        const filterState = {
            status,
            brand,
            linkedDomain,
            filterData
        };
        localStorage.setItem("filterStateBrandTemplate", JSON.stringify(filterState));
    }, [status, brand, linkedDomain, filterData]);
    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })
    useEffect(() => {
        const cancelTokenSources = [];

        const getWebsiteList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            // setCancelTokenSources([...cancelTokenSources, source]);
            cancelTokenSources.push(source);

            const websiteData = {
                page: page,
                perPage: perPage,
                key: sortkey,
                sort: sortOrder,
                search: search,
                exportStaus: false,
                viaStatus: status.arr,
                viaBrand: brand.arr,
                viaSiteLink: linkedDomain.arr,
            };

            try {
                const res = await GetBrandTemplateListCancelToken(websiteData, source.token);
                setTemplateData(res?.data?.data?.data);
                setTotalRows(res.data?.data?.total);
                setPerPage(res.data?.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getWebsiteList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, status, brand, linkedDomain, searchStatusCheck, updateData]);

    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const res = await BrandTemplateDropdown(dropdownSource.token);
                setFilterData({
                    ...res.data, status: res.data.status ? res.data.status : [], brand: res.data.brand ? res.data.brand : [], linkedDomain: res.data.siteLink || []
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();

        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["wbssitebrandtmpview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, [deleterow]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setBrand({ arr: [], checkObj: {} });
        setLinkedDomain({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
    };

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };


    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteBrandTemplate({ id: id })
                    .then((res) => {
                        Swal.fire("Deleted!", "Brand Template has been deleted.", "success");
                        setUpdateData(!updateData);
                    })
                    .catch((error) => console.log(error));
            }
        });
    }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "templateName",
            sortField: "templateName",
            sortable: true,
            cell: (row) => (
                <PermissionsGate scopes={['wbssitebrandtmpedit']} RenderError={() => (<p>{row?.templateName}</p>)}>

                <Link
                    className="as-text-blue curser feature-name"
                    to={`/websiteManagement/siteSetup/brand_Templates/brand_information/update/${row.brandID}`}
                    title={row.templateName}
                    >
                    <span className="textLimit100">{row.templateName}</span>
                </Link >
                </PermissionsGate>
            ),
        },
        {
            name: "Linked Domain",
            selector: "siteDomain",
            sortField: "siteDomain",
            // sortable: true,
            cell: (row) => (
                <>
                    {row.getSiteLink?.map((item, index) =>
                        <a
                            key={index}
                            className="as-text-blue curser feature-name"
                            href={`https://${item.siteDomain}`}
                            target="_blank"
                            title={item.siteDomain}
                        >
                            <span className="textLimit100">{item.siteDomain}</span>
                        </a>
                    )}
                </>
            ),
        },
        {
            name: "Brand",
            selector: "brand_name",
            sortField: "brand_name",
            sortable: true,
            // maxWidth: "120px",
            // minWidth: "120px",
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={`/administration/brandSetting/editBrand/${row.brandLinked}`}
                    title={row.brand_name}
                >
                    <span className="textLimit100">{row.brand_name}</span>
                </Link >
            ),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            // sortable: true,
            // maxWidth: "120px",
            // minWidth: "120px",
            cell: (row) => (row.status ? BrandTemplateStatus(row.status).html : "-"),
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <PermissionsGate
                            scopes={["wbssitebrandtmpedit"]}
                            RenderError={() => (
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    disabled
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                        >
                        <Link
                            className="btn btn-primary rounded-circle"
                            to={`/websiteManagement/siteSetup/brand_Templates/brand_information/update/${row.brandID}`}
                            title="Open"
                        >
                            <i className="fal fa-folder-open"></i>
                        </Link>
                        </PermissionsGate>
                        <PermissionsGate scopes={['wbssitebrandtmpdelete']} RenderError={() => (
                            <button
                                className="btn btn-primary rounded-circle"
                                title="Open"
                                disabled
                            >
                                <i className="fal fa-trash-alt"></i>
                            </button>
                        )}>

                        <button
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleDelete(row.brandID)}
                            title="Delete"
                            type="submit"
                            >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                            </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);


    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Name", "Linked Sites", "Brand", "Status"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const templateData = {
            page: page,
            perPage: perPage,
            key: sortkey,
            sort: sortOrder,
            search: search,
            exportStatus: true,
            viaStatus: status.arr,
            viaBrand: brand.arr,
            viaSiteLink: linkedDomain.arr,
        };

        GetBrandTemplateList(templateData)
            .then((res) => {
                data = res.data.data;
                data = data?.map((row) => ({
                    ...row,
                    Name: row?.templateName || "",
                    "Linked Sites": row.getSiteLink ? row.getSiteLink.map((d, i) => d?.siteDomain).join(",") :"-",
                    Brand: row?.brand_name ? row?.brand_name : "-",
                    Status: row?.status ? BrandTemplateStatus(row.status)?.text
                        : "-",
                }));

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row?.templateName,
                            row.getSiteLink.map((d, i) => d?.siteDomain).join(",") || "-",
                            row.brand_name,
                            BrandTemplateStatus(row.status)?.text ||"-",
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                Swal.close();
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={debouncedTerm}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={filterData?.status}
                                            state={status}
                                            setState={setStatus}
                                            renderLabelFunction={BrandTemplateStatus}
                                        />
                                    </div>
                                </div>
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Brand"
                                            optionArr={filterData?.brand}
                                            state={brand}
                                            setState={setBrand}
                                            renderLabelFunction={RenderQuizTopic}
                                            uniqueId="brand"

                                        />
                                    </div>
                                </div>
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Linked Domain"
                                            optionArr={filterData?.linkedDomain}
                                            state={linkedDomain}
                                            setState={setLinkedDomain}
                                        // renderLabelFunction={RenderBrandList}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "Brand_Templates_list");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "Brand_Templates_list");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "Brand_Templates_list");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                <PermissionsGate
                                    RenderError={() => (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                            disabled
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    )}
                                    scopes={["wbssitebrandtmpadd"]}
                                >
                                    <Link to={`/websiteManagement/siteSetup/brand_Templates/brand_information/create`}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    </Link>
                                </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    paginationDefaultPage={page}
                    progressPending={loading}
                    data={templateData}
                    progressComponent={<SkeletonTicketList />}
                    defaultSortField={sortkey}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    // noDataComponent={Str.noRecord}
                    onSort={handleSort}
                    sortServer
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    );
}

export default BrandTemplates;

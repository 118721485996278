import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { getBlockVariantTypeDropDown, getBrandTemplate, getBrandTemplateStatus, getHeaderDropDownListStatic, getHeaderDropdown, getPopUpListStatic, updateBrandTemplateHomePage } from "../../../../services/BrandTemplateServices";
import axios from "../../../../utils/axios";
import { FormField } from "../../../common/FormFields";



const BrandHome = () => {
    const { subId, subType } = useParams();
    const history = useHistory();
    const [statusArr, setStatusArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [headerArr, setHeaderArr] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [brandData, setBrandData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, []);
    console.log('BrandHome rendered');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getBrandTemplate(subId);
                setBrandData(res.data?.data);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };
        if (subId) {
            fetchData();
        }
    }, [subId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteTitle: brandData.siteTitle || '',
            header: brandData.header || '',
            homePagePopUPID: brandData.homePagePopUPID || '',
            analyticsCode: brandData.analyticsCode || '',
            metaKeywords: brandData.metaKeywords || '',
            metaTitle: brandData.metaTitle || '',
            metaDecription: brandData.metaDecription || '',

            homePagePrimaryCategoryText: brandData.homePagePrimaryCategoryText || '',
            isHomePagePrimaryCategoryShowBlock: brandData.isHomePagePrimaryCategoryShowBlock || 0,
            isHomePagePrimaryCategorySearchBarShow: brandData.isHomePagePrimaryCategorySearchBarShow || 0,

            homePageSecCategoryText: brandData.homePageSecCategoryText || '',
            isHomePageSecCategoryShowBlock: brandData.isHomePageSecCategoryShowBlock || 0,

            homePageUpcomingText: brandData.homePageUpcomingText || '',
            isHomePageUpcomingShowBlock: brandData.isHomePageUpcomingShowBlock || 0,

            homePageLearningVariant: brandData.homePageLearningVariant || '',
            homePageLearningText: brandData.homePageLearningText || '',
            isHomePageLearningShowBlock: brandData.isHomePageLearningShowBlock || 0,

            homePageTestimonialsVariant: brandData.homePageTestimonialsVariant || '',
            homePageTestimonialsText: brandData.homePageTestimonialsText || '',
            isHomePageTestimonialsShowBlock: brandData.isHomePageTestimonialsShowBlock || 0,

            homePageNewsText: brandData.homePageNewsText || '',
            isHomePageNewsShowBlock: brandData.isHomePageNewsShowBlock || 0,

        },
        validationSchema: Yup.object().shape({
            siteTitle: Yup.string().required('Site Title is required').nullable().trim(),
            header: Yup.string().required('Header is required').nullable().trim(),
            homePagePopUPID: Yup.string().required('PopUP is required'),
            analyticsCode: Yup.string(),
            metaKeywords: Yup.string(),
            metaTitle: Yup.string(),
            metaDecription: Yup.string(),
            homePagePrimaryCategoryText: Yup.string().required('Schools Category Block Title Text is required').nullable().trim(),
            homePageSecCategoryText: Yup.string().required('Secondary Category Block Title Text is required').nullable().trim(),
            homePageUpcomingText: Yup.string().required('Upcoming Open Days Block Title Text is required').nullable().trim(),
            homePageLearningText: Yup.string().required('Learning Methods Block Title Text is required').nullable().trim(),
            homePageTestimonialsText: Yup.string().required('Testimonials Block Title Text is required').nullable().trim(),
            homePageNewsText: Yup.string().required('News Block Title Text is required').nullable().trim(),

        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (subType === 'Create') {
                Swal.fire('Error!', 'Something went wrong', 'error');
            } else if (subType === 'update') {
               await updateBrandTemplateHomePage({ ...values, brandID: subId })
                    .then(response => {
                        Swal.fire('Success!', 'Brand Template updated successfully!', 'success');
                        history.push("/websiteManagement/siteSetup/brand_Templates");
                    })
                    .catch(error => {
                        Swal.fire('Error!', 'Failed to update Brand Template', 'error');
                    });
            }
            setSubmitting(false);
        },
    });
    const { handleSubmit } = formik
    return (
        <div className="card card-body-inr">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Home Page Information</h4>
                        </div>
                    </div>
                    <FormField field={{ name: "siteTitle", label: "Site Title" }} formik={formik} />
                    <FormField field={{ name: "header", label: "Default Header" }} formik={formik} selectOptions={headerArr} />
                    <FormField field={{ name: "homePagePopUPID", label: "Popup" }} formik={formik} selectOptions={popUpArr} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Schools Category Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePagePrimaryCategoryShowBlock"
                                    name="isHomePagePrimaryCategoryShowBlock"
                                    checked={formik.values.isHomePagePrimaryCategoryShowBlock == 1}
                                    onChange={(e) => {
                                        console.log("Updating isHomePagePrimaryCategoryShowBlock:", e.target.checked);
                                        formik.setFieldValue(
                                            "isHomePagePrimaryCategoryShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePagePrimaryCategoryShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Course Search Bar">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePagePrimaryCategorySearchBarShow"
                                    name="isHomePagePrimaryCategorySearchBarShow"
                                    checked={formik.values.isHomePagePrimaryCategorySearchBarShow == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePagePrimaryCategorySearchBarShow",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePagePrimaryCategorySearchBarShow"
                                >
                                    Show Course Search Bar
                                </label>
                            </div>
                        </div>
                    </div>
                    <FormField field={{ name: "homePagePrimaryCategoryText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>


                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Secondary Category Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageSecCategoryShowBlock"
                                    name="isHomePageSecCategoryShowBlock"
                                    checked={formik.values.isHomePageSecCategoryShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageSecCategoryShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageSecCategoryShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageSecCategoryText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Upcoming Open Days Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageUpcomingShowBlock"
                                    name="isHomePageUpcomingShowBlock"
                                    checked={formik.values.isHomePageUpcomingShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageUpcomingShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageUpcomingShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageUpcomingText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Learning Methods Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageLearningShowBlock"
                                    name="isHomePageLearningShowBlock"
                                    checked={formik.values.isHomePageLearningShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageLearningShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageLearningShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageLearningVariant", label: "Block Variant", required: false }} selectOptions={learnignBlockArr} formik={formik} col_md={6}
                        col_lg={3} />
                    <FormField field={{ name: "homePageLearningText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={6} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">Testimonials Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageTestimonialsShowBlock"
                                    name="isHomePageTestimonialsShowBlock"
                                    checked={formik.values.isHomePageTestimonialsShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageTestimonialsShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageTestimonialsShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageTestimonialsVariant", label: "Block Variant", required: false }} selectOptions={testimonialsBlockArr} formik={formik} col_md={6}
                        col_lg={3} />
                    <FormField field={{ name: "homePageTestimonialsText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={6} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>


                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header">News Block</h4>
                        </div>
                    </div>
                    <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Block Settings"}>
                            Block Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isHomePageNewsShowBlock"
                                    name="isHomePageNewsShowBlock"
                                    checked={formik.values.isHomePageNewsShowBlock == 1}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "isHomePageNewsShowBlock",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="isHomePageNewsShowBlock"
                                >
                                    Show  Block
                                </label>
                            </div>
                        </div>

                    </div>
                    <FormField field={{ name: "homePageNewsText", label: "Block Title Text" }} formik={formik} col_md={6}
                        col_lg={9} />
                    <div className="col-md-12">
                        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
                    </div>
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header"><FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> SEO Settings</h4>
                        </div>
                    </div>
                    <FormField field={{ name: "analyticsCode", label: "Analytics Code", required: false }} formik={formik} />
                    <FormField field={{ name: "metaTitle", label: "Meta Title", required: false }} formik={formik} />
                    <FormField field={{ name: "metaDecription", label: "Meta Description", required: false }} formik={formik} />
                    <FormField field={{ name: "metaKeywords", label: "Meta Keywords", required: false }} formik={formik} />
                </div>
                <div className="form-group form-group-save-cancel">
                    <button className="btn btn-save btn-success" disabled={formik.isSubmitting} type="submit" title="Save">
                        {formik.isSubmitting ? (
                            <i className="fas fa-cog fa-spin"></i>
                        ) : (
                            <i className="fal fa-save"></i>
                        )} Save
                    </button>
                    {Object.keys(formik.values).map((key) => {
                        if (formik.touched[key] && formik.errors[key]) {
                            return (
                                <div className="invalid-feedback d-block">
                                    {formik.errors[key]}
                                </div>
                            );
                        }
                    })}
                </div>
            </form>
        </div>
    );
};

export default BrandHome;

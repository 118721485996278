import { useFormik } from "formik";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { InitialRender } from "../../../common/Helper";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";

function ExamRegList(props) {
  const [studentData, setStudentData] = useState();
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [salesPerson, setSalesPerson] = useState([]);
  const [erpData, setErpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [reRegId, setReRegId] = useState("");

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  function getValue(arrName, label) {
    let labelName = "";
    if (arrName === "sales_person") {
      for (let item of salesPerson) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }
      return labelName;
    } else {
      for (let item of dropdownValues[arrName]) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }

      if (labelName === "" && arrName === "re_registration_status")
        return label;
      return labelName;
    }
  }

  useEffect(() => {
    setStudentData(props.studentData);
    let temp =
      props && props.studentData && props.studentData.exam_registrations
        ? props.studentData.exam_registrations
        : {};
    let reRegId =
      props && props.studentData && props.studentData.re_reg_data
        ? props.studentData.re_reg_data.reregistration_id
        : "";
    setReRegId(reRegId);
    // console.log("erp -=-=-> ", temp);
    setErpData(temp ? temp : []);
  }, [props]);

  const columns = useMemo(() => [
    // {
    //   name: "ID or Passport",
    //   selector: "id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <p>
    //       <span className="overflow-ellipsis2" title={row.id}>
    //         <b>#{row.id}</b>
    //       </span>
    //       </p>
    //     </div>
    //   ),
    // },
    {
      name: "Exam ID Number",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <p className="as-text-blue">
          {row?.exam_id_number ? row?.exam_id_number : "-"}
        </p>
      ),
    },
    {
      name: "Exam Period",
      selector: "date_entered",
      sortable: true,
      cell: (row) => <>{row.SpExams ? row?.SpExams[0]?.period : "-"}</>,
    },
    {
      name: "Subjects",
      selector: "type",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsSubjects
            ? row?.SpExamsRegistrationsSubjects.map((data) => {
                return <p>{data.subjectGET?.SpCourse[0]?.name}</p>;
              })
            : "-"}
        </>
      ),
    },
    {
      name: "Term",
      selector: "assingnee",
      sortable: true,
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsSubjects
            ? row?.SpExamsRegistrationsSubjects.map((data) => {
                return <p>{data.mark != "" ? data.mark : "-"}</p>;
              })
            : "-"}
        </>
      ),
    },
    {
      name: "Notes",
      selector: "attendance",
      sortable: true,
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsSubjects
            ? row?.SpExamsRegistrationsSubjects.map((data) => {
                return <p>{data.attendance != "" ? data.attendance : "-"}</p>;
              })
            : "-"}
        </>
      ),
    },
    {
      name: "Write",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsSubjects
            ? row?.SpExamsRegistrationsSubjects.map((data) => {
                return (
                  <p>
                    <input type="checkbox" checked={data?.written} />
                  </p>
                );
              })
            : "-"}
        </>
      ),
    },
    {
      name: "Exam",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsSubjects
            ? row?.SpExamsRegistrationsSubjects.map((data) => {
                return <p>{data.dhetresult != "" ? data.dhetresult : "-"}</p>;
              })
            : "-"}
        </>
      ),
    },
    {
      name: "Student Type",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.student_type ? row.student_type : "-"}</>,
    },
    {
      name: "Centre Alias",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <>
          {row?.SpExamsRegistrationsCentres?.centre_alias
            ? row?.SpExamsRegistrationsCentres?.centre_alias
            : "-"}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row?.status ? row?.status : "-"}</>,
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <a
              href={`https://ami.myaie.ac/studentAdministration/externalExam/details/open/registration/${row.id}/details/${row.exam}`}
              className="btn btn-primary rounded-circle"
              title="Open"
              target="_blank"
            >             
                <i className="fal fa-folder-open"></i>
            </a>
          </div>
        </div>
      ),
    },
  ]);

  function dataToRender() {
    let updatedData = [];
    let allData = erpData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes =
          item.id && item.id.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempName = allData.filter((item) => {
        let includes = item.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempId, ...tempName];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    return updatedData;
  }

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Exam ID Number",
      "Exam Period",
      "Subjects",
      "Term",
      "Exam",
      "Student Type",
      "Centre Alias",
      "Status",
    ];

    data = data?.map((row) => ({
      ...row,
      // ID: row?.id ? row?.id : "-",
      "Exam ID Number": row.exam_id_number ? row.exam_id_number : "-",
      "Exam Period": row.SpExams ? row?.SpExams[0].period : "-",
      Subjects: row?.SpExamsRegistrationsSubjects
        ? row?.SpExamsRegistrationsSubjects.map((data) => {
            return data.subjectGET?.SpCourse[0]?.name;
          })
        : "-",
      Term: row?.SpExamsRegistrationsSubjects
        ? row?.SpExamsRegistrationsSubjects.map((data) => {
            return data.mark;
          })
        : "-",
      // Write: row?.SpExamsRegistrationsSubjects
      //   ? row?.SpExamsRegistrationsSubjects.map((data) => {
      //       return (
      //         <p>
      //           <input type="checkbox" checked={data?.written} />
      //         </p>
      //       );
      //     })
      //   : "-",
      Exam: row?.SpExamsRegistrationsSubjects
        ? row?.SpExamsRegistrationsSubjects.map((data) => {
            return data.dhetresult;
          })
        : "-",
      "Student Type": row.student_type ? row.student_type : "-",
      "Centre Alias": row?.SpExamsRegistrationsCentres?.centre_alias
        ? row?.SpExamsRegistrationsCentres?.centre_alias
        : "-",
      Status: row.status ? row.status : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Exam ID Number"],
          row["Exam Period"],
          row["Subjects"],
          row["Term"],
          row["Exam"],
          row["Student Type"],
          row["Centre Alias"],
          row["Status"]
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom marLeft135">
        <div className="edit-icon new-card-header">
          <div className="card-header">Exam Registrations</div>
        </div>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={(e) => handleFilters(e, "search")}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      // onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "ERP_Data");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "ERP_Data");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "ERP_Data");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading === true ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="Firstname"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
}

export default ExamRegList;

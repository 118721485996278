import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { AddCampusPages, AddCampusResourse } from "../../../../services/CampusPagesServer";


const UpdateResourses = ({row}) => {
    const history = useHistory();
    const [update , setUpdate] = useState(false);
    const [iconName, setIconName] = useState("");

    const givenPermissions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    const formik = useFormik({
        initialValues: {
            campusId: update === false && "",
            resourceName: update === false && "",
            uploadResource: update === false && ""
        },
        validationSchema: Yup.object({
            resourceName: Yup.string().required("Resource Name is required"),
        }),
        onSubmit: async (values, actions) => {
            if (update) {
                try {
                    await AddCampusResourse(values);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                    actions.resetForm();
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            } else {
                try {
                    await AddCampusResourse(values);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            }
        }
    });

    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            formik.setFieldValue("uploadResource", e.currentTarget.value);
            setIconName(file.name);
        }
    };

    useEffect(() => {
        if (row) {
            setUpdate(true);
            formik.setValues({
                campusId: row.id,
                resourceName: row.resourceName,
                uploadResource: row.uploadResource || null
            });
            setIconName(row?.uploadResource ? row?.uploadResource.split('/').pop() : '');
        }
    }, [row])

    return (
        <div className="card card-profile-info-card border-0">
            <div className="card-body-inr card-body-info">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                                <label>Resource Name*</label>
                                <input
                                    type="text"
                                    className={
                                        "form-control" +
                                        (formik.errors.resourceName && formik.touched.resourceName
                                            ? " is-invalid"
                                            : "")
                                    }
                                    placeholder="Enter Resource Name"
                                    name="resourceName"
                                    id="resourceName"
                                    title="Resource Name"
                                    value={formik.values.resourceName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                                <label>
                                Upload Resource <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="form-group atttach-file">
                                        <label
                                            className={
                                                formik.errors.uploadResource && formik.touched.uploadResource && "file-req is-invalid"
                                            }
                                        >
                                            <i className="fal fa-upload"></i>
                                            <span>Upload</span>
                                            <input
                                                type="file"
                                                className="form-control form-control-aatch-file"
                                                onChange={(e) => handleFileChange(e)}
                                                name="uploadResource"
                                                id="uploadResource"
                                                accept=".png,.jpg,.jpeg"
                                            />
                                        </label>
                                    </div>
                                    {iconName && (
                                        <div className="frm-group d-flex align-items-center" style={{ marginLeft: 20 }}>
                                            <ul className="list-unstyled attached-file-ul m-0">
                                                <li>
                                                    <a href={formik.values.uploadResource} target="_blank">
                                                        <i className="fal fa-file"></i>
                                                        &nbsp;{iconName}
                                                    </a>
                                                </li>
                                            </ul>

                                            <button
                                                className="btn btn-danger rounded-circle"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    formik.setFieldValue("uploadResource", "");
                                                    setIconName("");
                                                }}
                                            >
                                                <i className="fal fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-group-save-cancel mt-4">
                        <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                        >
                            <i className="fal fa-save"></i>
                            Save
                        </button>

                        <button className="btn btn-close btn-danger" type="button" data-dismiss="modal" title="Close">
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>
                    {Object.keys(formik.errors).map((key) => {
                        if (formik.touched[key]) {
                            return (
                                <div className="invalid-feedback d-block" key={key}>
                                    {formik.errors[key]}
                                </div>
                            );
                        }
                        return null;
                    })}
                </form>
            </div>
        </div>
    );
};

export default UpdateResourses;

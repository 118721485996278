import React, { useEffect, useMemo, useState } from "react";

import Swal from "sweetalert2";

import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import { TrimText } from "../../common/TrimText";
import DataTable from "react-data-table-component";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import CreateStudyPathModuleModel from "./CreateStudyPathModuleModel";
import { Link } from "react-router-dom";
import { getProgrammeStudyPathModuleList } from "../../../services/ProgramService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import PermissionsGate from "../../../utils/permissionGate";

const TableStudyPath = ({
  tableData,
  setTableData,
  tableData1,
  setTableData1,
  search,
  setSearch,
  handleSort,
  formik,
  loading,
  label,
  name,
  modelID = "addModules2",
  module_type,
  totalRows,
  ArrayFields
}) => {
  const {subId} = useParams()
  const {type} = useParams()
  const [sortKey,setSortKey] = useState("")
  const [sortOrder,setSortOrder] = useState("")
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "start",
    sortOrder: "DESC"
})
const [searchAssigned,setSearchAssigned] = useState("")
 
  

  const totalCredits = () => {
    return tableData?.reduce((total, item) => {
      return total + (item?.credits || 0);
    }, 0);
  };
  const addProgramme = (row, type) => {
    let assigntemp = [...tableData];
    let availtemp = [...tableData1];
    if (type === "add") {
      assigntemp.push(row);
      // availtemp = availtemp.filter((data) => data.id !== row.id);
    } else if (type === "delete") {
      assigntemp = assigntemp.filter((data) => data.Id !== row.Id);
    }
    setTableData([...assigntemp]);
    formik.setFieldValue(ArrayFields, assigntemp?.map((item) => item.Id) || [])
    setTableData1([...availtemp]);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          to={`/program-management-center/modules/module/open/${row.Id}`}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Code ? (
            <span title={row.Code} className="overflow-ellipsis2 as-text-blue">
              {TrimText(row.Code, 25)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      name: "Duration (Weeks)",
      selector: "Duration",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          {row.Duration ? (
            <span
              title={row.Duration}
              className="overflow-ellipsis2 as-text-blue"
            >
              {TrimText(row.Duration, 25)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Credits",
      selector: "credits",
      sortable: true,
      cell: (row) =>
        row.credits ? <div title={row.credits}>{row.credits}</div> : "-",
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link
              to={`/program-management-center/modules/module/open/${row.Id}`}
              className="btn btn-primary rounded-circle"
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {
              type == "open" ? (

                <PermissionsGate scopes={['pmcstudypathmoduleedit']} RenderError={() => (
                  <button
                  className="btn btn-danger rounded-circle"
                  title="Delete"
                  disabled
                  >
                         <i className="fal fa-trash-alt"></i>
                    </button>
                )}>


            <button
              title="Delete"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Add it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    addProgramme(row, "delete");
                  }
                });
              }}
              >
              <i className="fal fa-trash-alt"></i>
            </button>
              </PermissionsGate>
          ) : <button
          title="Delete"
          type="button"
          className="btn btn-danger rounded-circle"
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Add it!",
            }).then((result) => {
              if (result.isConfirmed) {
                addProgramme(row, "delete");
              }
            });
          }}
          >
          <i className="fal fa-trash-alt"></i>
        </button>
        }
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    // setModuleType({ arr: [], checkObj: {} });
    // setDuration({ arr: [], checkObj: {} });
  };

  const exportDataAssignedStd = () => {
    let data = dataToRenderAssignedStd();
    const header = ["Name", "Code", "Duration (Weeks)", "Credits"];
    
    data = data?.map((row) => {
      return ({
        ...row,
        "Name": row.name ? row.name : "-",
        "Code": row.Code ? row.Code : "-",
        "Duration (Weeks)": row.Duration ? row.Duration : "-",
        "Credits": row.credits ? row.credits : "-",
      })
    });
    const pdfData = data.map((row) => {
      return [row["Name"], row["Code"],row["Duration (Weeks)"], row["Credits"]];
    });    
    return {
        header,
        data,
        pdfData,
        columnStyles: {}
    }
  };

  const dataToRenderAssignedStd = () => {
    let updatedData = [];
    let allData = tableData?.map(
      (d,i) => {
        if(d.credits == null){
          d.credits = 0;
        }
        if(d.Duration == null){
          d.Duration = 0;
        }
        return d
      }
    );
    if (searchAssigned.length) {
      let tempStudent = allData.filter((item) => {
        let includes = item.name?.toLowerCase().toString().includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      // let tempStudentNo = allData.filter((item) => {
      //   let includes = item.student_crm_id?.toString().toLowerCase().includes(searchAssigned.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });

      let data = [...tempStudent];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    // if (statusAssigned.arr?.length) {
    //   let tempAction = updatedData;
    //   let tempResult = tempAction.filter((item) => {
    //     const startsWith = statusAssigned.arr?.find((post, index) => {
    //       if (post?.toString() === item?.status.toString()) return item;
    //     });
    //     if (startsWith) {
    //       return startsWith;
    //     } else return null;
    //   });
    //   updatedData = tempResult;
    // }

    // if (courseResultAssigned.arr?.length) {
    //   let tempMonth = updatedData;
    //   let tempResult = tempMonth.filter((item) => {
    //     const startsWith = courseResultAssigned.arr.find((post, index) => {
    //       let courseResult = item.pass_status
    //       if (post.toString() === courseResult.toString()) return item;
    //     });
    //     if (startsWith) {
    //       return startsWith;
    //     } else return null;
    //   });
    //   updatedData = tempResult;
    // }

    if (searchAssigned?.length) {
      return updatedData;
    } else {
      return tableData;
    }
  };
  return (
    <>
      <div className="col-md-12">
        <div className="new-card-header">
          <div className="card-header">{label}</div>
        </div>
      </div>
      <div className="col-md-3 col-lg-3">
        <div className="form-group-blk mb-3">
          <label>Credits Required* </label>
          <input
            type="number"
            className={
              "form-control" +
              (formik.errors.name && formik.touched.name ? " is-invalid" : "")
            }
            placeholder="Enter phase name"
            name={name}
            id={name}
            title="Credits Required"
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="col-md-4 col-lg-4">
        <div className="form-group-blk mb-3">
          <label className="mb-3">Total Credits</label>
          <br />
          <span className="mt-1">{totalCredits()}</span>
        </div>
      </div>
      <div className="col-12">
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
          <div className="custom-table-div filter-search-icon card-table-custom">
            <div className="search-filter-div">
              {/* <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          id="assessment-table-main_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className=""
                              placeholder="Search"
                              aria-controls="assessment-table-main"
                              onChange={handleSearchFilter}
                              value={search}
                            />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    {/*end of learning method*/}
                    {/* <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={() => resetFilter()}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Assigned Modules");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Assigned Modules");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Assigned Modules");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk gap-3">
                  <div className="add-ticket-blk button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Add Modules"
                      type="button"
                      data-toggle="modal"
                      data-target={`#${modelID}`}
                    >
                      <i className="fal fa-plus"></i>Add Modules
                    </button>
                  </div>
                </div>
              </div> */} 
            </div>
            {/* <DataTable
              data={tableData}
              defaultSortField="Portal"
              defaultSortAsc={true}
              columns={columns}
              // onSort={handleSort}
              paginationTotalRows={totalRows}
              pagination={true}
              noDataComponent={Str.noRecord}
              progressPending={loading}
              progressComponent={<SkeletonTicketList />}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            ></DataTable> */}
            <DataTableComponentFrontPagination
                isInsidePopUp
                data={dataToRenderAssignedStd()}
                columns={columns}
                loading={loading}
                search={searchAssigned}
                // onSort={handleSortFront}
                setSearch={setSearchAssigned}
                // totalRows={assignTotalRows}
                exportData={exportDataAssignedStd}
                // search={searchAssigned}
                // setSearch={setSearchAssigned}
                exportFileName="Assign Modules"
                tableButton={[
                  <PermissionsGate scopes={['pmcstudypathmoduleedit']} RenderError={() => (
                    <button
                        className="btn btn-primary"
                        title="Open"
                        disabled
                    >
                        <i className="fal fa-plus"></i>Add Modules
                    </button>
                )}>

                  <button className="btn btn-primary" title="Assign Students" type="button" data-toggle="modal" data-target={`#${modelID}`} disabled={(formik.values.AssessmentType == 9 && !formik.values.override_mark_ass_id)}>
                    <i className="fal fa-plus"></i>Add Modules
                  </button>
                  </PermissionsGate>
                ]}
                // filters={[
                //   {
                //     filterName: "Status",
                //     optionArr: statusAssignedList,
                //     state: statusAssigned,
                //     setState: setStatusAssigned,
                //     renderLabelFunction: RenderStudentStatus,
                //     uniqueId: "assignedStatus"
                //   },
                //   {
                //     filterName: "Course Result",
                //     optionArr: courseResultAssignedList,
                //     state: courseResultAssigned,
                //     setState: setCourseResultAssigned,
                //     renderLabelFunction: RenderQuizAttemptResultStatus,
                //     uniqueId: "assigned"
                //   }
                // ]}
              />
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal fade"
        id={modelID}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div class="modal-header modal-header-custom">
              <h5 class="modal-title">
                <i class="fal fa-plus"></i> Add Modules
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {console.log("", tableData1)}
            <CreateStudyPathModuleModel
              addProgramme={addProgramme}
              setTableData1={setTableData1}
              tableData1={[...tableData1]}
              tableData={tableData}
              module_type={module_type}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// let MemoTableStudyPath = React.memo(TableStudyPath)

export default TableStudyPath;

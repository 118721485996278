import React, { useEffect, useMemo, useState } from 'react'
import { handleTableScroll } from "../../../../utils/commonFunction";
import { Link } from "react-router-dom";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../../utils/permissionGate";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import { DeleteCampusResousePages, GetCampusPageResourseListCancelToken, GetCampusResourseList } from '../../../../services/CampusPagesServer';
import CreateResourses from './CreateResourses';
import UpdateResourses from './UpdateResourses';

function Resouses() {
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [updateData, setUpdateData] = useState({});
    const [update, setUpdate] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [loading, setloading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const [sortOrder, setSortOrder] = useState("");
    const [campusPagesResourseData, setCampusPagesResourseData] = useState([]);

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });

    })

    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    const resetFilter = () => {
        setSearchStatusCheck({});
        setSearch("");
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const handleClick = (row) => {
        console.log(row)
        setSelectedData(row)
        setUpdate(true)
    }

    useEffect(() => {
        const cancelTokenSources = [];

        const getCampusPageResourseList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const campusResoursePage = {
                page: page,
                perPage: perPage,
                key: sortkey,
                sort: sortOrder,
                search: search,
            };

            try {
                const res = await GetCampusPageResourseListCancelToken(campusResoursePage, source.token);
                // console.log(res?.data?.data?.data);
                setCampusPagesResourseData(res?.data?.data?.data);
                setTotalRows(res.data?.data?.total);
                setPerPage(res.data?.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getCampusPageResourseList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, searchStatusCheck, updateData]);

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "resourceName",
            sortField: "resourceName",
            sortable: true,
            // minWidth: "190px",
            cell: (row) => (
                    <div>
                        {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                        <Link
                            className="as-text-blue curser feature-name"
                            data-toggle="modal" 
                            data-target="#updateresource"
                            onClick={() => handleClick(row)}
                            title={row.resourceName}
                        >
                            <span className="textLimit100">{row.resourceName}</span>
                        </Link>
                        {/* </PermissionsGate> */}
                    </div>
            ),
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <button
                            className="btn btn-primary h-auto rounded-circle"
                            title="Update Upload Resource"
                            data-toggle="modal" 
                            data-target="#updateresource"
                            onClick={() => handleClick(row)}
                        >
                            <i className="fal fa-folder-open"></i>
                        </button>

                        <button
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleDelete(row.id)}
                        >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteCampusResousePages(id)
                    .then((res) => {
                        Swal.fire("Deleted!", "Campus Resourse Page has been deleted.", "success");
                        setUpdateData(!updateData);
                    })
                    .catch((error) => console.log(error));
            }
        });
    }

    // TODO Excel
    // TODO Csv
    // TODO Pdf

    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Name"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const campusPagesResourseData = {
            exportStatus: "true",
        };

        GetCampusResourseList(campusPagesResourseData)
            .then((res) => {
                data = res?.data?.data;
                data = data?.map((row) => {
                    return ({
                        ...row,
                        Name: row?.resourceName ? row?.resourceName : "-",
                    })
                });

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data?.map((row) => {

                        return [
                            row.resourceName,
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    return (
        <>
            <div className="my-tickets-info-list Tickets-main-wrap mb-4">
                <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_filter" className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="search"
                                                    className=""
                                                    placeholder="Search"
                                                    aria-controls="assessment-table-main"
                                                    onChange={handleSearchFilter}
                                                    value={debouncedTerm}
                                                />
                                            </label>
                                            <div className="filter-eff filter-data-btn">
                                                <button className="filter-buttons">
                                                    <i className="fal fa-filter"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group">
                                    <div className="reset-btn-group">
                                        <div className="button-reset dropdown-comman">
                                            <button
                                                className="btn btn-primary"
                                                onClick={resetFilter}
                                                title="Reset"
                                            >
                                                <i className="fal fa-redo"></i>Reset
                                            </button>
                                        </div>
                                        <div className="files-export-group">
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("xlsx", "websitelist");
                                                }}
                                                title="Export spreadsheet"
                                            >
                                                <i className="fal fa-file-excel icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("csv", "Students_list");
                                                }}
                                                title="Export CSV"
                                            >
                                                <i className="fal fa-file-csv icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("pdf", "Students_list");
                                                }}
                                                title="Export PDF"
                                            >
                                                <i className="fal fa-file-pdf icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search-filter-div-right">
                            <div className=" filter-search-bar-blk">
                                <div className="add-ticket-blk button-reset dropdown-comman">
                                    <PermissionsGate
                                        RenderError={() => (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                title="Upload Resource"
                                                disabled={loading ? true : false}
                                                data-toggle="modal" data-target="#resource"
                                            >
                                                <i className="fal fa-upload"></i>Upload Resource
                                            </button>
                                        )}
                                        scopes={["sadd"]}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Upload Resource"
                                            disabled={loading ? true : false}
                                            data-toggle="modal" data-target="#resource"
                                        >
                                            <i className="fal fa-upload"></i>Upload Resource
                                        </button>
                                    </PermissionsGate>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        paginationDefaultPage={page}
                        progressPending={loading}
                        data={campusPagesResourseData}
                        progressComponent={<SkeletonTicketList />}
                        defaultSortField={sortkey}
                        defaultSortAsc={false}
                        columns={columns}
                        pagination={true}
                        // noDataComponent={Str.noRecord}
                        onSort={handleSort}
                        sortServer
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                </div>
            </div>

            <div
                className="topic-add-modal modal fade"
                id="updateresource"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content modal-border-update">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    <i className="fal fa-upload"></i> Update Upload Resource
                                </h5>
                                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            </div>
                            <div>
                                <UpdateResourses row={selectedData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="topic-add-modal modal fade"
                id="resource"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content modal-border-update">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    <i className="fal fa-upload"></i> Create Upload Resource
                                </h5>
                                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            </div>
                            <div>
                                <CreateResourses />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resouses
import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Papa from "papaparse";
import { useFormik } from "formik";
import * as XLSX from "xlsx";
import * as Yup from "yup";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Swal from "sweetalert2";
import $ from "jquery";
import PermissionsGate from "../../../utils/permissionGate";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { RenderAssessmentType, RenderLearningMethod } from "../../../utils/CommonGroupingItem";
import { GetAssesmentsDropdown, GetAssesmentsDropdownCancelToken, GetAssesmentsList, GetAssesmentsListCancelToken, SendQuickNotification } from "../../../services/DashBoardServices";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { ColorCode, ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

const AssessmentTable = ({globalReset,filterIntake,filterLearningMethod,filterSchool,filterLecturer}) => {
    const history = useHistory();
    const { type } = useParams();
    const [disabled, setDisabled] = useState(false);
    const [assessmentList, setAssessmentList] = useState();
    const [search, setSearch] = useState("");
    const [showing, setShowing] = useState({ arr: [10], checkObj: {upcomingshowing10 : true} });
    const [course, setCourse] = useState({ arr: [], checkObj: [] });
    const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [assessmentSubmType, setAssessmentSubmType] = useState({ arr: [], checkObj: {} });
    const [assessmentType, setAssessmentType] = useState({ arr: [], checkObj: {} });
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [showCkEditor, setShowCkEditor] = useState(false);
    const [AssessmentDropdown, setAssessmentDropdown] = useState({ course: [], intake: [], learning: [], typeFilter:[], actualTypeFilter: [] });
    const [editorState, setEditorState] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [updateData, setUpdateData] = useState(false);
    const [isDropdownFetched, setIsDropdownFetched] = useState(false)
    const [storedGlobal,setStoredGlobal] = useState([])
    const [isChildReset,setIsChildReset] = useState(false)
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortOrder: "DESC",
        sortKey: "startDate"
    })
    const [notificationFields, setNotificationFields] = useState({
        class_id: "",
        subject: "",
        message: "",
        attachment: {},
        class_name: "",
    });
    useEffect(
        () => {
          setStoredGlobal(JSON.parse(localStorage.getItem("globalDashboardFilters")))
        },[filterIntake,filterLearningMethod]
      )
      useEffect(
        () => {
           if(storedGlobal?.intake?.arr.length != 0 && storedGlobal?.intake?.checkObj != undefined && storedGlobal?.intake.checkObj != null){
            var newObj = Object.keys(storedGlobal.intake?.checkObj).map(d => d.replace('globalIntakeintake',`${type}intake`).concat(` ${storedGlobal.intake.checkObj[d]}`)).map(d => d.split(" "))
            var jjj = newObj.map(d => d[0])
            var iii = newObj.map(d => d[1])
            var newestObj = jjj.map((d,i) => ` ${d} : ${iii[i]}`)
            var finalObj = {}
            var key;
            var value;
            newestObj.forEach(item => {
              var parts = item.split(":")
              key = parts[0]
              value = parts[1]
              key = key.trim()
              value = value.trim()
              if(value == "true"){value = true}
              if(value == "false"){value = false}
              finalObj[key] = value
            })
            let arrayMade = [];
            for(let d of storedGlobal.intake.arr){
              for(let data of AssessmentDropdown.intake){
                if(d == data.value){
                  arrayMade.push(d)
                }
              }
            }
            setIntake({...intake,arr : arrayMade,checkObj:finalObj})
          }
           else if(globalReset){
            var newObj = intake.checkObj
            for (let key in newObj) {
              newObj[key] = false;
            }
            setIntake({arr:[],checkObj: newObj})
          }
           
        },[storedGlobal,filterIntake,globalReset,AssessmentDropdown]
      )
      useEffect(
        () => {
           if(storedGlobal?.learningMethod?.arr.length != 0 && storedGlobal?.learningMethod?.checkObj != undefined && storedGlobal?.learningMethod.checkObj != null){
            var newObj = Object.keys(storedGlobal.learningMethod?.checkObj).map(d => d.replace('globalLearninglearning',`${type}learning`).concat(` ${storedGlobal.learningMethod.checkObj[d]}`)).map(d => d.split(" "))
            var jjj = newObj.map(d => d[0])
            var iii = newObj.map(d => d[1])
            var newestObj = jjj.map((d,i) => ` ${d} : ${iii[i]}`)
            var finalObj = {}
            var key;
            var value;
            newestObj.forEach(item => {
              var parts = item.split(":")
              key = parts[0]
              value = parts[1]
              key = key.trim()
              value = value.trim()
              if(value == "true"){value = true}
              if(value == "false"){value = false}
              finalObj[key] = value
            })
            let arrayMade = [];
            for(let d of storedGlobal.learningMethod.arr){
              for(let data of AssessmentDropdown.learning){
                if(d == data.value){
                  arrayMade.push(d)
                }
              }
            }
            setLearningMethod({...learningMethod,arr : arrayMade,checkObj:finalObj})
          }
           else if(globalReset){
            var newObj = learningMethod.checkObj
            for (let key in newObj) {
              newObj[key] = false;
            }
            setLearningMethod({arr:[],checkObj: newObj})
          }
           
        },[storedGlobal,filterLearningMethod,globalReset,AssessmentDropdown]
      )
    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateAssessmentsDashboardStartsToday"));
        if (storedFilters) {
            setCourse(storedFilters.course);
            setLearningMethod(storedFilters.learningMethod);
            setIntake(storedFilters.intake);
            setAssessmentSubmType(storedFilters.assessmentSubmType);
            setAssessmentType(storedFilters.assessmentType);
            setAssessmentDropdown(storedFilters.AssessmentDropdown);
        }
      }, []);
      useEffect(() => {
        const filterState = {
            course,
            learningMethod,
            intake,
            assessmentType,
            assessmentSubmType,
            AssessmentDropdown,
            showing
        };
        localStorage.setItem("filterStateAssessmentsDashboardStartsToday", JSON.stringify(filterState));
      }, [ course, learningMethod, intake,showing, assessmentType, assessmentSubmType, AssessmentDropdown]);
    useEffect(() => {
        const cancelTokenSources = [];
        const getData = async () => {
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });            

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                let data = {
                    viaAssesmentTime: [type]
                }

                let res = await GetAssesmentsDropdownCancelToken(data, source.token)
                if (res.status == 200) {                    
                    setAssessmentDropdown({ course: [], intake: [], learning: [], typeFilter : [] })
                    setAssessmentDropdown({ ...res.data, course: res.data.subject, intake: res.data.yearsAndIntaks, learning: res.data.intakeType, typeFilter : res.data.typeFilter, actualTypeFilter: res.data.actualTypeFilter })
                    setIsDropdownFetched(true)
                }
            } catch (error) {
                console.log("err :", error)
            }
        }

        getData()

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [type])

    useEffect(() => {
        const cancelTokenSources = [];        
        const getData = async () => {            
            setLoading(true)
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });            

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            if(isDropdownFetched){

                try {
                    const res = await GetAssesmentsListCancelToken({
                    page: tableState.page,
                    perPage: tableState.perPage,
                    viaCourse: course.arr,
                    viaIntake: intake.arr,
                    viaStudytype: learningMethod.arr,
                    globalIntake : filterIntake.newValue?.length > 0 ? filterIntake.newValue : [],
                    globalLearningMethod : filterLearningMethod.newValue?.length > 0 ? filterLearningMethod.newValue : [],
                    globalSchool: filterSchool.newValue?.length > 0 ? filterSchool.newValue : [],
                    globalLecturer: filterLecturer.newValue?.length > 0 ? filterLecturer.newValue : [],
                    viaStatus: [],
                    viaSearch: search,
                    key: tableState.sortKey,
                    sort: tableState.sortOrder,
                    viaAssesmentTime: [type],
                    showing: showing.arr,
                    exportStaus: "false",
                    typeFilter: assessmentSubmType.arr,
                    actualTypeFilter: assessmentType.arr
                }, source.token);
                if (res.status === 200) {
                    setAssessmentList(res.data?.result);
                    setTotalRows(res.data?.Pagination?.total);
                    setLoading(false);
                }
            } catch (error) {
                // setLoading(false);
                console.log(error);
            }
        }
        }
        getData();
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [
        search,
        course,
        learningMethod,
        filterIntake,
        filterLearningMethod,
        filterSchool,
        filterLecturer,
        intake,
        showing,
        tableState,
        updateData,
        assessmentType,
        assessmentSubmType,
        isDropdownFetched
    ]);

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortable: true,
            sortField: "name",
            cell: (row) => (
                row.disable_assessment=="1" ? 
                    <span className="feature-name">
                        <span className="textLimit100">{row.name}</span>
                    </span>
                 : <Link
                    title={row.name}
                    to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/Details/${row.id}`}
                    className="as-text-blue curser feature-name"
                >
                    <span className="textLimit100">{row.name ? row.name : "-"}</span>
                </Link>
            ),
        },
        {
            name: "Course",
            selector: "courseName",
            sortable: true,
            sortField: "course",
            cell: (row) => (
                <Link
                    title={row.courseName ? row.courseName : "-"}
                    className="as-text-blue curser feature-name"
                    to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
                >
                    <span className="textLimit100">{row.courseName ? row.courseName : "-"}</span>
                </Link>
            ),
        },
        {
            name: "Learning Method",
            selector: "studyType",
            sortable: true,
            sortField: "learningmethod",
            cell: (row) => (
                <p className="learningMethodText">
                    {" "}
                    {row.studyType ? RenderLearningMethod(row.studyType).html : "-"}{" "}
                </p>
            ),
        },
        {
            name: "Assessment Type",
            selector: "assignment_type",
            // sortable: true,
            sortField: "assignment_type",
            cell: (row) => (row.assignment_type ? RenderAssessmentType(row?.ass_type).html : "-"),
        },
        {
            name: "Assessment Submission Type",
            selector: "assignment_type",
            // sortable: true,
            sortField: "assignment_type",
            cell: (row) => (row.assignment_type ? RenderAssessmentType(row?.assignment_type).html : "-"),
        },
        {
            name: "Start",
            selector: "assignmentDateOpen",
            sortable: true,
            sortField: "assignmentDateOpen",
            cell: (row) => (
                <span className="feature-name">
                    {row.assignmentDateOpen ? (
                        <div className="dateTime">
                            <span className="right-space textLimit10">
                                {moment.unix(row.assignmentDateOpen).format(TABLE_DATE_FORMAT)}
                            </span>
                            <span className="textLimit100">{moment.unix(row.assignmentDateOpen).format(TABLE_TIME_FORMAT)}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </span>
            ),
        },
        {
            name: "End",
            selector: "assignmentDateClose",
            sortable: true,
            sortField: "assignmentDateClose",
            cell: (row) => (
                <span className="feature-name">
                    {row.assignmentDateClose ? (
                        <div className="dateTime">
                            <span className="right-space textLimit100">
                                {moment.unix(row.assignmentDateClose).format(TABLE_DATE_FORMAT)}
                            </span>
                            <span className="textLimit100">{moment.unix(row.assignmentDateClose).format(TABLE_TIME_FORMAT)}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </span>
            ),
        },
        {
            name: "Intake",
            selector: "intake",
            sortable: true,
            sortField: "intake",
            cell: (row) => (
                <p>
                    {row.intakeYear || row.number ? (
                        <>
                            {" "}
                            {row.intakeYear ? row.intakeYear : ""}-{row.number ? row["number"] : ""}
                        </>
                    ) : (
                        "-"
                    )}
                </p>
            ),
        },
        {
            name: "Marker",
            selector: "markerList",
            sortable: true,
            sortField: "marker",
            cell: (row) => row.markerList && row.markerList.length ? row.markerList.map(item => {
                return (
                    <div className="assigned-title-block-new">
                        <div className="assigned-title-itm">
                            <span
                                className={
                                    "assigned-title-blk  name-icon cat-dark-red " +
                                    ColorRender(item?.Firstname + item?.Lastname)
                                }
                            >
                                {item.picture_me ? (
                                    <img
                                        src={`${IMAGE_URL}/${item.picture_me.replaceAll(
                                            "/home/myaie/public_html/",
                                            ""
                                        )}`}
                                        alt="AIE"
                                    />
                                ) : (
                                    InitialRender([
                                        item?.Firstname,
                                        item?.Lastname,
                                    ])
                                )}
                                <span className={`profile-box-2-status ${item.is_active.toString() ? item.is_active ? "Online" : "Offline" : "Offline"}`}>
                                    <i className="fas fa-circle"></i>
                                </span>
                                <Hover
                                    firstName={item?.Firstname}
                                    lastName={item?.Lastname}
                                    photo={item.picture_me}
                                    email={item.Email}
                                    mobile={item.Mobile}
                                    right={true}
                                    role={""}
                                    status={item.Status}
                                    activity_status={item.is_active.toString()}
                                />
                            </span>
                            <span
                                className="as-text-blue curser feature-name"
                                title={item?.Firstname + " " + item?.Lastname}
                                onClick={() =>
                                    history.push(
                                        `/systemAdministration/userManagement/open/${item.UserID}/aboutYou`
                                    )
                                }
                            >
                                <span className="textLimit100">{item?.Firstname ? item?.Firstname + " " + item?.Lastname : "-"}</span>
                            </span>
                        </div>
                    </div>
                )
            }) : "-"
        },
        {
            name: "Moderator",
            selector: "Moderator",
            sortable: true,
            sortField: "moderator",
            cell: (row) => (
                <>
                    {row.moderatorName && row.moderatorName.trim() ? (
                        <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                                <span
                                    className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("lecturer")
                                    }
                                >
                                    {row.moderatorProfile ? (
                                        <img
                                            src={`${IMAGE_URL}/${row.moderatorProfile.replaceAll(
                                                "/home/myaie/public_html/",
                                                ""
                                            )}`}
                                            alt="AIE"
                                        />
                                    ) : (
                                        InitialRender([
                                            row.moderatorName.trim(),
                                            // row.moderatorName.trim().split(" ")[1],
                                        ])
                                    )}
                                    <span className={`profile-box-2-status ${row.moderator_activity_status ? row.moderator_activity_status.toLowerCase() == "online" ? "Online" : row.moderator_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                        firstName={row.moderatorName.trim()}
                                        // lastName={row.moderatorName.trim().split(" ")[1]}
                                        photo={row.moderatorProfile}
                                        email={row.moderatorEmail}
                                        mobile={row.moderatorContact}
                                        right={true}
                                        role={"lecturer"}
                                        status={row.moderator_status}
                                        activity_status={row.moderator_activity_status}
                                    />
                                </span>
                                <span
                                    className="as-text-blue curser feature-name"
                                    title={row?.moderatorName?.trim()}
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${row.moderatorID}/aboutYou`
                                        )
                                    }
                                >
                                    <span className="textLimit100">{row?.moderatorName?.trim() ? row?.moderatorName?.trim() : "-"}</span>
                                </span>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Due Date",
            selector: "marking_due_date",
            sortable: true,
            sortField: "",
            cell: (row) => (
                <span className="feature-name">
                    {row.marking_due_date ? (
                        <div className="dateTime">
                            <span className="right-space textLimit100">
                                {moment.unix(row.marking_due_date).format(TABLE_DATE_FORMAT)}
                            </span>
                            <span className="textLimit100">{moment.unix(row.marking_due_date).format(TABLE_TIME_FORMAT)}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </span>
            ),
        },
        (type === "Making_due_today" || type === "Making_over_due") && {
            name: "Submission",
            selector: "submission_ratio",
            sortable: true,
            sortField: "",
            cell: (row) => (
                <>
                    {row.submission_ratio ? "Ratio: " + row.submission_ratio : "-"} <br />
                    {row.totle_submission ? "Total: " + row.totle_submission : "-"}
                </>
            ),
        },

        type === "Making_over_due" && {
            name: "#Days Overdue",
            selector: "daysofoverdues",
            sortable: true,
            sortField: "daysofoverdues",
            cell: (row) => <>{row.daysOfOverdues ? row.daysOfOverdues : "-"}</>,
        },
        {
            name: "Status",
            selector: "status",
            sortable: false,
            sortField: "",
            cell: (row) => (
                <>
                    {(type === "Upcoming" ||
                        type === "Open" ||
                        type === "Today") && (
                            <>
                                <span title="Doc-uploaded">
                                    {row?.quiz_id ? "Quiz: " : "Doc: "}
                                    {row?.quiz_id
                                        ? (<span className="icon-success">
                                            <i className="fal fa-check"></i>
                                        </span>)
                                        : row.doc_uploaded.toLowerCase() == "yes" ? (
                                            <span className="icon-success">
                                                <i className="fal fa-check"></i>
                                            </span>
                                        ) : (
                                            <span className="icon-danger">
                                                <i className="fal fa-times"></i>
                                            </span>
                                        )}
                                </span>
                                <br />
                            </>
                        )}

                    <span className={ColorRender("Status" + row.status)}>
                        {row.status}
                    </span>
                </>
            ),
        },
        {
            name: "Marking Status ",
            selector: "markingStatus",
            sortable: true,
            sortField: "",
            cell: (row) => (
                <p>
                    {" "}
                    {row.markingStatus
                        ? ColorCode("marking" + row.markingStatus)
                            ? ColorCode("marking" + row.markingStatus)
                            : "-"
                        : "-"}{" "}
                </p>
            ),
        },
        {
            name: "Action",
            selector: "Action",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        {row.disable_assessment=="1" ?
                         <button
                         className="btn btn-primary rounded-circle"
                         title="Open"
                         disabled={row.disable_assessment=="1"}
                         >
                             <i className="fal fa-folder-open"></i>
                         </button>
                        : <Link
                            className="btn btn-primary rounded-circle"
                            to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/Details/${row.id}`}
                            title="Open"
                        >
                            <i className="fal fa-folder-open"></i>
                        </Link>}
                        {/* DO NOT DELETE BELOW COMMENTED BLOCK */}
                        {/* <button
              className="ml-1 btn btn-primary rounded-circle"
              title="Quick Notification"
              onClick={() => {
                setShowCkEditor(true);
                setSelectedFile("");
                setEditorState("")
                $("#notifications12").modal("show");
                setNotificationFields((prevState) => {
                  return {
                    ...prevState,
                    class_id: row.course_id,
                    class_name: row.courseName,
                  };
                });
              }}
              target="_blank"
            >
              <i className="fal fa-bell"></i>
            </button> */}
                    </div>
                </div>
            ),
        },
    ]);

    const exportData = (fileType, fileName) => {
        let data;
        const header = [
            "Name",
            "Course",
            "Learning Method",
            "Assessment Type",
            "Start",
            "End",
            "Intake",
            "Marker",
            "Moderator",
            "Due Date",
            "Submission Ratio(%)",
            "Total Submission",
            "Days Overdue",
            "Doc Uploaded",
            "Status",
            "Marking Status",
        ];
        if (
            !type === "Upcoming" &&
            !type === "Today" &&
            !type === "Open"
        ) {
            header.splice(header.indexOf("Doc Uploaded"), 1);
        }
        if (
            !type === "Making_due_today" &&
            !type === "Making_over_due"
        ) {
            header.splice(header.indexOf("Submission Ratio(%)"), 2);
        }
        if (!type === "Making_over_due") {
            header.splice(header.indexOf("Days Overdue"), 1);
        }

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        GetAssesmentsList({
            viaCourse: course.arr,
            viaIntake: intake.arr,
            viaStudytype: learningMethod.arr,
            typeFilter: assessmentSubmType.arr,
            actualTypeFilter: assessmentType.arr,
            globalIntake : filterIntake.newValue?.length > 0 ? filterIntake.newValue : [],
            globalLearningMethod : filterLearningMethod.newValue?.length > 0 ? filterLearningMethod.newValue : [],
            globalSchool: filterSchool.newValue?.length > 0 ? filterSchool.newValue : [],
            globalLecturer: filterLecturer.newValue?.length > 0 ? filterLecturer.newValue : [],
            viaStatus: [],
            viaSearch: search,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            viaAssesmentTime: [type],
            exportStaus: "true",
            showing: showing.arr,
        })
            .then((res) => {
                data = res.data.result;
                data = data?.map((row) => ({
                    Id: row.id ? row.id : "-",
                    Name: row.name ? row.name : "-",

                    Course: row.courseName ? row.courseName : "-",
                    "Learning Method": row.studyType ? RenderLearningMethod(row.studyType).text : "-",
                    "Assessment Type": row.ass_type ? RenderAssessmentType(row.ass_type).text : "-",
                    "Assessment Submission Type": row.assignment_type ? RenderAssessmentType(row.assignment_type).text : "-",
                    "Start": row.assignmentDateOpen
                        ? `${moment.unix(row.assignmentDateOpen).format(TABLE_DATE_TIME_FORMAT)}`
                        : "-",
                    "End": row.assignmentDateClose
                        ? `${moment.unix(row.assignmentDateClose).format(TABLE_DATE_TIME_FORMAT)}`
                        : "-",
                    Intake: `${row.intakeYear}-${row.number}`,
                    Marker: row["markerName"] ? row["markerName"] : "-",
                    Moderator: row["moderatorName"] ? row["moderatorName"] : "-",
                    "Due Date": row.marking_due_date
                        ? moment.unix(row.marking_due_date).format(TABLE_DATE_TIME_FORMAT)
                        : "-",
                    "Submission Ratio(%)": row?.submission_ratio
                        ? row?.submission_ratio
                        : "-",
                    "Total Submission": row.totle_submission ? row.totle_submission : "-",
                    "Days Overdue": row.daysOfOverdues ? row.daysOfOverdues : "-",
                    "Doc Uploaded": row.doc_uploaded,
                    Status: row.status === "open" ? "Open" : "Closed",
                    "Marking Status": row.markingStatus ? row.markingStatus : "-",
                }));

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["Name"],
                            row["Course"],
                            row["Learning Method"],
                            row["Assessment Type"],
                            row["Start"],
                            row["End"],
                            row["Intake"],
                            row["Marker"],
                            row["Moderator"],
                            row["Due Date"],
                            row["Submission Ratio(%)"],
                            row["Total Submission"],
                            row["Days Overdue"],
                            row["Doc Uploaded"],
                            row["Status"],
                            row["Marking Status"],
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        // columnStyles: {
                        //     0: {
                        //         columnWidth: 15,
                        //     },
                        //     1: {
                        //         columnWidth: 20,
                        //     },
                        //     2: {
                        //         columnWidth: 10,
                        //     },
                        //     3: {
                        //         columnWidth: 10,
                        //     },
                        //     4: {
                        //         columnWidth: 15,
                        //     },
                        //     5: {
                        //         columnWidth: 15,
                        //     },
                        //     6: {
                        //         columnWidth: 10,
                        //     },
                        //     7: {
                        //         columnWidth: 20,
                        //     },
                        //     8: {
                        //         columnWidth: 15,
                        //     },
                        //     9: {
                        //         columnWidth: 15,
                        //     },
                        //     10: {
                        //         columnWidth: 15,
                        //     },
                        //     11: {
                        //         columnWidth: 15,
                        //     },
                        //     12: {
                        //         columnWidth: 10,
                        //     },
                        //     13: {
                        //         columnWidth: 10,
                        //     }
                        // },
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => console.error(err));
    };

    function cancelQuickNotification() {
        setNotificationFields({ ...notificationFields });
        setEditorState("");
        setSelectedFile("");
        formik.resetForm();
        setShowCkEditor(false);
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            class_name: notificationFields.class_name,
            subject: "",
            attachment: "",
            content: "",
        },
        validationSchema: Yup.object({
            subject: Yup.string().required("Subject is required"),
            attachment: Yup.string().required("Attachment is required").trim(),
            content: Yup.string()
                .test("required", "Content is required", (value) => {
                    if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
                        return false
                    }
                    if (!editorState) {
                        return false
                    }
                    return true;
                })
                .trim()
        }),
        onSubmit: (values) => {
            setDisabled(true);

            let formData = new FormData();
            formData.append("subject", values.subject);
            formData.append("message", editorState);
            formData.append("attachment", values.attachment);
            formData.append("class_id", notificationFields.class_id);
            formData.append("isEmail", 1);
            formData.append("isPushnotificationActive", 1);

            SendQuickNotification(formData)
                .then((data) => {
                    if (data.status == 200) {
                        setDisabled(false);
                        setNotificationFields({
                            class_id: "",
                            subject: "",
                            message: "",
                            attachment: {},
                            class_name: "",
                        });
                        $("#notifications12").modal("hide");
                        formik.resetForm("");
                        setEditorState("");
                        setSelectedFile("")
                        setShowCkEditor(false)
                        Swal.fire("Notification!", "Successfully notified.", "success");
                        setUpdateData(!updateData)
                        // getDashboard();
                    }
                })
                .catch((error) => {
                    console.log("error ---> ", error);
                });
        },
    });

    return (
        <>
            <div className="tabs-wrap">            
                <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                <div className="table_tab_section_main"><i className="fa fa-calendar-check"></i> Submissions</div>  
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Today");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Today" ? "active" : ""
                                }`}
                            id="pills-tab2"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                        >
                            <i className="fal fa-calendar"></i>Opens Today 
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Open");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Open" ? "active" : ""
                                }`}
                            id="pills-tab3"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab13"
                            aria-selected="true"
                        >
                            <i className="fal fa-calendar"></i>Open
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            // setViaAssesmentTime(["Upcoming"]);
                            history.push("/home/assessment/upcoming");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "upcoming" ? "active" : ""
                                }`}
                            id="pills-tab1"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                        >
                            <i className="fal fa-arrow-circle-right"></i>
                            Upcoming
                        </a>
                    </li>
                    <div className="table_divider"> <i className="fa fa-user-edit"></i> Marking</div>                                     
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Making_due_today");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Making_due_today" ? "active" : ""
                                }`}
                            id="pills-tab17"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab17"
                            aria-selected="true"
                        >
                            <i className="fal fa-calendar-edit"></i>
                            Due Today
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Marking_pending");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Marking_pending" ? "active" : ""
                                }`}
                            id="pills-tab19"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab19"
                            aria-selected="true"
                        >
                            <i className="fa fa-calendar"></i>
                            Open
                        </a>
                    </li>   
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Making_over_due");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Making_over_due" ? "active" : ""
                                }`}
                            id="pills-tab18"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab18"
                            aria-selected="true"
                        >
                            <i className="fal fa-calendar-exclamation"></i>
                            Overdue
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Upcoming_marking");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Upcoming_marking" ? "active" : ""
                                }`}
                            id="pills-tab19"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab19"
                            aria-selected="true"
                        >
                            <i className="fa fa-arrow-circle-right"></i>
                            Upcoming
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/assessment/Closed");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "Closed" ? "active" : ""
                                }`}
                            id="pills-tab19"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab19"
                            aria-selected="true"
                        >
                            <i className="fa fa-check-circle"></i>
                            Closed
                        </a>
                    </li>
                </ul>
            </div>
            <DataTableComponent
                data={assessmentList}
                columns={columns.filter(item => item)}
                setSearch={setSearch}
                state={tableState}
                setState={setTableState}
                totalRows={totalRows}
                loading={loading}
                exportFunction={exportData}
                setIsChildReset={setIsChildReset}
                exportFileName="Assessment_list"
                isInsidePopUp
                filters={[
                    (type === "upcoming" || type === "Upcoming_marking") && {
                        filterName: "Showing",
                        optionArr: [
                            {
                                value: "10",
                                label: "10 days"
                            },
                            {
                                value: "20",
                                label: "20 days"
                            },
                            {
                                value: "30",
                                label: "30 days"
                            },
                            {
                                value: "-1",
                                label: "All"
                            },
                        ],
                        state: showing,
                        setState: setShowing,
                        uniqueId: type,
                        singleSelect: true
                    },
                    {
                        filterName: "Course",
                        optionArr: AssessmentDropdown?.course,
                        state: course,
                        setState: setCourse,
                        uniqueId: type,
                        isSearchFilter: true
                    },
                    {
                        filterName: "Learning Method",
                        optionArr: storedGlobal.learningMethod?.arr.length ? AssessmentDropdown.learning.filter(element => storedGlobal.learningMethod.arr.includes(element.value)) : AssessmentDropdown.learning,
                        state: learningMethod,
                        setState: setLearningMethod,
                        uniqueId: type,
                        renderLabelFunction: RenderLearningMethod,
                    },
                    {
                        filterName: "Intake",
                        optionArr: storedGlobal.intake?.arr.length ? AssessmentDropdown.intake.filter(element => storedGlobal.intake.arr.includes(element.value)) : AssessmentDropdown.intake ,
                        state: intake,
                        setState: setIntake,
                        uniqueId: type,
                        isOptionReversed: true,
                    },
                    {
                        filterName: "Assessment Type",
                        optionArr: AssessmentDropdown?.actualTypeFilter,
                        state: assessmentType,
                        setState: setAssessmentType,
                        uniqueId: type,
                        renderLabelFunction: RenderAssessmentType,
                    },
                    {
                        filterName: "Assessment Submision Type",
                        optionArr: AssessmentDropdown?.typeFilter,
                        state: assessmentSubmType,
                        setState: setAssessmentSubmType,
                        uniqueId: type,
                        renderLabelFunction: RenderAssessmentType,
                    }
                ].filter(item => item)}
            />

            {showCkEditor && <div
                className="topic-add-modal modal zindexadd fade show"
                style={{ display: "block" }}
                id="notifications12"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                >
                    <div className="modal-content modal-border-update">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body p-0">

                                <div className="modal-header modal-header-custom">
                                    <h5 className="modal-title">
                                        <i className="fal fa-bell"></i> Quick Notifications
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            formik.resetForm()
                                            $("#notifications12").modal("hide");
                                            setEditorState("");
                                            setShowCkEditor(false);
                                            setSelectedFile("");
                                            formik.setFieldValue("attachment", "")
                                        }}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>
                                <div className="t p-30px">
                                    <div className="topic-add-modal-content">
                                        <div className="row center-label">
                                            <div className="col-md-12">
                                                <div className="form-group-blk mb-3">
                                                    <label>Class Name</label>
                                                    <input
                                                        type="text"
                                                        className={"form-control form-control-aatch-file"}
                                                        disabled
                                                        title="Class Name"
                                                        value={formik.values.class_name}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group-blk mb-3">
                                                    <label>Subject *</label>
                                                    <input
                                                        type="text"
                                                        title="Subject"
                                                        name="subject"
                                                        className={
                                                            "form-control form-control-aatch-file" +
                                                            (formik.errors.subject && formik.touched.subject
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        value={formik.values.subject}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}

                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group-blk mb-3">
                                                    <label>Content *</label>
                                                    <HtmlInputEditor
                                                        editorState={editorState}
                                                        setEditorState={setEditorState}
                                                        hideSign={true}
                                                        isCKEditor={true}
                                                        isInvalid={
                                                            formik.touched.content &&
                                                            formik.errors.content
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group-blk mb-3">
                                                    <label>Attachment *</label>
                                                    <div className="form-group atttach-file m-0 d-flex align-items-center">
                                                        <label
                                                            className={
                                                                formik.errors.attachment &&
                                                                formik.touched.attachment &&
                                                                "file-req is-invalid"
                                                            }
                                                        >
                                                            <i className="fal fa-paperclip"></i>
                                                            <span>Add Attachment</span>
                                                            <input
                                                                type="file"
                                                                className="form-control  form-control-aatch-file"
                                                                onChange={(e) => {
                                                                    if (e.target.files.length) {
                                                                        formik.setFieldValue(
                                                                            "attachment",
                                                                            e.target.files[0]
                                                                        );
                                                                        setSelectedFile(URL.createObjectURL(e.target.files[0]));
                                                                    }
                                                                }}
                                                                placeholder="File"
                                                            />
                                                        </label>
                                                    </div>
                                                    {selectedFile ? (
                                                        <Link
                                                            className="mt-1 d-flex"
                                                            to={{ pathname: selectedFile }}
                                                            target="_blank"
                                                        >
                                                            {selectedFile}
                                                        </Link>
                                                    ) : null}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group form-group-save-cancel mt-3">
                                        <PermissionsGate
                                            scopes={["cedit"]}
                                            errorProps={{ disabled: true }}
                                        >
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={disabled}
                                            >
                                                {disabled ? (
                                                    <i className="fas fa-cog fa-spin"></i>
                                                ) : (
                                                    <i className="fal fa-check"></i>
                                                )}
                                                Send
                                            </button>
                                        </PermissionsGate>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                                setEditorState("");
                                                cancelQuickNotification();
                                            }}
                                        >
                                            <i className="fal fa-times"></i>
                                            Close
                                        </button>
                                        {Object.keys(formik.values).map(key => {
                                            if (formik.touched[key] && formik.errors[key]) {
                                                return (
                                                    <div className="invalid-feedback d-block">
                                                        {formik.errors[key]}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default AssessmentTable;

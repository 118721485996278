import React from 'react'
import { Level } from '../components/systemadministration/usermanagement/CheckRole';
const stc = require('string-to-color');

// only for Learning Method or Study Type
export const RenderLearningMethod = (data, origin) => {
    switch (data ? data.toString().replace("-", "").replace(" ", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        case "part_time_online":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "full_time_online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "full_time_hybrid":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "correspondence":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence", origin });
        case "online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part time":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "2":
            return UIRender({ color: "cat-green", text: "Diploma", type: "learning method", origin });
        case "4":
            return UIRender({ color: "cat-orange", text: "Bachelor’s Degree", type: "learning method", origin });
        case "5":
           return UIRender({ color: "cat-skyblue", text: "Honours Degree", type: "learning method", origin });
        case "3":
           return UIRender({ color: "cat-red", text: "Higher Certificate", type: "learning method", origin });
        case "7":
           return UIRender({ color: "cat-purple", text: "Learning Path", type: "learning method", origin });
        default:
            return UIRender({ color: "N/A", text: "-", type: "learning method", origin });
    }
};

//course Delivery 
export const RenderDeliveryMethod = (data, origin) => {
    switch (data ? data.toString().replace("-", "").replace(" ", "").toLowerCase() : "") {
        case "scheduled":
            return UIRender({ color: "cat-blue", text: "Instructor-Led Classes", type: "delivery method", origin });
        case "mentored":
            return UIRender({ color: "cat-yellow", text: "Mentored", type: "delivery method", origin });
        case "selfpaced":
            return UIRender({ color: "textdanger", text: "Self-paced", type: "delivery method", origin });
        default:
            return UIRender({ color: "N/A", text: "-", type: "delivery method", origin });
    }
};

export const RenderBookingState = (data, origin) => {

    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "3":
        return UIRender({ color: "cat-red", text: "Active-Closed", type: "state", origin });
        case "1":
            return UIRender({ color: "cat-green", text: "Active-Open", type: "state", origin });
        case "4":
            return UIRender({ color: "cat-blue", text: "Active-Delivered", type: "state", origin });
        case "2":
            return UIRender({ color: "red", text: "Cancelled", type: "state", origin });
        default:
            return UIRender({ color: "N/A", text: data, type: "state", origin });
    }
};

export const RenderSoftphoneType = (data,origin) => {
    
    switch (data != null && data != undefined ? data.toString().replace("-","").toLowerCase() : "") {
        case "1": 
            return UIRender({ color : "cat-outgoing" , text :"Outgoing",type : "type",origin})
        case "3":
            return UIRender({ color : "cat-missed" , text :"Missed",type : "type",origin})
        case "2":
            return UIRender({ color : "cat-incoming" , text :"Incoming",type : "type",origin})
        case "4":
            return UIRender({ color : "cat-purple" , text :"Declined",type : "type",origin})
        default:
            return UIRender({ color: "N/A", text: data, type: "state", origin });
    }
}
export const RenderLearningMethodGradebook = (data, origin) => {

    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        case "part_time_online":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "full_time_online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "full_time_hybrid":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "learning method", origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method", origin });
        case "correspondence":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence", origin });
        case "online":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "learning method", origin });
        case "part time":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "learning method",origin });
        default:
            return UIRender({ color: "N/A", text: "-", type: "learning method", origin });
    }
};
export const RenderFeatureAndBenefits = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "program":
            return UIRender({ color: "skyblue", text: "Program", type: "features And Benefits",origin });
        case "institute":
            return UIRender({ color: "red", text: "Institute", type: "features And Benefits",origin });
        case "faculty":
            return UIRender({ color: "green", text: "Faculty", type: "features And Benefits",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "features And Benefits" });
    }
};

// Only for list with Short course and programme options, DON'T ADD ANY OTHER OPTION
export const RenderSLP = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "programs":
            return UIRender({ color: "cat-blue", text: "Programs", type: "slp",origin });
        case "shortcourse":
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "slp",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "slp" });
    }
};
export const MappingSchool = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "visual_design":           
                return "School of Visual Design, Marketing & Branding";                     
        case "draughting":           
                return "School of Draughting & Technical Design";                    
        case "it":           
                return "School of Information Technology & Data Science";                    
        case "engineering":           
                return "School of Engineering & Science";                     
        case "business":           
                return "School of Business, Entrepreneurship & Finance";                     
        case "architecture":           
                return "School of Architecture & The Built Environment";                     
        case "gdc":           
                return "Greenside Design Center";                    
        case "school of draughting & technical design":           
                return "School of Draughting & Technical Design";                    
        case "school of engineering & science":           
                return "School of Engineering & Science";                    
        case "school of business, entrepreneurship & finance":           
                return "School of Business, Entrepreneurship & Finance";
        case "school of architecture & the built environment":           
                return "School of Architecture & The Built Environment";
        case "greenside design center":           
                return "Greenside Design Center";                    
        case "fg":           
                return "School of Game Design & Development";                    
        case "fst":           
                return "School of Music & Sound Production";                    
        case "School of Game Design & Development":           
                return "School of Game Design & Development";                    
        case "School of Music & Sound Production":           
                return "School of Music & Sound Production";                    
        case "beauty":           
                return "School of Beauty and Health Science";                    
        case "artisans":           
                return "School of Artisans, Trades and Professions";                    
        default:           
                return "--"
    }
}
// Only For School Type
export const RenderSchool = (data, origin) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "visual_design":
      return UIRender({
        color: "cat-skyblue",
        text: "School of Visual Design, Marketing & Branding",
        type: "school",
        origin,
      });
    case "draughting":
      return UIRender({
        color: "cat-greenhex",
        text: "School of Draughting & Technical Design",
        type: "school",
        origin,
      });
    case "it":
      return UIRender({
        color: "cat-blue",
        text: "School of Information Technology & Data Science",
        type: "school",
        origin,
      });
    case "engineering":
      return UIRender({
        color: "cat-yellow",
        text: "School of Engineering & Science",
        type: "school",
        origin,
      });
    case "business":
      return UIRender({
        color: "cat-red",
        text: "School of Business, Entrepreneurship & Finance",
        type: "school",
        origin,
      });
    case "architecture":
      return UIRender({
        color: "cat-brown",
        text: "School of Architecture & The Built Environment",
        type: "school",
        origin,
      });
    case "gdc":
      return UIRender({
        color: "cat-pink",
        text: "Greenside Design Center",
        type: "school",
        origin,
      });
    case "school of draughting & technical design":
      return UIRender({
        color: "cat-greenhex",
        text: "School of Draughting & Technical Design",
        type: "school",
        origin,
      });
    case "school of engineering & science":
      return UIRender({
        color: "cat-yellow",
        text: "School of Engineering & Science",
        type: "school",
        origin,
      });
    case "school of business, entrepreneurship & finance":
      return UIRender({
        color: "cat-red",
        text: "School of Business, Entrepreneurship & Finance",
        type: "school",
        origin,
      });
    case "school of architecture & the built environment":
      return UIRender({
        color: "cat-brown",
        text: "School of Architecture & The Built Environment",
        type: "school",
        origin,
      });
    case "greenside design center":
      return UIRender({
        color: "cat-pink",
        text: "Greenside Design Center",
        type: "school",
        origin,
      });
    case "fg":
      return UIRender({
        color: "cat-pinkshade",
        text: "School of Game Design & Development",
        type: "school",
        origin,
      });
    case "fst":
      return UIRender({
        color: "cat-blueshade",
        text: "School of Music & Sound Production",
        type: "school",
        origin,
      });
    case "School of Game Design & Development":
      return UIRender({
        color: "cat-pinkshade",
        text: "School of Game Design & Development",
        type: "school",
        origin,
      });
    case "School of Music & Sound Production":
      return UIRender({
        color: "cat-blueshade",
        text: "School of Music & Sound Production",
        type: "school",
        origin,
      });
    case "beauty":
      return UIRender({
        color: "cat-pink",
        text: "School of Beauty and Health Science",
        type: "school",
        origin,
      });
    case "artisans":
      return UIRender({
        color: "cat-brown",
        text: "School of Artisans, Trades and Professions",
        type: "school",
        origin,
      });
    default:
      return UIRender({
        color: "NA",
        text: "--",
        type: "school",
      });
  }
};

//Only for Progromme Type
export const RenderProgrammeType = (data, origin) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "online":
      return UIRender({
        color: "cat-yellowshade",
        text: "Online",
        type: "programme",
        origin,
      });

    case "Diploma":
      return UIRender({
        color: "cat-green",
        text: "Diploma",
        type: "programme",
        origin,
      });

    case "1":
      return UIRender({
        color: "cat-blue",
        text: "Certificate",
        type: "programme",
        origin,
      });

    case "2":
      return UIRender({
        color: "cat-green",
        text: "Diploma",
        type: "programme",
        origin,
      });

    case "3":
      return UIRender({
        color: "cat-red",
        text: "Higher Certificate",
        type: "programme",
        origin,
      });

    case "4":
      return UIRender({
        color: "cat-orange",
        text: "Bachelor’s Degree",
        type: "programme",
        origin,
      });

    case "5":
      return UIRender({
        color: "cat-skyblue",
        text: "Honours Degree",
        type: "programme",
        origin,
      });

        case "6":
            return UIRender({ color: "cat-brown", text: "Master’s Degree", type: "programme", origin });
        
        case "7":
            return UIRender({ color: "cat-purple", text: "Learning Path", type: "programme", origin });

    case "8":
      return UIRender({
        color: "cat-red",
        text: "Occupational Certificate",
        type: "programme",
        origin,
      });

    case "Certificate":
      return UIRender({
        color: "cat-green",
        text: "Certificate",
        type: "programme",
        origin,
      });

    case "higherCertificate":
      return UIRender({
        color: "cat-green",
        text: "Higher Certificate",
        type: "programme",
        origin,
      });

    case "bachelorsDegree":
      return UIRender({
        color: "cat-green",
        text: "Bachelor's Degree",
        type: "programme",
        origin,
      });

    case "honoursDegree":
      return UIRender({
        color: "cat-green",
        text: "Honours Degree",
        type: "programme",
        origin,
      });

    case "mastersDegree":
      return UIRender({
        color: "cat-green",
        text: "Masters Degree",
        type: "programme",
        origin,
      });

    default:
      return UIRender({
        color: "cat-green",
        text: "Masters Degree",
        type: "programme",
        origin,
      });
  }
};

export const RenderTypeOfProgramme = (data) =>{
    console.log("Debug: Input data:", data);


  return UIRender({ data, type: "programme", origin });
};

//Only for Course Type
export const RenderCourseType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "scheduled":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "mentored":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "information":
            return UIRender({ color: "cat-blue", text: "Academic", type: "course type",origin });

        case "orientation":
            return UIRender({ color: "cat-green", text: "Orientation", type: "course type",origin });

        case "shortcourse":
            return UIRender({ color: "cat-yellow", text: "SLP", type: "course type",origin });

        case "short course":
            return UIRender({ color: "cat-yellow", text: "SLP", type: "course type",origin });

        case "rereg_shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });

    default:
      return UIRender({ color: "NA", text: "-", type: "course type" });
  }
};



// only for Correspondence
export const RenderCorrespondence = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "full":
            return UIRender({ color: "cat-skyblue", text: "Full-Time, Hybrid", type: "correspondence",origin });
        case "fulltime":
            return UIRender({ color: "cat-skyblue", text: "Full-Time, Hybrid", type: "correspondence",origin });
        case "part":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "parttime":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "fulltimecontact":
            return UIRender({ color: "cat-skyblue", text: "Full Time, Hybrid", type: "correspondence",origin });
        case "parttimeonline":
            return UIRender({ color: "cat-blue", text: "Part-Time, Online", type: "correspondence",origin });
        case "cores":
            return UIRender({ color: "cat-blue", text: "Part Time, Online", type: "correspondence",origin });
        case "fulltimeonline":
            return UIRender({ color: "cat-lightblue", text: "Full Time, Online", type: "correspondence",origin });
        case "selfplaced":
            return UIRender({ color: "cat-red", text: "Self-Paced", type: "learning method", origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "correspondence" });
    }
};
//only for registration list type
export const RenderStudentType = (data) => {
    switch (data) {
        case "south-african-citizen":
            return UIRender({ color: "cat-skyblue", text: "South African Citizen", type: "studentType"});
        case "international-student":
            return UIRender({ color: "cat-red", text: "International Student", type: "studentType" });
        default:
            return UIRender({ color: "black", text: "N/A", type: "studentType" });
    }
};

// only for programme or course type
export const RenderProgrammeOrCourseType = (data, origin) => {
    switch (data) {
        case "shortcourse":
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "proogrammeOrCourseType", origin });
        case "programs":
            return UIRender({ color: "cat-blue", text: "Programs", type: "proogrammeOrCourseType", origin });
        case "1":
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "proogrammeOrCourseType", origin });
        case "0":
            return UIRender({ color: "cat-blue", text: "Programs", type: "proogrammeOrCourseType", origin });
        case 1 :
            return UIRender({ color: "cat-yellowshade", text: "Short Course", type: "proogrammeOrCourseType", origin });
        case 0 :
            return UIRender({ color: "cat-blue", text: "Programs", type: "proogrammeOrCourseType", origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "proogrammeOrCourseType" });
    }
};

//only for Ticket Level
export const RenderTicketLevel = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "1":
            return UIRender({ color: "cat-green", text: "1", type: "ticketLevel",origin });
        case "2":
            return UIRender({ color: "cat-blue", text: "2", type: "ticketLevel",origin });
        case "3":
            return UIRender({ color: "cat-yellow", text: "3", type: "ticketLevel",origin });
        case "4":
            return UIRender({ color: "cat-red", text: "4", type: "ticketLevel",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "ticketLevel" });
    }
};


//only for Status in pmc table
export const RenderPMCStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "new":
            return UIRender({ color: "skyblue", text: "New", type: "pmcStatus", backgroundColorHex: "#e0ffff", colorHex: "#40e0d0",origin });
        case "draft":
            return UIRender({ color: "red", text: "Draft", type: "pmcStatus", backgroundColorHex: "#ffaaaa", colorHex: "#ff0000",origin });
        case "final":
            return UIRender({ color: "green", text: "Final", type: "pmcStatus", backgroundColorHex: "#d5ffd9", colorHex: "#00c915",origin });
        case "approved":
            return UIRender({ color: "yellowshade", text: "Approved", type: "pmcStatus", backgroundColorHex: "#e7e8d7", colorHex: "#abb072",origin });
        case "inactive":
            return UIRender({ color: "blue", text: "Inactive", type: "pmcStatus", backgroundColorHex: "#e0edff", colorHex: "#1899d2",origin });
        case "active":
            return UIRender({ color: "blue", text: "Inactive", type: "pmcStatus", backgroundColorHex: "#e0edff", colorHex: "#1899d2",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "pmcStatus" });
    }
};

//only for Status in quiz table
export const RenderQuizStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "new":
            return UIRender({ color: "skyblue", text: "New", type: "quiz", backgroundColorHex: "#e0ffff", colorHex: "#40e0d0",origin });
        case "draft":
            return UIRender({ color: "red", text: "Draft", type: "quiz", backgroundColorHex: "#ffaaaa", colorHex: "#ff0000",origin });
        case "active":
            return UIRender({ color: "green", text: "Active", type: "quiz", backgroundColorHex: "#d5ffd9", colorHex: "#00c915",origin });
        case "inactive":
            return UIRender({ color: "blue", text: "Inactive", type: "quiz", backgroundColorHex: "#e0edff", colorHex: "#1899d2",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "quiz" });
    }
};

export const RenderBookingActiveStatus = (data) => {
    switch (data) {
      case "Active":
        return <p className="as-green-bg as-widget">Active</p>;
      case "Active-Rescheduled":
        return <p className="as-brown-bg as-widget">Active-Rescheduled</p>;
      case "Active-Retrain":
        return <p className="as-blue-bg as-widget">Active-Retrain</p>;
      case "Cancelled":
        return <p className="as-red-bg as-widget">Cancelled</p>;
      default:
        return <p className="as-black-bg as-widget">data</p>;
    }
  };

  export const RenderDelegateType = (data) => {
    switch (data) {
      case "In Class Attendee":
        return <p className="as-green-bg as-widget">In Class Attendee</p>;
      case "Virtual Attendee":
        return <p className="as-purple-bg as-widget">Virtual Attendee</p>;
      default:
        return <p className="as-black-bg as-widget">data</p>;
    }
  };
  


  export const RenderDelegateLaptopType = (data) => {
    switch (data) {
      case "Fascility equipment to be provided":
        return <p className="as-green-bg as-widget">Fascility to provide equipment</p>;
      case "Using own personal  laptop":
        return <p className="as-purple-bg as-widget">Using personal laptop</p>;
      default:
        return <p className="as-black-bg as-widget">{data}</p>;
    }
  };

  export const RenderITSetup = (data) => {
    switch (data) {
      case "Pending":
        return <p className="as-red-bg as-widget">Pending</p>;
      case "Setup Complete":
        return <p className="as-green-bg as-widget">Setup Complete</p>;
    case "Not Required":
        return <p className="as-purple-bg as-widget">Not Required</p>;        
      default:
        return <p className="as-black-bg as-widget">{data}</p>;
    }
  };
  export const RenderCateringSetup = (data) => {
    switch (data) {
      case "Pending":
        return <p className="as-red-bg as-widget">Pending</p>;
      case "Catering Complete":
        return <p className="as-green-bg as-widget">Catering Complete</p>;
      case "Not Required":
        return <p className="as-purple-bg as-widget">Not Required</p>;        
      default:
        return <p className="as-black-bg as-widget">{data}</p>;
    }
  };

  export const RenderCreditBearing = (data) => {
    switch (data) {
      case "Yes":
        return <p className="as-green-bg as-widget">Yes</p>;
      case "No":
        return <p className="as-red-bg as-widget">No</p>;
      default:
        return <p className="as-black-bg as-widget">data</p>;
    }
  };
// Only for Type in Module, There are Two Different Type of type in Module, PMC
export const RenderTypeInModule = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "core":
            return UIRender({ color: "skyblue", text: "Core", type: "typeInModule",origin });
        case "specialised":
            return UIRender({ color: "red", text: "Specialised", type: "typeInModule",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "typeInModule" });
    }
};

// Only for ModuleType, There are Two Different Type of type in Module, PMC
export const RenderModuleType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "information":
            return UIRender({ color: "cat-skyblue", text: "Information", type: "ModuleType",origin });
        case "mentored":
            return UIRender({ color: "cat-red", text: "Mentored", type: "ModuleType",origin });
        case "orientation":
            return UIRender({ color: "cat-green", text: "Orientation", type: "ModuleType",origin });
        case "scheduled":
            return UIRender({ color: "cat-yellowshade", text: "Scheduled", type: "ModuleType",origin });
        case "shortcourse":
            return UIRender({ color: "cat-blue", text: "Short Course", type: "ModuleType",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "ModuleType" });
    }
};

// Only for ModuleType, There are Two Different Type of type in Module, PMC
export const RenderModuleTerm = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "term1":
            return UIRender({ color: "skyblue", text: "Term1", type: "ModuleTerm",origin });
        case "term2":
            return UIRender({ color: "red", text: "Term1", type: "ModuleTerm",origin });
        case "term3":
            return UIRender({ color: "green", text: "Term1", type: "ModuleTerm",origin });
        case "term4":
            return UIRender({ color: "blue", text: "Term1", type: "ModuleTerm",origin });
        default:
            return UIRender({ color: "black", text: "N/A", type: "ModuleTerm" });
    }
};

// Only for Type in Assessment
export const RenderAssessmentType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {        

        case "assignment":
            return UIRender({ color: "cat-skyblue", text: "Assignment", type: "AssessmentType",origin });
        case "1":
            return UIRender({ color: "cat-skyblue", text: "Assignment", type: "AssessmentType",origin });    
        case "external exam":
            return UIRender({ color: "cat-red", text: "External Exam", type: "AssessmentType",origin });
        case "0":
            return UIRender({ color: "cat-red", text: "External Exam", type: "AssessmentType",origin });            
        case "internal exam":
            return UIRender({ color: "cat-green", text: "Internal Exam", type: "AssessmentType",origin });
        case "2":
            return UIRender({ color: "cat-green", text: "Internal Exam", type: "AssessmentType",origin });
        case "poeformative":
            return UIRender({ color: "cat-blue", text: "PoE-Formative", type: "AssessmentType",origin });
        case "4":
            return UIRender({ color: "cat-blue", text: "PoE-Formative", type: "AssessmentType",origin });
        case "poeinformal activities":
            return UIRender({ color: "cat-yellowshade", text: "PoE-Informal Activities", type: "AssessmentType",origin });
        case "6":
            return UIRender({ color: "cat-yellowshade", text: "PoE-Informal Activities", type: "AssessmentType",origin });
        case "7":
            return UIRender({ color: "cat-purple", text: "Survey", type: "AssessmentType",origin });
        case "poesummative":
            return UIRender({ color: "cat-yellow", text: "PoE-Summative", type: "AssessmentType",origin });
        case "5":
            return UIRender({ color: "cat-yellow", text: "PoE-Summative", type: "AssessmentType",origin });
        case "test":
            return UIRender({ color: "cat-brown", text: "Test", type: "AssessmentType",origin });
        case "8":
            return UIRender({ color: "cat-blueshade", text: "Supplementary Exam", type: "AssessmentType",origin });
        case "supplementary exam":
            return UIRender({ color: "cat-blueshade", text: "Supplementary Exam", type: "AssessmentType",origin });
        case "9":
            return UIRender({ color: "cat-greenhex", text: "Sick Test", type: "AssessmentType",origin });
        case "sick test":
            return UIRender({ color: "cat-greenhex", text: "Sick Test", type: "AssessmentType",origin });
        case "3":
            return UIRender({ color: "cat-brown", text: "Test", type: "AssessmentType",origin });
        case "scheduled":
            return UIRender({ color: "cat-green", text: "Scheduled", type: "course type",origin });
    
        case "mentored":
            return UIRender({ color: "cat-blue", text: "Mentored", type: "course type",origin });
    
        case "information":
            return UIRender({ color: "cat-red", text: "Information", type: "course type",origin });
    
        case "orientation":
            return UIRender({ color: "cat-brown", text: "Orientation", type: "course type",origin });
    
        case "shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
    
        case "short course":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
    
        case "rereg_shortcourse":
            return UIRender({ color: "cat-yellow", text: "Short Course", type: "course type",origin });
        
        case "hybrid":
            return UIRender({ color: "cat-pinkhex", text: "Hybrid", type: "AssessmentType",origin });
        case "smart":
            return UIRender({ color: "cat-blue-heptica", text: "Smart Assessment", type: "AssessmentType",origin });
        case "document":
            return UIRender({ color: "cat-green-nature", text: "Document", type: "AssessmentType",origin });
        
        default:
            return UIRender({ color: "cat-red", text: "External Exam", type: "AssessmentType",origin });
    }
};

// Only for Laptop Spec
export const RenderLaptopSpec = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "basic_laptop":
            return UIRender({ color: "cat-skyblue", text: "Basic Spec Laptop", type: "laptopSpec",origin });
        case "high_laptop":
            return UIRender({ color: "cat-red", text: "High Spec Laptop", type: "laptopSpec",origin });
        case "medium_laptop":
            return UIRender({ color: "cat-green", text: "Medium Spec Laptop", type: "laptopSpec",origin });
        case "no_laptop":
            return UIRender({ color: "cat-blue", text: "No Laptop Required", type: "laptopSpec",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "laptopSpec" });
    }
};

// Only for Campus
export const RenderCampus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "capetown":
            return UIRender({ color: "cat-skyblue", text: "Cape Town", type: "Campus",origin });
        case "cape town":
            return UIRender({ color: "cat-skyblue", text: "Cape Town", type: "Campus",origin });
        case "centpre":
            return UIRender({ color: "cat-red", text: "Centurion, Pretoria", type: "Campus",origin });
        case "correspondence":
            return UIRender({ color: "cat-green", text: "Correspondence", type: "Campus",origin });
        case "gdc":
            return UIRender({ color: "cat-blue", text: "Greenside", type: "Campus",origin });
        case "Greenside":
            return UIRender({ color: "cat-blue", text: "Greenside", type: "Campus",origin });
        case "midjohn":
            return UIRender({ color: "cat-yellowshade", text: "Midrand", type: "Campus",origin });
        case "midrand":
            return UIRender({ color: "cat-yellowshade", text: "Midrand", type: "Campus",origin });
        case "online":
            return UIRender({ color: "cat-yellow", text: "Online", type: "Campus",origin });
        case "hatfield":
            return UIRender({ color: "cat-lightgreen", text: "Hatfield", type: "Campus",origin });
        case "on site":
            return UIRender({ color: "cat-purple", text: "Hatfield", type: "Campus",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "Campus" });
    }
};

// This will only be used in Class type.
export const RenderUserRole = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "accounts":
            return UIRender({ color: "cat-red", text: "Accounts", type: "userRole",origin });
        case "admin":
            return UIRender({ color: "cat-skyblue", text: "Admin", type: "userRole",origin });
        case "content editor":
            return UIRender({ color: "cat-black", text: "Content Editor", type: "userRole",origin });
        case "developer":
            return UIRender({ color: "cat-red", text: "Developer", type: "userRole",origin });
        case "lecturer":
            return UIRender({ color: "cat-greenhex", text: "Lecturer", type: "userRole",origin });
        case "manager":
            return UIRender({ color: "cat-blue", text: "Manager", type: "userRole",origin });
        case "programme coordinator":
            return UIRender({ color: "cat-black", text: "Programme Coordinator", type: "userRole",origin });
        case "sales":
            return UIRender({ color: "cat-blue", text: "Sales", type: "userRole",origin });
        case "student support":
            return UIRender({ color: "cat-greenhex", text: "Student Support", type: "userRole",origin });
        case "":
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        case null:
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        case undefined:
            return UIRender({ color: "cat-black", text: "N/A", type: "userRole",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "userRole" });
    }
};

// This will only be used in user location.
export const RenderUserLocation = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "midrand":
            return UIRender({ color: "cat-red", text: "Midrand", type: "userLocation",origin });
        case "johannesburg":
            return UIRender({ color: "cat-skyblue", text: "Johannesburg", type: "userLocation",origin });
        case "cape town":
            return UIRender({ color: "cat-blue", text: "Cape Town", type: "userLocation",origin });
        case "hatfield":
            return UIRender({ color: "cat-pink", text: "Hatfield", type: "userLocation",origin });
        case "remote/online":
            return UIRender({ color: "cat-yellowshade", text: "Remote/Online", type: "userLocation",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "userLocation" });
    }
};

// This will only be used in user Company.
export const RenderUserCompany = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "academic institute of excellence (aie)":
            return UIRender({ color: "cat-red", text: "Academic Institute of Excellence (AIE)", type: "userCompany",origin });
        case "greenside design center (gdc)":
            return UIRender({ color: "cat-skyblue", text: "Greenside Design Center (GDC)", type: "userCompany",origin });
        case "learnfast":
            return UIRender({ color: "cat-blue", text: "Learnfast", type: "userCompany",origin });
        case "cadco":
            return UIRender({ color: "cat-red", text: "Cadco", type: "userCompany",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "userCompany" });
    }
};

// This will only be used in user Company.
export const RenderAuditTrailActionType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "created":
            return UIRender({ color: "cat-blue", text: "Created", type: "auditTrailActionType",origin });
        case "edited":
            return UIRender({ color: "cat-greenhex", text: "Edited", type: "auditTrailActionType",origin });
        case "deleted":
            return UIRender({ color: "cat-red", text: "Deleted", type: "auditTrailActionType",origin });
        case "profile picture edited":
            return UIRender({ color: "cat-yellowshade", text: "Picture Edited", type: "auditTrailActionType",origin });
        case "approved":
            return UIRender({ color: "cat-greenhex", text: "Approved", type: "auditTrailActionType", origin });
        case "declined":
            return UIRender({ color: "cat-red", text: "Declined", type: "auditTrailActionType", origin });
        case "imported":
            return UIRender({ color: "cat-yellowshade", text: "Imported", type: "auditTrailActionType", origin });
        case "uploaded":
            return UIRender({ color: "cat-yellowshade", text: "Uploaded", type: "auditTrailActionType", origin });
        case "logged in (desktop)":
            return UIRender({ color: "cat-yellowshade", text: "Logged In (Desktop)", type: "auditTrailActionType", origin });
        case "logged out (desktop)":
            return UIRender({ color: "cat-lightblue", text: "Logged Out (Desktop)", type: "auditTrailActionType", origin });
        case "logged in (mobile)":
            return UIRender({ color: "cat-blue", text: "Logged In (Mobile)", type: "auditTrailActionType", origin });
        case "logged out (mobile)":
            return UIRender({ color: "cat-yellow", text: "Logged Out (Mobile)", type: "auditTrailActionType", origin });
        case "read ticket":
            return UIRender({ color: "cat-blueshade", text: "Read Ticket", type: "auditTrailActionType", origin });
        case "replied to ticket":
            return UIRender({ color: "cat-pink", text: "Replied to Ticket", type: "auditTrailActionType", origin });
        case "opened resource":
            return UIRender({ color: "cat-brown", text: "Opened Resource", type: "auditTrailActionType", origin });
        case "read notification":
            return UIRender({ color: "cat-red", text: "Read Notification", type: "auditTrailActionType", origin });
        case 'submitted assessment':
          return UIRender({ color: 'cat-green', text: 'Submitted Assessment', type: 'auditTrailActionType', origin })
        case 'submitted assessment (desktop)':
          return UIRender({ color: 'cat-green', text: 'Submitted Assessment  (Desktop)', type: 'auditTrailActionType', origin });
        case 'submitted assessment (mobile)':
          return UIRender({ color: 'cat-light-green', text: 'Submitted Assessment  (Mobile)', type: 'auditTrailActionType', origin });
        case 'launched assessment (desktop)':
          return UIRender({ color: 'cat-yellowshade', text: 'Launched Assessment (Desktop)', type: 'auditTrailActionType', origin });
        case 'launched assessment (mobile)':
          return UIRender({ color: 'cat-blue', text: 'Launched Assessment (Mobile)', type: 'auditTrailActionType', origin });
        case "declined":
            return UIRender({ color: "cat-skyblue", text: "Declined", type: "auditTrailActionType", origin });
        case "re-registration new":
            return UIRender({ color: "cat-yellowshade", text: "Re-Registration New", type: "auditTrailActionType", origin });
        case "remind me later":
            return UIRender({ color: "cat-greenhex", text: "Remind Me Later", type: "auditTrailActionType", origin });
        case "exam registration":
            return UIRender({ color: "cat-lightblue", text: "Exam Registration", type: "auditTrailActionType", origin });
        case "rejected":
            return UIRender({ color: "cat-orange", text: "Rejected", type: "auditTrailActionType" });    
        case "marked as final":
            return UIRender({ color: "cat-purple", text: "Marked as Final", type: "auditTrailActionType" });   
        case "merged":
            return UIRender({ color: "cat-skyblue", text: "Merged", type: "auditTrailActionType" });    
        case "set to accounts":
            return UIRender({ color: "cat-brown", text: "Set To Accounts", type: "auditTrailActionType" }); 
        case "posted to netsuite":
            return UIRender({ color: "cat-blue", text: "Posted To Netsuite", type: "auditTrailActionType" }); 
        case "save & add to live portal clicked":
            return UIRender({ color: "cat-green", text: "Save & Add to Live Portal Clicked", type: "auditTrailActionType" });      
        case "sent welcome letter":
            return UIRender({ color: "cat-green", text: "Sent Welcome Letter", type: "auditTrailActionType" });      
        case "logged in (desktop)":
            return UIRender({ color: "cat-yellowshade", text: "Logged In (Desktop)", type:"auditTrailActionType" });
        case "logged out (desktop)":
            return UIRender({ color: "cat-lightblue", text: "Logged Out (Desktop)", type:"auditTrailActionType" });
        case "logged in (mobile)":
            return UIRender({ color: "cat-blue", text: "Logged In (Mobile)", type:"auditTrailActionType" });
        case "logged out (mobile)":
            return UIRender({ color: "cat-yellow", text: "Logged Out (Mobile)", type:"auditTrailActionType" });
        case "declined":
            return UIRender({ color: "cat-skyblue", text: "Declined", type:"auditTrailActionType" });
        case "opened resource":
            return UIRender({ color: "cat-brown", text: "Opened Resource", type:"auditTrailActionType" });
        case "re registration new":
            return UIRender({ color: "cat-yellowshade", text: "Re-Registration New", type:"auditTrailActionType" });
        case "read notification":
            return UIRender({ color: "cat-red", text: "Read Notification", type:"auditTrailActionType" });
        case "read ticket":
            return UIRender({ color: "cat-blueshade", text: "Read Ticket", type:"auditTrailActionType" });
        case "remind Me later":
            return UIRender({ color: "cat-greenhex", text: "Remind Me Later", type:"auditTrailActionType" });
        case "replied to ticket":
            return UIRender({ color: "cat-pink", text: "Replied to Ticket", type:"auditTrailActionType" });
        case "submitted assessment":
            return UIRender({ color: "cat-pinkshade", text: "Submitted Assessment", type:"auditTrailActionType" });
        case "online":
            return UIRender({ color: "cat-green", text: "Online", type:"auditTrailActionType" });
        case "offline":
          return UIRender({ color: "cat-red", text: "Offline", type:"auditTrailActionType" });
        case "logged in (ami phone)":
            return UIRender({ color: "cat-purple", text: "Logged In (AMI Phone)", type:"auditTrailActionType" });
        case "logged out (ami phone)":
            return UIRender({ color: "cat-missed", text: "Logged Out (AMI Phone)", type:"auditTrailActionType" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "auditTrailActionType" });
    }
};

//only for Appeal Submission Status
export const RenderSubmissionStatus = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "appealpending":
            return UIRender({ color: "cat-yellow", text: "Appeal-Pending", type: "submissionStatus",origin });
        case "appealapproved":
        case "approved":
            return UIRender({ color: "cat-greenhex", text: "Appeal-Approved", type: "submissionStatus",origin });
        case "draft":
            return UIRender({ color: "cat-skyblue", text: "Draft", type: "submissionStatus",origin });
        case "marked":
            return UIRender({ color: "cat-green", text: "Marked", type: "submissionStatus",origin });
        case "not submitted":
            return UIRender({ color: "cat-yellowshade", text: "Not-Submitted", type: "submissionStatus",origin });
        case "submitted":
            return UIRender({ color: "cat-red", text: "Submitted", type: "submissionStatus",origin });
        case "closednot submitted":
            return UIRender({ color: "cat-dark-red", text: "Closed-Not Submitted", type: "submissionStatus",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "submissionStatus" });
    }
};

//this will only be used course report -> attendance type
export const RenderAttendanceType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "virtual":
            return UIRender({ color: "cat-greenhex", text: "Virtual Classroom", type: "attendanceType",origin });
        case "on campus":
            return UIRender({ color: "cat-red", text: "On Campus Class", type: "attendanceType",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "attendanceType" });
    }
};

//this will only be used for department.
export const RenderDepartment = (data,origin) => {
    let value = data.toString().toLowerCase().replace("-", " ")
    return UIRender({ color: stc(value), text: value, type: "department",origin });
};

export const RenderMenuType = (data,origin) => {
    switch (data) {
        case "Main Menu":
            return UIRender({ color: "cat-blueHead", text: "Main Menu", type: "menuType",origin });
        case "Standard Menu":
            return UIRender({ color: "cat-greenhex", text: "Standard Menu", type: "menuType",origin });
        case "Footer Menu":
            return UIRender({ color: "cat-purple", text: "Footer Menu", type: "menuType",origin });
        case "Midrand":
            return UIRender({ color: "cat-purple", text: "Midrand", type: "menuType",origin });
        case "Cape Campus":
            return UIRender({ color: "cat-purple", text: "Cape Campus", type: "menuType",origin });
        case "School of Business, Entrepreneurship & Finance":
            return UIRender({ color: "cat-purple", text: "School of Business, Entrepreneurship & Finance", type: "menuType",origin });
        case "School of Artisans & Technical Trades":
            return UIRender({ color: "cat-purple", text: "School of Artisans & Technical Trades", type: "menuType",origin });
        case "School of Beauty & Health Science":
            return UIRender({ color: "cat-purple", text: "School of Beauty & Health Science", type: "menuType",origin });
        case "School of Engineering & Science":
            return UIRender({ color: "cat-purple", text: "School of Engineering & Science", type: "menuType",origin });
        case "School of Draughting & Technical Design":
            return UIRender({ color: "cat-purple", text: "School of Draughting & Technical Design", type: "menuType",origin });
        case "School of Information Technology & Data Science":
            return UIRender({ color: "cat-purple", text: "School of Information Technology & Data Science", type: "menuType",origin });
        default:
            return UIRender({ color: "cat-black", text: data, type: "menuType" });
    }
};

export const RenderType = (data,origin) => {
    switch (data) {
        default:
            return UIRender({ color: "cat-blue", text: data, type: "menuType" });
    }
};

//this will only be used for department.
export const RenderCategory = (data,origin) => {
    let value = data.toString().toLowerCase().replace("-", " ")
    return UIRender({ color: stc(value), text: value, type: "category",origin });
};

//Only used for version filter.
export const RenderVersion = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "v1":
            return UIRender({ color: "cat-skyblue", text: "V1", type: "versionFilter",origin });
        case "v2":
            return UIRender({ color: "cat-red", text: "V2", type: "versionFilter",origin });
        case "v3":
            return UIRender({ color: "cat-yellow", text: "V3", type: "versionFilter",origin });
        case "v4":
            return UIRender({ color: "cat-blue", text: "V4", type: "versionFilter",origin });
        case "v5":
            return UIRender({ color: "cat-greenhex", text: "V5", type: "versionFilter",origin });
        case "v6":
            return UIRender({ color: "cat-skyblue", text: "V6", type: "versionFilter",origin });
        case "v7":
            return UIRender({ color: "cat-red", text: "V7", type: "versionFilter",origin });
        case "v8":
            return UIRender({ color: "cat-yellow", text: "V8", type: "versionFilter",origin });
        case "v9":
            return UIRender({ color: "cat-blue", text: "V9", type: "versionFilter",origin });
        case "v10":
            return UIRender({ color: "cat-greenhex", text: "V10", type: "versionFilter",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "versionFilter" });
    }
};

//only for attendance health filter
export const RenderAttendanceHealth = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {

        case "critical":
            return UIRender({ color: "cat-red", text: "Critical", type: "attendanceHealth",origin });
        case "good":
            return UIRender({ color: "cat-green", text: "Good", type: "attendanceHealth",origin });
        case "moderate":
            return UIRender({ color: "cat-orange", text: "Moderate", type: "attendanceHealth",origin });
        default:
            return UIRender({ color: "cat-black", text: "N/A", type: "attendanceHealth" });
    }
};

//only for level for question
export const RenderQuestionLevel = (data) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "easy":
      return UIRender({
        color: "cat-green",
        text: "Easy",
        type: "questionLevel",
      });
    case "hard":
      return UIRender({
        color: "cat-red",
        text: "Hard",
        type: "questionLevel",
      });
    case "medium":
      return UIRender({
        color: "cat-blue",
        text: "Medium",
        type: "questionLevel",
      });
    default:
      return UIRender({
        color: "cat-black",
        text: "N/A",
        type: "questionLevel",
      });
  }
};

//only for question type
export const RenderQuestionType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "multiple choice single answer":
            return UIRender({ icon: <i class="fal fa-tasks"></i>, color: "cat-yellow", text: "Multiple Choice Single Answer", type: "questionType" });
        case "multiple choice multiple answer":
            return UIRender({ icon: <i class="far fa-check-square"></i>, color: "cat-greenhex", text: "Multiple Choice Multiple Answer", type: "questionType" });
        case "match the column":
            return UIRender({ icon: <i class="far fa-line-columns" style="font-size: 24px;"></i>, color: "cat-light-pink", text: "Match the Column", type: "questionType" });
        case "short answer":
            return UIRender({ icon: <i class="far fa-comment-alt-lines"></i>, color: "cat-blue", text: "Short Answer", type: "questionType" });
        case "long answer":
            return UIRender({ icon: <i class="fal fa-align-left"></i>, color: "cat-red", text: "Long Answer", type: "questionType" });
        case "choice matrix":
            return UIRender({ icon: <i class="fal fa-table"></i>,  color: "cat-light-green", text: "Choice Matrix", type: "questionType" });
        case "fill in the blank":
            return UIRender({ icon: <i class="far fa-keyboard"></i>, color: "cat-dark-blue", text: "Fill in the Blank", type: "questionType" });
        case "order list":
                return UIRender({ icon: <i class="fas fa-sort-numeric-down"></i>, color: "cat-extra-blue", text: "Order list", type: "questionType" });
        case "document upload":
            return UIRender({ icon: <i class="fal fa-file-upload"></i>, color: "cat-purple", text: "Document upload", type: "questionType" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "questionType" });
    }
}

//only for question type
export const RenderQuestionTypeForm = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "multiple choice single answer":
            return UIRender({ icon: <i class="fal fa-tasks"></i>, color: "cat-yellow", text: "Multiple Choice Single Answer", type: "questionTypeForm" });
        case "multiple choice multiple answer":
            return UIRender({ icon: <i class="far fa-check-square"></i>, color: "cat-greenhex", text: "Multiple Choice Multiple Answer", type: "questionTypeForm" });
        case "match the column":
            return UIRender({ icon: <i class="far fa-line-columns" style="font-size: 24px;"></i>, color: "cat-light-pink", text: "Match the Column", type: "questionTypeForm" });
        case "short answer":
            return UIRender({ icon: <i class="far fa-comment-alt-lines"></i>, color: "cat-blue", text: "Short Answer", type: "questionTypeForm" });
        case "long answer":
            return UIRender({ icon: <i class="fal fa-align-left"></i>, color: "cat-red", text: "Long Answer", type: "questionTypeForm" });
        case "choice matrix":
            return UIRender({ icon: <i class="fal fa-table"></i>,  color: "cat-light-green", text: "Choice Matrix", type: "questionTypeForm" });
        case "fill in the blank":
            return UIRender({ icon: <i class="far fa-keyboard"></i>, color: "cat-dark-blue", text: "Fill in the Blank", type: "questionTypeForm" });
        case "order list":
                return UIRender({ icon: <i class="fas fa-sort-numeric-down"></i>, color: "cat-extra-blue", text: "Order list", type: "questionTypeForm" });
        case "document upload":
            return UIRender({ icon: <i class="fal fa-file-upload"></i>, color: "cat-purple", text: "Document upload", type: "questionTypeForm" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "questionTypeForm" });
    }
}
//only for Resource Management Campus
export const RenderResourceCampus = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "centpre":
            return UIRender({ color: "cat-yellow", text: "Centurion, Pretoria", icon : "fa-university", type: "resourceCampus" });
        case "capetown":
            return UIRender({ color: "cat-greenhex", text: "Cape Town", icon : "fa-university", type: "resourceCampus" });
        case "midjohn":
            return UIRender({ color: "cat-skyblue", text: "Midrand", icon : "fa-university", type: "resourceCampus" });
        case "online":
            return UIRender({ color: "cat-blue", text: "Online", icon : "fa-wifi", type: "resourceCampus" });
        case "gdc":
            return UIRender({ color: "cat-brown", text: "Greenside", icon : "fa-university", type: "resourceCampus" });
        case "correspondence":
            return UIRender({ color: "cat-red", text: "Correspondence", icon : "fa-university", type: "resourceCampus" });
        default:
            return UIRender({ color: "cat-black", text: data, type: "resourceCampus" });
    }
};

export const RenderSClassType = (data) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "on_campus":
      return UIRender({
        color: "cat-blue",
        text: "On-campus",
        type: "classType",
      });
    case "hybrid":
      return UIRender({
        color: "cat-yellow",
        text: "Hybrid",
        type: "classType",
      });
    case "bbb_class":
      return UIRender({
        color: "cat-green",
        text: "Virtual",
        type: "classType",
      });
    default:
      return UIRender({ color: "black", text: "N/A", type: "classType" });
  }
};

// If any change is required then add condition on type, type will return which function called UIRender
export const UIRender = ({ color, text, type, backgroundColorHex, colorHex, origin, icon, key }) => {
    if(type == "questionType"){
        return {
            color,
            text,
            icon,
            html: (
                <div className='d-flex'>
                    <span className={"cat istyle " + color }>{text !== "-" && <i className={icon?.props?.class +" "+ "mr-1"}></i>}</span>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                </div>
                // <span className={"cat " + color} title={text} icon={icon}>
                //     {text !== "-" && <i className={"fa fa-"+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                // </span>
            ),
        }
    }
    if (type == "subjectItems" || type=="studentListFilter") {
        return {
            color,
            text,
            icon,
            html: (
                <div className='d-flex items cat'>
                    <span className={"cat istyle " + color}>{text !== "-" && <i className={icon}></i>}</span>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                </div>
            ),
        }
    }
    if (type == "LastLogin" || type == "pmcPageStatus") {
        return {
            
            text,
            html: (
                <div className='d-flex items cat'>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                </div>
            ),
        }
    }
    if(type == "questionTypeForm"){
        return {
            color,
            text,
            icon,
            html: (
                <div className='d-flex'>
                    {origin ? <span className='textLimit100'>{text}</span> : text}
                    <span className={"cat ml-2 istyle " + color }>{text !== "-" && <i className={icon?.props?.class +" "+ "mr-1"}></i>}</span>
                </div>
                // <span className={"cat " + color} title={text} icon={icon}>
                //     {text !== "-" && <i className={"fa fa-"+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                // </span>
            ),
        }
    }
    if(type == "brandList" || type === "programme"){
        return {
            color,
            text,
            html: (
                <span className={"cat " + color} style={{ color }} title={text}>
                    {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    
    if(type == "resourceCampus"){
        return {
            color,
            text,
            html: (
                <span className={"cat "+ color} title={text}>
                    {text !== "-" && <i className={"fas "+ icon +" "+ "mr-1"}></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    if (type == "department" || type == "category") {
        return {
            color,
            text,
            html: (
                <span className={"cat "} style={{ color, textTransform: "capitalize" }} title={text}>
                    {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    if (type == "header") {
        return {
            color,
            text,
            html: (
                <span className={"cat "} style={{ color, textTransform: "capitalize" }} title={text}>
                    {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
                </span>
            ),
        }
    }
    let bgColor = "", colorCode = "";
    if (type == "pmcStatus" || type == "quiz") {
        bgColor = backgroundColorHex;
        colorCode = colorHex
    }
    return {
        color,
        text,
        bgColor,
        colorCode,
        html: (
            <span className={"cat " + color} title={text}>
                {text !== "-" && <i className="fas fa-circle mr-1"></i>} {origin ? <span className='textLimit100'>{text}</span> : text}
            </span>
        ),
    }
}

export const exportRegistrationStatus = (data) => {
  switch (data) {
    case "Re-Registration-Accounts":
      return "as-skyblue-bg as-widget";
    case "assign-subjects":
      return "as-red-bg as-widget";
    case "Closed-Ordered":
      return "as-red-bg as-widget";
    case "Closed-Ordered2":
      return "as-red-bg as-widget";
    case "Final-Status":
      return "as-yellow-bg as-widget";
    case "In-Active":
      return "as-yellow-bg as-widget";
    case "new-status":
      return "as-green-bg as-widget";
    case "Preliminary Re-Registration":
      return "as-green-bg as-widget";
    case "Re-Registration":
      return "as-yellow-bg as-widget";
    case "Re-Registration Cancelled":
      return "as-red-bg as-widget";
    case "Re-Registration Rejected":
      return "as-yellowshade-bg as-widget";
    case "Re-Registration-Accounts Rejected":
      return "as-green-bg as-widget";
    case "Re-Registration-Final":
      return "as-green-bg as-widget";
    case "Re-Registration-RCO":
      return "as-red-bg as-widget";
    case "Re-Registration-Sales":
      return "as-blue-bg as-widget";
    case "Re-Registration-Sales Admin":
      return "as-green-bg as-widget";
    case "Re-Registration-Sales Invoiced":
      return "as-red-bg as-widget";
    case "Re-Registration-Sales-Rejected":
      return "as-skyblue-bg as-widget";
    case "Re-Registration-Study Kit Pending":
      return "as-green-bg as-widget";
    case "Registration Withdrawn":
      return "as-yellowshade-bg as-widget";
    case "Rejected":
      return "as-blue-bg as-widget";
    case "Review":
      return "as-blue-bg as-widget";
  }
};

const CampusRender = ({color, text, icon, type}) =>{
    if(type=="dropdown"){
        return {
            color,
            text,
            html : (
                <span className='cat ' style={{color:color ? color : "#000"}} title={text}>
                    {(text!=="-" && text!=="") &&<i className={"fas "+ icon +" "+ "mr-1"}></i>} {text} 
                </span>
            )
        }
    }else{

    }
}

export const RenderCampusDropdown = (campus)=>{
    switch (campus.label){
        case "Online":
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-wifi", type:"dropdown"});
        case "online":
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-wifi", type:"dropdown"});
        default:
            return CampusRender({color : campus.color, text : campus.label, icon : "fa-university", type:"dropdown"});
    }
}

// only for brand

export const RenderBrands = (label,color) => {
    return UIRender({ color: color, text: label, type: "brandList" });
}


export const RenderBrandList = (brand_name) => {
    switch (brand_name){
        case "Academic Institute of Excellence (AIE)":
            return UIRender({color : "cat-blue", text : brand_name, type:"brandList"});
        case "Emendy Multimedia Institute":
            return UIRender({color : "cat-red", text : brand_name, type:"brandList"});
        case "Greenside Design Center (GDC)":
            return UIRender({color : "cat-green", text: brand_name, type:"brandList"});
        case "LearnFast":
            return UIRender({color : "cat-orange", text: brand_name, type:"brandList"});
        case "The CAD Corporation":
            return UIRender({color : "cat-brown", text: brand_name, type:"brandList"});
        default:
            return UIRender({ color: "NA", text: "-", type: "brandList" });    
    }
}
 
export const RenderBrandListMultiLine = (brand_name) => {
    switch (brand_name){
        case "Academic Institute of Excellence (AIE)":
            return UIRender({origin: true, color : "cat-blue", text : brand_name, type:"brandList"});
        case "Emendy Multimedia Institute":
            return UIRender({origin: true, color : "cat-red", text : brand_name, type:"brandList"});
        case "Greenside Design Center (GDC)":
            return UIRender({origin: true, color : "cat-green", text: brand_name, type:"brandList"});
        case "LearnFast":
            return UIRender({origin: true, color : "cat-orange", text: brand_name, type:"brandList"});
        case "The CAD Corporation":
            return UIRender({origin: true, color : "cat-brown", text: brand_name, type:"brandList"});
        default:
            return UIRender({ origin: true, color: "NA", text: "-", type: "brandList" });    
    }
}
// export const RenderBrandList = (data) => {
//     switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
//         case "1":
//             return UIRender({ color: "blue", text: "In Progress", type: "courseStatus" });
//         case "28":
//             return UIRender({ color: "red", text: "Past", type: "courseStatus" });
//         case "13":
//             return UIRender({ color: "green", text: "Upcoming", type: "courseStatus" });
//         case "24":
//             return UIRender({ color: "skyblue", text: "Today", type: "courseStatus" });
//         default:
//             return UIRender({ color: "black", text: data, type: "courseStatus" });
//     }
// };

// only for programme or course type
export const RenderCertificateType = (data,origin) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "course":
            return UIRender({ color: "cat-orange", text: "Course", type: "certificate",origin });
        case "subject":
            return UIRender({ color: "cat-orange", text: "Course", type: "certificate",origin });
        case "qualification":
            return UIRender({ color: "cat-blue", text: "Programme", type: "certificate",origin });
        case "qualifications":
            return UIRender({ color: "cat-blue", text: "Programme", type: "certificate",origin });
        case "grademix":
            return UIRender({ color: "cat-green", text: "GradeMix", type: "certificate",origin });   
        case "programme gradebook setting":
            return UIRender({ color: "cat-skyblue", text: "Programme Gradebook Setting", type: "certificate" });
        case "notifications":
            return UIRender({ color: "cat-yellow", text: "Notifications", type: "certificate" });
        case "assign_subject":
            return UIRender({ color: "cat-red", text: "Assigned Subject", type: "certificate" });
        case "delete_assign_subject":
            return UIRender({ color: "cat-brown", text: "Delete Assigned Subject", type: "certificate" });
        case "not_applicable":
            return UIRender({ color: "cat-red", text: "Not Applicable", type: "certificate",origin });
        default:
            return UIRender({ color: "NA", text: "-", type: "certificate" });
    }
};

// only for Course Resource type
export const RenderCourseResourceType = (data) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "assemblies":
      return UIRender({
        color: "cat-orange",
        text: "Assemblies",
        type: "resourceType",
      });
    case "assembly":
      return UIRender({
        color: "cat-orange",
        text: "Assembly",
        type: "resourceType",
      });
    case "assesment":
      return UIRender({
        color: "cat-blue",
        text: "Assesment",
        type: "resourceType",
      });
    case "assigned_program":
      return UIRender({
        color: "cat-red",
        text: "Assigned Program",
        type: "resourceType",
      });
    case "course":
      return UIRender({
        color: "cat-green",
        text: "Course",
        type: "resourceType",
      });
    case "course gradebook setting":
      return UIRender({
        color: "cat-skyblue",
        text: "Course Gradebook Setting",
        type: "resourceType",
      });
    case "notification":
      return UIRender({
        color: "cat-yellow",
        text: "Notification",
        type: "resourceType",
      });
    case "onlineclass":
      return UIRender({
        color: "cat-greenhex",
        text: "Online Class",
        type: "resourceType",
      });
    case "submission":
      return UIRender({
        color: "cat-brown",
        text: "Submission",
        type: "resourceType",
      });
    case "template assigned":
      return UIRender({
        color: "cat-green",
        text: "Certificate Assigned",
        type: "resourceType",
      });
    case "certificate assigned":
      return UIRender({
        color: "cat-green",
        text: "Certificate Assigned",
        type: "resourceType",
      });
    case "certificate generated":
      return UIRender({
        color: "cat-greenhex",
        text: "Certificate Generated",
        type: "resourceType",
      });
    default:
      return UIRender({ color: "NA", text: "-", type: "resourceType" });
  }
};

// only for Student Resource type
export const RenderStudentResourceType = (data) => {
    switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
        case "logout_and_login":
            return UIRender({ color: "cat-orange", text: "Logout and Login", type: "resourceType" });
        case "exams_registrations":
            return UIRender({ color: "cat-lightblue", text: "Exam Registration", type: "resourceType" });
        case "re_registrations":
            return UIRender({ color: "cat-blue", text: "Re-Registrations", type: "resourceType" });
        case "logged in":
            return UIRender({ color: "cat-red", text: "Logged in", type: "resourceType" });
        case "logged out":
            return UIRender({ color: "cat-green", text: "Logged out", type: "resourceType" });
        case "reregistration declined":
            return UIRender({ color: "cat-skyblue", text: "Re-Registration Declined", type: "resourceType" });
        case "not yet registered":
            return UIRender({ color: "cat-yellow", text: "Not Yet Registered", type: "resourceType" });
        case "reregistration new":
            return UIRender({ color: "cat-greenhex", text: "Re-Registration New", type: "resourceType" });
        case "reregistration declined":
            return UIRender({ color: "cat-brown", text: "Re-registration Declined", type: "resourceType" });
        case "assessment":
            return UIRender({ color: "cat-greenhex", text: "Assessment", type: "resourceType" });
        case "articulationregistration declined":
            return UIRender({ color: "cat-pink", text: "Articulation-registration Declined", type: "resourceType" });
        case "articulationregistration":
            return UIRender({ color: "cat-dark-red", text: "Articulation-Registration", type: "resourceType" });
        case "reffering to student hub resources":
            return UIRender({ color: "cat-yellowshade", text: "Referring to Student Hub resources", type: "resourceType" });
        case "notification":
            return UIRender({ color: "cat-purple", text: "Notification", type: "resourceType" });
        case "reregistration":
            return UIRender({ color: "cat-blueshade", text: "Re-Registration", type: "resourceType" });
        case "general_info":
            return UIRender({ color: "cat-pinkshade", text: "General Info", type: "resourceType" });
        case "personal_info":
            return UIRender({ color: "cat-blueshade", text: "Personal Info", type: "resourceType" });
        case "student_note":
            return UIRender({ color: "cat-purple", text: "Student Note", type: "resourceType" });
        case "courseandprogram":
            return UIRender({ color: "cat-yellowshade", text: "Course And Program", type: "resourceType" });
        case "account_payer_info":
            return UIRender({ color: "cat-dark-red", text: "Account Payer Info", type: "resourceType" });
        case "education_details":
            return UIRender({ color: "cat-pink", text: "Education Details", type: "resourceType" });
        case "certificates":
            return UIRender({ color: "cat-greenhex", text: "Certificates", type: "resourceType" });
        case "personal_doc":
            return UIRender({ color: "cat-brown", text: "Personal Document", type: "resourceType" });
        case "student":
            return UIRender({ color: "cat-yellow", text: "Student", type: "resourceType" });
        case "notifications":
            return UIRender({ color: "cat-skyblue", text: "Notifications", type: "resourceType" });
        case "confirmation_letter":
            return UIRender({ color: "cat-green", text: "Confirmation Letter", type: "resourceType" });
        case "metric_certificate":
            return UIRender({ color: "cat-blue", text: "Metric Certificate", type: "resourceType" });
        
        default:
            return UIRender({ color: "cat-black", text: data, type: "resourceType" });
    }
};

// only for Certificate Template Resource type
export const RenderCertificateResourceType = (data) => {
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
    case "certificate":
      return UIRender({
        color: "cat-orange",
        text: "Certificate",
        type: "certificateResourceType",
      });

    default:
      return UIRender({
        color: "cat-black",
        text: data,
        type: "certificateResourceType",
      });
  }
};


//Only for subject Items and studentList columns show
export const RenderSubjectItems = (data, key) => {
    switch (key) {
        case "timetable":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Timetable", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Timetable", type: "subjectItems" })
        case "chatroom":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Chatroom", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Chatroom", type: "subjectItems" })
        case "details":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Details", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Details", type: "subjectItems" })
        case "content":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Content", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Content", type: "subjectItems" })  
        case "genderDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Study Guide", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Study Guide", type: "subjectItems" })
        case "studentCard":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Study Guide", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Study Guide", type: "subjectItems" })
        case "nationalityDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Study Guide", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Study Guide", type: "subjectItems" })
        case "idPassportDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Study Guide", type: "subjectItems" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Study Guide", type: "subjectItems" })
        default:
            return "";
    }
};

//Only for subject Items filter
export const RenderSubjectItemsFilter = (data) => {
    switch (data) {
        case "timeTableTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Timetable", type: "subjectItems" })
        case "timeTableFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Timetable", type: "subjectItems" })
        case "chatroomTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Chatroom", type: "subjectItems" })
        case "chatroomFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Chatroom", type: "subjectItems" })
        case "detailsTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Details", type: "subjectItems" })
        case "detailsFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Details", type: "subjectItems" })
        case "contentTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Content", type: "subjectItems" })
        case "contentFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Content", type: "subjectItems" })
        default:
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: data, type: "subjectItems" })
    }
};


//Only for studentList columns items QA View show
export const RenderStudentListItems = (data, type) => {
    switch (type) {
        case "genderDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Gender", type: "studentListFilter" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Gender", type: "studentListFilter" })
        case "studentCard":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Generated", type: "studentListFilter" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Generated", type: "studentListFilter" })
        case "nationalityDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Nationality", type: "studentListFilter" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Nationality", type: "studentListFilter" })
        case "idPassportDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "ID/Passport", type: "studentListFilter" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "ID/Passport", type: "studentListFilter" })
        case "raceDemoGrapchic":
            return data === 1 || data === true
                ? UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Race", type: "studentListFilter" })
                : UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Race", type: "studentListFilter" })
        default:
            return "";
    }
};


//Only for subject Items filter QA View
export const RenderStudentListItemFilter = (data) => {
    switch (data) {
        case "NationalityTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Nationality", type: "studentListFilter" })
        case "NationalityFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Nationality", type: "studentListFilter" })
        case "generatedTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Generated", type: "studentListFilter" })
        case "generatedFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Generated", type: "studentListFilter" })
        case "genderTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Gender", type: "studentListFilter" })
        case "genderFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Gender", type: "studentListFilter" })
        case "IDPassportTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "ID/Passport", type: "studentListFilter" })
        case "IDPassportFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "ID/Passport", type: "studentListFilter" })
        case "raceTrue":
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Race", type: "studentListFilter" })
        case "raceFalse":
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "Race", type: "studentListFilter" })
        default:
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: data, type: "studentListFilter" })
    }
};

export const RenderLastLogin = (data) => {
    switch (data) {
        case "0":
            return UIRender({  text: "No first login", type: "LastLogin" })
        case "1":
            return UIRender({  text: "Last login within the past week", type: "LastLogin" })
        case "2":
            return UIRender({   text: "Last login within the past month", type: "LastLogin" })
        case "3":
            return UIRender({ text: "Last login within the past year", type: "LastLogin" })
        case "4":
            return UIRender({  text: "Last login over 1 year ago", type: "LastLogin" })
        
        default:
            return UIRender({   text: data, type: "LastLogin" })
    }
};

//programme credits

export const RenderCredits = (data) => { 
    switch (data) {
        case 0:
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "No credit", type: "studentListFilter" })
        case 1:
            return UIRender({ icon: "fal fa-check check-true", color: "cat-green", text: "Credit Bearing", type: "studentListFilter" })
        default:
            return UIRender({ icon: "fal fa-times no-cross", color: "cat-red", text: "-", type: "studentListFilter" })
    }
};

//Programme Level
export const RenderProgrammeLevel = (data, origin) => {
    switch (data) {
        case 0:
            return UIRender({ color: "cat-gray", text: "None", type: "programme level", origin });
        case 1:
            return UIRender({ color: "cat-light-green", text: "Beginner", type: "programme level", origin });
        case 2:
            return UIRender({ color: "cat-light-blue", text: "Intermediate", type: "programme level",origin });
        case 3:
            return UIRender({ color: "cat-light-purple", text: "Advanced", type: "programme level",origin });
      case 4:
        return UIRender({ color: "cat-gray", text: "None", type: "programme level", origin });
      default:
        return UIRender({ color: "N/A", text: "-", type: "programme level", origin });
    }
};

export const IconUIRender = ({color, text, icon}) =>{
    return {
        color,
        text,
        html : (<span title={text} style={{color:color}}><i className={`fal ${icon}`}></i> {text}</span>),
        iconOnly : (<span style={{color:color}}><i className={`fal ${icon}`}></i></span>)
    }
}
  
export const RenderFileType = (data) =>{
    const type = data.toString().toLowerCase();
    switch(type){
      case ".png":
        return IconUIRender({color : "#00B7C3", text : "PNG", icon : "fa-file-image"})
      case ".pdf":
        return IconUIRender({color : "#F40F02", text : "PDF", icon : "fa-file-pdf"})
      case ".doc":
        return IconUIRender({color : "#2B579A", text : "DOC", icon : "fa-file-word"})
      case ".jpg":
        return IconUIRender({color : "#00B7C3", text : "JPG", icon : "fa-file-image"})
      case ".xlxs":
        return IconUIRender({color : "#217346", text : "XLXS", icon : "fa-file-excel"})
      case ".wmv":
        return IconUIRender({color : "#CA5010", text : "WMV", icon : "fa-file-video"})
      case ".zip":
        return IconUIRender({color : "#7A7574", text : "ZIP", icon : "fa-file-archive"})
      case ".rar":
        return IconUIRender({color : "#7A7574", text : "RAR", icon : "fa-file-archive"})
      case ".mp4":
        return IconUIRender({color : "#CA5010", text : "MP4", icon : "fa-file-video"})
      case ".xls":
        return IconUIRender({color : "#217346", text : "XLS", icon : "fa-file-excel"})
      case ".docx":
        return IconUIRender({color : "#2B579A", text : "DOCX", icon : "fa-file-word"})
      case ".csv":
        return IconUIRender({color : "#217346", text : "CSV", icon : "fa-file-excel"})
      case ".txt":
        return IconUIRender({color : "#4F6BED", text : "TXT", icon : "fa-file-alt"})
      case ".html":
        return IconUIRender({color : "#00AD56", text : "HTML", icon : "fa-file-code"})
      case ".vsdx":
        return IconUIRender({color : "#4F6BED", text : "VSDX", icon : "fa-file-alt"})
      case ".webp":
        return IconUIRender({color : "#00AD56", text : "WEBP", icon : "fa-file-code"})
      case ".ppt":
        return IconUIRender({color : "#B7472A", text : "PPT", icon : "fa-file-powerpoint"})
      case ".mp3":
        return IconUIRender({color : "#5C2E91", text : "PPT", icon : "fa-file-music"})
    
      default:
        return IconUIRender({color : "#4F6BED", text : type, icon : "fa-file"})
    }
  }
  export const RenderBrandTemplate = (id) => {
    switch (id?.toString()) {
      case "2":
        return UIRender({
          color: "cat-blue",
          text: "Academic Institute of Excellence (AIE)",
          type: "brandList",
        });
      case "3":
        return UIRender({
          color: "cat-red",
          text: "Emendy Multimedia Institute",
          type: "brandList",
        });
      case "4":
        return UIRender({
          color: "cat-green",
          text: "Greenside Design Center (GDC)",
          type: "brandList",
        });
      case "5":
        return UIRender({
          color: "cat-orange",
          text: "LearnFast",
          type: "brandList",
        });
      case "6":
        return UIRender({
          color: "cat-brown",
          text: "The CAD Corporation",
          type: "brandList",
        });
      default:
        return UIRender({ color: "NA", text: "-", type: "brandList" });
    }
  };
  export const RenderMenuItemType = (data) => {
    console.log(data);
    switch (data) {
      case "Custom Link":
        return CampusRender({
          color: "#FB923C",
          text: data,
          icon: "fa-globe",
          type: "menuItem",
        });
      case "Sub-Menu":
        return CampusRender({
          color: "#22C55E",
          text: data,
          icon: "fa-indent",
          type: "menuItem",
        });
      case "Category Sub-Menu":
        return CampusRender({
          color: "#D946EF",
          text: data,
          icon: "fa-tag",
          type: "menuItem",
        });
      case "Column Divider":
        return CampusRender({
          color: "#14B8A6",
          text: data,
          icon: "fa-columns",
          type: "menuItem",
        });
      case "Static Heading":
        return CampusRender({
          color: "#818CF8",
          text: data,
          icon: "fa-heading",
          type: "menuItem",
        });
      case "Landing Page":
        return CampusRender({
          color: "#FB7185",
          text: data,
          icon: "fa-bookmark",
          type: "menuItem",
        });
      case "Standard Link Item":
        return CampusRender({
          color: "#FB7185",
          text: data,
          icon: "fa-bookmark",
          type: "menuItem",
        });
      default:
        return CampusRender({ text: data, icon: "-", type: "menuItem" });
    }
  };
  export const RenderPageTypeType = (data) => {
    switch (data) {
      case "Training Landing page":
        return CampusRender({
          color: "#14B8A6",
          text: data,
          icon: "fa-columns",
          type: "menuItem",
        });
      case "Static page":
        return CampusRender({
          color: "#818CF8",
          text: data,
          icon: "fa-heading",
          type: "menuItem",
        });
      case "Landing page":
        return CampusRender({
          color: "#FB7185",
          text: data,
          icon: "fa-bookmark",
          type: "menuItem",
        });
      case "Course page":
        return CampusRender({
          color: "#FB7185",
          text: data,
          icon: "fa-bookmark",
          type: "menuItem",
        });
      default:
        return CampusRender({ text: data, icon: "-", type: "menuItem" });
    }
  };
  export const RenderHeaderTypeMethod = (data, origin) => {
    console.log(data);
    
    switch (
      data
        ? data.toString().replace("-", "").replace(" ", "").toLowerCase()
        : ""
    ) {
      case "defaultheader":
        return UIRender({
          color: "#ABB072",
          text: "Default Header",
          type: "header",
          origin,
        });
      case "standardheader":
        return UIRender({
          color: "#00A9FF",
          text: "Standard Header",
          type: "header",
          origin,
        });

      default:
        return UIRender({
          color: "N/A",
          text: "-",
          type: "learning method",
          origin,
        });
    }
  };
  export const RenderHeaderPageType = (data, origin) => {
    switch (
      data
        ? data.toString().replace("-", "").replace(" ", "").toLowerCase()
        : ""
    ) {
      case "course":
        return UIRender({
          color: "#ABB072",
          text: "Course",
          type: "header",
          origin,
        });
      case "staticpage":
        return UIRender({
          color: "#5CB85C",
          text: "Static Page",
          type: "header",
          origin,
        });
      case "landingpage":
        return UIRender({
          color: "#FFA800",
          text: "Landing Page",
          type: "header",
          origin,
        });
      case "homepage":
        return UIRender({
          color: "#9747FF",
          text: "Home Page",
          type: "header",
          origin,
        });

      default:
        return UIRender({
          color: "N/A",
          text: "-",
          type: "learning method",
          origin,
        });
    }
  };

  export const RenderCategoriesType = (data, origin) => {
    switch (
      data
        ? data.toString().replace("-", "").replace(" ", "").toLowerCase()
        : ""
    ) {
      case "school":
        return UIRender({
          color: "#007BFF",
          text: "School",
          type: "header",
          origin,
        });
      case "category":
        return UIRender({
          color: "#C12EF5",
          text: "Category",
          type: "header",
          origin,
        });
      default:
        return UIRender({
          color: "N/A",
          text: "-",
          type: "learning method",
          origin,
        });
    }
  };

  export const RenderPopupType = (data, origin) => {
    switch (
      data
        ? data.toString().replace("-", "").replace(" ", "").toLowerCase()
        : ""
    ) {
      case "category_landing_page":
        return UIRender({
          color: "#007BFF",
          text: "Category Landing Page",
          type: "header",
          origin,
        });
      case "short_course":
        return UIRender({
          color: "#C12EF5",
          text: "Short Course",
          type: "header",
          origin,
        });
      case "programme":
        return UIRender({
          color: "#C12EF5",
          text: "Programme",
          type: "header",
            origin,
          });
      case "static_page":
        return UIRender({
          color: "#ABB072",
          text: "Static Page",
          type: "header",
            origin,
          });
      case "course_landing_page":
        return UIRender({
          color: "#C12EF5",
          text: "Course Landing Page",
          type: "header",
            origin,
          });
      case "campus_landing_page":
        return UIRender({
          color: "#C12EF5",
          text: "Campus Landing Page",
          type: "header",
            origin,
          });
      case "blog_and_news_page":
        return UIRender({
          color: "#C12EF5",
          text: "blog And News Page",
          type: "header",
            origin,
          });
      default:
        return UIRender({
          color: "N/A",
          text: "-",
          type: "learning method",
          origin,
        });
    }
  };
import React, { useState, useEffect, useMemo } from 'react';
import { InitialRender } from '../../common/Helper';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from '../../../utils/Constants';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  DeleteStudentRegistration,
  // GetReRegStudentListFilters,
  GetReRegStudentListFiltersCancelToken,
  GetStudentListBasedOnReregistrationCancelToken,
  // GetAllRegistrationsStudentsListFiltersCancelToken,
  ReRegisterStudent,
  // getStudentListBasedOnReregistration,
  programmeReRegisterSubmitStatus,
} from '../../../services/RegistrationService';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { RegStudentColorComb } from '../../systemadministration/usermanagement/CheckRole';
import Hover from '../../common/Hover';
import Tablefilter from '../../common/Tablefilter';
import {
  RenderLateReReg,
  RenderReason,
  RenderRegistrationStatus,
} from '../../../utils/CommonStatusItems';
import Swal from 'sweetalert2';
import PermissionsGate from '../../../utils/permissionGate';
import $ from 'jquery';
import axios from 'axios';

const StudentList = () => {
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [studentListStatus, setStudentListStatus] = useState([]);
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [reason, setReason] = useState({ arr: [], checkObj: {} });
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [salesRep, setSalesRep] = useState({ arr: [], checkObj: {} });
  const [lateReg, setLateReg] = useState({ arr: [], checkObj: {} });
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [studentID, setStudentID] = useState('');
  const [contactedNotes, setContactedNotes] = useState('');
  const [ContactLoading, setContactLoading] = useState(false);
  const [AddLoading, setAddLoading] = useState(false);
  
  const [filterData, setFilterData] = useState({
    intake: [],
    reason: [],
    status: [],
    salesRep: [],
  });
  const [deleteRow, setDeleteRow] = useState(false);

  const handleConatactedStudent = () => {
    setContactLoading(true);
    let data = {
      registration_id: id,
      status: studentID?.registration_status?.includes('Contacted')
        ? studentID?.registration_status
        : studentID?.registration_status + ' - Contacted',
      notes: contactedNotes,
      student_id: studentID?.student,
    };
    programmeReRegisterSubmitStatus(data)
      .then((res) => {
        setContactLoading(false);
        if (res.status === 200) {
          $('#feature-popup').modal('hide');
          setShowCkEditor(false);
          setStudentID('');
          setContactedNotes('');
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.data.message,
            onClose: () => {
              setDeleteRow(!deleteRow);
              //reload page
              window.location.reload();
            },
          });
        }
      })
      .catch((err) => {
        setContactLoading(false);
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${err?.response?.data?.data}`,
        });
      });
  };
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateReRegistrationStudents"));
    if (storedFilters) {
      setIntake(storedFilters.intake);
      setReason(storedFilters.reason);
      setStatus(storedFilters.status);
      setSalesRep(storedFilters.salesRep);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      intake,
      reason,
      status,
      salesRep
    };
    localStorage.setItem("filterStateReRegistrationStudents", JSON.stringify(filterState));
  }, [  intake, reason, status, salesRep]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getListData = async () => {
      setLoading(true);
      try {
        const res = await GetStudentListBasedOnReregistrationCancelToken(
          { id },
          source.token
        );
        if (res.status == 200) {
          setStudentListStatus(
            res && res.data && res.data.qualication ? res.data.qualication : []
          );
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    getListData();

    return () => {
      source.cancel('Component unmounted');
    };
  }, []);

  const filterReRegistrationStatus = () => {
    let unique = [];
    let uniqueStatusFilter = [];
    let filterReRegistrationStatus = [];
    studentListStatus.map((item) => {
      if (item.registration_status) {
        unique.push(item.registration_status);
      }
    });
    unique = [...new Set(unique)];
    unique.map((item) => {
      if (item) {
        uniqueStatusFilter.push({
          value: item,
          label: item,
        });
      }
    });
    filterReRegistrationStatus = uniqueStatusFilter;
    return filterReRegistrationStatus;
  };

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await GetReRegStudentListFiltersCancelToken(
          id,
          dropdownSource.token
        );

        if (res.status === 200) {
          setFilterData({
            intake: res.data.intakeFilter,
            reason: res.data.reasonFilter,
            status: res.data.statusFilter,
            salesRep: res?.data.salesPersonFilter,
            lateReReg: res?.data.lateReRegFilter
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [deleteRow]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentListStatus;
    if (search.length) {
      let tempStdNo = allData.filter((item) => {
        let includes = item.student_number
          ? item.student_number
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          : false;
        if (includes) {
          return includes;
        } else return null;
      });
      let tempFirstname = allData.filter((item) => {
        let includes =
          item.student_name &&
          item.student_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSalesRepFirstname = allData.filter((item) => {
        let includes =
          item.sale_person_first_name &&
          item.sale_person_first_name
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSalesRepLastname = allData.filter((item) => {
        let includes =
          item.sale_person_last_name &&
          item.sale_person_last_name
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempYear = allData.filter((item) => {
        let includes =
          item.year && item.year.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentId = allData.filter((item) => {
        let includes =
          item.student_id &&
          item.student_id.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        let includes =
          item.qualification_name &&
          item.qualification_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCreatedDate = allData.filter((item) => {
        let includes =
          item.created_date &&
          moment
            .unix(item.created_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempStdNo,
        ...tempFirstname,
        ...tempYear,
        ...tempStudentId,
        ...tempProgramme,
        ...tempCreatedDate,
        ...tempSalesRepFirstname,
        ...tempSalesRepLastname,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempIntakeYear = tempStatus.filter((item) => {
        const startsIntakeYear = intake.arr.find(function (post) {
          if (post == (item.full_intake_value ? item.full_intake_value : ''))
            return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });

      updatedData = tempIntakeYear;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempStatusData = tempStatus.filter((item) => {
        const findStatus = status.arr.find(function (post) {
          if (
            post == (item.registration_status ? item.registration_status : '')
          )
            return item;
        });
        if (findStatus) {
          return findStatus;
        } else return null;
      });

      updatedData = tempStatusData;
    }

    if (salesRep.arr.length) {
      let tempSalesRep = updatedData;
      let tempSalesRepData = tempSalesRep.filter((item) => {
        const findSalesRep = salesRep.arr.find(function (post) {
          if (post == (item.sale_person_id ? item.sale_person_id : ''))
            return item;
        });
        if (findSalesRep) {
          return findSalesRep;
        } else return null;
      });

      updatedData = tempSalesRepData;
    }

    if (reason.arr.length) {
      let tempReason = updatedData;
      let tempReasonData = tempReason.filter((item) => {
        const findReason = reason.arr.find(function (post) {
          if (post == (item.not_submit_reason ? item.not_submit_reason : ''))
            return item;
        });
        if (findReason) {
          return findReason;
        } else return null;
      });

      updatedData = tempReasonData;
    }

    if(lateReg.arr.length){
      let tempReg = updatedData;
      let tempRegData = tempReg.filter((item) =>{
        const findReg = lateReg.arr.find(function (post){
          if(post == (item.late_re_reg ? item.late_re_reg : 0))
          return  item;
        });        
        if(findReg){
          return findReg
        }else return null;
      });

      updatedData = tempRegData;
    }

    if (
      search.length ||
      intake.arr.length ||
      status.arr.length ||
      reason.arr.length ||
      salesRep.arr.length ||
      lateReg.arr.length
    ) {
      return updatedData;
    } else {
      return studentListStatus;
    }
  };

  const handleAddRegistration = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will create a new re-registration!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        setAddLoading(true);
        const sendData = {
          reregistration_id: id,
          student_id: data.student,
          early_bird_discount:
            data.early_bird_end_date >
            moment().unix()
              ? 'Yes'
              : 'No',
        };
        ReRegisterStudent(sendData)
          .then((res) => {
            setAddLoading(false);
            if (res.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: res.data.message,
                onClose: () => {
                  setDeleteRow(!deleteRow);
                  var RegID = res?.data?.register;
                  window.location.href = `/studentadministration/registration/open/registration?studentId=${RegID}&studentName=${data.student_name}`;                  
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `${err?.response?.data?.data}`,
            });
          });
      }
    });
  };

  const handleDeleteRegistration = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this registration!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const parameter = { uid: data.registration_id };
        DeleteStudentRegistration(parameter)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: res.data.message,
                onClose: () => {
                  setDeleteRow(!deleteRow);
                  window.location.reload();
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `${err?.response?.data?.data}`,
            });
          });
      }
    });
  };

  const sortingIntake = (rowA, rowB) => {
    const intake1 =
      rowA?.intake_year && rowA.intake_number
        ? rowA.intake_year + '-' + rowA.intake_number
        : '';
    const intake2 =
      rowB?.intake_year && rowB.intake_number
        ? rowB.intake_year + '-' + rowB.intake_number
        : '';
    return intake1.localeCompare(intake2);
  };

  const sortingSalesRep = (rowA, rowB) => {
    const sales1 =
      rowA?.sale_person_first_name && rowA.sale_person_last_name
        ? rowA.sale_person_first_name + '-' + rowA.sale_person_last_name
        : '';
    const sales2 =
      rowB?.sale_person_first_name && rowB.sale_person_first_name
        ? rowB.sale_person_first_name + '-' + rowB.sale_person_last_name
        : '';
    return sales1.localeCompare(sales2);
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA?.student_name ? rowA.student_name : '';
    const name2 = rowB?.student_name ? rowB.student_name : '';
    return name1.localeCompare(name2);
  };

  const reasonSort = (rowA, rowB) => {
    const name1 = rowA?.not_submit_reason ? rowA.not_submit_reason : '';
    const name2 = rowB?.not_submit_reason ? rowB.not_submit_reason : '';
    return name1.localeCompare(name2);
  };

  const stdNoSort = (rowA, rowB) => {
    const name1 = rowA?.student_number ? +rowA.student_number : 0;
    const name2 = rowB?.student_number ? +rowB.student_number : 0;
    return name1 - name2;
  };

  const stdIdSort = (rowA, rowB) => {
    const name1 = rowA?.student_id ? rowA.student_id : '';
    const name2 = rowB?.student_id ? rowB.student_id : '';
    return name1.localeCompare(name2);
  };

  const qualicationNameSort = (rowA, rowB) => {
    const name1 = rowA?.qualification_name ? rowA.qualification_name : '';
    const name2 = rowB?.qualification_name ? rowB.qualification_name : '';
    return name1.localeCompare(name2);
  };

  const dateSort = (rowA, rowB) => {
    const name1 = rowA.created_date
      ? moment.unix(rowA.created_date).unix()
      : '';
    const name2 = rowB.created_date
      ? moment.unix(rowB.created_date).unix()
      : '';
    return name1 - name2;
  };

  const columns = useMemo(() => [
    {
      name: 'Student',
      selector: 'student_name',
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={'assigned-title-blk  name-icon cat-dark-red '}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.photo
                    .replaceAll('/home/myaie/public_html/', '')
                    .replace('public/', '')}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.student_name.split(' ')[0],
                  row.student_name.split(' ').reverse()[0],
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == 'online'
                      ? 'Online'
                      : row.activity_status.toLowerCase() == 'away'
                      ? 'Away'
                      : 'Offline'
                    : 'Offline'
                }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.student_name.split(' ')[0]}
                lastName={row.student_name.split(' ').reverse()[0]}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                right={true}
                role="Sales"
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            {row.registration_id && row.registration_status !="Declined - Contacted" && row.registration_status !="Not Yet Registered - Contacted" && row.registration_status != "Declined" && row.registration_status != "Not Yet Registered" ? (
                <Link
                to={`/studentadministration/registration/open/registration?studentId=${row.registration_id}&studentName=${row.student_name}`}
                className="as-text-blue curser"
              ><span className="textLimit100">{row.student_name}</span></Link>
              )
              : (
                <Link 
                to={`/studentAdministration/students/open/${row.student}/general`}
                className="as-text-blue curser"
                ><span className="textLimit100">{row.student_name}</span></Link>)} 
          </div>
        </div>
      ),
    },
    {
      name: 'Re-registration Status',
      selector: 'registration_status',
      sortable: true,
      cell: (row) =>
        row.registration_status ? (
          <span title={row.registration_status}>
            {RenderRegistrationStatus(row.registration_status).html}
            {row.notes ? (
              <p className="decline-reason-comment" title={row?.notes}>
                {row.notes}
              </p>
            ) : null}
          </span>
        ) : (
          '-'
        ),
    },
    {
      name: 'Decline Reason',
      selector: 'not_submit_reason',
      sortFunction: reasonSort,
      sortable: true,
      cell: (row) => {
        if (row.not_submit_reason && filterData.reason.length) {
          const rowReason = filterData.reason.find(
            (item) => item.value == row.not_submit_reason
          );
          return (
            <span title={row.not_submit_reason_description}>
              {RenderReason(rowReason.label, rowReason.color).html}
              {row.not_submit_reason_description ? (
                <p className="decline-reason-comment">
                  {row.not_submit_reason_description}
                </p>
              ) : null}
            </span>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      name: 'Student No.',
      selector: 'student_number',
      sortFunction: stdNoSort,
      sortable: true,
      cell: (row) =>
        row.student_number ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_number}</span>
          </span>
        ) : (
          '-'
        ),
    },
    {
      name: 'Student ID',
      selector: 'student_id',
      sortable: false,
      cell: (row) =>
        row.student_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_id}</span>
          </span>
        ) : (
          '-'
        ),
    },
    {
      name: 'Programme',
      selector: 'qualification_name',
      sortFunction: qualicationNameSort,
      sortable: true,
      cell: (row) => (
        <span className="overflow-ellipsis2 fst_line2">
          <Link
            to={`/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`}
            className="as-text-blue curser"
            title={row.qualification_name ? row.qualification_name : ''}
          >
            {row.qualification_name ? row.qualification_name : '-'}
          </Link>
        </span>
      ),
    },
    {
      name: 'Intake',
      selector: 'intake_year',
      sortable: true,
      sortFunction: sortingIntake,
      cell: (row) => row.intake_year + '-' + row.intake_number,
    },
    {
      name: 'Date',
      selector: 'created_date',
      sortFunction: dateSort,
      sortable: true,
      cell: (row) => (
        // new Date()
        <>
          {row.created_date ? (
            <div className="dateTime">
              {/* <p className="right-space">{new Date(row.created_date)}</p> */}
              <p className="right-space">
                {moment.unix(row.created_date).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment.unix(row.created_date).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        return row.status ? RegStudentColorComb(row.status.toLowerCase()) : '-';
      },
    },
    {
      name: 'Sales Rep.',
      selector: 'sale_person_first_name',
      sortable: true,
      sortFunction: sortingSalesRep,
      cell: (row) =>
        row?.sale_person_first_name ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={'assigned-title-blk  name-icon cat-dark-red '}>
                {row?.sale_person_photo ? (
                  <img
                    src={`${IMAGE_URL}/${row?.sale_person_photo
                      .replaceAll('/home/myaie/public_html/', '')
                      .replace('public/', '')}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([
                    row.sale_person_first_name,
                    row.sale_person_last_name,
                  ])
                )}
                <span
                  className={`profile-box-2-status ${
                    row.sale_person_activity_status
                      ? row.sale_person_activity_status.toLowerCase() ==
                        'online'
                        ? 'Online'
                        : row.sale_person_activity_status.toLowerCase() ==
                          'away'
                        ? 'Away'
                        : 'Offline'
                      : 'Offline'
                  }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row.sale_person_first_name}
                  lastName={row.sale_person_last_name}
                  photo={row.sale_person_photo}
                  email={row.sale_person_email}
                  mobile={row.sale_person_mobile}
                  // right={true}
                  role="Sales"
                  activity_status={row.sale_person_activity_status}
                  showNumber={true}
                />
              </span>
                              <Link
                to={`/systemAdministration/userManagement/open/${row.sale_person_id}/aboutYou`}
                className="as-text-blue curser"
                >
                <span className="textLimit100">
                  {row?.sale_person_first_name +
                    ' ' +
                    row?.sale_person_last_name}
                </span>
</Link>
            </div>
          </div>
        ) : (
          '-'
        ),
    },
    {
      name: 'Late Re-Reg',
      selector: 'late_re_reg',
      sortable: true,
      cell: (row) => (
        <div className="custom-control custom-checkbox text-left table-curser" title="Late Re-Reg">
          <input
            type="checkbox"
            readOnly
            className="custom-control-input"
            id={`late_re_reg${row.id}`}
            name="late_re_reg"
            checked={row.late_re_reg==1} // Set the initial value from the data            
          />
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    {
      name: 'Action',
      selector: 'Action',
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
          {row.registration_id && row.registration_status !="Declined - Contacted" && row.registration_status !="Not Yet Registered - Contacted" && row.registration_status != "Declined" && row.registration_status != "Not Yet Registered" ? (
                <Link
                title="Open"                
                className="btn btn-primary rounded-circle"
                to={`/studentadministration/registration/open/registration?studentId=${row.registration_id}&studentName=${row.student_name}`}
              ><i className="fal fa-folder-open"></i></Link>
              )
              : (
                <Link 
                title="Open"                
                className="btn btn-primary rounded-circle disabled"to={`/studentAdministration/students/open/${row.student}/general`}
                ><i className="fal fa-folder-open disabled"></i></Link>)} 
            {row.registration_status == 'Not Yet Registered' ||
            row.registration_status == 'Declined' ||
            row.registration_status == "Declined - Contacted"  ||
            row.registration_status == "Not Yet Registered - Contacted" ? (
              <div className="as-buttons dropdown btn-dropdown-item">
                <button
                  className="btn btn-primary rounded-circle dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="More"
                >
                  {AddLoading ? (
                              <i className="fas fa-cog fa-spin"></i>
                            ) : (
                              <i className="fal fa-ellipsis-h-alt"></i>
                            )}
                  
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {row.reregistration_id && row.reregistration_id != '' && row.registration_id && row.registration_status !="Declined - Contacted" && row.registration_status !="Not Yet Registered - Contacted" && row.registration_status != "Declined" && row.registration_status != "Not Yet Registered" ? (
                    <div className="as-buttons btn-dropdown-item">
                      <PermissionsGate
                        scopes={['regdelete']}
                        errorProps={{ disabled: true }}
                      >
                        <button
                          onClick={() => {
                            handleDeleteRegistration(row);
                          }}
                          title="Delete Re-registration"
                          className="btn btn-danger rounded-circle"
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </PermissionsGate>
                    </div>
                  ) : (
                    <div className="as-buttons btn-dropdown-item">
                      <PermissionsGate
                        scopes={['regadd']}
                        errorProps={{ disabled: true }}
                      >
                        <button
                          onClick={() => {
                            handleAddRegistration(row);
                          }}
                          title="Add Re-registration" 
                          className="btn btn-primary rounded-circle"
                        >
                          <i className="fal fa-plus"></i>                          
                        </button>
                      </PermissionsGate>
                    </div>
                  )}

                  <PermissionsGate
                    scopes={['cprrslmac']}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      className="as-buttons btn btn-primary rounded-circle"
                      title="Mark As Called"
                      onClick={() => {
                        $('#feature-popup').modal('show');
                        setShowCkEditor(true);
                        setStudentID(row);
                      }}
                    >
                      <i className="fal fa-phone"></i>
                    </button>
                  </PermissionsGate>
                </div>
              </div>
            ) : row.registration_id != '' ? (
              <div className="as-buttons btn-dropdown-item">
                <PermissionsGate
                  scopes={['regdelete']}
                  errorProps={{ disabled: true }}
                >
                  <button
                    onClick={() => {
                      handleDeleteRegistration(row);
                    }}
                    title="Delete Re-registration"
                    className="btn btn-danger rounded-circle"
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            ) : (
              <div className="as-buttons btn-dropdown-item">
                <PermissionsGate
                  scopes={['regadd']}
                  errorProps={{ disabled: true }}
                >
                  <button
                    onClick={() => {
                      handleAddRegistration(row);
                    }}
                    title="Add Re-registration"
                    className="btn btn-primary rounded-circle"
                  >
                    <i className="fal fa-plus"></i>
                  </button>
                </PermissionsGate>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ]);

  function resetFilter() {
    setSearch('');
    setIntake({ arr: [], checkObj: {} });
    setStatus({ arr: [], checkObj: {} });
    setReason({ arr: [], checkObj: {} });
    setSalesRep({ arr: [], checkObj: {} });
    setLateReg({ arr: [], checkObj: {} });
  }

  const handleFilters = (e, type = '') => {
    const value = e.target.value;
    setSearch(value);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();

    let header = [
      'Student',
      'Mobile',
      'Email',
      'Re-registration Status',
      'Decline Reason',
      'Decline Reason Description',
      'Student No.',
      'Student Id',
      'Programme',
      'Intake',
      'Date',
      'Status',
      'Sales Rep.',
    ];

    data = data?.map((row) => ({
      ...row,
      Student: row?.student_name ? row.student_name : '-',
      Mobile: row?.mobile ? row.mobile : '-',
      Email: row?.email ? row.email : '-',
      'Re-registration Status': row?.registration_status
        ? filterData?.status?.find(
            (item) => item?.value == row?.registration_status
          )?.label
        : row?.registration_status,
      'Decline Reason': row?.not_submit_reason
        ? filterData.reason.find((item) => item?.value == row?.not_submit_reason)
            .label
        : '-',
      'Decline Reason Description': row?.not_submit_reason_description
        ? row?.not_submit_reason_description
        : '-',
      'Student No.': row?.student_number ? row.student_number : '-',
      'Student Id': row?.student_id ? row.student_id : '-',
      Programme: row?.qualification_name ? row.qualification_name : '-',
      Intake: row?.intake_year ? row.intake_year + '-' + row.intake_number : '-',
      Date: row?.created_date
        ? moment.unix(row.created_date).format(TABLE_DATE_FORMAT) +
          ' ' +
          moment.unix(row.created_date).format(TABLE_TIME_FORMAT)
        : '-',
      Status: row.status ? row.status : '-',
      'Sales Rep.': row?.sale_person_first_name
        ? row?.sale_person_first_name + ' ' + row?.sale_person_last_name
        : '-',
    }));

    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === 'csv') {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        'text/csv',
        anchor.download,
        anchor.href,
      ].join(':');
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === 'xlsx') {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === 'pdf') {
      const compatibleData = data.map((row) => {
        return [
          row['Student'],
          row['Mobile'],
          row['Email'],
          row['Re-registration Status'],
          row['Decline Reason'],
          row['Decline Reason Description'],
          row['Student No.'],
          row['Student Id'],
          row['Programme'],
          row['Intake'],
          row['Date'],
          row['Status'],
          row['Sales Rep.'],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: 'left',
          // valign: "center",
          fontSize: 8,
        },
        columnStyles: {
          0: {
            columnWidth: 15,
          },
          1: {
            columnWidth: 13,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 14,
          },
          4: {
            columnWidth: 14,
          },
          5: {
            columnWidth: 14,
          },
          6: {
            columnWidth: 14,
          },
          7: {
            columnWidth: 19,
          },
          8: {
            columnWidth: 12,
          },
          9: {
            columnWidth: 18,
          },
          10: {
            columnWidth: 12,
          },
          11: {
            columnWidth: 15,
          },
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom submisson-table-bg">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={(e) => handleFilters(e, 'search')}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName={'Re-registration Status'}
                        optionArr={filterReRegistrationStatus()}
                        state={status}
                        setState={setStatus}
                        isOptionReversed={true}
                        uniqueId="re-reg-status"
                      />
                      <Tablefilter
                        filterName={'Decline Reason'}
                        uniqueId="re-reg-reason"
                        optionArr={filterData.reason}
                        state={reason}
                        setState={setReason}
                        isOptionReversed={true}
                        renderLabelFunction={RenderReason}
                      />
                      <Tablefilter
                        filterName={'Intake'}
                        optionArr={filterData.intake}
                        state={intake}
                        setState={setIntake}
                        isOptionReversed={true}
                      />
                      <Tablefilter
                        filterName={'Sales Rep'}
                        optionArr={filterData.salesRep}
                        state={salesRep}
                        setState={setSalesRep}
                        isOptionReversed={true}
                      />
                      <Tablefilter
                        filterName={'Late Re-Reg'}
                        optionArr={filterData.lateReReg}
                        state={lateReg}
                        setState={setLateReg}
                        isOptionReversed={true}
                        renderLabelFunction={RenderLateReReg}
                      />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        title="Reset"
                        onClick={resetFilter}
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData('xlsx', 'Registration List');
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData('csv', 'Registration List');
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData('pdf', 'Registration List');
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                            <Link
                                to={`/studentadministration/registration/open-re-registration/add`}
                            >
                                <button className="btn btn-primary" title="Create New">
                                    <i className="fal fa-plus"></i>Create New
                                </button>
                            </Link>
                            </div>
                        </div>
                    </div> */}
          </div>
          {loading ? (
            <SkeletonTicketList />
          ) : (
            <DataTable
              data={dataToRender()}
              defaultSortField={'created_date'}
              defaultSortAsc={false}
              columns={columns}
              pagination={true}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          )}
        </div>
      </div>
      {showCkEditor && (
        <div
          className="topic-add-modal modal fade show"
          style={{ display: 'block' }}
          id="feature-popup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content modal-border-update">
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-phone"></i> Mark As Called
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      $('#feature-popup').modal('hide');
                      setShowCkEditor(false);
                      setStudentID('');
                      setContactedNotes('');
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Notes</label>
                          <textarea
                            className={
                              'form-control' + (false ? ' is-invalid' : '')
                            }
                            placeholder="Add Notes"
                            title="Notes"
                            value={contactedNotes}
                            onChange={(e) => setContactedNotes(e.target.value)}
                            rows={3}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-primary"
                      title="Save"
                      onClick={() => handleConatactedStudent()}
                      disabled={!contactedNotes}
                    >
                      {ContactLoading ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-save"></i>
                      )}
                      Save
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        $('#feature-popup').modal('hide');
                        setShowCkEditor(false);
                        setStudentID('');
                        setContactedNotes('');
                      }}
                    >
                      <i className="fal fa-times"></i>Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentList;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
    getStaticPagesBrandList,
    getStaticPagesHeaderList,
    getStaticPagesPopupList
} from "../../../services/StaticPgesServer";
import { getCampusPagesStatusList } from "../../../services/CampusPagesServer";
import { AddCategoryLandingPages, getCategoryLandingCategoryDropdown, GetCategoryLandingUpdateList, updateCategoryLandingPages } from "../../../services/CategoryLandingPageServer";
import PermissionsGate from "../../../utils/permissionGate";
import CategoryAssignedProgrammes from "./assignedProgrammes/CategoryAssignedProgrammes";
import { getAssigendPagesAndProgrammeShortcourse } from "../../../services/PopupsPagesServer";
import axios from "axios";


const CategoryLandingPagesOpt = () => {
    const history = useHistory();
    const { id, type } = useParams();
    const [updateData, setUpdateData] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [description, setDescription] = useState("")
    const [CustomCss, setCustomCss] = useState("")
    const [checkbox, setCheckbox] = useState({
        addCustomCss: false,
        useCustomColor: false,
    });
    const [statusArr, setStatusArr] = useState([]);
    const [assignedRec, setAssignedRec] = useState([]);
    const [assignloading, setAssignLoading] = useState(false);
    const [popup, setPopup] = useState([]);
    const [header, setHeader] = useState([]);
    const [loading, setLoading] = useState(false);

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete.map((data) => data.brandID);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const getProgramsList = async () => {
            setAssignLoading(true);

            try {
                let res = await getAssigendPagesAndProgrammeShortcourse(id, "1");

                if (res.status === 200) {
                    setAssignedRec(res.data && res?.data ? res?.data : []);
                    setAssignLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }
        }

        getProgramsList()
    }, [id])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            getCampusPagesStatusList()
                .then(res => setStatusArr(res.data.publishedStatus))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getCategoryLandingCategoryDropdown()
                .then(res => setLinkedCampusData(res.data.Data))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getStaticPagesPopupList()
                .then(res => setPopup(res.data.Data))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getStaticPagesHeaderList()
                .then(res => setHeader(res?.data?.Data))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getStaticPagesBrandList()
                .then(res => setBrandList(res.data.brandTypesFilters))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, []);

    useEffect(() => {
        setLoading(true);
        GetCategoryLandingUpdateList(id)
            .then(res => {
                setUpdateData(res?.data?.Data)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }, [id])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            TrainingCourseLandingPageID: updateData?.TrainingCourseLandingPageID || "",
            PageTitle: updateData?.PageTitle || "",
            status: updateData?.status || "",
            category: updateData?.category || "",
            ShowTitleInBody: checkbox.useCustomColor ? 1 : 0,
            BodyContent: description,
            SliderID: updateData?.SliderID || "",
            PopupID: updateData?.PopupID || "",
            formEmail: updateData?.formEmail || "",
            conversionCode: updateData?.conversionCode || "",
            metaTitle: updateData?.MetaTitle || "",
            metaDescription: updateData?.MetaDescription || "",
            metaKeywords: updateData?.MetaKeywords || "",
            checkCustomCss: checkbox.addCustomCss ? 1 : 0,
            customCss: CustomCss,
            Brand: brandID || [],
            assignedPagesAndPMC: assignedRec.length > 0 ? assignedRec.map((item) => ({
                id: item?.id,
                flag: item?.flag,
            })) : [],
        },
        validationSchema: Yup.object({
            PageTitle: Yup.string().required("Page Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            BodyContent: Yup.string().required("Page Content is required"),
            customCss: Yup.string().when('addCustomCss', {
                is: 1,
                then: Yup.string().required("Custom CSS is required"),
                otherwise: Yup.string().nullable()
            })
        }),
        onSubmit: async (values, actions) => {

            const formData = new FormData();

            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );

            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                const value = cleanedValues[key];

                if (Array.isArray(value)) {
                    if (key === "assignedPagesAndPMC") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    }
                } else {
                    formData.append(key, value !== undefined && value !== null ? value : "");
                }
            });

            if (type === "open") {
                setLoading(true);
                updateCategoryLandingPages(formData)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "updated Successfully",
                        });
                        actions.resetForm();
                    })
                    .catch(err => {
                        console.error(err)
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: err.response?.data?.message || "An error occurred",
                        });
                    })
                    .finally(() => setLoading(false));
            } else {
                setLoading(true);
                try {
                    await AddCategoryLandingPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    setLoading(false);
                    history.push("/website_management/pages/categorylandingpages/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                    setLoading(false);
                }
            }
        }
    });

    useEffect(() => {
        setCheckbox({
            ...checkbox,
            addCustomCss: updateData?.checkCustomCss === 1 ? true : false,
            useCustomColor: updateData?.ShowTitleInBody === 1 ? true : false,
        })
        setDescription(updateData?.BodyContent)
        setCustomCss(updateData?.customCss)
    }, [updateData])

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header" title="Page Information">
                    <div className="card-header pt-0">Page Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Page Title">
                                    <label>Page Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.PageTitle && formik.touched.PageTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Page Title"
                                        name="PageTitle"
                                        id="PageTitle"
                                        value={formik.values.PageTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status *</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.status && formik.touched.status
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={statusArr.find(val => val.value === formik.values.status)}
                                        onChange={(option) => {
                                            formik.setFieldValue("status", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={"Select Status"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Linked Category">
                                    <label>Linked Category</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.category && formik.touched.category
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="category"
                                        value={linkedCampusData.find(val => val.value === formik.values.category)}
                                        onChange={(option) => {
                                            formik.setFieldValue("category", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={linkedCampusData}
                                        maxMenuHeight={175}
                                        placeholder={"School of Engineering"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center" title="Show Page Title">
                                <label className="mb-0">
                                    Show Page Title <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={checkbox.useCustomColor}
                                            onChange={(e) => setCheckbox({ ...checkbox, useCustomColor: e.target.checked })}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Page Content">
                                    <label>Page Content*</label>
                                    <HtmlInputEditor
                                        editorState={description}
                                        setEditorState={(editorState) => {
                                            setDescription(editorState);
                                            formik.setFieldValue('BodyContent', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2 mt-0" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="new-card-header col-md-12">
                                <div className="card-header pt-0" title="Page Settings">Page Settings</div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Header">
                                    <label>Header <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.SliderID && formik.touched.SliderID
                                            && "is-invalid"}`}
                                        name="SliderID"
                                        value={header.find(val => val.value === formik.values.SliderID)}
                                        onChange={(option) => {
                                            formik.setFieldValue("SliderID", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={header}
                                        maxMenuHeight={175}
                                        placeholder={"AIE Default"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Popup">
                                    <label>Popup <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.PopupID && formik.touched.PopupID
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="PopupID"
                                        value={popup.find(val => val.value === formik.values.PopupID)}
                                        onChange={(option) => {
                                            formik.setFieldValue("PopupID", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={popup}
                                        maxMenuHeight={175}
                                        placeholder={"Default"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Form Email">
                                    <label>Form Email</label>
                                    <input
                                        type="email"
                                        className={
                                            "form-control" +
                                            (formik.errors.formEmail && formik.touched.formEmail
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Form Email"
                                        name="formEmail"
                                        id="formEmail"
                                        value={formik.values.formEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="SEO Settings">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} color="#EF8700" /> SEO Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Conversion Code">
                                    <label htmlFor="code">Conversion Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.conversionCode && formik.touched.conversionCode
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Conversion Code"
                                        name="conversionCode"
                                        id="conversion"
                                        maxLength={20}
                                        value={formik.values.conversionCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Title">
                                    <label>Meta Title</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaTitle && formik.touched.metaTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Title"
                                        name="metaTitle"
                                        value={formik.values.metaTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Description">
                                    <label>Meta Description</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaDescription && formik.touched.metaDescription
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Description"
                                        name="metaDescription"
                                        value={formik.values.metaDescription}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Keywords">
                                    <label>Meta Keywords</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Keywords"
                                        name="metaKeywords"
                                        value={formik.values.metaKeywords}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Style Settings">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} color="#EF8700" /> Style Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3" title="Add Custom CSS">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={checkbox.addCustomCss}
                                            onChange={(e) => setCheckbox({ ...checkbox, addCustomCss: e.target.checked })}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            {checkbox.addCustomCss === true && <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Customs CSS">
                                    <label>Customs CSS*</label>
                                    <HtmlInputEditor
                                        editorState={CustomCss}
                                        setEditorState={(editorState) => {
                                            setCustomCss(editorState);
                                            formik.setFieldValue('customCss', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>}

                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Assigned Programmes & Pages">
                                    <h4 className="card-header">
                                        Assigned Programmes & Pages
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <CategoryAssignedProgrammes
                                    id={id}
                                    assignedRec={assignedRec}
                                    setAssignedRec={setAssignedRec}
                                    assignloading={assignloading}
                                    setAssignLoading={setAssignLoading}
                                    type={type}
                                />
                            </div>
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[type == "create" ? 'wbscourselpageadd' : 'wbscourselpageedit']}>

                            <button
                                className="btn btn-save btn-success"
                                type="submit"
                                title="Save"
                            >
                                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                Save
                            </button>
                            </PermissionsGate>

                            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => {
                                type === "open" ? formik.resetForm() : history.goBack();
                            }}>
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CategoryLandingPagesOpt;

import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { Trimester, ExamStatus } from "../../../DataSelect";
import Str from "../../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../../../utils/permissionGate";
import {
  AddeditExamData,
  GeteditExamData,
  GetExamPeriodList,
  getFilterDetails,
  NotAssignedIntakelist,
  NotAssignedIntakelistFilter,
} from "../../../../../services/ExternalExamServices";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import Tablefilter from "../../../../common/Tablefilter";
import { RenderLearningMethod } from "../../../../../utils/CommonGroupingItem";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import { FORM_DATE_FORMAT } from "../../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { setExamName } from "../../../../../store/actions";
import axios from "axios";

const DetailsTabList = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const history = useHistory();
  const [editorState, setEditorState] = React.useState("");
  const [search, setSearch] = useState("");
  const [learningMethod, setLearningMethod] = useState({arr:[], checkObj:{}});
  const [FilterList, setFilterList] = useState({study_type : [], intake : []});
  const [editData, setEditData] = useState({});
  const [userData, setUserData] = useState([]);
  const [examTarget, setExamTarget] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingTarget, setLoadingTarget] = useState(false);
  const [examPeriodData, setExamPeriodData] = useState([]);
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [search1, setSearch1] = useState("");
  const [gearLoading, setGearLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false)

  // TODO Api Call
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () =>{
      try {
        const res = await GetExamPeriodList(source.token);
        if(res.status==200){
          let period = res.data.data;
          let data = period.map((val) => ({
            value: val.exam_period,
            label: val.exam_period,
          }));
          setExamPeriodData(data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    
    getData();
    return () =>{
      source.cancel('Component unmounted');
    }
  }, []);

  useEffect(() => {
    const targetSource = axios.CancelToken.source();
    const getExamTargetData = async () => {
      setLoadingTarget(true);
      try {
        const res = await NotAssignedIntakelist(id,targetSource.token);
  
        if(res.status==200){
          let dummy=[];
          let data = res?.data?.data.length && res?.data?.data.map((val,index)=>(
            dummy.push({id:index,concatlist:val.concatlist, new_intake:val.new_intake, number:val.number, type:val.type, year:val.year})
         ))
         dummy.unshift({
           id:Number(res.data.All_active_students.value),
           concatList:res.data.All_active_students.name,
           number:res.data.All_active_students.name,
           type:res.data.All_active_students.name, 
           year:Number(res.data.All_active_students.value),
         });
  
         setExamTarget(dummy);
         setLoadingTarget(false);
        }
        
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoadingTarget(false);
        }
      }      
    }
  
    getExamTargetData();

    return () =>{
      targetSource.cancel('Component unmounted');
    }
  }, [])

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await NotAssignedIntakelistFilter(filterSource.token);
        if(res.status==200){
          setFilterList({
            ...res.data, 
            study_type: res.data.intakeTypeFilter, 
            intake: res.data.yearsAndIntaksFilter
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }

    }
    
    getFilters();
    return () =>{
      filterSource.cancel('Component unmounted');
    }
  }, [])
  

  useEffect(() => {
    if (id) {
      const source = axios.CancelToken.source();
      const getDetails = async () =>{
        setloading(true);
        try {
          const res = await GeteditExamData(id, source.token);
          if(res.status==200){
            dispatch(setExamName(res.data.data.period));
            let finalVal = res.data.data.intake_list.map(item => {
              if(item.intake==-1){
                return({
                  id:-1,
                  concatList:"All Active Students",
                  number:"All Active Students",
                  type:"All Active Students", 
                  year:-1,
                })
              }else{
                let intakeVal = item.intake.split("_")
                return (
                  {
                    ...item,
                    number: intakeVal[0] ? intakeVal[0] : "",
                    year: intakeVal[1] ? intakeVal[1].toString() : "",
                    type: intakeVal[2] ? intakeVal[2] : "",
                    concatlist: item.intake
                  }
                )
              }
            })
  
            setUserData(finalVal);
            setloading(false);
            if (id) {
              let detail = res?.data?.data?.content;
              setEditorState(detail);
              setContentLoading(true);
              setEditData(res.data.data);
            }
          }
          
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            setloading(false);
            setContentLoading(true);
          }
        }
      }
      getDetails();      
      return () =>{
        source.cancel('Component unmounted');
      }
    }
  }, []);

  function addDays(date, days) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
  }

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const handleSearch1Filter = (e) => {
    const value = e.target.value;
    setSearch1(value);
  };

  const resetFilter1=()=>{
    setSearch1("");
  }
  
  // TODO Filtering

  const resetFilter = () => {
    setLearningMethod({ arr: [], checkObj: {} });
    setSearch("");
    setIntake({ arr: [], checkObj: {} })
  };

  // TODO Data
  const dataToTarget = () => {
    let updatedData = [];
    let allData = examTarget;
    if (search.length) {
      let tempIntake = allData.filter((item) => {
        let includes = (item?.year && item?.number) && (item?.year+"-"+item?.number.toString())
          .toString()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempIntake];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (learningMethod.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = learningMethod.arr.find(function (user, index) {
          if (user.toLowerCase() == (item.type ? item.type.toLowerCase() : ""))
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (intake.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = intake.arr.find(function (user, index) {
          console.log("intake",item.year);
          if (user.toLowerCase() == (item.year +"/"+ item.number).toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (
      learningMethod.arr.length ||
      intake.arr.length ||
      search.length
    ) {
      return updatedData;
    } else {
      return examTarget;
    }
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search1.length) {
      let tempIntake = allData.filter((item) => {
        let includes = (item.year+"-"+item.number.toString())
          .toString()
          .includes(search1.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempIntake,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search1.length) {
      return updatedData;
    } else {
      return userData;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) =>
        row.id === -1 ?
          row.number
        : row.year && row.number
          ? row.year + "-" + row.number
          : "-",
    },

    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => row.id === -1 ? row.number : row.type ? RenderLearningMethod(row.type).html : "-",
    },

    {
      name: "Action",
      selector: "",

      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={type=="add" ? ["eerleadd"]: ["eerleedit"]} errorProps={{disabled: true}}>
              <button
                title="Delete"
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row,row.UserID)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const targetColumns = useMemo(() => [
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) =>
      row.id === -1 ? row.number 
      : (row.year && row.number) ? row.year + "-" + row.number : "-",
    },

    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) =>
      row.id === -1 ? row.number 
      : row.type ? RenderLearningMethod(row.type).html : "-"
    },

    {
      name: "Action",
      selector: "",

      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Add"
              className="btn btn-primary rounded-circle"
              // data-dismiss="modal"
              onClick={() => handleAdd(row, row.id)}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);
  const handleAdd = (data, id) => {
    const addData = examTarget.filter((val)=>{
      return val.id!==id
    })
    setExamTarget(addData)
    setUserData((prev) => [...prev, data]);
//     function deepEqual(x, y) {
//       const ok = Object.keys, tx = typeof x, ty = typeof y;
//       return x && y && tx === 'object' && tx === ty ? (
//         ok(x).length === ok(y).length &&
//           ok(x).every(key => deepEqual(x[key], y[key]))
//       ) : (x === y);
//     }
//     if (!userData.filter(val => deepEqual(val, row)).length) {
//       setUserData((prev) => [...prev, row]);
//     } else {
 
// Swal.fire({
//   title: "Already exist",
//   text: "This Exam Target is already assigned",
//   icon: "error",  })
//     }
  };


  const handleDelete = (row,ID) => {
    console.log(row);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let arr = userData
        arr.splice(arr.indexOf(row),1)
        setExamTarget((prev)=>[...prev,row]);
        setUserData(()=>[...arr])
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      }
    });
  };

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Intake", "Learning Method"];

    data = data?.map((row) => ({
      ...row,
      Intake: row?.year && row?.number ? row?.year + "-" + row?.number : "-",

      "Learning Method": row?.type ? RenderLearningMethod(row?.type).text : "NA",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Intake"], row["Learning Method"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const exportTargetData = (fileType, fileName) => {
    let data = dataToTarget();
    const header = ["Intake", "Learning Method"];

    data = data?.map((row) => ({
      ...row,
      Intake: row?.year && row?.number ? row?.year + "-" + row?.number : "-",

      "Learning Method": row?.type ? RenderLearningMethod(row?.type).text : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Intake"], row["Learning Method"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      exam_period: editData ? editData.period : "",
      trimester: editData ? editData.trimester : "",
      exam_status: editData ? editData.status : "",
      start_date: editData.start? new Date(moment.unix(editData.start)): "",
      end_date: editData.end? new Date(moment.unix(editData.end)): "",
      close_date: editData.close? new Date(moment.unix(editData.close)): "",
      hard_launch_date: editData.hard_launch_date? new Date(moment.unix(editData.hard_launch_date)): "",
      content: "",
    },
    validationSchema: Yup.object({
      exam_period: Yup.string().required("Exam Period is required "),
      trimester: Yup.string().required("Trimester is required"),
      exam_status: Yup.string().required("Exam Status is required"),
      start_date: Yup.string().required("Start Date is required"),
      end_date: Yup.string().required("End Date is required"),
      close_date: Yup.string().required("Registration Close Date is required").test(
        "is-greater",
        "Registration close date should be less than end date",
        function (value) {
          const { end_date } = this.parent;
          return moment(value).isSameOrBefore(moment(end_date));
        }
      ),
      hard_launch_date: Yup.string().required("Hard Launch Date is required"),
      content: Yup.string().test(
      "required",
      "Content is Required",
      (value) => {
        let feature = formatQuestion(editorState);
        let isInvalid = feature == "-";
        return !isInvalid;
      }
    ),
    }),
    onSubmit: (values, { resetForm }) => {
      
      let startDate = values.start_date ? new Date(values.start_date).getTime().toString() / 1000 : "";
      let endDate = values.end_date ? new Date(values.end_date).getTime().toString() / 1000 : "";
      let closeDate = values.close_date ? new Date(values.close_date).getTime().toString() / 1000 : "";
      
      if (startDate > endDate && startDate > closeDate) {
        formik.setErrors({
          start_date: "Start date should be less than end date and close date",
        });
        return;
      }else if(startDate > endDate){
        formik.setErrors({
          start_date: "Start date should be less than end date",
        });
        return;
      }else if(startDate > closeDate){
        formik.setErrors({
          start_date: "Start date should be less than close date",
        });
        return;
      }
      setGearLoading(true)
      let intakeData = userData.map((item,ind)=> {
        return {id : item.id === -1 ? item.id.toString() : item.concatlist}
      })
      let formData = new FormData();


      formData.set("uid", id ? id : "");
      formData.set("period", values?.exam_period);
      formData.set("start", moment(values?.start_date).format("DD-MM-YYYY"));
      formData.set("end", moment(values?.end_date).format("DD-MM-YYYY"));
      formData.set("close", moment(values?.close_date).format("DD-MM-YYYY"));
      formData.set("hard_launch_date", moment(values?.hard_launch_date).format("DD-MM-YYYY"));
      formData.set("content", editorState);
      formData.set("intakes", intakeData ? JSON.stringify(intakeData) : "");
      formData.set("status", values?.exam_status);
      formData.set("trimester", values?.trimester);
      
      AddeditExamData(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        if(type=="add"){
          history.push("/studentAdministration/externalExam/details/table");
        }
        
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      }).finally(()=>{
        setGearLoading(false)
      });
    },
  });

  const handleCancel = () =>{
    if(type=="add"){
      history.push("/studentAdministration/externalExam/details/table");
    }else{
      formik.resetForm();
    }
  }
  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable">
        <div className="custom-table-div filter-search-icon card card-table-custom appeals-tables">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label htmlFor="trimester">Trimester*</label>
                <div className="form-icon-group mb-4" title="Trimester">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.trimester && formik.touched.trimester
                        ? " is-invalid"
                        : "")
                    }
                    name="trimester"
                    value={Trimester.filter((val) => {
                      return val.value == formik.values.trimester;
                    })}
                    onChange={(value) =>{
                      if(value){
                        formik.setFieldValue("trimester", value.value)
                      } else {
                        formik.setFieldValue("trimester", "")
                      }
                    }
                    }
                    isClearable
                    onBlur={formik.handleBlur}
                    options={Trimester}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.trimester
                        ? formik.values.trimester
                        : "Trimester  "
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="exam_status">Exam Status*</label>
                <div className="form-icon-group mb-4" title="Exam Status">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.exam_status && formik.touched.exam_status
                        ? " is-invalid"
                        : "")
                    }
                    name="exam_status"
                    value={ExamStatus.filter((val) => {
                      return val.value == formik.values.exam_status;
                    })}
                    onChange={(value) =>{
                      if(value){
                        formik.setFieldValue("exam_status", value.value)
                      } else {
                        formik.setFieldValue("exam_status", "")
                      }
                    }
                    }
                    isClearable
                    onBlur={formik.handleBlur}
                    options={ExamStatus}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.exam_status
                        ? formik.values.exam_status
                        : "Exam Status  "
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="exam_period">Exam Period*</label>
                <div className="form-icon-group mb-4" title="Exam Period">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.exam_period && formik.touched.exam_period
                        ? " is-invalid"
                        : "")
                    }
                    name="exam_period"
                    value={examPeriodData.filter((val) => {
                      return val.value == formik.values.exam_period;
                    })}
                    onChange={(value) =>{
                      if(value){
                        formik.setFieldValue("exam_period", value.value)
                      } else {
                        formik.setFieldValue("exam_period", "")
                      }
                    }
                    }
                    isClearable
                    options={examPeriodData.sort((a,b)=>{return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1})}
                    onBlur={formik.handleBlur}
                    // options={}
                    maxMenuHeight={175}
                    placeholder={"Exam Period"}
                  />
                </div>
              </div>              
              <div className="col-md-3">
                <label>Registration Start Date*</label>
                <div className="form-group-blk mb-3" title="Registration Start Date">
                  <DatePicker
                    minDate={new Date()}
                    dateFormat={FORM_DATE_FORMAT}
                    className={
                      "form-control cursor-pointer" +
                      (formik.errors.start_date && formik.touched.start_date
                        ? " is-invalid"
                        : "")
                    }
                    selected={formik.values.start_date}
                    // onChange={(date) => setStartDate(date)}
                    onChange={(date) => {
                      formik.setFieldValue("start_date", date);
                    }}
                    title="Registration Start Date"
                    placeholderText="Select Registration Start Date"
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    dateFormatCalendar="MMMM"
                    showYearDropdown
                    scrollableYearDropdown
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Hard Launch Date*</label>
                <div className="form-group-blk mb-3" title="Hard Launch Date">
                  <DatePicker
                    minDate={new Date()}
                    dateFormat={FORM_DATE_FORMAT}
                    className={
                      "form-control cursor-pointer" +
                      (formik.errors.hard_launch_date && formik.touched.hard_launch_date
                        ? " is-invalid"
                        : "")
                    }
                    selected={formik.values.hard_launch_date}
                    // onChange={(date) => setStartDate(date)}
                    onChange={(date) => {
                      formik.setFieldValue("hard_launch_date", date);
                    }}
                    title="Hard Launch Date"
                    placeholderText="Select Hard Launch Date"
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    dateFormatCalendar="MMMM"
                    showYearDropdown
                    scrollableYearDropdown
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Registration Close Date*</label>
                <div
                  className="form-group-blk mb-3"
                  title="Registration Close Date"
                >
                  <DatePicker
                    minDate={formik.values.start_date}
                    maxDate={formik.values.end_date}
                    dateFormat={FORM_DATE_FORMAT}
                    className={
                      "form-control cursor-pointer" +
                      (formik.errors.close_date && formik.touched.close_date
                        ? " is-invalid"
                        : "")
                    }
                    selected={formik.values.close_date}
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormatCalendar="MMMM"
                    title="Registration Close Date"
                    onChange={(date) => {
                      formik.setFieldValue("close_date", date);
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    placeholderText="Select Registration Close Date"
                    // onChange={(e) => onHandleEndDate(e)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Exam End Date*</label>
                <div className="form-group-blk mb-3" title="Exam End Date">
                  <DatePicker
                    minDate={addDays(formik.values.start_date,1)}
                    disabled={!formik.values.start_date}
                    dateFormat={FORM_DATE_FORMAT}
                    className={
                      "form-control cursor-pointer" +
                      (formik.errors.end_date && formik.touched.end_date
                        ? " is-invalid"
                        : "")
                    }
                    selected={formik.values.end_date}
                    // onChange={(date) => setEndDate(date)}
                    onChange={(date) => {
                      formik.setFieldValue("end_date", date);
                    }}
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormatCalendar="MMMM"
                    title="Exam End Date"
                    placeholderText="Select Exam End Date"
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>                
              </div>              
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Details*</label>
                <div className="form-group-blk mb-3" title="Content">
                  {((contentLoading) || type=="add") && <HtmlInputEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                    isCKEditor
                    hideSign
                    isInvalid={
                      formik.touched.content &&
                      formik.errors.content
                    }
                  />}
                </div>
              </div>
            </div>
            <hr />
            <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearch1Filter}
                          value={search1}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll"></div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter1}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "External Details");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "External Details");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "External Details");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate
                    errorProps={{ disabled: true }}
                    scopes={type=="add" ? ["eerleadd"]: ["eerleedit"]}
                  >
                    <button
                      className="btn btn-primary"
                      title="Add Exam Target"
                      data-toggle="modal"
                      data-target="#notifications1"
                      // onClick={getExamTargetData}
                      type="button"
                    >
                      <i className="fal fa-plus"></i>Add Exam Target
                    </button>
                  </PermissionsGate>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <SkeletonTicketList />
          ) : (
            <DataTable
              data={dataToRender()}
              defaultSortField="year"
              defaultSortAsc={true}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
            />
          )}
            <div className="form-group form-group-save-cancel mt-3">
            <PermissionsGate errorProps={{ disabled: true }} scopes={type=="add" ? ["eerleadd"]: ["eerleedit"]}>
              <button
                className="btn btn-save btn-success"
                type="submit"
                title="Save"
              >
                {gearLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
              </button>
              </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={() =>{handleCancel()}}
              >
                <i className="fal fa-times"></i>
                Cancel
              </button>
              {formik.touched.exam_period && formik.errors.exam_period ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.exam_period}
                </div>
              ) : null}
              {formik.touched.trimester && formik.errors.trimester ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.trimester}
                </div>
              ) : null}
              {formik.touched.exam_status && formik.errors.exam_status ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.exam_status}
                </div>
              ) : null}
              {formik.touched.start_date && formik.errors.start_date ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.start_date}
                </div>
              ) : null}
              {formik.touched.hard_launch_date && formik.errors.hard_launch_date ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.hard_launch_date}
                </div>
              ) : null}
              {formik.touched.end_date && formik.errors.end_date ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.end_date}
                </div>
              ) : null}
              {formik.touched.close_date && formik.errors.close_date ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.close_date}
                </div>
              ) : null}
              {formik.touched.content && formik.errors.content ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.content}
                </div>
              ) : null}
            </div>
          </form>
          
          {/* Exam Target table display in popup */}
          <div
            className="topic-add-modal modal fade"
            id="notifications1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered available-assessment-modal"
              role="document"
            >
              <div className="modal-content">
              <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i>  Add Exam Target</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                <div className="modal-body">
                 

                  <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
                    <div className="custom-table-div filter-search-icon card-table-custom">
                      <div className="search-filter-div">
                        <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                          <div
                            id="assessment-table-main_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                              <div
                                id="assessment-table-main_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  <input
                                    type="search"
                                    className=""
                                    placeholder="Search"
                                    aria-controls="assessment-table-main"
                                    onChange={handleSearchFilter}
                                    value={search}
                                  />
                                </label>
                                <div className="filter-eff filter-data-btn">
                                  <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                  </button>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="filter-button-group">
                          <div className="filter-scroll">
                            <div className={`filter-scroll-inner  filter-custom-new`}>
                              <Tablefilter
                                filterName={"Intake"}
                                optionArr={FilterList?.intake}
                                state={intake}
                                setState={setIntake}
                                isOptionReversed={true}
                              />
                              <Tablefilter
                                filterName={"Learning Method"}
                                optionArr={FilterList?.study_type}
                                state={learningMethod}
                                setState={setLearningMethod}
                                renderLabelFunction={RenderLearningMethod}
                              />
                            </div>
                          </div>
                          <div className="reset-btn-group">
                            <div className="button-reset dropdown-comman">
                              <button
                                className="btn btn-primary"
                                onClick={resetFilter}
                                title="Reset"
                              >
                                <i className="fal fa-redo"></i>Reset
                              </button>
                            </div>
                            <div className="files-export-group">
                              <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                  exportTargetData("xlsx", "exam-target");
                                }}
                                title="Export spreadsheet"
                              >
                                <i className="fal fa-file-excel icon"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                  exportTargetData("csv", "exam-target");
                                }}
                                title="Export CSV"
                              >
                                <i className="fal fa-file-csv icon"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                  exportTargetData("pdf", "exam-target");
                                }}
                                title="Export PDF"
                              >
                                <i className="fal fa-file-pdf icon"></i>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                        </div>
                      </div>
                      
                      {loadingTarget ? (
                        <SkeletonTicketList />
                      ) : (
                        <DataTable
                          data={dataToTarget()}
                          defaultSortField="year"
                          defaultSortAsc={true}
                          columns={targetColumns}
                          pagination={true}
                          noDataComponent={Str.noRecord}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsTabList;

import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import { DeleteStudentRegistration, GetAllRegistrationsStudentsList, GetAllRegistrationsStudentsListCancelToken, GetAllRegistrationsStudentsListFiltersCancelToken, GetQualificationOptionsInactiveCancelToken, GetsalesPersonFilterListCancelToken } from "../../../services/RegistrationService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { Link } from "react-router-dom";
import Hover from "../../common/Hover";
import FilterComponent from "../../common/FilterComponent";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import Tablefilter from "../../common/Tablefilter";
import { RenderProgrammeStudentStatus, RenderRegistrationStatus } from "../../../utils/CommonStatusItems";
import { RenderBrandList, RenderCampus, RenderLaptopSpec, RenderLearningMethod, RenderSchool } from "../../../utils/CommonGroupingItem";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";

const ListofRegisteredStudents = () => {
  
  const [loading, setloading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [value, onChange] = useState([]);
  const [qualificationValue, setqualificationValue] = useState([])
  const [search, setSearch] = useState("");
  const [salesPerson, setSalesPerson] = useState([]);
  const [salesFilter, setSalesFilter] = useState([]);
  const [searchSalesCheck, setSearchSalesCheck] = useState({});
  const [qualification, setQualification] = useState([]);
  const [searchQualificationCheck, setSearchQualificationCheck] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState('created_date');
  const [sortOrder, setSortOrder] = useState("DESC");
  const [qualOption, setQualOption] = useState([])
  const [NSQualOptions, setNSQualOptions] = useState([])
  
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [applicationStatus, setApplicationStatus] = useState({ arr: [], checkObj: {} });
  const [school, setSchool] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [brandName, setBrandName] = useState({ arr: [], checkObj: {} });
  const [campus, setCampus] = useState({ arr: [], checkObj: {} });
  const [laptop, setLaptop] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({status : [], aStatus : [], sPerson : [], school : [], intake : [], learning : [], campus : [], brand: [], laptop : []})
  const [debouncedTerm, setDebouncedTerm] = useState("")
  // const [cancelTokenSources, setCancelTokenSources] = useState([]);
  const [deleteData, setDeleteData] = useState(false)
  
  useEffect(() => {
    handleTableScroll()
  }, [loading])
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])
  
  useEffect(() => {
    const cancelTokenSources = [];
    const getAllRegistrationsStudentsList = async ()=>{
      setloading(true)
      
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
      
      let values = {
        page,
        limit: perPage,
        intake: intake.arr,
        viaStatus: status.arr,
        learning_method: learningMethod.arr,
        search,
        key: sortkey,
        sort: sortOrder,
        exportStaus: "false",
        appStatus: applicationStatus.arr,
        salesPerson: salesFilter,
        qualification,
        school: school.arr,
        campus: campus.arr,
        laptop: laptop.arr,
        brand_Name : brandName.arr,
        isReRegister: []
      }
      
      try {
        const res = await GetAllRegistrationsStudentsListCancelToken(values, source.token);
        setStudentList(res && res.data && res.data.registrations_student_list ? res.data.registrations_student_list.data : []);
        setTotalRows(res.data.registrations_student_list.total)
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    
    getAllRegistrationsStudentsList();
    
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page, perPage, intake, status, learningMethod, search, sortkey, sortOrder, applicationStatus, salesFilter, qualification, school, campus, laptop, brandName, searchSalesCheck, searchQualificationCheck, deleteData]);
  
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    
    const fetchData = async () => {
      try {
        const res = await GetAllRegistrationsStudentsListFiltersCancelToken(dropdownSource.token);
        setFilterData({...res.data, status : res.data?.studentStatusFilters, aStatus : res.data?.applicationStatusFilters, school : res.data?.schoolFilter, intake : res.data?.yearsAndIntaksFilter,
          learning : res.data?.learningMethodFilter, campus : res.data?.campusSelectedFilter, laptop : res.data?.laptopSelectedFilter, brand : res.data?.brandFilter, sPerson : res.data?.salePersonFilter})
          setNSQualOptions(res.data?.netsuiteProductIds);    
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };
      
      fetchData();
      
      return () => {
        dropdownSource.cancel('Component unmounted');
      };
    }, []);
    
    useEffect(() => {
      const qualificationSource = axios.CancelToken.source();
      
      const fetchQualData = async () => {
        try {
          const res = await GetQualificationOptionsInactiveCancelToken(false, qualificationSource.token);
          let tempArr = [];
          res.data.data.items.map((item) => {
            if(NSQualOptions.includes(parseInt(item.id))){
              let singleItem = {
                value: item.id,
                label: item.displayname,
                departmentId: item.department,
              };          
              tempArr.push(singleItem);
            }
          });
          let sortedArr = tempArr.sort(sort_by('label', false, (a) => a?.toUpperCase()))
          setQualOption([...sortedArr]);
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };
      
      fetchQualData();
      
      return () => {
        qualificationSource.cancel('Component unmounted');
      };
    }, [NSQualOptions]);

    useEffect(() => {
      const storedFilters = JSON.parse(localStorage.getItem("filterStateLORS"));
      if (storedFilters) {
        setBrandName(storedFilters.brandName);
        setSchool(storedFilters.school);
        setIntake(storedFilters.intake);
        setLearningMethod(storedFilters.learningMethod);
        setCampus(storedFilters.campus);
        setLaptop(storedFilters.laptop);
        setStatus(storedFilters.status);
        setApplicationStatus(storedFilters.applicationStatus);
        setSalesFilter(storedFilters.salesFilter); //salesFilter
      }
    }, []);
    useEffect(() => {
      const filterState = {
        brandName,
        school,
        intake,
        learningMethod,
        campus,
        laptop,
        status,
        applicationStatus,
        salesFilter
      };
      localStorage.setItem("filterStateLORS", JSON.stringify(filterState));
    }, [brandName, school, intake, learningMethod, campus, laptop, status, applicationStatus, salesFilter]);
    
    useEffect(() => {
      const salesDropdownSource = axios.CancelToken.source();
      const getSalesFilterName = async () => {
        try {
          const response = await GetsalesPersonFilterListCancelToken(salesDropdownSource.token);
          let newSales = response.data.data.map((data) => {
            return {
              value: data.UserID,
              label: data.name
            }
          })
          let sortedArr = newSales.sort(sort_by('label', false, (a) => a?.trim()?.toUpperCase()))
          setSalesPerson(sortedArr);
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      }
      
      getSalesFilterName();
      
      return () => {
        salesDropdownSource.cancel('Component unmounted');
      };
    }, [])
    
    const sort_by = (field, reverse, primer) => {
      const key = primer ?
      function (x) {
        return primer(x[field])
      } :
      function (x) {
        return x[field]
      };
      reverse = !reverse ? 1 : -1;
      return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }
    
    const handleDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          DeleteStudentRegistration({ uid: id })
          .then((res) => {
            Swal.fire(
              "Removed!",
              "Registration has been removed.",
              "success"
            );
          })
          .catch((error) => console.log(error));
          // handleData();
          setDeleteData(!deleteData)
        }
      });
    };
    
    const columns = useMemo(() => [
      {
        name: "Full Name",
        selector: "first_name",
        sortField: "fullname",
        sortable: true,
        cell: (row) => (
          <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
          <span className={"assigned-title-blk  name-icon bgBlue "}>
          {row.photo ? (<img src={`${IMAGE_URL}/${row.photo.replaceAll("public/").replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" />) : (
            InitialRender([parseName(row.first_name), parseName(row.last_name)])
          )}
          <span className={`profile-box-2-status ${row.student_activity_status && row.student_activity_status.toLowerCase()=="online" ? "Online" : "Offline"}`}>
          <i className="fas fa-circle"></i>
          </span>
          <Hover firstName={parseName(row.first_name)} lastName={parseName(row.last_name)} photo={row.photo ? row.photo.replaceAll("home/myaie/public_html/", "") : ""} email={row.email} mobile={row.mobile} right={true} role="Sales" showNumber={true} number={row.student_crm_id} status={row.student_status} activity_status={row.student_activity_status} />
          </span>
          
          <Link to={`/studentadministration/registration/open/registration?studentId=${row.uid}&studentName=${parseName(row.first_name)} ${parseName(row.last_name)}`} >
          <span className="as-text-blue curser feature-name" title={row.first_name + " " + row.last_name}><span className="">{parseName(row.first_name + " " + row.last_name)}</span></span>
          </Link>
          </div>
          </div>
        ),
      },
      {
        name: "Student No.",
        selector: "student_crm_id",
        sortField: "student_crm_id",
        sortable: true,
        cell: (row) => row.student_crm_id  ? `${row.student_crm_id}` : "-"
      },
      {
        name: "Date",
        selector: "created_date",
        sortable: true,
        sortField: "created_date",
        cell: (row) => (
          <>
          {row.created_date ? (
            <div className="">
            <p className="right-space">
            {moment.unix(row.created_date).format(TABLE_DATE_FORMAT)}
            </p>
            <p>{moment.unix(row.created_date).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
          </>
        ),
      },
      {
        name: "Status",
        selector: "current_status",
        sortable: true,
        sortField: "C_status",
        cell: (row) => RenderProgrammeStudentStatus(row.current_status).html
      },
      {
        name: "Application Status",
        selector: "status",
        sortField: "status",
        sortable: true,
        cell: (row) => row.status ? RenderRegistrationStatus(row.status).html : "-"
      },
      {
        name: "Sales Person",
        selector: "sales_person_first_name",
        sortable: true,
        sortField: "salesperson",
        cell: (row) => (
          <>
          {row.sales_person_first_name ? (
            <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon bgBlue "}>
            {row.sales_photo ? (<img src={`${IMAGE_URL}/${row.sales_photo.replaceAll("public/","").replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" />) : (
              InitialRender([row.sales_person_first_name, row.sales_person_last_name,])
            )}
            <span className={`profile-box-2-status ${row.sales_person_activity_status && row.sales_person_activity_status.toLowerCase()=="online" ? "Online" : "Offline"}`}>
            <i className="fas fa-circle"></i>
            </span>
            <Hover firstName={row.sales_person_first_name} lastName={row.sales_person_last_name} photo={row.sales_photo} email={row.sales_person_email} mobile={row.sales_person_mobile} right={true} role="Sales" status={row.sales_person_status} activity_status={row.sales_person_activity_status}/>
            </span>
            <Link className="as-text-blue curser textLimit76 " to={`/systemAdministration/userManagement/open/${row.sale_person_id}3/aboutYou`}>
            {row.sales_person_first_name}
            </Link>
            </div>
            </div>
          ) : (
            "NA"
          )}
          </>
        ),
      },
      {
        name: "Qualification",
        selector: "qualification",
        sortField: "netsuite_product_id",
        sortable: true,
        cell: (row) => {
          return (
            <span className="overflow-ellipsis2 fst_line2">
            <p className={"titleTextLimit30"} title={row.qualification ? getValue("qualification", row.qualification) : "-"} >
            {row.qualification ? getValue("qualification", row.qualification) : "-"}
            </p>
            </span>
          );
        },
      },
      {
        name: "Brand",
        selector: "brand_name",
        sortable: true,
        sortField: "brand_name",
        cell: (row) => row.brand_name ?  <span className="feature-name"><span className="textLimit100"> { RenderBrandList(row.brand_name).html}</span> </span>  : "-"
      },
      {
        name: "School",
        selector: "academy",
        sortField: "academy",
        sortable: true,
        cell: (row) => row.academy ?  <span className="feature-name"><span className="textLimit100"> { RenderSchool(row.academy).html}</span> </span>  : "-"
      },
      {
        name: "Intake",
        selector: "intake_year",
        sortField: "intake_year",
        sortable: true,
        cell: (row) => row.intake_year && row.intake_year !== "0-0" ? `${row.intake_year}` : "-"
      },
      {
        name: "Delivery Method",
        selector: "delivery_methode",
        sortField: "delivery_methode",
        sortable: true,
        cell: (row) => row.delivery_methode ? <span className="feature-name"><span className="textLimit100">{RenderLearningMethod(row.delivery_methode).html}</span></span> : "-"
      },
      {
        name: "Campus",
        selector: "campus_selected",
        sortField: "campus_selected",
        sortable: true,
        cell: (row) => row.campus_selected ? <span className="feature-name"><span className="textLimit100">{RenderCampus(row.campus_selected).html}</span></span> : "-"
      },
      {
        name: "Laptop",
        selector: "laptop",
        sortField: "laptop",
        sortable: true,
        cell: (row) => row.laptop ? <span className="feature-name"><span className="textLimit100">{RenderLaptopSpec(row.laptop).html}</span></span> : "-"
      },
      // {
      //   name: "Tracking No",
      //   selector: "number",
      //   sortable: true,
      //   sortField: "tracking",
      //   cell: (row) => {
        //     return <p title={row.tracking_no}>{row.tracking_no ? TrimText(row.tracking_no, 10) : "-"}</p>;
      //   },
      // },
      {
        name: "Actions",
        selector: "",
        cell: (row) => (
          <div className="assessment-08">
          <div className="as-buttons">
          <PermissionsGate scopes={["regedit"]}>
          <Link
          to={`/studentadministration/registration/open/registration?studentId=${row.uid}&studentName=${row.first_name} ${row.last_name}`}
          className="btn btn-primary rounded-circle"
          title="Open"
          >
          <i className="fal fa-folder-open"></i>
          </Link>
          </PermissionsGate>
          <PermissionsGate scopes={["regdelete"]}>
          <button
          title="Unlink"
          className="btn btn-danger rounded-circle"
          onClick={() => handleDelete(row.uid)}
          >
          <i className="fal fa-trash-alt"></i>
          </button>
          </PermissionsGate>
          
          </div>
          </div>
        ),
      },
    ]);
    
    const handleFilters = (e, type = "") => {
      const value = e.target.value;
      if (type === "search") {
        setSearch(value);
      } else if (type === "sales") {
        let arr = salesFilter;
        if (arr.includes(value)) {
          arr.splice(arr.indexOf(value), 1);
        } else {
          arr.push(value);
        }
        setSalesFilter(arr);
        let id = e.target.id;
        setSearchSalesCheck({ ...searchSalesCheck, [id]: !searchSalesCheck[id] });
      } else if (type === "qualification") {
        let arr = qualification;
        if (arr.includes(value)) {
          arr.splice(arr.indexOf(value), 1);
        } else {
          arr.push(value);
        }
        setQualification(arr);
        let id = e.target.id;
        setSearchQualificationCheck({ ...searchQualificationCheck, [id]: !searchQualificationCheck[id], });
      }
    };
    
    function resetFilter() {
      setSearch("");
      setDebouncedTerm("")
      setSalesFilter([]);
      setSearchSalesCheck({});
      setQualification([]);
      setSearchQualificationCheck({});
      setqualificationValue([])
      onChange([])
      setIntake({ arr: [], checkObj: {} });
      setStatus({ arr: [], checkObj: {} });
      setApplicationStatus({ arr: [], checkObj: {} });
      setSchool({ arr: [], checkObj: {} });
      setLearningMethod({ arr: [], checkObj: {} });
      setCampus({ arr: [], checkObj: {} });
      setBrandName({ arr: [], checkObj: {} });
      setLaptop({ arr: [], checkObj: {} });
    }
    
    function getValue(arrName, label) {
      let labelName = "";
      if (arrName == "qualification") {
        let output = qualOption.filter(item => item?.value && item?.value.toString().toLowerCase() == label && label.toString().toLowerCase())
        if (output?.length) {
          labelName = output[0].label
        } else {
          labelName = "-";
        }
      } else {
        for (let item of dropdownValues[arrName]) {
          if (item?.value?.toString() == label?.toString()) {
            labelName = item.label;
            break;
          } else {
            labelName = "NA";
          }
        }
      }
      
      if (labelName === "" && arrName === "re_registration_status") return label;
      return labelName;
    }
    
    const exportData = (fileType, fileName) => {
      Swal.fire({
        title: "File downloading",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      let values = {
        page,
        limit: perPage,
        intake: intake.arr,
        viaStatus: status.arr,
        learning_method: learningMethod.arr,
        search,
        key: sortkey,
        sort: sortOrder,
        exportStaus: "true",
        appStatus: applicationStatus.arr,
        salesPerson: salesFilter,
        qualification,
        school: school.arr,
        campus: campus.arr,
        brand_Name : brandName.arr,
        laptop: laptop.arr,
        isReRegister: []
      }
      GetAllRegistrationsStudentsList(values)
      .then((res) => {
        let data = res.data.registrations_student_list;
        const header = [
          "Full Name",
          "Student Number",
          "email",
          "ID/Passport",
          "Date",
          "Status",
          "Application Status",
          "Sales Person",
          "Qualification",
          "School",
          "Intake",
          "Delivery Method",
          "Campus",
          "Laptop",
          "Brand",
          "Tracking No",
          "Date Collected/Dispatched",
          "Laptop Serial Number",
          "Laptop Tracking No",
          "Laptop Date Collected/Dispatched"
        ];
        
        data = data?.map((row) => ({
          ...row,
          "Full Name": row.first_name + " " + row.last_name,
          "Student Number": row.student_crm_id ? row.student_crm_id : "-",
          "Email" : row.email ? row.email : "-",
          "ID/Passport" : row.rsa_identity ? row.rsa_identity : "-",
          Date: row.created_date
          ? moment.unix(row.created_date).format(TABLE_DATE_FORMAT) +
          " " +
          moment.unix(row.created_date).format(TABLE_TIME_FORMAT)
          : "-",
          Status: row.current_status,
          "Application Status": row.status
          ? RenderRegistrationStatus(row.status).text
          : "-",
          "Sales Person":
          row.sales_person_first_name + " " + row.sales_person_last_name,
          Qualification: row.qualification
          ? getValue("qualification", row.qualification)
          : "-",
          School: row.academy ? RenderSchool(row.academy).text : "-",
          Intake: row.intake_year ? row.intake_year : "-",
          "Delivery Method": row.delivery_methode
          ? RenderLearningMethod(row.delivery_methode).text
          : "-",
          Campus: row.campus_selected
          ? RenderCampus(row.campus_selected).text
          : "-",
          Laptop: row.laptop ? RenderLaptopSpec(row.laptop).text : "-",
          "Brand" : row.brand_name ? RenderBrandList(row.brand_name).text : "-",
          "Tracking No": row.tracking_no ? row.tracking_no : "-",
          "Date Collected/Dispatched": row.kit_collected_date ? moment.unix(row.kit_collected_date).format(TABLE_DATE_FORMAT) : "-",
          "Laptop Serial Number": row.laptop_sr ? row.laptop_sr : "-",
          "Laptop Tracking No" : row.laptop_tracking_num ? row.laptop_tracking_num : "-",
          "Laptop Date Collected/Dispatched" : row.laptop_collected_date ? moment.unix(row.laptop_collected_date).format(TABLE_DATE_FORMAT) : "-",
        }));
        
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
          
          const blobURL = window.URL.createObjectURL(blob);
          
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
          
          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
          
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            
            return [
              row.first_name && row.last_name ? row.first_name + " " + row.last_name : "-",
              row.student_crm_id ? row.student_crm_id : "-",
              row.email ? row.email : "-",
              row.rsa_identity ? row.rsa_identity : "-",
              row.created_date
              ? moment.unix(row.created_date).format(TABLE_DATE_FORMAT) +
              " " +
              moment.unix(row.created_date).format(TABLE_TIME_FORMAT)
              : "-",
              row.current_status ? row.current_status : "-",
              RenderRegistrationStatus(row.status).text,
              row.sales_person_first_name + " " + row.sales_person_last_name,
              getValue("qualification", row.qualification),
              RenderSchool(row.academy).text,
              row.intake_year ? row.intake_year : "-",
              RenderLearningMethod(row.delivery_methode).text,
              RenderCampus(row.campus_selected).text,
              RenderLaptopSpec(row.laptop).text,
              RenderBrandList(row.brand_name).text,
              //row.brand_reg ? row.brand_reg : "-",
              row.tracking_no ? row.tracking_no : "-",
              row["Date Collected/Dispatched"] ? row["Date Collected/Dispatched"] : "-",
              row["Laptop Serial Number"] ? row["Laptop Serial Number"] : "-",
              row.laptop_tracking_num ? row.laptop_tracking_num : "-",
              row.laptop_collected_date ? moment.unix(row.laptop_collected_date).format(TABLE_DATE_FORMAT) : "-"
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            columnStyles: {
              0: {
                columnWidth: 10,
              },
              1: {
                columnWidth: 11,
              },
              2: {
                columnWidth: 15,
              },
              3: {
                columnWidth: 13,
              },
              4: {
                columnWidth: 11,
              },
              5: {
                columnWidth: 13,
              },
              6: {
                columnWidth: 12,
              },
              7: {
                columnWidth: 15,
              },
              8: {
                columnWidth: 10,
              },
              9: {
                columnWidth: 11,
              },
              10: {
                columnWidth: 15,
              },
              11: {
                columnWidth: 12,
              },
              12: {
                columnWidth: 15,
              },
              13: {
                columnWidth: 10,
              },
              14: {
                columnWidth: 8,
              },
              15: {
                columnWidth: 10,
              },
              16: {
                columnWidth: 8,
              },
              17: {
                columnWidth: 8,
              },
              18: {
                columnWidth: 8,
              },
            },
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          
          return false;
        }
      })
    };
    
    function parseName(name) {
      const parser = new DOMParser();
      const decodedString = parser.parseFromString(
        `<!doctype html><body>${name}`,
        "text/html"
      ).body.textContent;
      return decodedString;
    }
    const handlePageChange = (pageNo) => {
      setPage(pageNo);
    };
    
    const handlePerRowsChange = (newPerPage, page) => {
      setPerPage(newPerPage);
    };
    
    const handleSort = (column, sortDirection) => {
      setSortKey(column.sortField);
      setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };
    
    return (
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
      <div className="search-filter-div">
      <div className="search-filter-div-left">
      <div className="system-administration-table table-responsive">
      <div className="table-responsive-div">
      <div
      id="assessment-table-main_wrapper"
      className="dataTables_wrapper no-footer"
      >
      <div
      id="assessment-table-main_wrapper"
      className="dataTables_wrapper no-footer"
      >
      <div
      id="assessment-table-main_filter "
      className="dataTables_filter search-filed-new"
      >
      <label>
      <input
      type="search"
      className=""
      placeholder="Search"
      aria-controls="assessment-table-main"
      onChange={e => setDebouncedTerm(e.target.value)}
      value={debouncedTerm}
      />
      </label>
      <div className="filter-eff filter-data-btn">
      <button className="filter-buttons">
      <i className="fal fa-filter"></i>
      </button>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div className="filter-button-group">
      
      <div className="filter-scroll">
      <div className={`filter-scroll-inner  filter-custom-new`}>
      <Tablefilter
      filterName={"Status"}
      optionArr={filterData.status}
      state={status}
      setState={setStatus}
      renderLabelFunction={RenderProgrammeStudentStatus}
      />
      <Tablefilter
      filterName={"Application Status"}
      optionArr={filterData.aStatus}
      state={applicationStatus}
      setState={setApplicationStatus}
      renderLabelFunction={RenderRegistrationStatus}
      stopOptionSorting={true}
      />
      {/* Sales Person Related Filters */}
      <div className="assessment-table-filter">
      <div className="btn-type-filter dropdown-comman assesment-dropdown">
      <div className="dropdown multiselect">
      <button className={`btn btn-default dropdown-toggle ${salesFilter.length ? "btn-selected" : ""}`} type="button" id="salesDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Sales Person" >
      <span>
      Sales Person <i className="fal fa-angle-down"></i>
      </span>
      </button>
      <form className="dropdown-menu remove-list-style">
      <ul aria-labelledby="salesDropdownMenu">
      {filterData.sPerson && filterData.sPerson.length ? (
        <FilterComponent
        value={value}
        onChange={onChange}
        dataResponse={filterData.sPerson}
        backendResponse={setSalesFilter}
        
        />
      ) : ""
    }
    </ul>
    </form>
    </div>
    </div>
    </div>
    {/* End of School Related Filters */}
    
    {/* Qualification Related Filters */}
    <div className="assessment-table-filter">
    <div className="btn-type-filter dropdown-comman assesment-dropdown">
    <div className="dropdown multiselect">
    <button
    className={`btn btn-default dropdown-toggle ${qualification.length ? "btn-selected" : ""
    }`}
    type="button"
    id="qualificationDropdownMenu"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    title="Qualification"
    >
    <span>
    Qualification <i className="fal fa-angle-down"></i>
    </span>
    </button>
    <form className="dropdown-menu remove-list-style">
    <ul aria-labelledby="qualificationDropdownMenu">
    {qualOption && qualOption.length ? (
      <FilterComponent
      value={qualificationValue}
      onChange={setqualificationValue}
      dataResponse={qualOption}
      backendResponse={setQualification}
      
      />
    ) : ""
    
    
  }
  </ul>
  </form>
  </div>
  </div>
  </div>
  <Tablefilter
  filterName={"Brand"}
  optionArr={filterData.brand}
  state={brandName}
  setState={setBrandName}
  renderLabelFunction={RenderBrandList}
  />
  <Tablefilter
  filterName={"School"}
  optionArr={filterData.school}
  state={school}
  setState={setSchool}
  renderLabelFunction={RenderSchool}
  />
  <Tablefilter
  filterName={"Intake"}
  optionArr={filterData.intake}
  state={intake}
  setState={setIntake}
  isOptionReversed={true}
  />
  <Tablefilter
  filterName={"Delivery Method"}
  optionArr={filterData.learning}
  state={learningMethod}
  setState={setLearningMethod}
  renderLabelFunction={RenderLearningMethod}
  />
  <Tablefilter
  filterName={"Campus"}
  optionArr={filterData.campus}
  state={campus}
  setState={setCampus}
  renderLabelFunction={RenderCampus}
  />
  <Tablefilter
  filterName={"Laptop"}
  optionArr={filterData.laptop}
  state={laptop}
  setState={setLaptop}
  renderLabelFunction={RenderLaptopSpec}
  />
  <div className="assessment-table-filter">
  <div className="btn-type-filter dropdown-comman ">
  <div className="dropdown multiselect">
  <button className="btn btn-primary dropdown-toggle" onClick={resetFilter} title="Reset">
  <span>
  <i className="fal fa-redo"></i>Reset
  </span>
  </button>
  </div>
  </div>
  </div>
  <div className="reset-btn-group">
  <div className="files-export-group">
  <button
  type="button"
  className="btn btn-files"
  onClick={() => {
    exportData("xlsx", "Registered_Students");
  }}
  title="Export spreadsheet"
  >
  <i className="fal fa-file-excel icon"></i>
  </button>
  <button
  type="button"
  className="btn btn-files"
  onClick={() => {
    exportData("csv", "Registered_Students");
  }}
  title="Export CSV"
  >
  <i className="fal fa-file-csv icon"></i>
  </button>
  <button
  type="button"
  className="btn btn-files"
  onClick={() => {
    exportData("pdf", "Registered_Students");
  }}
  title="Export PDF"
  >
  <i className="fal fa-file-pdf icon"></i>
  </button>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div className="search-filter-div-right">
  <div className=" filter-search-bar-blk">
  <div className="add-ticket-blk button-reset dropdown-comman">
  <Link to={`/studentadministration/registration/create`}>
  <button className="btn btn-primary" title="Create New">
  <i className="fal fa-plus"></i>Create New
  </button>
  </Link>
  </div>
  </div>
  </div>
  </div>
  <DataTable
  data={studentList}
  defaultSortField="created_date"
  defaultSortAsc={false}
  columns={columns}
  pagination={true}
  onSort={handleSort}
  noDataComponent={Str.noRecord}
  paginationServer
  sortServer
  paginationTotalRows={totalRows}
  onChangeRowsPerPage={handlePerRowsChange}
  onChangePage={handlePageChange}
  highlightOnHover={false}
  progressPending={loading}
  progressComponent={<SkeletonTicketList />}
  paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
  />
  </div>
  </div>
);
};

export default ListofRegisteredStudents;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { RenderPMCStatus } from "../../../../utils/CommonGroupingItem";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { AddCampusPages, GetCampusUpdateList, updateCampusPages } from "../../../../services/CampusPagesServer";
import { AddTestimonial, getTestimonialColorList, getTestimonialDropdownlist, GetUpdateTestimonial, UpdateTestimonial } from "../../../../services/TestimonialsServer";
import ReactStars from "react-rating-stars-component";
import { FormField } from "../../../common/FormFields";
import PermissionsGate from "../../../../utils/permissionGate";


const LayoutTestimonialForm = () => {
    const history = useHistory();
    const { subTab, tab } = useParams();

    const [updateData, setUpdateData] = useState(null);
    const [testimonialColour, setTestimonialColour] = useState([]);
    const [description, setDescription] = useState("")
    const [checkbox, setCheckbox] = useState({
        is_override_color_variable: false,
        showVideo: false,
    });
    const [brandValue, setbrandValue] = useState([]);
    const [templateName, setTemplateName] = useState([]);
    const [dropdownlist, setDropdownlist] = useState([]);
    const [iconName, setIconName] = useState("");

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete.map((data) => data.brand_id);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            getTestimonialDropdownlist()
                .then(res => setDropdownlist(res?.data))
        };

        fetchData();
    }, []);

    useEffect(() => {
        GetUpdateTestimonial(subTab)
            .then(res => {
                // console.log(res?.data?.data)
                setUpdateData(res?.data?.data)
            })
    }, [subTab])

    const formik = useFormik({
        enableReinitialize: tab === "update" ? true : false,
        initialValues: {
            id: updateData?.id || "",
            title: updateData?.title || "",
            category: updateData?.category || "",
            status: updateData ? updateData?.status : "",
            testimonial_type: updateData?.testimonial_type || 1,
            Brand: brandID || [],
            image: "",
            content: description,
            is_video_show: checkbox.showVideo ? 1 : 0,
            video_url: updateData?.video_url || "",
            testimonial_color: updateData?.testimonial_color || "",
            is_override_color_variable: checkbox.is_override_color_variable ? 1 : 0,
            video_preview_image: "",
            rating: 0,

        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            category: Yup.string().required("Related School is required"),
            testimonial_type: Yup.string().required("Type is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            content: Yup.string().required("Testimonial Content Templates is required"),
            // video_url : Yup.string().required("video URL is required"),
            testimonial_color: Yup.string().required("color is required"),
        }),
        onSubmit: async (values, actions) => {
            if (tab === "update") {
                UpdateTestimonial(values)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "updated Successfully",
                        });
                        actions.resetForm();
                        history.push("/websiteManagement/layoutNavigation/testimonials");
                    })
                    .catch(err => console.error(err))
            } else {
                try {
                    await AddTestimonial(values);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/websiteManagement/layoutNavigation/testimonials");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            }
        }
    });

    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            formik.values.testimonial_type === 2 ? formik.setFieldValue("video_preview_image", e.currentTarget.value) : formik.setFieldValue("image", e.currentTarget.value);
            setIconName(file.name);
        }
    };

    const colourStyles = {
        option: (styles, { data }) => {
            const { bgColor, colorCode } = RenderPMCStatus(data.value);
            return {
                ...styles,
                backgroundColor: bgColor,
                color: colorCode,
                cursor: 'default'
            };
        }
    };

    useEffect(() => {
        let colorValue = {
            brandID: templateName
        }

        getTestimonialColorList(colorValue)
            .then(res => setTestimonialColour(res.data.result))
            .catch(err => console.error(err))
    }, [templateName])

    useEffect(() => {
        setbrandValue(dropdownlist?.brandTemplateList?.filter((data) => formik.values.Brand.includes(data.value)))
    }, [formik.values.Brand])

    useEffect(() => {
        setTemplateName(brandValue?.map(data => data.value))
    }, [brandValue])

    useEffect(() => {
        setCheckbox({
            ...checkbox,
            is_override_color_variable: updateData?.is_override_color_variable,
            showVideo: updateData?.is_video_show,
        })
        setDescription(updateData?.content)
        formik.values.testimonial_type === 2 ? formik.setFieldValue("video_preview_image", updateData?.video_preview_image) : formik.setFieldValue("image", updateData?.image);
        setIconName(formik.values.testimonial_type === 2 ? updateData?.video_preview_image : updateData?.image);
        formik.setFieldValue('rating', updateData?.rating);
    }, [updateData])

    const handleRatingChange = (newRating) => {
        formik.setFieldValue('rating', newRating);
    };

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0">Testimonial Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3">
                                    <label>Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.title && formik.touched.title
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Title"
                                        name="title"
                                        id="title"
                                        title="Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="RelatedSchool">
                                    <label>Related School*</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.category && formik.touched.category
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="category"
                                        value={dropdownlist?.schoolList?.find(val => val.value === formik.values.category)}
                                        onChange={(option) => {
                                            formik.setFieldValue("category", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.schoolList}
                                        maxMenuHeight={175}
                                        placeholder={"Select Related School"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.status && formik.touched.status
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={dropdownlist?.statusList?.find(val => val.value === formik.values.status)}
                                        onChange={(option) => {
                                            formik.setFieldValue("status", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.statusList}
                                        maxMenuHeight={175}
                                        placeholder={"Select"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="PageInformation">
                                    <label>Type*</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.testimonial_type && formik.touched.testimonial_type
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="testimonial_type"
                                        value={dropdownlist?.testimonialType?.find(val => val.value === formik.values.testimonial_type)}
                                        onChange={(option) => {
                                            formik.setFieldValue("testimonial_type", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.testimonialType}
                                        maxMenuHeight={175}
                                        placeholder={"Testimonial"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandValue}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.brandTemplateList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            {
                                formik.values.testimonial_type === 2 &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3">
                                        <label>
                                            Video URL* <i className="fal fa-info-circle"></i>
                                        </label>
                                        <input
                                            type="text"
                                            className={
                                                "form-control" +
                                                (formik.errors.video_url && formik.touched.video_url
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder="Enter Video URL"
                                            name="video_url"
                                            id="video_url"
                                            title="Video URL"
                                            value={formik.values.video_url}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                formik.values.testimonial_type === 1 &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                        <label>Rating*</label>
                                        <ReactStars
                                            value={formik.values.rating}
                                            onChange={handleRatingChange}
                                            size={24}
                                            activeColor="#00A9FF"
                                            classNames={"mt-md-1"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                formik.values.testimonial_type === 2 &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3">
                                        <label>
                                            Video Preview Image* <i className="fal fa-info-circle"></i>
                                        </label>
                                        <div className="form-group atttach-file">
                                            <label
                                                className={
                                                    formik.errors.video_preview_image && formik.touched.video_preview_image && "file-req is-invalid"
                                                }
                                            >
                                                <i className="fal fa-upload"></i>
                                                <span>Upload</span>
                                                <input
                                                    type="file"
                                                    className="form-control form-control-aatch-file"
                                                    onChange={(e) => handleFileChange(e)}
                                                    name="video_preview_image"
                                                    id="video_preview_image"
                                                    accept=".png,.jpg,.jpeg"
                                                />
                                            </label>
                                        </div>
                                        {iconName && (
                                            <div className="frm-group d-flex align-items-center">
                                                <ul className="list-unstyled attached-file-ul m-0">
                                                    <li>
                                                        <a href={formik.values.image} target="_blank">
                                                            <i className="fal fa-file"></i>
                                                            &nbsp;{iconName}
                                                        </a>
                                                    </li>
                                                </ul>

                                                <button
                                                    className="btn btn-danger rounded-circle"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        formik.setFieldValue("image", "");
                                                        setIconName("");
                                                    }}
                                                >
                                                    <i className="fal fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {
                                (formik.values.testimonial_type === 1 || formik.values.testimonial_type === 3) &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3">
                                        <label>
                                            Image <i className="fal fa-info-circle"></i>
                                        </label>
                                        <div className="form-group atttach-file">
                                            <label
                                                className={
                                                    formik.errors.image && formik.touched.image && "file-req is-invalid"
                                                }
                                            >
                                                <i className="fal fa-upload"></i>
                                                <span>Upload</span>
                                                <input
                                                    type="file"
                                                    className="form-control form-control-aatch-file"
                                                    onChange={(e) => handleFileChange(e)}
                                                    name="image"
                                                    id="image"
                                                    accept=".png,.jpg,.jpeg"
                                                />
                                            </label>
                                        </div>
                                        {iconName && (
                                            <div className="frm-group d-flex align-items-center">
                                                <ul className="list-unstyled attached-file-ul m-0">
                                                    <li>
                                                        <a href={formik.values.image} target="_blank">
                                                            <i className="fal fa-file"></i>
                                                            &nbsp;{iconName}
                                                        </a>
                                                    </li>
                                                </ul>

                                                <button
                                                    className="btn btn-danger rounded-circle"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        formik.setFieldValue("image", "");
                                                        setIconName("");
                                                    }}
                                                >
                                                    <i className="fal fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }


                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Testimonial Content*</label>
                                    <HtmlInputEditor
                                        editorState={description}
                                        setEditorState={(editorState) => {
                                            setDescription(editorState);
                                            formik.setFieldValue('content', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            {formik.values.testimonial_type === 1 &&
                                <>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <label className="mb-0">
                                            Show Video <i className="fal fa-info-circle"></i>
                                        </label>
                                        <div className="toggle-switch">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={checkbox.showVideo}
                                                    onChange={(e) => setCheckbox({ ...checkbox, showVideo: e.target.checked })}
                                                />
                                                <span className="slider slider-round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {checkbox?.showVideo ? <div className="col-md-10">
                                        <div className="form-group-blk mb-3">
                                            <label>
                                                Video URL <i className="fal fa-info-circle"></i>
                                            </label>
                                            <input
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (formik.errors.video_url && formik.touched.video_url
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                placeholder="Enter Video URL"
                                                name="video_url"
                                                id="video_url"
                                                title="Video URL"
                                                value={formik.values.video_url}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                </>
                            }
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} color="#EF8700" /> Style Settings
                                    </h4>
                                </div>
                            </div>
                            {formik.values.is_override_color_variable === 1 ? <>
                                {[
                                    { label: "Testimonial Colour *", name: "testimonial_color", color: true, required: false, type: "color", },
                                ].map((field, index) => (
                                    <FormField key={index} field={field} formik={formik} />
                                ))}
                            </> : <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">
                                    <label>Testimonial Colour *</label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.testimonial_color && formik.touched.testimonial_color && "is-invalid"}`}
                                        name="campusColor"
                                        value={testimonialColour.filter(val => formik.values.testimonial_color.includes(val.value.toString()))}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("testimonial_color", value.value);
                                            } else {
                                                formik.setFieldValue("testimonial_color", "");
                                            }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={testimonialColour}
                                        maxMenuHeight={175}
                                        placeholder={"Default School Colour"}
                                    />
                                    {formik.errors.testimonial_color &&
                                        formik.touched.testimonial_color ? (
                                        <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                    ) : null}
                                </div>
                            </div>}
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                                <label className="mb-0">
                                    Override Colour Variable <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            // checked={checkbox.is_override_color_variable}
                                            // onChange={(e) => setCheckbox({ ...checkbox, is_override_color_variable: e.target.checked })}
                                            checked={formik.values?.is_override_color_variable === 1}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "is_override_color_variable",
                                                    e.target.checked ? 1 : 0
                                                )
                                                formik.setFieldValue(
                                                    "testimonial_color",
                                                    e.target.checked ? "#000000" : ""
                                                )
                                            }
                                            }
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[tab == "create" ? 'wbslntestimonialsadd' : 'wbslntestimonialsedit']}>

                            <button
                                className="btn btn-save btn-success"
                                type="submit"
                                title="Save"
                                >
                                <i className="fal fa-save"></i>
                                Save
                            </button>
                            </PermissionsGate>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LayoutTestimonialForm;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TreeTheme from "react-sortable-tree-theme-minimal";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import axios from "axios";
import { AddMenus, getCategoryLandingColorList, getMenuDropdown, GetUpdateMenus, UpdateMenus } from "../../../services/Menusserver";
import HtmlParser from "react-html-parser";
import AddMenuItems from "./AddMenuItems";
import Placeholder from "../../courses/ContentAssembliesBlock/AssemblyListComponent/PalaceHolder";
import PermissionsGate from "../../../utils/permissionGate";

const LayoutForm = ({ id }) => {
  let history = useHistory();
  const { tab } = useParams();
  const [MenuDetails, setMenuDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sitesFilters, setSitesFilters] = useState([]);
  const [iconName, setIconName] = useState("");
  const [menuStructure, setMenuStructure] = useState([]);
  const [menuColor, setMenuColor] = useState([]);
  const [templateName, setTemplateName] = useState([]);
  const [brandValue, setbrandValue] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [menuStructureData, setMenuStructureData] = useState([]);
  const [checkbox, setCheckbox] = useState({
    MenuOverrideColor: false
  });

  let brandID = updateData?.brandTemplete?.map((data) => data.BrandID);

  // get data for update

  useEffect(() => {
    const cancelTokenSources = [];

    const getMenuUpdateItemList = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetUpdateMenus(id, source.token);
        // console.log(res?.data?.data);
        setUpdateData(res?.data?.data)
        setIconName(res?.data?.data?.MenuLogo);
        let data_items = res?.data?.data?.menusActiveItems?.map((item) => {
          console.log(item, "toggle item");
          return {
            id: item.MenuItemID,
            // title: <TitleWithIcon data={item} itemTitle={item?.item_name} />,
            title: item.menu_item_name,
            // type: item.item_type,
            data: { ...item },
            className: "parent-node",
          }
        });
        setMenuStructure(data_items);
      }
      catch (error) {
        console.error(error);
      }
    }

    if (id) {
      getMenuUpdateItemList();
    }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [id]);

  // get dropdown menu list

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getMenuDropdown()
        if (data.status === 200) {
          setMenuDetails(data && data?.data)
          setSitesFilters(data.data.sitesFilters.map(item => {
            return {
              value: item.value,
              label: HtmlParser(`<span className="status-color-select" style="color:#000000;" title=${item?.label}>${item?.label}</span>`),
              stringLabel: item.label
            }
          }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()
  }, []);

  const handleRemoveItem = (node) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unlink it!",
    }).then((result) => {
      if (result.isConfirmed) {
       const updatedData = menuStructure.filter(item => item.id !== node.id);
       setMenuStructure(updatedData)
       setMenuStructureData([...menuStructureData , node])
      }
    });
  }

  // Function to handle file input change

  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("MenuLogo", e.currentTarget.value);
      setIconName(file.name);
    }
  };

  const formik = useFormik({
    enableReinitialize: tab === "update" && true,
    initialValues: {
      MenuID: updateData.MenuID || "",
      MenuName: updateData?.MenuName || '',
      MenuType: updateData?.MenuType || '',
      MenuStatus: updateData ? updateData?.MenuStatus : '',
      Brand: brandID || [],
      MenuColor: updateData.MenuColor || "",
      MenuOverrideColor: checkbox.MenuOverrideColor ? 1 : 0,
      MenuLogo: updateData?.MenuLogo || '',
      MenuItems: updateData?.MenuItems || []
    },
    validationSchema: Yup.object().shape({
      MenuName: Yup.string().required("Menu Name is required"),
      MenuType: Yup.string().required("Menu type is required"),
      Brand: Yup.string().required("Assigned Brand Templates is required"),
      MenuColor: Yup.string().required("Menu colour is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (tab === 'create') {
        setLoading(true);
        AddMenus(values)
          .then((response) => {
            // console.log('Menus created successfully:', response);
            Swal.fire('Success!', 'Menus created successfully!', 'success');
            history.push('/websiteManagement/layoutNavigation/menus');
          })
          .catch((error) => {
            console.error('Error creating menus:', error);
            Swal.fire('Error!', 'Failed to create menus', 'error');
          });
      } else if (tab === 'update') {
        UpdateMenus(values, id)
          .then((response) => {
            // console.log('Menus updated successfully:', response);
            Swal.fire('Success!', 'Menus updated successfully!', 'success');
            history.push('/websiteManagement/layoutNavigation/menus');
          })
          .catch((error) => {
            console.error('Error updating Menus:', error);
            Swal.fire('Error!', 'Failed to update menus', 'error');
          });
      }
    },
  });

  useEffect(() => {
    setCheckbox({
      MenuOverrideColor: updateData?.MenuOverrideColor === 1 ? true : false,
    })

    formik.setFieldValue("MenuLogo", updateData.MenuLogo);
    setIconName(updateData.MenuLogo);
  }, [updateData])

  useEffect(() => {
    let colorValue = {
      brandID: templateName
    }

    getCategoryLandingColorList(colorValue)
      .then(res => setMenuColor(res.data.result))
      .catch(err => console.error(err))
  }, [templateName])

  useEffect(() => {
    setbrandValue(sitesFilters.filter((data) => formik.values.Brand.includes(data.value)))
  }, [formik.values.Brand])

  useEffect(() => {
    setTemplateName(brandValue.map(data => data.value))
  }, [brandValue])

  useEffect(() => {
    const menuItemIDs = menuStructure.map(item => item.data.MenuItemID);
    formik.setFieldValue("MenuItems", menuItemIDs);
  }, [menuStructure]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-9 col-12">
            <div className="card card-profile-info-card course-details-tab-sec">
              <div className="course-info-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header">
                    Menu Information
                  </div>
                </div>
                <div className="card-body-inr card-body-info">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4">
                        <label>Menu Namesaas *</label>
                        <input
                          type="text"
                          className={`form-control ${formik.errors.MenuName && formik.touched.MenuName && "is-invalid"}`}
                          name="MenuName"
                          title="MenuName"
                          placeholder="Menu Name"
                          value={formik.values.MenuName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.MenuName && formik.touched.MenuName && (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4">
                        <label>Menu Type *</label>
                        <Select
                          className={"form-control custom-select-box" + (formik.errors.MenuType && formik.touched.MenuType ? " is-invalid" : "")}
                          name="MenuType"
                          value={MenuDetails?.menusTypesFilters?.find(item => item.value === formik.values.MenuType)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("MenuType", value.value);
                            } else {
                              formik.setFieldValue("MenuType", "");
                            }
                          }}
                          placeholder={formik.values.MenuType ? formik.values.MenuType : "Select..."}
                          onBlur={formik.handleBlur}
                          options={MenuDetails.menusTypesFilters}
                          maxMenuHeight={175}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group-blk mb-4">
                        <label>Status <i className="fal fa-info-circle"></i></label>
                        <Select
                          className={"form-control custom-select-box" + (formik.errors.MenuStatus && formik.touched.MenuStatus ? " is-invalid" : "")}
                          name="MenuStatus"
                          value={MenuDetails?.menusStatusFilters?.find(item => item.value === formik.values.MenuStatus)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("MenuStatus", value.value);
                            } else {
                              formik.setFieldValue("MenuStatus", "");
                            }
                          }}
                          placeholder={formik.values.MenuStatus ? formik.values.MenuStatus : "Select..."}
                          onBlur={formik.handleBlur}
                          options={MenuDetails.menusStatusFilters}
                          maxMenuHeight={175}
                          isClearable
                        />

                        {formik.errors.MenuStatus &&
                          formik.touched.MenuStatus && (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group-blk">
                        <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                        <Select
                          isMulti
                          name="Brand"
                          value={brandValue}
                          options={sitesFilters}
                          onBlur={formik.handleBlur}
                          maxMenuHeight={175}
                          onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                          isClearable
                          className={`form-control color-drop custom-select-box is-multi basic-multi-select ${formik.errors.Brand && formik.touched.Brand && " is-invalid"}`}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="course-sec-gap course-resource-sec">
                    <div className="edit-icon new-card-header">
                      <div className="card-header">
                        <i className="fal fa-cog"></i>&nbsp;
                        Menu Settings
                      </div>
                    </div>

                    <div className="card-body-inr">
                      <div className="row">
                        <div className="col-md-6 col-lg-4">
                          <div className="form-icon-group mb-4">
                            <label>Menu Colour *</label>
                            <Select
                              className={`form-control custom-select-box ${formik.errors.MenuColor && formik.touched.MenuColor && "is-invalid"}`}
                              name="MenuColor"
                              value={menuColor.filter(val => formik.values.MenuColor.includes(val.value.toString()))}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("MenuColor", value.value);
                                } else {
                                  formik.setFieldValue("MenuColor", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={menuColor}
                              maxMenuHeight={175}
                              placeholder={"Accent 1"}
                            />
                            {formik.errors.programSupport &&
                              formik.touched.programSupport ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-center">
                          <label className="mb-0">
                            Override Colour Variable <i className="fal fa-info-circle"></i>
                          </label>
                          <div className="toggle-switch ml-2">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={checkbox.MenuOverrideColor}
                                onChange={(e) => setCheckbox({ MenuOverrideColor: e.target.checked })}
                              />
                              <span className="slider slider-round"></span>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="form-group-blk mb-3">
                            <label>
                              Menu Icon * <i className="fal fa-info-circle"></i>
                            </label>
                            <div className="d-flex align-items-center">
                              <div className="form-group atttach-file">
                                <label
                                  className={
                                    formik.errors.MenuLogo && formik.touched.MenuLogo && "file-req is-invalid"
                                  }
                                >
                                  <i className="fal fa-upload"></i>
                                  <span>Upload</span>
                                  <input
                                    type="file"
                                    className="form-control form-control-aatch-file"
                                    onChange={(e) => handleFileChange(e)}
                                    name="MenuLogo"
                                    id="menuLogo"
                                    accept=".png,.jpg,.jpeg"
                                  />
                                </label>
                              </div>
                              {iconName && (
                                <div className="frm-group d-flex align-items-center" style={{ marginLeft: 20 }}>
                                  <ul className="list-unstyled attached-file-ul m-0">
                                    <li>
                                      <a href={formik.values.MenuLogo} target="_blank">
                                        <i className="fal fa-file"></i>
                                        &nbsp;{iconName}
                                      </a>
                                    </li>
                                  </ul>

                                  <button
                                    className="btn btn-danger rounded-circle"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      formik.setFieldValue("MenuLogo", "");
                                      setIconName("");
                                    }}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="course-sec-gap course-setting-sec d-none d-md-block d-lg-none">
                    <div className="edit-icon new-card-header">
                      <div className="card-header">Menu Structure</div>
                    </div>
                    <div className="card-body-inr">
                    {menuStructure.length > 0 ?
                    <SortableTree
                      theme={TreeTheme}
                      className="my-class"
                      isVirtualized={false}
                      treeData={menuStructure}
                      onChange={(treeData) => setMenuStructure(treeData)}
                      maxDepth={3}
                      // onDragStateChanged={(dragData) => handleDragNode(dragData)}
                      style={{
                        padding: "0px",
                        width: "100%",
                      }}
                      // onVisibilityToggle={(data) => nodeToggle(data)}
                      generateNodeProps={(rowInfo) => {
                        const { node } = rowInfo;
                        return {
                          class: (node.type === 1) ? "main-category-node" : node.type === 3 ? "sub-category-node" : "item-node",
                          buttons: [
                            <div className='drag-option-container'>
                              {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["cadelete"]}> */}

                              <button
                                className='drag-option btn btn-danger rounded-circle'
                                type="button"
                                label="Delete"
                                title="Delete"
                                onClick={() => handleRemoveItem(node)}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                              {/* </PermissionsGate> */}
                            </div>
                          ],
                          style: { borderRadius: "10px", width: "100%" }
                        }
                      }}
                      placeholderRenderer={Placeholder}
                    />
                    : <p className="menuStructure">No Menu Items Added</p>}

                      <div className="form-group form-group-save-cancel mt-3">
                        <button
                          className="btn btn-save btn-success"
                          type="button"
                          title="Add Items"
                          disabled={loading ? true : false}
                          data-toggle="modal" data-target="#menuitem"
                        >
                          {loading === false ? (
                            <i className="fal fa-plus"></i>
                          ) : (
                            <i class="fas fa-cog fa-spin"></i>
                          )}
                          Add Items
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <PermissionsGate scopes={[tab == "create" ? 'wbslnmenusadd' : 'wbslnmenusedit']} RenderError={() => (
                  <button
                    className="btn btn-save btn-success"
                    title="Save"
                    disabled
                    >
                    <i className="fal fa-save"></i> Save
                  </button>
                )}>

                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={loading ? true : false}
                  >
                  {loading === false ? (
                    <i className="fal fa-save"></i>
                  ) : (
                    <i className="fas fa-cog fa-spin"></i>
                  )}
                  Save
                </button>
                </PermissionsGate>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    formik.resetForm();
                    history.push("/websiteManagement/layoutNavigation/menus");
                  }}
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="card card-profile-info-card course-details-tab-sec">
              <div className="course-sec-gap course-setting-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header">Menu Structure</div>
                </div>
                <div className="card-body-inr">
                  {menuStructure.length > 0 ?
                    <SortableTree
                      theme={TreeTheme}
                      className="my-class"
                      isVirtualized={false}
                      treeData={menuStructure}
                      onChange={(treeData) => setMenuStructure(treeData)}
                      maxDepth={3}
                      // onDragStateChanged={(dragData) => handleDragNode(dragData)}
                      style={{
                        padding: "0px",
                        width: "100%",
                      }}
                      // onVisibilityToggle={(data) => nodeToggle(data)}
                      generateNodeProps={(rowInfo) => {
                        const { node } = rowInfo;
                        return {
                          class: (node.type === 1) ? "main-category-node" : node.type === 3 ? "sub-category-node" : "item-node",
                          buttons: [
                            <div className='drag-option-container'>
                              {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["cadelete"]}> */}

                              <button
                                className='drag-option btn btn-danger rounded-circle'
                                type="button"
                                label="Delete"
                                title="Delete"
                                onClick={() => handleRemoveItem(node)}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                              {/* </PermissionsGate> */}
                            </div>
                          ],
                          style: { borderRadius: "10px", width: "100%" }
                        }
                      }}
                      placeholderRenderer={Placeholder}
                    />
                    : <p className="menuStructure">No Menu Items Added</p>}

                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-save btn-success"
                      type="button"
                      title="Add Items"
                      disabled={loading ? true : false}
                      data-toggle="modal" data-target="#menuitem"
                    >
                      {loading === false ? (
                        <i className="fal fa-plus"></i>
                      ) : (
                        <i class="fas fa-cog fa-spin"></i>
                      )}
                      Add Items
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div
        className="topic-add-modal modal fade"
        id="menuitem"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-plus"></i> Add Menu Items
                </h5>
                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
              </div>
              <div className="p-4">
                <AddMenuItems id={id} setMenuStructure={setMenuStructure} menuStructureData={menuStructureData} setMenuStructureData={setMenuStructureData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutForm;

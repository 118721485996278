import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import LayoutCategory from "../components/layoutNavigation/LayoutCategory";
import LayoutMenus from "../components/layoutNavigation/LayoutMenus";
import LayoutHeaders from "../components/layoutNavigation/LayoutHeaders";
import LayoutTestimonials from "../components/layoutNavigation/layoutTestimonials/LayoutTestimonials";
import PermissionsGate from "../utils/permissionGate";
// This will be shown in merge-all branch

function LayoutNavigation() {
    const history = useHistory();
    const { tab } = useParams();

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div
                    className={
                        "sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")
                    }
                >
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 class="text-left-align landing-heading heading_color_dynamic">
                                                Layout <span>& Navigation</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        title="Back"
                                                        className="btn btn-white-bordered"
                                                    >
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab == "categories" ||
                                        tab == "menus" ||
                                        tab == "header" ||
                                        tab == "auditTrail" ||
                                        tab == "testimonials") && (
                                            <ul
                                                className="nav nav-pills mb-4"
                                                id="tabs"
                                                role="tablist"
                                            >
                                                <PermissionsGate scopes={['wbslncategoriesview']} RenderError={() => null}>
                                                <li
                                                    className="nav-item"
                                                    onClick={() =>
                                                        history.push(
                                                            `/websiteManagement/layoutNavigation/categories`
                                                        )
                                                    }
                                                    >
                                                    <Link
                                                        className={`nav-link ${tab === "categories" && "active"
                                                        }`}
                                                        id="pills-tab1"
                                                        
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layoutNavigation/categories`}
                                                        role="tab"
                                                        aria-controls="pills-tab11"
                                                        aria-selected="true"
                                                        >
                                                        <i className="fa fa-layer-plus"></i>
                                                        Categories
                                                    </Link>
                                                </li>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={['wbslnmenusview']} RenderError={() => null}>

                                                <li
                                                    className="nav-item"
                                                    onClick={() =>
                                                        history.push(
                                                            `/websiteManagement/layoutNavigation/menus`
                                                        )
                                                    }
                                                    >
                                                    <Link
                                                        className={`nav-link ${tab === "menus" && "active"
                                                        }`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layoutNavigation/menus`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                        >
                                                        <i class="fa fa-bars"></i> Menus
                                                    </Link>
                                                </li>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={['wbslnheadersview']} RenderError={() => null}>

                                                <li
                                                    className="nav-item"
                                                    onClick={() =>
                                                        history.push(
                                                            `/websiteManagement/layoutNavigation/header`
                                                        )
                                                    }
                                                    >
                                                    <Link
                                                        className={`nav-link ${tab === "header" && "active"
                                                        }`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layoutNavigation/header`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                        >
                                                        <i class="fa fa-newspaper"></i> Headers
                                                    </Link>
                                                </li>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={['wbslntestimonialsview']} RenderError={() => null}>

                                                <li
                                                    className="nav-item"
                                                    onClick={() =>
                                                        history.push(
                                                            `/websiteManagement/layoutNavigation/testimonials`
                                                        )
                                                    }
                                                    >
                                                    <Link
                                                        className={`nav-link ${tab === "testimonials" && "active"
                                                        }`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layoutNavigation/testimonials`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                        >
                                                        <i class="fa fa-star"></i> Testimonials
                                                    </Link>
                                                </li>
                                                </PermissionsGate>
                                                <li
                                                    className="nav-item"
                                                    onClick={() =>
                                                        history.push(
                                                            `/websiteManagement/layoutNavigation/auditTrail`
                                                        )
                                                    }
                                                >
                                                    <Link
                                                        className={`nav-link ${tab === "auditTrail" && "active"
                                                            }`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layoutNavigation/auditTrail`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                    >
                                                        <i className="fal fa-history"></i>Audit trail
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade active show"
                                            id="pills-tab11"
                                            role="tabpanel"
                                            aria-labelledby="pills-tab1"
                                        >
                                            {tab === "categories" && <LayoutCategory />}
                                            {tab === "menus" && <LayoutMenus />}
                                            {tab === "header" && <LayoutHeaders />}
                                            {tab === "testimonials" && <LayoutTestimonials/> }
                                            {tab === "auditTrail" && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LayoutNavigation;

import React from "react";
import Swal from "sweetalert2";
import { download } from "../../utils/FileDownload";
import { downloadURL, zipURL } from "../../utils/Constants";

export const RenderAttachList = (props) => {
    const downloadFile = (filePath, fileName) => {
        if (filePath && !filePath.includes(downloadURL)) {
            filePath = filePath.replace("https://", "").replace("http://", "");
            filePath = downloadURL + filePath;
        }
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
              Swal.showLoading();
            },
        });
        
        let fileURL = filePath;
        download(fileURL, fileName);
    }
    const openFile = (filePath) => {
        if (!filePath?.includes(downloadURL)) {
            filePath = filePath?.replace(downloadURL, "");
            //add https:// to start of filepath if it is not there
            
        }
        window.open(filePath, "_blank"); // Open file in a new tab
    };
    // console.log("props?.docType",props?.docType)
    // console.log(props);
    const renderFileIcon = (docType) => {
        const fileType = docType && docType.split(".").reverse()[0];
        switch (fileType) {
            case 'docx':
            return 'fal fa-file-word';
            case 'zip':
            return 'fal fa-file-archive';
            case 'pdf':
            return 'fal fa-file-pdf';
            case 'xlsx':
            return 'fal fa-file-excel';
            case 'jpg':
            case 'jpeg':
            case 'png':
            return 'fal fa-file-image';
            case 'txt':
            return 'fal fa-file-alt';
            case 'pptx':
            return 'fal fa-file-powerpoint';
            default:
            return 'fal fa-file';
        }
    }
    
    return (<div className="curser uploaded-files" title={props?.fileName} onClick={() => openFile(props?.filePath, props?.fileName)}>
    <div className="uploded-files-item">
    <div className="uploded-files-item-txt" >
    <figure>
    <i className={renderFileIcon(props?.fileName)}></i>
    </figure>
    <figcaption >
    <h4 className="oneline-ellipsis">{props?.fileName}</h4>
    <p>{props?.size}</p>
    </figcaption>
    </div>
    
    {props?.showClose ? (
        <button onClick={() =>  props?.removeF(props) } type="button" className="btn file-remove-btn"><i className="fal fa-times"></i></button>
    ) : (
        <button type="button"  onClick={(e) => {e.stopPropagation(); downloadFile(props?.filePath, props?.fileName);}} className="btn file-remove-btn rm-box-shadow"><i className="fal fa-download"></i></button>
    )}
    </div>
    </div>
)
}
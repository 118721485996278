// import React, { useState, useMemo, useEffect, useRef } from "react";
// import { InitialRender } from "../common/Helper";
// import { Link, useHistory, useLocation, useParams } from "react-router-dom";
// import Papa from "papaparse";
// import * as XLSX from "xlsx";
// import JsPDF from "jspdf";
// import "jspdf-autotable";
// import { IMAGE_URL, SP_PASS, TABLE_DATE_FORMAT } from "../../utils/Constants";
// import moment from "moment";
// import Swal from "sweetalert2";
// import PermissionsGate from "../../utils/permissionGate";
// import { GetListOfDashboard, GetfilterlistDashboard, GetList, GetCourselist, ProgrammesListDropdown, CouresListDropdown, deleteCourse, GetCourselistCancelToken, GetCouresListDropdownCancelToken } from "../../services/DashBoardServices";
// import RolesEdit from "../systemadministration/PermissionManagement/RolesEdit";
// import { ColorCode, ColorRender } from "../systemadministration/usermanagement/CheckRole";
// import { TrimText } from "../common/TrimText";
// import { Color } from "./ColorCode";
// import Hover from "../common/Hover";
// import { RenderCourseType, RenderLearningMethod, RenderSchool } from "../../utils/CommonGroupingItem";
// import axios from "axios";
// import DataTableComponent from "../common/DataTableComponent";
// import { RenderReadStatus } from "../../utils/CommonStatusItems";
// import $ from "jquery";
// import CopyCourse from "../courses/CopyCourse";

// const ListofCourse = () => {
//   const history = useHistory();
//   const location = useLocation();
//   const { type } = useParams()
//   const [programmeIds, setProgrammeIds] = useState({ arr: [], checkObj: [] })
//   const [search, setSearch] = useState("");
//   const [courseList, setCourseList] = useState([]);
//   const [totalRows, setTotalRows] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [filterstatus, setFilterStatus] = useState([
//     type.charAt(0).toUpperCase() + type.slice(1),
//   ]);
//   const [intake, setIntake] = useState({ arr: [], checkObj: {} });
//   const [reply, setReply] = useState({ arr: [], checkObj: {} });
//   const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
//   const [school, setSchool] = useState({ arr: [], checkObj: {} });
//   const [courseDropdown, setCourseDropdown] = useState({ programme: [], intake: [], learning: [], school: [], type: [] })
//   const [showing, setShowing] = useState({ arr: ["1"], checkObj: { "showing1": true } });
//   const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
//   const [deleteRow, setDeleteRow] = useState(false);
//   const [tableState, setTableState] = useState({
//     page: 1,
//     perPage: 10,
//     sortKey: "start",
//     sortOrder: "DESC"
//   })
//   const [courseId, setCourseId] = useState("");
//   const [cancelTokenSources, setCancelTokenSources] = useState([]);
//   const [prevLocation, setPrevLocation] = useState(type)
//   const [tempObj, setTempObj] = useState({});

//   useEffect(() => {
//     if (programmeIds.arr.length || learningMethod.arr.length || intake.arr.length || school.arr.length || courseType.arr.length || showing.arr[0] !== "1" || search) {
//       resetFilter();
//     }
//   }, [type]);

//   useEffect(() => {
//     const cancelTokenDropdownSources = [];

//     const fetchData = async () => {
//       cancelTokenDropdownSources.forEach(source => {
//         source.cancel('New request made');
//       });

//       const source = axios.CancelToken.source();
//       cancelTokenDropdownSources.push(source);

//       let data = {
//         viaStatus: filterstatus.length > 0 ? filterstatus : ["Upcoming"]
//       }
//       if (type == "selfplaced") {
//         data = {
//           viaLearnType: [type]
//         }
//       }

//       try {
//         const res = await GetCouresListDropdownCancelToken(data, source.token);
//         setCourseDropdown({ programme: [], intake: [], learning: [], school: [], type: [] });
//         setCourseDropdown({ ...res.data, programme: res.data.programme, intake: res.data.yearsAndIntaks, learning: res.data.intakeType, school: res.data.schoolType ? res.data.schoolType : [], type: res.data.subjectTypeFilter });
//       } catch (error) {
//         if (!axios.isCancel(error)) {
//           console.error(error);
//         }
//       }
//     }

//     fetchData();

//     return () => {
//       cancelTokenDropdownSources.forEach(source => {
//         source.cancel('Component unmounted');
//       });
//     };

//   }, [filterstatus])

//   useEffect(() => {
//     if (JSON.stringify({
//       search,
//       programmeIds,
//       reply,
//       learningMethod,
//       intake,
//       school,
//       courseType,
//       showing,
//       deleteRow,
//       tableState,
//       type: prevLocation
//     }) == JSON.stringify(tempObj)) {
//       return
//     } else {
//       setTempObj({
//         search,
//         programmeIds,
//         reply,
//         learningMethod,
//         intake,
//         school,
//         courseType,
//         showing,
//         deleteRow,
//         tableState,
//         type
//       })
//     }
//     if (prevLocation !== type) {
//       if (tableState.page !== 1) {
//         setTableState(prevState => ({ ...prevState, page: 1 }))
//         return
//       }
//       if (tableState.perPage !== 10) {
//         setTableState(prevState => ({ ...prevState, perPage: 10 }))
//         return
//       }
//       if (tableState.sortKey !== "start") {
//         setTableState(prevState => ({ ...prevState, sortKey: "start" }))
//         return
//       }
//       if (tableState.sortOrder !== "DESC") {
//         setTableState(prevState => ({ ...prevState, sortOrder: "DESC" }))
//         return
//       }
//       setPrevLocation(type)
//     }
//     getDashboard();
//     return () => {
//       cancelTokenSources.forEach(source => {
//         source.cancel('Component unmounted');
//       });
//     };
//   }, [
//     search,
//     programmeIds,
//     reply,
//     learningMethod,
//     intake,
//     school,
//     courseType,
//     showing,
//     deleteRow,
//     tableState,
//     type
//   ])

//   const getDashboard = async () => {
//     setLoading(true);

//     cancelTokenSources.forEach(source => {
//       source.cancel('New request made');
//     });

//     const source = axios.CancelToken.source();
//     setCancelTokenSources([...cancelTokenSources, source]);

//     let data = {
//       pageNo: tableState.page,
//       size: tableState.perPage,
//       intake: intake.arr,
//       viaLearnType: learningMethod.arr,
//       key: tableState.sortKey,
//       sort: tableState.sortOrder,
//       viaStatus: filterstatus.length > 0 ? filterstatus : ["Upcoming"],
//       viaSearch: search,
//       viaProgramme: programmeIds.arr,
//       viaMessage: reply.arr,
//       viaSchoolType: school.arr,
//       viaSubjectType: courseType.arr,
//       showing: showing.arr[0]
//     };
//     if (type == "selfplaced") {
//       data = {
//         pageNo: tableState.page,
//         size: tableState.perPage,
//         intake: intake.arr,
//         viaLearnType: [type],
//         key: tableState.sortKey,
//         sort: tableState.sortOrder,
//         viaStatus: [],
//         viaSearch: search,
//         viaProgramme: programmeIds.arr,
//         viaMessage: reply.arr,
//         viaSchoolType: school.arr,
//         viaSubjectType: courseType.arr,
//         showing: showing.arr[0]
//       };
//     }

//     try {
//       const res = await GetCourselistCancelToken(data, source.token);
//       setCourseList(res.data?.result);
//       setTotalRows(res.data?.Pagination.total);
//       if(res.status == 200){
//         setLoading(false);
//       }
//     } catch (error) {
//       if (!axios.isCancel(error)) {
//         console.error(error);
//         setLoading(false);
//       }
//     }
//   };

//   const resetFilter = () => {
//     setSearch("");
//     setIntake({ arr: [], checkObj: {} });
//     setReply({ arr: [], checkObj: {} });
//     setProgrammeIds({ arr: [], checkObj: [] })
//     setLearningMethod({ arr: [], checkObj: {} })
//     setSchool({ arr: [], checkObj: {} })
//     setShowing({ arr: ["1"], checkObj: { "showing1": true } });
//     setCourseType({ arr: [], checkObj: {} });
//   };

//   const handleDeleteCourse = (id) => {
//     const parameter = { intake: id };
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         deleteCourse(parameter).then((res) => {
//           if (res.status === 200) {
//             Swal.fire("Deleted!", "Your record has been deleted.", "success");
//             setDeleteRow(!deleteRow)
//           }
//         });
//       }
//     });
//   }

//   const handleCopyCourse = (row) =>{
//     setCourseId(row.id); 
//     $("#copyCourse").modal({backdrop: 'static', keyboard: false},"show");
//   }

//   const columns = useMemo(() => [
//     {
//       name: "Name",
//       selector: "name",
//       sortField: "name",
//       sortable: true,
//       cell: (row) => (
//         <div className="assigned-title-block-new">
//           <div className="dashboard-message-count">
//             {row.messageCount != 0 && row.messageCount != null ? <Link to={`/courseAdministration/coursesdetails/${row.id}/post-feed/show`} target="_blank"><i title="You have unread messages" className="fal fa-comment-lines message-count mt-2 mr-2 curser"></i></Link> : ""}
//             <div
//               className="assigned-title-itm overflow-ellipsis2 fst_line2"
//               title={row.name}
//             >
//               <Link
//                 className="as-text-blue curser textLimit2"
//                 to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
//                 title={row.name}
//               >
//                 {row.name ? row.name : "-"}
//               </Link>


//             </div>
//           </div>

//         </div>
//       ),
//     },
//     {
//       name: "Start",
//       selector: "start",
//       sortField: "startdate",
//       sortable: true,
//       cell: (row) => (
//         <>
//           {row.start ? (
//             <>
//               <p className="right-space">
//                 {moment.unix(row.start).format(TABLE_DATE_FORMAT)}
//               </p>
//             </>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "End",
//       selector: "end",
//       sortable: true,
//       sortField: "enddate",
//       cell: (row) => (
//         <>
//           {row.end ? (
//             <>
//               <p className="right-space">
//                 {moment.unix(row.end).format(TABLE_DATE_FORMAT)}
//               </p>
//             </>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Learning Method",
//       selector: "study_type",
//       sortable: true,
//       sortField: "type",
//       cell: (row) => (
//         <p className="learningMethodText">
//           {row && row.type ? RenderLearningMethod(row.type).html : "-"}
//         </p>
//       ),
//     },
//     {
//       name: "Programme",
//       selector: "qualificationName",
//       sortable: true,
//       cell: (row) => (
//         <span className="overflow-ellipsis2 fst_line2">
//           <Link
//             className="as-text-blue curser"
//             title={row.qualificationName}
//             to={{
//               pathname: `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`,
//             }}
//           >
//             {row && row.qualificationName ? row.qualificationName : "-"}
//           </Link>
//           <span
//             onClick={() => {
//               history.push(
//                 `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`
//               );
//             }}
//           ></span>
//         </span>
//       ),
//     },
//     {
//       name: "#Students",
//       selector: "totalRows",
//       sortable: true,
//       sortField: "students",
//       cell: (row) => <p>{row.totalRows ? row["totalRows"] : "-"}</p>,
//     },
//     {
//       name: "Intake",
//       selector: "year",
//       sortable: true,
//       sortField: "intake",
//       cell: (row) => (
//         <p>
//           {row.year || row.number ? (
//             <>
//               {" "}
//               {row.year ? row.year : ""}-{row.number ? row["number"] : ""}
//             </>
//           ) : (
//             "-"
//           )}
//         </p>
//       ),
//     },
//     {
//       name: "Lecturer",
//       selector: "lECTURER",
//       sortable: true,
//       sortField: "lecturer",
//       cell: (row) => (
//         <>
//           {row.lECTURER ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("lecturer")
//                   }
//                 >
//                   {row.lecturer_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.lecturer_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.lECTURER.split(" ")[0],
//                       row.lECTURER.replace(/ +/g, " ").split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.lecturer_activity_status ? row.lecturer_activity_status.toLowerCase() == "online" ? "Online" : row.lecturer_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.lECTURER.split(" ")[0]}
//                     lastName={row.lECTURER.replace(/ +/g, " ").split(" ")[1]}
//                     photo={row.lecturer_profile_pic}
//                     email={row.lecturer_email}
//                     mobile={row.lecturer_contact ? row?.lecturer_contact : ""}
//                     right={false}
//                     role={"lecturer"}
//                     status={row.lecturer_status}
//                     activity_status={row.lecturer_activity_status}
//                   />
//                 </span>
//                 <span
//                   title={row.lECTURER}
//                   className="as-text-blue curser feature-name"
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.lECTURERID}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.lECTURER ? row["lECTURER"] : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Coach",
//       selector: "programme_coordinator_name",
//       sortable: true,
//       sortField: "coach",
//       cell: (row) => (
//         <>
//           {row.programme_coordinator_name ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("Coach")
//                   }
//                 >
//                   {row.programme_coordinator_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.programme_coordinator_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.programme_coordinator_name.split(" ")[0],
//                       row.programme_coordinator_name
//                         .replace(/ +/g, " ")
//                         .split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.coach_activity_status ? row.coach_activity_status.toLowerCase() == "online" ? "Online" : row.coach_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.programme_coordinator_name.split(" ")[0]}
//                     lastName={
//                       row.programme_coordinator_name
//                         .replace(/ +/g, " ")
//                         .split(" ")[1]
//                     }
//                     photo={row.programme_coordinator_profile_pic}
//                     email={row.programme_coordinator_email}
//                     mobile={
//                       row.programme_coordinator_contact
//                         ? row?.programme_coordinator_contact
//                         : ""
//                     }
//                     right={false}
//                     role={"Coach"}
//                     status={row.coach_status}
//                     activity_status={row.coach_activity_status}
//                   />
//                 </span>
//                 <span
//                   className="as-text-blue curser feature-name"
//                   title={row.programme_coordinator_name}
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.COACHID}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.programme_coordinator_name
//                     ? row["programme_coordinator_name"]
//                     : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Support",
//       selector: "SUPPORT",
//       sortable: true,
//       sortField: "support",
//       cell: (row) => (
//         <>
//           {row.SUPPORT && row.SUPPORT ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("student support")
//                   }
//                 >
//                   {row.programme_support_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.programme_support_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.SUPPORT.split("")[0],
//                       row.SUPPORT.replace(/ +/g, " ").split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.support_activity_status ? row.support_activity_status.toLowerCase() == "online" ? "Online" : row.support_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.SUPPORT}
//                     photo={row.programme_support_profile_pic}
//                     email={row.programme_support_email}
//                     mobile={
//                       row.programme_support_contact
//                         ? row.programme_coordinator_contact
//                         : ""
//                     }
//                     right={false}
//                     role={"student support"}
//                     status={row.support_status}
//                     activity_status={row.support_activity_status}
//                   />
//                 </span>
//                 <span
//                   className="as-text-blue curser feature-name"
//                   title={row.SUPPORT}
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.programme_support_id}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.SUPPORT ? row["SUPPORT"] : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "School",
//       selector: "school_type",
//       sortable: true,
//       sortField: "school_type",
//       cell: (row) => row.school_type ? RenderSchool(row.school_type).html : "-"
//     },
//     {
//       name: "Type",
//       selector: "subject_type",
//       sortable: true,
//       sortField: "subject_type",
//       cell: (row) => row.subject_type ? RenderCourseType(row.subject_type).html : "-"
//     },
//     {
//       name: "Action",
//       selector: "Action",
//       cell: (row) => (
//         <div className="assessment-08 btn-dropdown-grp">
//           <div className="as-buttons d-flex">
//             <PermissionsGate scopes={["cedit"]} RenderError={() => (
//               <button className="btn btn-primary rounded-circle" title="Open" disabled>
//                 <i className="fal fa-folder-open"></i>
//               </button>
//             )}>
//               <Link
//                 className="btn btn-primary rounded-circle"
//                 to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
//                 title="Open"
//               >
//                 <i className="fal fa-folder-open"></i>
//               </Link>
//             </PermissionsGate>
//             <div className="dropdown btn-dropdown-item">
//               <button
//                 className="btn btn-primary rounded-circle dropdown-toggle"
//                 type="button"
//                 id="dropdownMenuButton"
//                 data-toggle="dropdown"
//                 aria-haspopup="true"
//                 aria-expanded="false"
//                 title="More"
//               >
//                 <i className="fal fa-ellipsis-h-alt"></i>
//               </button>
//               <div
//                 className="dropdown-menu"
//                 aria-labelledby="dropdownMenuButton"
//               >                
//                 {(row.subject_type == "shortcourse" || row.subject_type == "short course") ? 
//                   <PermissionsGate scopes={["cadd"]} errorProps={{disabled:true}}>
//                     <button 
//                       className="btn btn-primary rounded-circle" 
//                       title="Copy To Future Date" 
//                       data-toggle="modal" 
//                       data-target="#copyCourse" 
//                       onClick={()=>{handleCopyCourse(row)}}
//                     >
//                       <i className="far fa-copy"></i>
//                     </button> 
//                   </PermissionsGate>
//                 : <></>} 
//                 <Link
//                   className="btn btn-primary rounded-circle"
//                   to={{
//                     pathname: `https://student.myaie.ac/courseinfo/${row.qualification_id}/subject/${row.intakeID}/${SP_PASS}`,
//                   }}
//                   target="_blank"
//                   title="Student View"
//                 >
//                   <i className="fal fa-eye"></i>
//                 </Link>
//                 <PermissionsGate scopes={["cdelete"]} errorProps={{ disabled: true }}>
//                   <button className="btn btn-danger rounded-circle" title="Delete"
//                     onClick={() => handleDeleteCourse(row.id)}
//                   >
//                     <i className="fal fa-trash-alt"></i>
//                   </button>
//                 </PermissionsGate>
//               </div>
//             </div>
//           </div>
//         </div>
//       ),
//     },
//   ]);

//   const selfColumns = useMemo(() => [
//     {
//       name: "Name",
//       selector: "name",
//       sortField: "name",
//       sortable: true,
//       cell: (row) => (
//         <div className="assigned-title-block-new">
//           <div className="dashboard-message-count">
//             {row.messageCount != 0 && row.messageCount != null ? <Link to={`/courseAdministration/coursesdetails/${row.id}/post-feed/show`} target="_blank"><i title="You have unread messages" className="fal fa-comment-lines message-count mt-2 mr-2 curser"></i></Link> : ""}
//             <div
//               className="assigned-title-itm overflow-ellipsis2 fst_line2"
//               title={row.name}
//             >
//               <Link
//                 className="as-text-blue curser textLimit2"
//                 to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
//                 title={row.name}
//               >
//                 {row.name ? row.name : "-"}
//               </Link>


//             </div>
//           </div>

//         </div>
//       ),
//     },
//     {
//       name: "Published Date",
//       selector: "intakePublish",
//       sortField: "intakePublish",
//       sortable: true,
//       cell: (row) => (
//         <>
//           {row.intakePublish ? (
//             <>
//               <p className="right-space">
//                 {moment.unix(row.intakePublish).format(TABLE_DATE_FORMAT)}
//               </p>
//             </>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Learning Method",
//       selector: "study_type",
//       sortable: true,
//       sortField: "type",
//       cell: (row) => (
//         <p className="learningMethodText">
//           {row && row.type ? RenderLearningMethod(row.type).html : "-"}
//         </p>
//       ),
//     },
//     {
//       name: "Programme",
//       selector: "qualificationName",
//       sortable: true,
//       cell: (row) => (
//         <span className="overflow-ellipsis2 fst_line2">
//           <Link
//             className="as-text-blue curser"
//             title={row.qualificationName}
//             to={{
//               pathname: `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`,
//             }}
//           >
//             {row && row.qualificationName ? row.qualificationName : "-"}
//           </Link>
//           <span
//             onClick={() => {
//               history.push(
//                 `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`
//               );
//             }}
//           ></span>
//         </span>
//       ),
//     },
//     {
//       name: "#Students",
//       selector: "totalRows",
//       sortable: true,
//       sortField: "students",
//       cell: (row) => <p>{row.totalRows ? row["totalRows"] : "-"}</p>,
//     },
//     {
//       name: "Intake",
//       selector: "year",
//       sortable: true,
//       sortField: "intake",
//       cell: (row) => (
//         <p>
//           {row.year || row.number ? (
//             <>
//               {" "}
//               {row.year ? row.year : ""}-{row.number ? row["number"] : ""}
//             </>
//           ) : (
//             "-"
//           )}
//         </p>
//       ),
//     },
//     {
//       name: "Lecturer",
//       selector: "lECTURER",
//       sortable: true,
//       sortField: "lecturer",
//       cell: (row) => (
//         <>
//           {row.lECTURER ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("lecturer")
//                   }
//                 >
//                   {row.lecturer_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.lecturer_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.lECTURER.split(" ")[0],
//                       row.lECTURER.replace(/ +/g, " ").split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.lecturer_activity_status ? row.lecturer_activity_status.toLowerCase() == "online" ? "Online" : row.lecturer_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.lECTURER.split(" ")[0]}
//                     lastName={row.lECTURER.replace(/ +/g, " ").split(" ")[1]}
//                     photo={row.lecturer_profile_pic}
//                     email={row.lecturer_email}
//                     mobile={row.lecturer_contact ? row?.lecturer_contact : ""}
//                     right={false}
//                     role={"lecturer"}
//                     status={row.lecturer_status}
//                     activity_status={row.lecturer_activity_status}
//                   />
//                 </span>
//                 <span
//                   title={row.lECTURER}
//                   className="as-text-blue curser feature-name"
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.lECTURERID}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.lECTURER ? row["lECTURER"] : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Coach",
//       selector: "programme_coordinator_name",
//       sortable: true,
//       sortField: "coach",
//       cell: (row) => (
//         <>
//           {row.programme_coordinator_name ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("Coach")
//                   }
//                 >
//                   {row.programme_coordinator_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.programme_coordinator_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.programme_coordinator_name.split(" ")[0],
//                       row.programme_coordinator_name
//                         .replace(/ +/g, " ")
//                         .split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.coach_activity_status ? row.coach_activity_status.toLowerCase() == "online" ? "Online" : row.coach_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.programme_coordinator_name.split(" ")[0]}
//                     lastName={
//                       row.programme_coordinator_name
//                         .replace(/ +/g, " ")
//                         .split(" ")[1]
//                     }
//                     photo={row.programme_coordinator_profile_pic}
//                     email={row.programme_coordinator_email}
//                     mobile={
//                       row.programme_coordinator_contact
//                         ? row?.programme_coordinator_contact
//                         : ""
//                     }
//                     right={false}
//                     role={"Coach"}
//                     status={row.coach_status}
//                     activity_status={row.coach_activity_status}
//                   />
//                 </span>
//                 <span
//                   className="as-text-blue curser feature-name"
//                   title={row.programme_coordinator_name}
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.COACHID}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.programme_coordinator_name
//                     ? row["programme_coordinator_name"]
//                     : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "Support",
//       selector: "SUPPORT",
//       sortable: true,
//       sortField: "support",
//       cell: (row) => (
//         <>
//           {row.SUPPORT && row.SUPPORT ? (
//             <div className="assigned-title-block-new">
//               <div className="assigned-title-itm">
//                 <span
//                   className={
//                     "assigned-title-blk  name-icon cat-dark-red " +
//                     ColorRender("student support")
//                   }
//                 >
//                   {row.programme_support_profile_pic ? (
//                     <img
//                       src={`${IMAGE_URL}/${row.programme_support_profile_pic.replaceAll(
//                         "/home/myaie/public_html/",
//                         ""
//                       )}`}
//                       alt="AIE"
//                     />
//                   ) : (
//                     InitialRender([
//                       row.SUPPORT.split("")[0],
//                       row.SUPPORT.replace(/ +/g, " ").split(" ")[1],
//                     ])
//                   )}
//                   <span className={`profile-box-2-status ${row.support_activity_status ? row.support_activity_status.toLowerCase() == "online" ? "Online" : row.support_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
//                     <i className="fas fa-circle"></i>
//                   </span>
//                   <Hover
//                     firstName={row.SUPPORT}
//                     photo={row.programme_support_profile_pic}
//                     email={row.programme_support_email}
//                     mobile={
//                       row.programme_support_contact
//                         ? row.programme_coordinator_contact
//                         : ""
//                     }
//                     right={false}
//                     role={"student support"}
//                     status={row.support_status}
//                     activity_status={row.support_activity_status}
//                   />
//                 </span>
//                 <span
//                   className="as-text-blue curser feature-name"
//                   title={row.SUPPORT}
//                   onClick={() =>
//                     history.push(
//                       `/systemAdministration/userManagement/open/${row.programme_support_id}/aboutYou`
//                     )
//                   }
//                 >
//                   <span className="textLimit100">{row.SUPPORT ? row["SUPPORT"] : "-"}</span>
//                 </span>
//               </div>
//             </div>
//           ) : (
//             "-"
//           )}
//         </>
//       ),
//     },
//     {
//       name: "School",
//       selector: "school_type",
//       sortable: true,
//       sortField: "school_type",
//       cell: (row) => row.school_type ? RenderSchool(row.school_type).html : "-"
//     },
//     {
//       name: "Type",
//       selector: "subject_type",
//       sortable: true,
//       sortField: "subject_type",
//       cell: (row) => row.subject_type ? RenderCourseType(row.subject_type).html : "-"
//     },
//     {
//       name: "Action",
//       selector: "Action",
//       cell: (row) => (
//         <div className="assessment-08 btn-dropdown-grp">
//           <div className="as-buttons d-flex">
//             <PermissionsGate scopes={["cedit"]} RenderError={() => (
//               <button className="btn btn-primary rounded-circle" title="Open" disabled>
//                 <i className="fal fa-folder-open"></i>
//               </button>
//             )}>
//               <Link
//                 className="btn btn-primary rounded-circle"
//                 to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
//                 title="Open"
//               >
//                 <i className="fal fa-folder-open"></i>
//               </Link>
//             </PermissionsGate>
//             <div className="dropdown btn-dropdown-item">
//               <button
//                 className="btn btn-primary rounded-circle dropdown-toggle"
//                 type="button"
//                 id="dropdownMenuButton"
//                 data-toggle="dropdown"
//                 aria-haspopup="true"
//                 aria-expanded="false"
//                 title="More"
//               >
//                 <i className="fal fa-ellipsis-h-alt"></i>
//               </button>
//               <div
//                 className="dropdown-menu"
//                 aria-labelledby="dropdownMenuButton"
//               >
//                 <Link
//                   className="btn btn-primary rounded-circle"
//                   to={{
//                     pathname: `https://student.myaie.ac/courseinfo/${row.qualification_id}/subject/${row.intakeID}/${SP_PASS}`,
//                   }}
//                   target="_blank"
//                   title="Student View"
//                 >
//                   <i className="fal fa-eye"></i>
//                 </Link>
//                 <PermissionsGate scopes={["cdelete"]} errorProps={{ disabled: true }}>
//                   <button className="btn btn-danger rounded-circle" title="Delete"
//                     onClick={() => handleDeleteCourse(row.id)}
//                   >
//                     <i className="fal fa-trash-alt"></i>
//                   </button>
//                 </PermissionsGate>
//               </div>
//             </div>
//           </div>
//         </div>
//       ),
//     },
//   ]);

//   const exportData = (fileType, fileName) => {
//     let data = courseList;
//     let header = [
//       "Name",
//       "Start",
//       "End",
//       "Status",
//       "Learning Method",
//       "Programme",
//       "Intake",
//       "Student",
//       "Lecturer",
//       "Couch",
//       "Support",
//       "School",
//       "Type"
//     ];

//     Swal.fire({
//       title: "File downloading",
//       onOpen: function () {
//         Swal.showLoading();
//       },
//     });

//     let data1 = {
//       pageNo: tableState.page,
//       size: tableState.perPage,
//       viaIntakeYear: intake.arr,
//       viaLearnType: learningMethod.arr,
//       exportStatus: "true",
//       viaStatus: filterstatus.length > 0 ? filterstatus : ["Upcoming"],
//       key: tableState.sortKey,
//       sortOrder: tableState.sortOrder,
//       viaProgramme: programmeIds.arr,
//       viaSearch: search,
//       viaSchoolType: school.arr,
//       viaSubjectType: courseType.arr,
//       showing: showing.arr
//     };

//     if (type == "selfplaced") {
//       header = [
//         "Name",
//         "Published Date",
//         "Status",
//         "Learning Method",
//         "Programme",
//         "Intake",
//         "Student",
//         "Lecturer",
//         "Couch",
//         "Support",
//         "School",
//         "Type"
//       ];
//       data1 = {
//         pageNo: tableState.page,
//         size: tableState.perPage,
//         viaIntakeYear: intake.arr,
//         viaLearnType: [type],
//         exportStatus: "true",
//         viaStatus: [],
//         key: tableState.sortKey,
//         sortOrder: tableState.sortOrder,
//         viaProgramme: programmeIds.arr,
//         viaSearch: search,
//         viaSchoolType: school.arr,
//         viaSubjectType: courseType.arr,
//         showing: showing.arr
//       };
//     }
//     GetCourselist(data1)
//       .then((res) => {
//         data = res.data.result;
//         data = data?.map((row) => ({
//           Name: row.name,
//           "Start": row.start
//             ? `${moment.unix(row.start).format(TABLE_DATE_FORMAT)}`
//             : "-",
//           "End": row.end
//             ? `${moment.unix(row.end).format(TABLE_DATE_FORMAT)}`
//             : "-",
//           "Published Date": row.intakePublish
//             ? `${moment.unix(row.intakePublish).format(TABLE_DATE_FORMAT)}`
//             : "-",
//           Status: row.nestedStatus ? row.nestedStatus : "-",
//           "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
//           Programme: row.qualificationName ? row?.qualificationName : "-",
//           Intake: `${row.year}-${row.number}`,
//           Student: row.totalRows ? row.totalRows : "-",
//           Lecturer: row.lECTURER ? row.lECTURER : "-",
//           Couch: row.programme_coordinator_name
//             ? row.programme_coordinator_name
//             : "-",
//           Support: row.SUPPORT ? row.SUPPORT : "-",
//           School: row.school_type ? RenderSchool(row.school_type).text : "-",
//           Type: row.subject_type ? RenderCourseType(row.subject_type).text : "-"
//         }));

//         if (fileType === "csv") {
//           const csvString = Papa.unparse({ fields: header, data });
//           const blob = new Blob([csvString], {
//             type: "text/csv;charset=utf-8,",
//           });

//           const blobURL = window.URL.createObjectURL(blob);

//           const anchor = document.createElement("a");
//           anchor.download = fileName;
//           anchor.href = blobURL;
//           anchor.dataset.downloadurl = [
//             "text/csv",
//             anchor.download,
//             anchor.href,
//           ].join(":");
//           anchor.click();

//           setTimeout(() => {
//             URL.revokeObjectURL(blobURL);
//           }, 1000);
//           Swal.close();
//         } else if (fileType === "xlsx") {
//           const compatibleData = data.map((row) => {
//             const obj = {};
//             header.map((col, index) => {
//               obj[col] = row[col];
//             });
//             return obj;
//           });

//           let wb = XLSX.utils.book_new();
//           let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
//           XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
//           XLSX.writeFile(wb, `${fileName}.xlsx`);
//           Swal.close();
//           return false;
//         }
//         if (fileType === "pdf") {
//           const compatibleData = data.map((row) => {
//             if (type == "selfplaced") {
//               return [
//                 row["Name"],
//                 row["Published Date"],
//                 row["Status"],
//                 row["Learning Method"],
//                 row["Programme"],
//                 row["Intake"],
//                 row["Student"],
//                 row["Lecturer"],
//                 row["Couch"],
//                 row["Support"],
//                 row["School"],
//                 row["Type"]
//               ];
//             }
//             return [
//               row["Name"],
//               row["Start"],
//               row["End"],
//               row["Status"],
//               row["Learning Method"],
//               row["Programme"],
//               row["Intake"],
//               row["Student"],
//               row["Lecturer"],
//               row["Couch"],
//               row["Support"],
//               row["School"],
//               row["Type"]
//             ];
//           });
//           const doc = new JsPDF();
//           doc.autoTable({
//             head: [header],
//             body: compatibleData,
//             styles: {
//               minCellHeight: 10,
//               minCellWidth: 5,
//               halign: "left",
//               fontSize: 8,
//             },
//           });
//           doc.save(`${fileName}.pdf`);
//           Swal.close();
//           return false;
//         }
//       })
//       .catch((error) => {
//         console.log("error >>>>>> ", error);
//       });
//   };
//   return (
//     <>
//       {/* upper tab code start */}
//       <div className="tabs-wrap">
//         <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
//           <li
//             className="nav-item"
//             onClick={() => {
//               setFilterStatus(["In Progress"]);
//               history.push("/home/courses/In Progress");
//             }}
//           >
//             <a
//               className={`nav-link cursor-pointer ${type === "In Progress" ? "active" : ""
//                 }`}
//               id="pills-tab2"
//               data-toggle="pill"
//               role="tab"
//               aria-controls="pills-tab12"
//               aria-selected="true"
//             >
//               <i className="fal fa-calendar-alt"></i>In-Progress
//             </a>
//           </li>
//           <li
//             className="nav-item"
//             onClick={() => {
//               setFilterStatus(["selfplaced"]);
//               history.push("/home/courses/selfplaced");
//             }}
//           >
//             <a
//               className={`nav-link cursor-pointer ${type === "selfplaced" ? "active" : ""
//                 }`}
//               id="pills-tab2"
//               data-toggle="pill"
//               role="tab"
//               aria-controls="pills-tab12"
//               aria-selected="true"
//             >
//               <i className="fal fa-calendar-alt"></i>Self Paced
//             </a>
//           </li>
//           <li
//             className="nav-item"
//             onClick={() => {
//               setFilterStatus(["Upcoming"]);
//               history.push("/home/courses/upcoming");
//             }}
//           >
//             <a
//               className={`nav-link cursor-pointer ${type === "upcoming" ? "active" : ""
//                 }`}
//               id="pills-tab3"
//               data-toggle="pill"
//               role="tab"
//               aria-controls="pills-tab13"
//               aria-selected="true"
//             >
//               <i className="fal fa-arrow-circle-right"></i>Upcoming
//             </a>
//           </li>
//           <li
//             className="nav-item"
//             onClick={() => {
//               setFilterStatus(["Past"]);
//               history.push("/home/courses/past");
//             }}
//           >
//             <a
//               className={`nav-link cursor-pointer ${type === "past" ? "active" : ""
//                 }`}
//               id="pills-tab1"
//               data-toggle="pill"
//               role="tab"
//               aria-controls="pills-tab11"
//               aria-selected="true"
//             >
//               <i className="fal fa-history"></i>Past
//             </a>
//           </li>
//         </ul>
//       </div>
//       {/* tab code End */}
//       <DataTableComponent
//         data={courseList}
//         loading={loading}
//         state={tableState}
//         setState={setTableState}
//         setSearch={setSearch}
//         totalRows={totalRows}
//         columns={type=="selfplaced" ? selfColumns : columns}
//         exportFunction={exportData}
//         exportFileName={"Course_list"}
//         isInsidePopUp
//         filters={[
//           filterstatus == "Past" && {
//             filterName: "Showing",
//             optionArr: [{ label: "1 Year", value: "1" }, { label: "2 Year", value: "2" }, { label: "3 Year", value: "3" }, { label: "5 Year", value: "5" }, { label: "All", value: "-1" }],
//             state: showing,
//             setState: setShowing,
//             singleSelect: true
//           },
//           type != "selfplaced" && {
//             filterName: "Learning Method",
//             optionArr: courseDropdown.learning,
//             state: learningMethod,
//             setState: setLearningMethod,
//             renderLabelFunction: RenderLearningMethod
//           },
//           {
//             filterName: "Programme",
//             optionArr: courseDropdown.programme,
//             state: programmeIds,
//             setState: setProgrammeIds,
//             isSearchFilter: true
//           },
//           {
//             filterName: "Intake",
//             optionArr: courseDropdown.intake,
//             state: intake,
//             setState: setIntake,
//             isOptionReversed: true
//           },
//           {
//             filterName: "Reply",
//             optionArr: [
//               {
//                 label: "Read",
//                 value: "read"
//               },
//               {
//                 label: "Unread",
//                 value: "unread"
//               }
//             ],
//             state: reply,
//             setState: setReply,
//             renderLabelFunction: RenderReadStatus
//           },
//           {
//             filterName: "School",
//             optionArr: courseDropdown.school,
//             state: school,
//             setState: setSchool,
//             renderLabelFunction: RenderSchool
//           },
//           type != "selfplaced" && {
//             filterName: "Type",
//             optionArr: courseDropdown.type,
//             state: courseType,
//             setState: setCourseType,
//             renderLabelFunction: RenderCourseType
//           }
//         ].filter(item => item)}
//       />
//       <CopyCourse courseId={courseId} refresh={()=>{setDeleteRow(!deleteRow);setCourseId("")}} />
//     </>
//   );

// };

// export default ListofCourse;

import React from 'react'
import InProgressCourses from './InProgressCourses'
import { useHistory, useParams } from 'react-router-dom'
import UpcomingCourse from './UpcomingCourse'
import PastCourses from './PastCourses'
import AllCourse from './AllCourse'
import SelfPlacedCourses from './SelfPlacedCourses'

function Course({globalReset, filterIntake,filterSchool,filterLearningMethod,filterLecturer}) {
  const { type } = useParams()
  const history = useHistory()

  return (
    <>
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
        <li
            className="nav-item"
            onClick={() => {
              // setFilterStatus(["In Progress"]);
              history.push("/home/courses/allCourses");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "allCourses" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-globe-africa"></i>All Courses
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              // setFilterStatus(["In Progress"]);
              history.push("/home/courses/In Progress");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "In Progress" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-calendar-alt"></i>In-Progress
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              // setFilterStatus(["selfplaced"]);
              history.push("/home/courses/selfplaced");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "selfplaced" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-calendar-alt"></i>Self Paced
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              // setFilterStatus(["Upcoming"]);
              history.push("/home/courses/upcoming");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "upcoming" ? "active" : ""
                }`}
              id="pills-tab3"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab13"
              aria-selected="true"
            >
              <i className="fal fa-arrow-circle-right"></i>Upcoming
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              // setFilterStatus(["Past"]);
              history.push("/home/courses/past");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "past" ? "active" : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-history"></i>Past
            </a>
          </li>
        </ul>
      </div>
      { type === "allCourses" ? <AllCourse globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>
        : type === "In Progress" ? <InProgressCourses globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>
         : type === "upcoming" ? <UpcomingCourse globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>
          : type === "past" ? <PastCourses globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>
            : <SelfPlacedCourses globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>}
    </>
  )
}

export default Course
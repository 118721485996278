import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { AddModule, GetListofModule, UpdateModule } from "../../../services/ProgramService";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { RenderPMCStatus } from "../../../utils/CommonGroupingItem";
import HtmlParser from "react-html-parser";
import { GetNetsuiteItemsCancelToken } from "../../../services/CourseService";
import axios from "axios";
const statusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span class="red">Draft  </span> `)
  },
  {
    value: "New",
    label: HtmlParser(`<span class="skyblue"> New </span>`)
  },
  {
    value: "Final",
    label: HtmlParser(` <span class="green">Final</span>`)
  },
  {
    value: "Approved",
    label: HtmlParser(`<span class="yellowshade">Approved</span>`)
  },
  {
    value: "Inactive",
    label: HtmlParser(`<span class="blue">Inactive</span>`)
  },
]

const ModuleDetail = ({detail = {}, description, setDescription, prescribedTextBooks, setPrescribedTextBooks, isEditorStateReady}) => {
  const history = useHistory();
  const { id, type } = useParams();
  const [disabled, setDisabled] = useState(false);
  // const [detail, setDetail] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [modalDuration, setModalDuration] = useState([]);
  // const [description, setDescription] = useState("")
  // const [prescribedTextBooks, setPrescribedTextBooks] = useState("");
  const [isPreReqNone, setIsPreReqNone] = useState(true);
  const [isCoreqNone, setIsCoreqNone] = useState(true);
  const [netsuiteList, setNetsuiteList] = useState([]);
  // const [isEditorStateReady, setIsEditorStateReady] = useState(false);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken?.source();
      cancelTokenSources.push(source);
      try { 
            const data = await GetNetsuiteItemsCancelToken(
              '',
              source.token
            );
            if (data.status === 200) {
              setNetsuiteList(
                data && data.data && data.data.data && data.data.data.items
                ? data.data.data.items
                : []
              );
            }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log('error :', error);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    GetListofModule()
      .then((res) => {
        let moduleData = res?.data.map((mod) => ({
          value: mod.Id,
          label: mod.Portal,
        }));
        moduleData.push({ value: 0, label: "None" })
        setModuleList(moduleData);
      })
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    let response = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    let duration = [];
    for (let i = 1; i <= 52; i++) {
      let obj = { value: i, label: i };
      duration.push(obj);
    }
    setModalDuration(duration);
  }, []);

  useEffect(() => {
    // setIsEditorStateReady(false);
    // (type === "open" || type === "duplicate") &&
      // GetModuleDetail({ MId: id })
      //   .then((res) => {
      //     let apiValue = res?.data?.data[0];
      //     setDetail({ ...apiValue, pre_requisites: apiValue.pre_requisites ? JSON.parse(apiValue.pre_requisites) : [], co_requisites: apiValue.co_requisites ? JSON.parse(apiValue.co_requisites) : [] });
      //     // const descriptionBlock = convertFromHTML(res?.data?.data[0]?.Description);
      //     // const description = ContentState.createFromBlockArray(descriptionBlock?.contentBlocks, descriptionBlock?.entityMap);
      //     // const prescribedTextBooksBlock = convertFromHTML(res?.data?.data[0]?.prescribed_text);
      //     // const prescribedTextBooksValue = ContentState.createFromBlockArray(prescribedTextBooksBlock?.contentBlocks, prescribedTextBooksBlock?.entityMap);
      //     setDescription(res?.data?.data[0]?.Description);
      //     setPrescribedTextBooks(res?.data?.data[0]?.prescribed_text);
      //     setIsEditorStateReady(true);
      //   })
      //   .catch((err) => {
      //     setIsEditorStateReady(true)
      //     console.log(err)
      //   });
  }, [type]);

  const formik = useFormik({
    enableReinitialize: type === "open" || type === "duplicate" ? true : false,
    initialValues: {
      Code: detail?.Code ? detail.Code : "",
      Portal: detail?.Portal ? detail.Portal : "",
      Marketing_Name: detail?.Marketing_Name ? detail.Marketing_Name : "",
      Duration: detail?.Duration ? detail.Duration : "",
      credits: detail?.credits ? detail.credits : "",
      pre_requisites: detail?.pre_requisites ? detail.pre_requisites : [0],
      co_requisites: detail?.co_requisites ? detail.co_requisites : [0],
      prescribed_text: detail?.prescribed_text ? detail.prescribed_text : "",
      type: detail?.type ? detail.type : "",
      Module_Type: detail?.Module_Type ? detail.Module_Type : "",
      term: detail?.term ? detail?.term : "",
      Description: detail?.Description ? detail?.Description : "",
      module_status: detail?.module_status ? detail?.module_status : "",
      netsuite_product : detail?.netsuite_product ? detail.netsuite_product : "",
      netsuite_department_id : detail?.netsuite_department_id ? detail.netsuite_department_id : "",

    },
    validationSchema: Yup.object({
      Code: Yup.string()
        .max(20, "Code should not be greater than 20 Character")
        .required("Code is required")
        .trim(),
      Portal: Yup.string()
        .required("Portal Name is required").max(255, "Portal Name should not be greater than 255 Character")
        .trim(),
      // Description: Yup.string().test("required", "Description is Required", (value) => {
      //   const editorJSON1 = (convertToRaw(description.getCurrentContent()));
      //   let isInvalid = (editorJSON1.blocks.length == 1 && editorJSON1.blocks[0].text.trim() == "")
      //   return !isInvalid;
      // }),
      // prescribed_text: Yup.string().test("required", "Prescribed Text is Required", (value) => {
      //   const editorJSON1 = (convertToRaw(prescribedTextBooks.getCurrentContent()));
      //   let isInvalid = (editorJSON1.blocks.length == 1 && editorJSON1.blocks[0].text.trim() == "")
      //   return !isInvalid;
      // }),
      module_status: Yup.string().required("Status is required"),
      Module_Type: Yup.string().required("Module Type is required"),
      term: Yup.string().required("Term is required"),
      type: Yup.string().required("Type is required"),
      // Marketing_Name: Yup.string()
      //   .max(55, "Marketing Name should not be greater than 55 Character")
      //   .required("Marketing Name is required")
      //   .trim(),
      // Duration: Yup.string()
      //   .required("Duration is required"),
      // credits: Yup.string()
      //   .matches("^[0-9]*$", "Credits must be number")
      //   .required("Credits is required"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      // const Description = draftToHtml(
      //   convertToRaw(description.getCurrentContent())
      // );
      // const prescribed_text = draftToHtml(
      //   convertToRaw(prescribedTextBooks.getCurrentContent())
      // );
      // let Description = JSON.stringify(convertToRaw(description.getCurrentContent()))
      // let prescribed_text = JSON.stringify(convertToRaw(prescribedTextBooks.getCurrentContent()))
      values = { ...values, pre_requisites: JSON.stringify(values.pre_requisites), co_requisites: JSON.stringify(values.co_requisites) }
      values = { ...values, Description: description, prescribed_text: prescribedTextBooks }
      if (type === "open") {
        UpdateModule({ ...values, MId: id })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated Successfully",
            });
            setDisabled(false);
            // history.push("/program-management-center/modules/module/table");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data,
            });
            setDisabled(false);
          });
      } else {
        if (type == "duplicate") {
          values = { ...values, duplicateId: id };
        }
        AddModule(values)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            history.push("/program-management-center/modules/module/table");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
          });
      }
    },
  });

  const ModuleType = [
    { value: "Information", label: "Information" },
    { value: "Mentored", label: "Mentored" },
    { value: "Orientation", label: "Orientation" },
    { value: "Scheduled", label: "Scheduled" },
  ];
  const ModuleTerm = [
    { value: "term1", label: "Term1" },
    { value: "term2", label: "Term2" },
    { value: "term3", label: "Term3" },
    { value: "term4", label: "Term4" },
  ];
  const Type = [
    { value: "core", label: "Core" },
    { value: "specialised", label: "Specialised" },
    { value: "foundation", label: "Foundation" },
  ]
  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const modifiyOptionsForSelect = (arr, type = '') => {
    let modifiedArr = [];
    if (
      type == 'courseType' ||
      type == 'studyType' ||
      type == 'correspondence'
    ) {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: arr[key], label: arr[key], key: key });
      });
    } else if (
      type == 'lecturer' ||
      type == 'programCoordinator' ||
      type == 'programSupport'
    ) {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.name, label: ele.name, id: ele.UserID });
      });
    } else if (type == 'intakeYear') {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.year, label: ele.year });
      });
      modifiedArr.sort(function (a, b) {
        return b.value - a.value;
      });
    } else if (type == 'intakeNumber') {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: key, label: key });
        // modifiedArr.push({ value: arr[key], label: arr[key], number: key });
      });
    } else if (type == 'crm') {
      arr.map((elem) => {
        modifiedArr.push({
          value: elem[1] + ' (' + elem[4] + ') ' + elem[2],
          label: elem[1] + ' (' + elem[4] + ') ' + elem[2],
          id: elem[0],
        });
      });
    } else if (type === 'netsuite') {
      arr.map((elem) => {
        modifiedArr.push({
          value: elem.id,
          label: elem.displayname,
          department: elem.department
        });
      });
    }

    return modifiedArr;
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: !isDisabled
        //     ? isSelected
        //       ? data.color
        //       : color.alpha(0.3).css()
        //     : undefined,
        // },
      };
    },
  };


  return (
    <div className="card card-profile-info-card">
      <div className="">
        {/* <div className="new-card-header">
          <div className="card-header">Module Details</div>
        </div> */}

        <div className="card-body-inr card-body-info">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="form-group-blk mb-3">
                  <label>Portal Name *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.Portal && formik.touched.Portal
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter portal name"
                    name="Portal"
                    id="portalName"
                    title="Portal Name"
                    value={formik.values.Portal}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Status">
                  <label>Status *</label>
                  <Select
                    styles={colourStyles}
                    className={
                      "form-control color-drop custom-select-box" +
                      (formik.errors.module_status &&
                        formik.touched.module_status
                        ? " is-invalid"
                        : "")
                    }
                    name="status"
                    value={statusArr?.filter(val=>val?.value === formik.values.module_status)}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("module_status", value.value)
                      } else {
                        formik.setFieldValue("module_status", "")
                      }
                    }}
                    // menuIsOpen={true}
                    onBlur={formik.handleBlur}
                    options={statusArr}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.module_status
                        ? formik.values.module_status
                        : "Select Status  "
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label htmlFor="code">Code *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.Code && formik.touched.Code
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter code"
                    name="Code"
                    id="code"
                    title="Code"
                    maxLength={20}
                    value={formik.values.Code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Duration">
                  <label>Duration</label>

                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.Duration && formik.touched.Duration
                        ? " is-invalid"
                        : "")
                    }
                    name="Duration"
                    value={modalDuration?.filter(val=>val.value === formik.values.Duration)}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("Duration", value.value)
                      } else {
                        formik.setFieldValue("Duration", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={modalDuration}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.Duration
                        ? formik.values.Duration
                        : "Select Duration  "
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-group-blk mb-3">
                  <label>Marketing Name</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.Marketing_Name &&
                        formik.touched.Marketing_Name
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter marketing name"
                    title="Enter marketing name"
                    name="Marketing_Name"
                    value={formik.values.Marketing_Name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3">
                  <label>Credits</label>
                  <input
                    type="number"
                    className={
                      "form-control" +
                      (formik.errors.credits && formik.touched.credits
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter credits"
                    title="Enter credits"
                    name="credits"
                    value={formik.values.credits}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Module Type">
                  <label>Module Type *</label>

                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.Module_Type && formik.touched.Module_Type
                        ? " is-invalid"
                        : "")
                    }
                    name="ModuleType"
                    value={ModuleType?.filter(val=>val.value === formik.values.Module_Type)}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("Module_Type", value.value)
                      } else {
                        formik.setFieldValue("Module_Type", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={ModuleType}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.Module_Type
                        ? formik.values.Module_Type
                        : "Select Module Type  "
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Term">
                  <label>Term *</label>

                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.term && formik.touched.term
                        ? " is-invalid"
                        : "")
                    }
                    name="term"
                    value={ModuleTerm.filter((data) => {
                      return data.value === formik.values.term;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("term", value.value)
                      } else {
                        formik.setFieldValue("term", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={ModuleTerm}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.term ? formik.values.term : "Select Term"
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Type">
                  <label>Type *</label>

                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.type && formik.touched.type
                        ? " is-invalid"
                        : "")
                    }
                    name="type"
                    value={Type.filter((data) => {
                      return data.value === formik.values.type;
                    })}
                    onChange={(value) =>{
                      if(value){
                        formik.setFieldValue("type", value.value)
                      } else {
                        formik.setFieldValue("type", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={Type}
                    maxMenuHeight={175}
                    placeholder={
                      formik.values.type ? formik.values.type : "Select Type"
                    }
                    isClearable
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Pre-Requisites">
                  <label>Pre-Requisites</label>

                  <Select
                    className={
                      "form-control custom-select-box is-multi"
                    }
                    name="pre_requisites"
                    value={moduleList.filter((data) => {
                      return formik.values.pre_requisites && formik.values.pre_requisites.includes(data.value);
                    })}
                    onChange={(value) => {
                      let valArr = []
                      if (isPreReqNone && value) {
                        valArr = value?.filter((obj) => {
                          return obj.value;
                        })
                        setIsPreReqNone(false)
                      } else if (value && value.map(item => !item.value).includes(true)) {
                        valArr = value?.filter((obj) => {
                          return !obj.value;
                        })
                        setIsPreReqNone(true)
                      } else {
                        valArr = value
                      }
                      formik.setFieldValue("pre_requisites", valArr?.map((obj) => {
                        return obj.value;
                      }))
                    }}
                    onBlur={formik.handleBlur}
                    options={moduleList}
                    maxMenuHeight={175}
                    isMulti={true}
                    placeholder={
                      formik.values.pre_requisites && formik.values.pre_requisites.length
                        ? formik.values.pre_requisites
                        : "Select Pre-Requisites"
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="form-group-blk mb-3" title="Co-Requisites">
                  <label>Co-Requisites</label>

                  <Select
                    className={
                      "form-control custom-select-box is-multi"
                    }
                    name="co_requisites"
                    value={moduleList.filter((data) => {
                      return formik.values.co_requisites && formik.values.co_requisites.includes(data.value);
                    })}
                    // onChange={(value) => {
                    //   formik.setFieldValue("co_requisites", value?.map((obj) => {
                    //     return obj.value;
                    //   }))
                    // }
                    // }
                    onChange={(value) => {
                      let valArr = []
                      if (isCoreqNone && value) {
                        valArr = value?.filter((obj) => {
                          return obj.value;
                        })
                        setIsCoreqNone(false)
                      } else if (value && value.map(item => !item.value).includes(true)) {
                        valArr = value?.filter((obj) => {
                          return !obj.value;
                        })
                        setIsCoreqNone(true)
                      } else {
                        valArr = value
                      }
                      formik.setFieldValue("co_requisites", valArr?.map((obj) => {
                        return obj.value;
                      }))
                    }}
                    onBlur={formik.handleBlur}
                    options={moduleList}
                    maxMenuHeight={175}
                    isMulti={true}
                    placeholder={
                      formik.values.co_requisites && formik.values.co_requisites.length
                        ? formik.values.co_requisites
                        : "Select Co-Requisites"
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                          <div className="form-icon-group mb-4 d-block" title="Netsuite Product">
                            <label>Netsuite Product</label>
                            <Select
                              className={
                                'form-control custom-select-box' +
                                (formik.errors.netsuite_product &&
                                formik.touched.netsuite_product
                                  ? ' is-invalid'
                                  : '')
                              }
                              name="netsuite_product"
                              value={modifiyOptionsForSelect(netsuiteList.filter((data) => data.id == formik.values.netsuite_product),"netsuite")}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue(
                                    'netsuite_product',
                                    value.value
                                  );
                                  formik.setFieldValue(
                                    'netsuiteProductName',
                                    value.label
                                  );
                                  formik.setFieldValue(
                                    'netsuite_department_id',
                                    value.department
                                  )
                                } else {
                                  formik.setFieldValue('netsuite_product', '');
                                  formik.setFieldValue(
                                    'netsuiteProductName',
                                    ''
                                  );
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={
                                netsuiteList
                                  ? modifiyOptionsForSelect(
                                      netsuiteList,
                                      'netsuite'
                                    )
                                  : []
                              }
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.netsuiteProductName
                                  ? formik.values.netsuiteProductName
                                  : 'Netsuite Product'
                              }
                            />
                          </div>
                        </div>
              <div className="col-md-12">
                <div className="form-group-blk mb-3">
                  <div style={{ display:"none" }}>{description}</div>
                  <label>Description</label>
                  {isEditorStateReady || type == "add" ? <HtmlInputEditor
                    editorState={description}
                    setEditorState={setDescription}
                    hideSign={true}
                    isCKEditor={true}
                  /> : ""}
                </div>
              </div>
              {isEditorStateReady || type == "add" ?
                <div className="col-md-12">
                  <div className="form-group-blk mb-3">
                    <label>Prescribed Textbooks</label>
                    <div style={{ display:"none" }}>{prescribedTextBooks}</div>
                    {/* <HtmlInputEditor
                    editorState={prescribedTextBooks}
                    setEditorState={setPrescribedTextBooks}
                    hideSign={true}
                  /> */}
                    <HtmlInputEditor
                      editorState={prescribedTextBooks}
                      setEditorState={setPrescribedTextBooks}
                      hideSign={true}
                      isCKEditor={true}
                    />
                  </div>
                </div> :
                <div className="col-md-12">
                  <div className="form-group-blk mb-3">
                    <label>Prescribed Textbooks</label>
                    <div style={{ display:"none" }}>{prescribedTextBooks}</div>
                    
                  </div>
                </div>}
            </div>
            <div className="form-group form-group-save-cancel mt-4">
              <button
                className="btn btn-save btn-success"
                type="submit"
                title="Save"
                disabled={disabled}
              >
                {disabled ? (
                  <i className="fas fa-cog fa-spin"></i>
                ) : (
                  <i className="fal fa-save"></i>
                )}
                Save
              </button>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={() => history.push("/program-management-center/modules/module/table")}
              >
                <i className="fal fa-times"></i>Cancel
              </button>
            </div>

            {Object.keys(formik.values).map(key => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                )
              }
            })}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModuleDetail;

import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
// import { DeleteBrandSetting, GetListOfBrandSetting } from "../../../services/BrandServices";
import {
  NetSuiteCustomerList,
  NetSuiteCustomerListCancelToken,
  NetSuiteCustomerService,
} from "../../../services/NetSuiteService";
import moment from "moment";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import axios from "axios";

const CustomersList = () => {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [deleterow, setDeleteRow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   setIsLoaded(true);
  //   let formData = new FormData();
  //   formData.append("method", "GET");

  //   NetSuiteCustomerList(formData)
  //     .then((response) => {
  //       response.data.data.items.map((i) => {
  //         i.datecreated = moment(i.datecreated, "DD/MM/YYYY").valueOf() / 1000;
  //       });
  //       setData(response?.data?.data?.items);
  //       setIsLoaded(false);
  //       setDeleteRow(false);
  //     })
  //     .catch((error) => {
  //       setIsLoaded(false);
  //       setDeleteRow(false);
  //     });
  // }, [deleterow]);

  useEffect(() => {
    setIsLoaded(true);
    const dropdownSource = axios.CancelToken.source();
    let formData = new FormData();
    formData.append("method", "GET");

    const fetchData = async () => {
      try {
        const response = await NetSuiteCustomerListCancelToken(formData, dropdownSource.token);
        if (response?.status == 200) {
          response.data.data.items.map((i) => {
            i.datecreated = moment(i.datecreated, "DD/MM/YYYY").valueOf() / 1000;
          });
          setData(response?.data?.data?.items);
          setIsLoaded(false);
          setDeleteRow(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
          setDeleteRow(false);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempcompanyname = allData.filter((item) => {
        let includes =
          item.altname &&
          item.altname.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes =
          item.email &&
          item.email.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdatecreated = allData.filter((item) => {

        let includes =
          item.datecreated &&
          moment
            .unix(new Date(item.datecreated), `DD/MM/YYYY`)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempcompanyname,
        ...tempdatecreated,
        ...tempEmail,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      selector: "altname",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <PermissionsGate
              scopes={["nmcrview"]}
              RenderError={() => (
                <a href="javascript:void(0);" className="as-text-blue curser ">
                  {row.altname}
                </a>
              )}
            >
              <a
                href="javascript:void(0);"
                className="as-text-blue curser "
                onClick={() => handleEdit(row, row.id)}
              >
                {row.altname}
              </a>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Name",
    //   selector: "firstname",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="assigned-title-block-new">
    //       <div className="assigned-title-itm">
    //           {/* <a href="javascript:void(0);" className="as-text-blue curser " onClick={() => handleEdit(row, row.id)}>
    //             {row.lastname}
    //           </a> */}
    //           {row.lastname}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (row.email ? row.email : "-"),
    },
    {
      name: "Date Created",
      selector: "datecreated",
      sortable: true,
      cell: (row) => (
        <>
          {row.datecreated ? (
            <div className="dateTime">
              <p className="right-space">
                {moment
                  .unix(new Date(row.datecreated), `DD/MM/YYYY`)
                  .format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>
                {moment
                  .format(TABLE_TIME_FORMAT)}
              </p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              errorProps={{ disabled: true }}
              scopes={["nmcredit"]}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/administration/netsuite/customers/edit/${row.id}`}

              >
                <i className="fal fa-folder-open"></i>
              </Link>
              {/* <button
                className="btn btn-primary rounded-circle"
                onClick={() => handleEdit(row, row.id)}
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </button> */}
            </PermissionsGate>
            <PermissionsGate
              errorProps={{ disabled: true }}
              scopes={["nmcrdelete"]}
            >
              <button
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row.id)}
                title="Delete"
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Deleting Record",
            showConfirmButton: false,
          });

          let formData = new FormData();
          formData.append("method", "DELETE");
          formData.append("customer_id", ID);
          NetSuiteCustomerService(formData)
            .then((res) => {
              setDeleteRow(!deleterow);
              Swal.fire("Deleted!", "Customer has been deleted.", "success");
            })
            .catch((err) => {
              if (err.response.status === 422) {
                setDeleteRow(!deleterow);
                Swal.fire(
                  "",
                  `This company type cannot be deleted because the company has associated transactions.`,
                  "warning"
                );
              }
            });
        }
      })
      .catch((error) => {
        setDeleteRow(!deleterow);
      });
  };

  const handleEdit = (row, ID) => {
    history.push(`/administration/netsuite/customers/edit/${ID}`);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Email", "Date Created"];

    data = data?.map((row) => ({
      ...row,
      Name: row?.altname ? row.altname : "-",
      Email: row.email ? row.email : "-",
      "Date Created": row.datecreated ? moment
        .unix(new Date(row.datecreated), `DD/MM/YYYY`)
        .format(TABLE_DATE_FORMAT) : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row.Email, row["Date Created"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >
                  <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >
                    <div id="assessment-table-main_filter" className="dataTables_filter" >
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="button-reset dropdown-comman">
                  <button className="btn btn-primary" onClick={resetFilter} title="Reset" >
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="files-export-group">
                  <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Netsuite_Customers"); }} title="Export spreadsheet" >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Netsuite_Customers"); }} title="Export CSV" >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Netsuite_Customers"); }} title="Export PDF" >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div class=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["nmcradd"]} RenderError={() =>
                  <button className="btn btn-primary" title="New Customer" disabled>
                    <i className="fal fa-plus"></i>New Customer
                  </button>}>
                  <Link to={`/administration/netsuite/customers/add`}>
                    <button className="btn btn-primary" title="New Customer">
                      <i className="fal fa-plus"></i>New Customer
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="datecreated"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
};

export default CustomersList;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderPMCStatus } from "../../../utils/CommonGroupingItem";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
    getStaticPagesBrandList,
    getStaticPagesPopupList,
    updateStaticPages
} from "../../../services/StaticPgesServer";
import { AddCampusPages, getCampusColorList, getCampusLocationDropdown, getCampusPagesStatusList, GetCampusUpdateList, updateCampusPages } from "../../../services/CampusPagesServer";
import Resouses from "./resourses/Resouses";
import PermissionsGate from "../../../utils/permissionGate";


const CampusPagesOpt = () => {
    const history = useHistory();
    const { id, type } = useParams();
    const [updateData, setUpdateData] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [campusColour, setCampusColour] = useState([]);
    const [description, setDescription] = useState("")
    const [address, setAddress] = useState("")
    const [CustomCss, setCustomCss] = useState("")
    const [tourText, setTourText] = useState("")
    const [checkbox, setCheckbox] = useState({
        showButtonForm: false,
        addCustomCss: false,
        useCustomColor: false,
        showBannerBlock: false,
    });
    const [statusArr, setStatusArr] = useState([]);
    const [brandValue, setbrandValue] = useState([]);
    const [templateName, setTemplateName] = useState([]);
    const [popup, setPopup] = useState([]);
    const [iconName, setIconName] = useState("");

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete.map((data) => data.BrandID);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            getCampusPagesStatusList()
                .then(res => setStatusArr(res.data.publishedStatus))
                .catch(error => console.error("Error fetching status data", error));

            getCampusLocationDropdown()
                .then(res => setLinkedCampusData(res.data.campusLocationFilters))

            getStaticPagesPopupList()
                .then(res => setPopup(res.data.Data))

            getStaticPagesBrandList()
                .then(res => setBrandList(res.data.brandTypesFilters))
        };

        fetchData();
    }, []);

    useEffect(() => {
        GetCampusUpdateList(id)
            .then(res => {
                // console.log(res?.data?.Data)
                setUpdateData(res?.data?.Data)
            })
    }, [id])

    const formik = useFormik({
        enableReinitialize: type === "update",
        initialValues: {
            id: updateData?.id || "",
            pageTitle: updateData?.pageTitle || "",
            status: updateData?.status || "",
            linkedCampus: updateData?.linkedCampus || "",
            contactNumber: updateData?.contactNumber || "",
            email: updateData?.email || "",
            campusColor: updateData?.campusColor || "",
            useCustomColor: checkbox.useCustomColor ? 1 : 0,
            campusContent: description,
            campusAddress: address,
            popUp: updateData?.popUp || "",
            formEmail: updateData?.formEmail || "",
            conversionCode: updateData?.conversionCode || "",
            metaTitle: updateData?.metaTitle || "",
            metaDescription: updateData?.metaDescription || "",
            metaKeywords: updateData?.metaKeywords || "",
            addCustomCss: checkbox.addCustomCss ? 1 : 0,
            CustomCss: CustomCss,
            Brand: brandID || [],
            showBannerBlock: checkbox.showBannerBlock ? 1 : 0,
            showButtonForm: checkbox.showButtonForm ? 1 : 0,
            googleMapsEmbed: updateData?.googleMapsEmbed || "",
            tourText: tourText,
            tourVideo: "",
        },
        validationSchema: Yup.object({
            pageTitle: Yup.string().required("Page Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            contactNumber: Yup.number().required("Contact Number is required"),
            email: Yup.string().email("Enter Correct Email ").required("Email is required"),
            campusColor: Yup.string().required("Color is required"),
            showBannerBlock: Yup.string().required("Show Banner Block is required"),
            showButtonForm: Yup.string().required("Show Button is required"),
            campusContent: Yup.string().required("Campus Content is required"),
            campusAddress: Yup.string().required("Campus Address is required"),
            tourText: Yup.string().required("Campus Tour Text is required"),
            CustomCss: Yup.string().when('addCustomCss', {
                is: 1,
                then: Yup.string().required("Custom CSS is required")
            })
        }),
        onSubmit: async (values, actions) => {
            if (type === "update") {
                updateCampusPages(values)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "updated Successfully",
                        });
                        actions.resetForm();
                        history.push("/website_management/pages/campuspages/table");
                    })
                    .catch(err => console.error(err))
            } else {
                try {
                    await AddCampusPages(values);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/website_management/pages/campuspages/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            }
        }
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckbox((prevCheckbox) => ({
            ...prevCheckbox,
            [name]: checked,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            formik.setFieldValue("tourVideo", e.currentTarget.value);
            setIconName(file.name);
        }
    };

    const colourStyles = {
        option: (styles, { data }) => {
            const { bgColor, colorCode } = RenderPMCStatus(data.value);
            return {
                ...styles,
                backgroundColor: bgColor,
                color: colorCode,
                cursor: 'default'
            };
        }
    };

    useEffect(() => {
        let colorValue = {
            brandID: templateName
        }

        getCampusColorList(colorValue)
            .then(res => setCampusColour(res.data.result))
            .catch(err => console.error(err))
    }, [templateName])

    useEffect(() => {
        setbrandValue(brandList.filter((data) => formik.values.Brand.includes(data.value)))
    }, [formik.values.Brand])

    useEffect(() => {
        setTemplateName(brandValue.map(data => data.value))
    }, [brandValue])
    

    useEffect(() => {
        setCheckbox({
            ...checkbox,
            showButtonForm: updateData?.showButtonForm,
            addCustomCss: updateData?.addCustomCss,
            useCustomColor: updateData?.useCustomColor,
            showBannerBlock: updateData?.showBannerBlock,
        })
        setDescription(updateData?.campusContent)
        setAddress(updateData?.campusAddress)
        setTourText(updateData?.tourText)
        setCustomCss(updateData?.CustomCss)
        formik.setFieldValue("tourVideo", updateData?.tourVideo);
        setIconName(updateData?.tourVideo);
    }, [updateData])

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0">Campus Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Page Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.pageTitle && formik.touched.pageTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Page Title"
                                        name="pageTitle"
                                        id="pageTitle"
                                        title="page Title"
                                        value={formik.values.pageTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandValue}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status *</label>
                                    <Select
                                        styles={colourStyles}
                                        className={
                                            "form-control color-drop custom-select-box" +
                                            (formik.errors.status && formik.touched.status
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={statusArr.find(val => val.value === formik.values.status)}
                                        onChange={(option) => {
                                            formik.setFieldValue("status", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.status || "Select Status"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="PageInformation">
                                    <label>Linked Campus</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.linkedCampus && formik.touched.linkedCampus
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="linkedCampus"
                                        value={linkedCampusData.find(val => val.value === formik.values.linkedCampus)}
                                        onChange={(option) => {
                                            formik.setFieldValue("linkedCampus", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={linkedCampusData}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.linkedCampus || "Select Linked Campus"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Contact Number *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.contactNumber && formik.touched.contactNumber
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Contact Number"
                                        name="contactNumber"
                                        id="contactNumber"
                                        title="Contact Number"
                                        value={formik.values.contactNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label>Email*</label>
                                    <input
                                        type="email"
                                        className={
                                            "form-control" +
                                            (formik.errors.email && formik.touched.email
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Email"
                                        name="email"
                                        id="email"
                                        title="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">
                                    <label>Campus Colour *</label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.campusColor && formik.touched.campusColor && "is-invalid"}`}
                                        name="campusColor"
                                        value={campusColour.filter(val => formik.values.campusColor.includes(val.value.toString()))}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("campusColor", value.value);
                                            } else {
                                                formik.setFieldValue("campusColor", "");
                                            }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={campusColour}
                                        maxMenuHeight={175}
                                        placeholder={"Accent 1"}
                                    />
                                    {formik.errors.campusColor &&
                                        formik.touched.campusColor ? (
                                        <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">
                                    <label>
                                        Campus Tour Elements * <i className="fal fa-info-circle"></i>
                                    </label>

                                    <div className="d-flex align-items-center mt-2">
                                        <div className="custom-check custom-control custom-checkbox mr-5" title="showButtonForm">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="showButtonForm"
                                                name="showButtonForm"
                                                checked={checkbox.showButtonForm}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="custom-control-label" htmlFor="showButtonForm">
                                                Show Buttons
                                            </label>
                                        </div>

                                        <div className="custom-check custom-control custom-checkbox" title="showBannerBlock">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="showBannerBlock"
                                                name="showBannerBlock"
                                                checked={checkbox.showBannerBlock}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="custom-control-label" htmlFor="showBannerBlock">
                                                Show Banner Block
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3">
                                    <label>
                                        Campus Tour Video * <i className="fal fa-info-circle"></i>
                                    </label>
                                    <div className="d-flex align-items-center">
                                        <div className="form-group atttach-file">
                                            <label
                                                className={
                                                    formik.errors.tourVideo && formik.touched.tourVideo && "file-req is-invalid"
                                                }
                                            >
                                                <i className="fal fa-upload"></i>
                                                <span>Upload</span>
                                                <input
                                                    type="file"
                                                    className="form-control form-control-aatch-file"
                                                    onChange={(e) => handleFileChange(e)}
                                                    name="tourVideo"
                                                    id="tourVideo"
                                                    accept=".png,.jpg,.jpeg"
                                                />
                                            </label>
                                        </div>
                                        {iconName && (
                                            <div className="frm-group d-flex align-items-center" style={{ marginLeft: 20 }}>
                                                <ul className="list-unstyled attached-file-ul m-0">
                                                    <li>
                                                        <a href={formik.values.tourVideo} target="_blank">
                                                            <i className="fal fa-file"></i>
                                                            &nbsp;{iconName}
                                                        </a>
                                                    </li>
                                                </ul>

                                                <button
                                                    className="btn btn-danger rounded-circle"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        formik.setFieldValue("tourVideo", "");
                                                        setIconName("");
                                                    }}
                                                >
                                                    <i className="fal fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Google Maps Embed Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.googleMapsEmbed && formik.touched.googleMapsEmbed
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Google Maps Embed Code"
                                        name="googleMapsEmbed"
                                        id="googleMapsEmbed"
                                        title="Google Maps Embed"
                                        value={formik.values.googleMapsEmbed}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Content*</label>
                                    <HtmlInputEditor
                                        editorState={description}
                                        setEditorState={(editorState) => {
                                            setDescription(editorState);
                                            formik.setFieldValue('campusContent', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Address*</label>
                                    <HtmlInputEditor
                                        editorState={address}
                                        setEditorState={(editorState) => {
                                            setAddress(editorState);
                                            formik.setFieldValue('campusAddress', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Campus Tour Text*</label>
                                    <HtmlInputEditor
                                        editorState={tourText}
                                        setEditorState={(editorState) => {
                                            setTourText(editorState);
                                            formik.setFieldValue('tourText', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        Campus Gallery
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Resouses />
                            </div>
                            <div className="new-card-header col-md-12">
                                <div className="card-header pt-0">Page Settings</div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Popup <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        styles={colourStyles}
                                        className={
                                            "form-control color-drop custom-select-box" +
                                            (formik.errors.popUp && formik.touched.popUp
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="popUp"
                                        value={popup.find(val => val.value === formik.values.popUp)}
                                        onChange={(option) => {
                                            formik.setFieldValue("popUp", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={popup}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.popUp || "Default"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Form Email</label>
                                    <input
                                        type="email"
                                        className={
                                            "form-control" +
                                            (formik.errors.formEmail && formik.touched.formEmail
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Form Email"
                                        name="formEmail"
                                        id="email"
                                        title="email"
                                        value={formik.values.formEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-4">
                                    <label>Campus Colour *</label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.campusColor && formik.touched.campusColor && "is-invalid"}`}
                                        name="campusColor"
                                        value={campusColour.filter(val => formik.values.campusColor.includes(val.value.toString()))}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("campusColor", value.value);
                                            } else {
                                                formik.setFieldValue("campusColor", "");
                                            }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={campusColour}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.campusColor
                                                ? formik.values.campusColor
                                                : "Accent 1"
                                        }
                                    />
                                    {formik.errors.campusColor &&
                                        formik.touched.campusColor ? (
                                        <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center">
                                <label className="mb-0">
                                    Use Custom Colour <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={checkbox.useCustomColor}
                                            onChange={(e) => setCheckbox({ ...checkbox, useCustomColor: e.target.checked })}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} color="#EF8700" /> SEO Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label htmlFor="code">Conversion Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.conversionCode && formik.touched.conversionCode
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Conversion Code"
                                        name="conversionCode"
                                        id="conversion"
                                        title="conversion"
                                        maxLength={20}
                                        value={formik.values.conversionCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Title</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaTitle && formik.touched.metaTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Title"
                                        title="metaTitle"
                                        name="metaTitle"
                                        value={formik.values.metaTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Description</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaDescription && formik.touched.metaDescription
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Description"
                                        title="metaDescription"
                                        name="metaDescription"
                                        value={formik.values.metaDescription}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3">
                                    <label>Meta Keywords</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Keywords"
                                        title="metaKeywords"
                                        name="metaKeywords"
                                        value={formik.values.metaKeywords}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} color="#EF8700" /> Style Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={checkbox.addCustomCss}
                                            onChange={(e) => setCheckbox({ ...checkbox, addCustomCss: e.target.checked })}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            {checkbox.addCustomCss && <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Customs CSS*</label>
                                    <HtmlInputEditor
                                        editorState={CustomCss}
                                        setEditorState={(editorState) => {
                                            setCustomCss(editorState);
                                            formik.setFieldValue('CustomCss', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>}
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[type == "create" ? 'wbscampuslpageadd' : 'wbscampuslpageedit']}>
                            <button
                                className="btn btn-save btn-success"
                                type="submit"
                                title="Save"
                                >
                                <i className="fal fa-save"></i>
                                Save
                            </button>
                            </PermissionsGate>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CampusPagesOpt;

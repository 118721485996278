import React from 'react'
import { API_BASE, IMAGE_URL } from '../../../../utils/Constants';
import { CKEditor } from 'ckeditor4-react';
import HtmlParser from 'react-html-parser';
import HtmlInputEditor from '../../../common/HtmlInputEditor';

function RenderOption({ selectedQ = "",ckstate, setCkState, orderState, orderList, editorState3, setEditorState, selectedOption, equationProps, matchColumnsProps, shortAnswerProps, multipleChoiceProps, fillInBlankProps, choiceMatrixProps }) {


    const handleKeyDown2 = (event, i) => {
        let temArr;
        if (fillInBlankProps.fillOption == null) {
            temArr = Array(parseInt(selectedOption)).fill([]);
        } else {
            temArr = [...(fillInBlankProps.fillOption)];
        }
        let targetArr = [...temArr[i]]
        if (event.key === "Enter") {
            if (event.target.value) {
                targetArr.push(event.target.value);
                event.target.value = ""
            }
        }
        temArr[i] = [...targetArr]
        fillInBlankProps.setFillOption([...temArr]);
    };

    const OrderList = () => {
        let arr = orderList ? orderList : Array(parseInt(selectedOption)).fill();
        return arr.map((ele, i) => {
            return (
                <div key={i} className="form-icon-group d-block">
                    <label htmlFor={`customCheck${i}`} className="ml-1">
                        Option {i + 1} *
                    </label>
                    <div className="mb-4">
                        <CKEditor
                            initData={HtmlParser(ele)}
                            onChange={({ editor }) => {
                                // let tempState = orderState
                                let tempState = arr
                                // let tempState = orderList
                                tempState[i] = editor.getData()
                                setCkState(tempState)
                                setEditorState(tempState);
                            }}
                            onInstanceReady={({ editor }) => {
                                editor.setData(HtmlParser(ele));
                            }}
                            config={{
                                preset: 'full',
                                // use these plugins if ckeditor preset is set to standard and not full
                                extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles,mathjax',
                                // extraPlugins:'dialog',
                                // toolbar: 'full',
                                //below code is to run videos in ckeditor
                                embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                                uploadUrl: '/uploadEditorFiles',
                                filebrowserUploadUrl: `${API_BASE}/uploadEditorFiles`,
                                mathJaxLib: '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS_HTML',
                                versionCheck: false, 
                                filebrowserBrowseUrl: `/fileSystem` // to open file system on browse button click 
                            }}
                            onFileUploadRequest={async function (evt) {
                                var fileLoader = evt.data.fileLoader,
                                    formData = new FormData(),
                                    xhr = fileLoader.xhr;
                                // let userToken = await localStorage.getItem("token") // user authorization
                                // xhr.setRequestHeader( 'Authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjQ1NCwiaWF0IjoxNjc5NjMzNjIxLCJleHAiOjE2Nzk2Njk2MjF9.y5lLd6r7GLv6-VHwK6CGtYv2CQltOtGnJWNMYmcs7VA` );
                                xhr.open('POST', fileLoader.uploadUrl, true);
                                formData.append('document', fileLoader.file, fileLoader.fileName);
                                fileLoader.xhr.send(formData);

                                // Prevented the default behavior.
                                evt.stop();
                            }}
                            onFileUploadResponse={function (evt) {
                                // Prevent the default response handler.
                                evt.stop();

                                // Get XHR and response.
                                var data = evt.data,
                                    xhr = data.fileLoader.xhr,
                                    response = xhr.responseText.split('|');
                                response[0] = IMAGE_URL + response[0].replace("home/myaie/public_html/", "").replace("public", "");

                                if (response[1]) {
                                    // An error occurred during upload.
                                    data.message = response[1];
                                    evt.cancel();
                                } else {
                                    data.url = response[0];
                                }
                            }}
                        />
                    </div>
                </div>

            )
        })
    }

    const MultipleChoice = () => {
        let arr = multipleChoiceProps.optionArr ? multipleChoiceProps.optionArr : Array(parseInt(selectedOption)).fill();
// console.log(multipleChoiceProps)
// console.log("attttt",arr)
        const handleCheck = (event) => {
            var updatedList = [...(multipleChoiceProps.checkedAns)];
            if (event.target.checked) {
                updatedList = [...(multipleChoiceProps.checkedAns), event.target.id];
            } else {
                updatedList.splice(multipleChoiceProps.checkedAns.indexOf(event.target.id), 1);
            }
            multipleChoiceProps.setCheckedAns(updatedList);
        };
        return arr.map((element, i) => {
            return (
                <div key={i} className="form-icon-group d-block">
                    {selectedQ === "Multiple Choice Single Answer" ? (
                        <>
                            <input type="radio" value={i} name="ID" id={`customCheck${i}`} checked={multipleChoiceProps.selectedAns == `customCheck${i}`} onChange={() => {
                                multipleChoiceProps.setselectedAns(`customCheck${i}`);
                            }} />
                            <label htmlFor={`customCheck${i}`} className="ml-1">
                                Option {i + 1} *
                            </label>
                        </>
                    ) : (
                        <>
                            <div className="custom-control custom-checkbox text-left mt-4">
                                <input type="checkbox" className="custom-control-input" id={`customCheck${i}`} value={1 / (+selectedOption)} checked={multipleChoiceProps.checkedAns.includes(`customCheck${i}`)} onChange={handleCheck} />
                                <label className="custom-control-label mb-1" htmlFor={`customCheck${i}`}>
                                    Option {i + 1} *
                                </label>
                            </div>
                        </>
                    )}

                    <div className="mb-4">
                        <CKEditor
                            initData={HtmlParser(element)}
                            onChange={({ editor }) => {
                                let tempState = multipleChoiceProps.optionEditor
                                tempState['customCheck' + i] = editor.getData()
                                setEditorState(tempState);
                            }}
                            onInstanceReady={({ editor }) => {
                                editor.setData(HtmlParser(element));
                            }}
                            config={{
                                preset: 'full',
                                // use these plugins if ckeditor preset is set to standard and not full
                                extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles,mathjax',
                                // extraPlugins:'dialog',
                                // toolbar: 'full',
                                //below code is to run videos in ckeditor
                                embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                                uploadUrl: '/uploadEditorFiles',
                                filebrowserUploadUrl: `${API_BASE}/uploadEditorFiles`,
                                mathJaxLib: '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS_HTML',
                                versionCheck: false,
                                filebrowserBrowseUrl: `/fileSystem` // to open file system on browse button click 
                            }}
                            onFileUploadRequest={async function (evt) {
                                var fileLoader = evt.data.fileLoader,
                                    formData = new FormData(),
                                    xhr = fileLoader.xhr;
                                // let userToken = await localStorage.getItem("token") // user authorization
                                // xhr.setRequestHeader( 'Authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjQ1NCwiaWF0IjoxNjc5NjMzNjIxLCJleHAiOjE2Nzk2Njk2MjF9.y5lLd6r7GLv6-VHwK6CGtYv2CQltOtGnJWNMYmcs7VA` );
                                xhr.open('POST', fileLoader.uploadUrl, true);
                                formData.append('document', fileLoader.file, fileLoader.fileName);
                                fileLoader.xhr.send(formData);

                                // Prevented the default behavior.
                                evt.stop();
                            }}
                            onFileUploadResponse={function (evt) {
                                // Prevent the default response handler.
                                evt.stop();

                                // Get XHR and response.
                                var data = evt.data,
                                    xhr = data.fileLoader.xhr,
                                    response = xhr.responseText.split('|');
                                response[0] = IMAGE_URL + response[0].replace("home/myaie/public_html/", "").replace("public", "");

                                if (response[1]) {
                                    // An error occurred during upload.
                                    data.message = response[1];
                                    evt.cancel();
                                } else {
                                    data.url = response[0];
                                }
                            }}
                        />
                    </div>
                </div>
            );
        });
    };

    const FillinTheBlank = () => {
        let arr = fillInBlankProps.fillOption.length ? fillInBlankProps.fillOption : Array(parseInt(selectedOption)).fill();
        return (
            <div className="row">
                {arr && arr.length > 0 && arr.map((val, ind) => (
                    <div className="col-md-6 col-lg-3 mb-4" title="Possible Answers" key={ind}>
                        <label className="">Option {ind + 1} *</label>
                        <div className="form-icon-group">
                            <div className="form-control custom-select-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="option"
                                    placeholder="Possible Answers"
                                    title="Possible Answers"
                                    onKeyDown={(e) => { handleKeyDown2(e, ind) }}
                                />

                            </div>
                        </div>
                        {fillInBlankProps.fillOption.length && fillInBlankProps.fillOption[ind].length > 0 && (
                            <ul className="tag-list mb-0">
                                {Array.isArray(fillInBlankProps.fillOption) && fillInBlankProps.fillOption[ind]?.map((ele, index) => (
                                    <li>
                                        {ele}
                                        <span onClick={() => fillInBlankProps.removeKeyword(ind, ele, index)}>
                                            <i className="fal fa-times"></i>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        )
    }

    const MatchColumns = () => {
        const handleChangeNonLinked = (e, index, values) => {
            let tempArr = matchColumnsProps.matchItemArr;
            tempArr.map((val, i) => {
                if(i == index) {
                    val[values] = e.target.value
                    return val
                }else {
                    return val
                }
            })
            matchColumnsProps.setMatchItemArr(tempArr)
        };
        return matchColumnsProps.matchItemArr.map((element, i) => {
            return (
                element.q_option != undefined && element.score != "0" ? <div key={i} className="d-block">
                    <div className="row">
                        <div className="col-4">
                            <label>Item *</label>
                            <div className="form-icon-group mb-4"
                                title="Label">
                                <input type="text"
                                    className={"form-control" + (matchColumnsProps.idEditorState3Invalid ? " is-invalid" : "")}
                                    name="item"
                                    placeholder="Label"
                                    title="Label"
                                    defaultValue={element.q_option}
                                    onChange={(e) => {
                                        handleChangeNonLinked(e, i, "q_option")
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <label>Match*</label>
                            <div className="form-icon-group mb-4"
                                title="Label">
                                <input type="text"
                                    className={"form-control" + (matchColumnsProps.idEditorState3Invalid ? " is-invalid" : "")}
                                    name="match"
                                    placeholder="Label"
                                    title="Label"
                                    defaultValue={element.q_option_match}
                                    onChange={(e) => {
                                        handleChangeNonLinked(e, i, "q_option_match")
                                    }}
                                />
                            </div>
                        </div>
                        {i == (matchColumnsProps.matchItemArr.length - 1) ? <div className="col-4">
                            <label></label>
                            <div className="mt-2">
                                <button type="button" class="btn btn-primary" title="Add Empty Match" onClick={() => {
                                    let tempArr = matchColumnsProps.matchItemArr;
                                    tempArr.push({
                                        q_option_match: "",
                                        id: tempArr.length,
                                    })
                                    matchColumnsProps.setMatchItemArr(tempArr)
                                }}><i class="fal fa-save"></i>Add Empty Match</button>
                            </div>
                        </div> : ""}
                    </div>
                </div> : <div key={i} className="d-block">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-icon-group mb-4"
                                title="Label">
                            </div>
                        </div>
                        <div className="col-4">
                            <label>Match *</label>
                            <div className="form-icon-group mb-4"
                                title="Label">
                                <input type="text"
                                    className={"form-control" + (matchColumnsProps.idEditorState3Invalid ? " is-invalid" : "")}
                                    name="match"
                                    placeholder="Label"
                                    title="Label"
                                    value={element.q_option_match}
                                    onChange={(e) => {
                                        handleChangeNonLinked(e, i, "q_option_match")
                                    }}
                                />
                            </div>
                        </div>
                        {i == (matchColumnsProps.matchItemArr.length - 1) ? <div className="col-4">
                            <label></label>
                            <div className="mt-2">
                                <button type="button" class="btn btn-primary" title="Add Empty Match"
                                    onClick={() => {
                                        let tempArr = matchColumnsProps.matchItemArr;
                                        tempArr.push({
                                            q_option_match: "",
                                            id: tempArr.length
                                        })
                                        matchColumnsProps.setMatchItemArr(tempArr)
                                    }}><i class="fal fa-save"></i>Add Empty Match</button>
                            </div>
                        </div> : ""}
                    </div>
                </div>
            );
        });
    };

    const ChoiceMatrix = () => {
        return (
            <div className="matrix-table">
                <label className="ml-1">Option *</label>
                <table className="export-table-border-header">
                    <thead>
                        <tr>
                            <th></th>
                            {choiceMatrixProps.matrixArray?.length && choiceMatrixProps.matrixArray[0].options.map((option, index) => (
                                <th key={index}>{option.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {choiceMatrixProps.matrixArray?.length && choiceMatrixProps.matrixArray.map((rowData, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{rowData?.rowTitle}</td>
                                {rowData?.options.map((option, optionIndex) => (
                                    <td key={optionIndex}>
                                        <div className="form-icon-group" title="SendWhen">
                                            <div
                                            >
                                                <input
                                                    type="radio"
                                                    checked={option.isSelected}
                                                    onChange={() => {
                                                        choiceMatrixProps.onChangeFunction(rowIndex, optionIndex)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    const ShortAnswer = () => {
        return (
            <div className="col-md-6 col-lg-4 mb-4">
                <label>Possible Answers *</label>
                <div className="form-icon-group" title="Label">
                    <input type="text"
                        className={"form-control" + (shortAnswerProps.isShortAnswerInvalid ? " is-invalid" : "")}
                        name="shortAnswer"
                        placeholder="Possible Answers"
                        title="Possible Answers"
                        onKeyDown={(e)=>shortAnswerProps.handleAddShortAnswer(e)}
                        onBlur={shortAnswerProps.handlePossibleAnswerBlur} />
                </div>
                {shortAnswerProps && shortAnswerProps?.shortAnswer && shortAnswerProps.shortAnswer.length > 0 ? (
                    <ul className="tag-list mb-0">
                        {shortAnswerProps.shortAnswer?.map((ele, index) => (
                            <li>
                                {ele}
                                <span onClick={() => shortAnswerProps.removeAnswerFunction(index)}>
                                    <i className="fal fa-times"></i>
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : ""}
            </div>
        )
    }

    const Equation = () => {
        return (
            <div className="col-md-12 mb-4">
                <label>Answer</label>
                <HtmlInputEditor editorState={equationProps.equationEditor} setEditorState={equationProps.setEquationEditor} hideSign={true} isCKEditor={true} from="quizManagement" />
            </div>
        )
    }
    return (
        <div>
            {selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer" ?
                MultipleChoice() :
                selectedQ == "Order List" ?
                    OrderList() :
                    selectedQ === "Match The Column" || selectedQ === "Match the Column" ?
                        MatchColumns() :
                        selectedQ == "Fill in the blank" && Array.isArray(fillInBlankProps.fillOption) && fillInBlankProps.fillOption.length ?
                            FillinTheBlank() :
                            selectedQ == "Choice Matrix" ?
                                ChoiceMatrix()
                                : null
            }
            <div className="course-info-sec">
                <div className="row">
                    {selectedQ === "Short Answer" ?
                        ShortAnswer() :
                        selectedQ === "Equation" ?
                            Equation() :
                            null
                    }
                </div></div>

        </div>
    )
}

export default RenderOption
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { AssignPageField, FileUploadField, FormField } from "../../common/FormFields";
import {
  addHeaderDetails,
  getAccentColorBaseOnBrandTmpList,
  getHeaderDetails,
  getHeaderDropDownList,
  getHeadersPageList,
  updateHeaderDetails,
} from "../../../services/LayoutHeaderServices";
import AssignPagePopup from "./AssignPagePopup";
import PermissionsGate from "../../../utils/permissionGate";

const HeaderInformation = ({ setHeaderName }) => {
  const { subId, subTab } = useParams();
  const history = useHistory();
  const [statusArr, setStatusArr] = useState([]);
  const [headerTypeArr, setHeaderTypeArr] = useState([]);
  const [headerButtonTypeArr, setHeaderButtonTypeArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [assignedPage, setAssignedPage] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getHeaderDropDownList();
        setStatusArr(res.data.headerStatus || []);
        setBrandArr(res.data.brandList || []);
        setHeaderTypeArr(res.data.headerType || []);
        setHeaderButtonTypeArr(res.data.headerButtonType || []);
      } catch (error) {
        // if (!axios.isCancel(error)) {
        console.error(error);
        // }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
      const assignedpages = () => {
        getHeadersPageList(subTab == "update" && subId)
        .then(res => setAssignedPage(res?.data?.data))
        .catch(err => console.log(err))
      }

      assignedpages();
  } , [])

  const getAccentColor = async (value) => {
    try {
      let res = await getAccentColorBaseOnBrandTmpList({
        brandID: value ? value : [],
      });
      setColorArr(res?.data?.result || []);
    } catch (error) {
      // if (!axios?.isCancel(error)) {
      console.error(error);
      // }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getHeaderDetails(subId);
        res.data.data.brandTemplete = res.data.data?.brandTemplete?.map(
          (el) => el.BrandID
        );
        getAccentColor([...res.data.data.brandTemplete]);
        setBrandData(res.data?.data);
        setHeaderName(res.data?.data?.SliderName);
        localStorage.setItem("headerName", res.data?.data?.SliderName);
      } catch (error) {
        // if (!axios?.isCancel(error)) {
        console.error(error);
        // }
      }
    };
    if (subId) {
      fetchData();
    }
  }, [subId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      SliderName: brandData?.SliderName || "",
      SliderStatus: brandData?.SliderStatus,
      IsDefault: brandData?.IsDefault,
      buttonType: brandData?.buttonType,
      Brand: brandData?.brandTemplete || [],
      buttonText: brandData?.buttonText || "",
      assignedPage: brandData?.assignedPage || "",
      titleHeader: brandData?.titleHeader || "",
      subtitleHeader: brandData?.subtitleHeader || "",
      ItemImgURL: brandData?.ItemImgURL || "",
      ItemVideo: brandData?.ItemVideo || "",
      SliderColor: brandData?.SliderColor || "",
      headerCID: brandData?.headerCID || "",
      SliderItemID: brandData?.SliderItemID || "",
      LinkURL: brandData?.LinkURL || "",
      contactFormActive: brandData?.contactFormActive || 0,
      isTitleUnderline: brandData?.isTitleUnderline || 0,
      isShowContent: brandData?.isShowContent || 0,
      isShowButton: brandData?.isShowButton || 0,
      isOverrideColor: brandData?.isOverrideColor || 0,
    },
    validationSchema: Yup.object().shape({
      SliderName: Yup.string().required("Name is required").nullable().trim(),
      SliderStatus: Yup.string().required("Status is required"),
      IsDefault: Yup.string().required("Header Type is required"),
      buttonType: Yup.string().required("Header Button Type is required"),
      Brand: Yup.string().required("Assigned Brand Templates are required"),
      buttonText: Yup.string().required("Header Button Text is required"),
      // assignedPage: Yup.string().required("Assigned Page is required"),
      titleHeader: Yup.string().required("Header Title Text is required"),
      subtitleHeader: Yup.string().required("Header Content Text is required"),
      // LinkURL: Yup.string().when('buttonType', {
      //   is: "2",
      //   then: Yup.string().required("Custom Link URL is required"),
      //   otherwise: Yup.string().nullable()
      // }
      // ),
      ItemImgURL: Yup.string().required("Banner Image is required"),
      ItemVideo: Yup.string().required("Banner Video is required"),
      SliderColor: Yup.string().required("Banner Accent Colour is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
      Object.keys(trimmedValues).forEach((key) => {
        if (Array.isArray(trimmedValues[key])) {
          trimmedValues[key].forEach((val, idx) => {
            formData.append(`${key}[${idx}]`, val);
          });
        } else {
          formData.append(key, trimmedValues[key]);
        }
      });
      try {
        if (subTab === "create") {
          await addHeaderDetails(formData);
          Swal.fire(
            "Success!",
            "Header details created successfully!",
            "success"
          );
        } else if (subTab === "update") {
          formData.append("SliderID", subId);
          await updateHeaderDetails(formData);
          Swal.fire(
            "Success!",
            "Header details updated successfully!",
            "success"
          );
        }
        history.push("/websiteManagement/layoutNavigation/header");
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab === "create" ? "create" : "update"
          } Header details`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  const checkboxOptions = [
    { name: "contactFormActive", label: "Show Form Block" },
    { name: "isTitleUnderline", label: "Show Title Underline" },
    { name: "isShowContent", label: "Show Content Text" },
    { name: "isShowButton", label: "Show Button" },
  ];

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Header Information</h4>
            </div>
          </div>

          <FormField
            field={{ name: "SliderName", label: "Name" }}
            formik={formik}
          />
          <FormField
            field={{ name: "SliderStatus", label: "Status", info: true }}
            formik={formik}
            selectOptions={statusArr}
          />
          <FormField
            field={{ name: "IsDefault", label: "Header Type", info: true }}
            formik={formik}
            selectOptions={headerTypeArr}
          />
          <FormField
            field={{
              name: "buttonType",
              label: "Header Button Type",
              info: true,
            }}
            formik={formik}
            selectOptions={headerButtonTypeArr}
          />
          <div className="col-md-6 col-lg-6">
            <label htmlFor="Brand">
              Assigned Brand Templates * <i className="fal fa-info-circle"></i>{" "}
            </label>
            <div className="form-icon-group mb-4">
              <Select
                className={`form-control custom-select-box ${formik.touched.Brand && formik.errors.Brand && "is-invalid"
                  }`}
                name="Brand"
                value={brandArr?.filter((val) =>
                  formik.values.Brand?.includes(val.value)
                )}
                onChange={(value) => {
                  formik.setFieldValue(
                    "Brand",
                    value ? value.map((v) => v.value) : []
                  );
                  getAccentColor(value ? value.map((v) => v.value) : []);
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={brandArr}
                maxMenuHeight={175}
                placeholder="Select"
                isMulti
              />
            </div>
          </div>
          <FormField
            field={{ name: "buttonText", label: "Header Button Text" }}
            formik={formik}
            col_md={6}
            col_lg={3}
          />
          {
            formik.values.buttonType == 2 ? <FormField
              field={{ name: "LinkURL", label: "Custom Link URL", required:false }}
              formik={formik}
              col_md={6}
              col_lg={3}
            />
              :
              formik.values.buttonType == 0 ? <AssignPageField name="termsAndConditionsPages" label="Assigned Page for Button*" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPage}
                setAssignedPage={setAssignedPage} />
                :
                formik.values.buttonType == 1 ? <AssignPageField name="termsAndConditionsPages" label="Assigned Qualification for Button*" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPage}
                  setAssignedPage={setAssignedPage} />
                  :
                  null
          }

          {/* <FileUploadField
            name="termsAndConditions"
            label="Terms & Conditions Page"
            formik={formik}
            accept=".png,.jpg,.jpeg"
            assignPage={true}
          /> */}
          <FormField
            field={{ name: "titleHeader", label: "Header Title Text" }}
            formik={formik}
            col_md={12}
            col_lg={12}
          />
          <FormField
            field={{ name: "subtitleHeader", label: "Header Content Text" }}
            formik={formik}
            col_md={12}
            col_lg={12}
          />
          <FileUploadField
            name="ItemImgURL"
            label="Banner Image *"
            formik={formik}
            accept=".png,.jpg,.jpeg"
            col_md={6}
            col_lg={2}
          />
          <FileUploadField
            name="ItemVideo"
            label="Banner Video"
            formik={formik}
            accept="video/*"
            col_md={8}
            col_lg={2}
          />
          <div className={`col-md-6 col-lg-8`}>
            <label>Header Block Content</label>

            {/* <div className="mt-2"> */}
            <div className="form-icon-group mb-4 mt-2">
              {checkboxOptions.map((checkbox) => (
                <div
                  className="custom-control custom-checkbox text-left mb-3 mr-3"
                  key={checkbox.name}
                >
                  <input
                    type="checkbox"
                    id={checkbox.name}
                    name={checkbox.name}
                    className={`custom-control-input ${formik.touched[checkbox.name] &&
                      formik.errors[checkbox.name] &&
                      "is-invalid"
                      }`}
                    checked={formik.values[checkbox.name] === 1}
                    onChange={(e) => {
                      formik.setFieldValue(
                        checkbox.name,
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                  <label
                    htmlFor={checkbox.name}
                    className="custom-control-label"
                  >
                    {checkbox.label}
                  </label>
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-12">
            <hr
              className="border border-2"
              style={{ borderColor: "#E6EBF1" }}
            />
          </div>

          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Style Settings</h4>
            </div>
          </div>

          {
            formik.values?.isOverrideColor ?
              <>
                {[
                  { label: "Banner Accent Colour *", name: "SliderColor", color: true, required: false, type: "color", },
                ].map((field, index) => (
                  <FormField key={index} field={field} formik={formik} />
                ))}
              </>
              :
              <FormField
                field={{ name: "SliderColor", label: "Banner Accent Colour" }}
                formik={formik}
                selectOptions={colorArr}
              />
          }

          <div className="col-md-6 col-lg-6 d-flex align-items-center">
            <label className="mb-0">
              Override Colour Variable <i className="fal fa-info-circle"></i>
            </label>
            <div className="toggle-switch ml-2">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formik.values?.isOverrideColor === 1}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "isOverrideColor",
                      e.target.checked ? 1 : 0
                    )
                    formik.setFieldValue(
                      "CategoryColor",
                      e.target.checked ? "#000000" : ""
                    )
                  }
                  }
                />
                <span className="slider slider-round"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 form-group form-group-save-cancel">
            <PermissionsGate scopes={[subTab == "create" ? 'wbslnheadersadd' : 'wbslnheadersedit']} RenderError={() => (
              <button className="btn btn-save btn-success" disabled>
                <i className="fal fa-save"></i> Save
              </button>
            )}>

            <button
              className="btn btn-save btn-success"
              disabled={formik.isSubmitting}
              type="submit"
              title="Save"
              >
              <i className="fal fa-save"></i> Save
            </button>
            </PermissionsGate>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              disabled={formik.isSubmitting}
              onClick={() =>
                history.push("/websiteManagement/layoutNavigation/header")
              }
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>
        </div>
        {Object.keys(formik.errors).map((key) => {
          if (formik.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formik.errors[key]}
              </div>
            );
          }
          return null;
        })}
      </form>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopupBrandTemp"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-file"></i> {formik.values.buttonType == 0 ? "Assigned Page for Button"  : "Assigned Qualification for Button"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <AssignPagePopup formik={formik} assignedPage={assignedPage} setAssignedPage={setAssignedPage} buttonType={formik.values.buttonType}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderInformation;

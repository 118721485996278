import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { CreateMicrosoftAc, SendMicrosoftNotification } from "../../../../../services/StudentsSettingServices";
import { EMAIL_REGEX } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";

function Office365Details({ studentData, microsoftLog, loading, brand }) {
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [officeLoading, setOfficeLoading] = useState(false)
  const handleCreateAccount = () => {
    let formData = new FormData();
    formData.append("student_id", id);
    formData.append("type", "microsoft");
    CreateMicrosoftAc(formData).then((res) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated successfully",
      })
      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });;
    });
  };

  const handleToggleStatus = async () => {
    setOfficeLoading(true)
    let formData = new FormData();
    formData.append("student_id", id);
    formData.append("status", formik.values.Status == 1 ? 0 : 1);
    formData.append("type", "status");
    formData.append("microsoftID", studentData.microsoftID);
    // return;
    CreateMicrosoftAc(formData).then((res) => {
      formik.setFieldValue("Status", formik.values.Status == 1 ? 0 : 1);
      setOfficeLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated successfully",
      })
    }).catch((err) => {
      setOfficeLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err.response.data.message,
      });
    });;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: studentData ? studentData.userPrincipalName : "",
      Status: studentData ? studentData.student_status : 0,
      sentGroups:{
        EmailNotification: false,
        PushNotification: false,
      },
    },
    validationSchema: Yup.object({
      // Email: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Please Enter Valid Email"),
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          })
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"]
        ]
      ),
    }),
    onSubmit: (values) => {
      // setDisabled(true);
      const data = {
        student_id: id,
        isPushnotification: values?.sentGroups?.PushNotification ? 1 : 0,
        isEmail: values?.sentGroups?.EmailNotification ? 1 : 0,
      };
      SendMicrosoftNotification(data).then(res =>  {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Notification sent to student successfully",
        });
      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      });
    },
  });

  const { handleSubmit } = formik;

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Office365 Details</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Email">Email Address </label>
            <div className="form-icon-group mb-4">
              <input type="text" id="Email" className={"form-control"} name="Email" placeholder="Enter Email" value={studentData && studentData.userPrincipalName} disabled title="Email" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>&nbsp;</label>
              <div className="form-group form-group-save-cancel">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
                  {studentData && studentData.created_date ? (
                    <button
                      className="btn btn-save btn-success rtimg-icon-btn"
                      type="button"
                      title="Microsoft Account Already Created"
                      onClick={handleCreateAccount}
                      disabled={true}
                    >
                      <i className="fal fa-plus"></i>
                      Create Microsoft Account
                    </button>
                  ) : brand == 24 || brand == 35 ? (
                    <button
                      className="btn btn-save btn-success rtimg-icon-btn"
                      type="button"
                      title="Not available for LF or Cadco"
                      onClick={handleCreateAccount}
                      disabled={true}
                    >
                      <i className="fal fa-plus"></i>
                      Create Microsoft Account
                    </button>
                  ) :
                  (
                    <button
                      className="btn btn-save btn-success rtimg-icon-btn"
                      type="button"
                      title="Create Microsoft Account"
                      onClick={handleCreateAccount}
                      disabled={ loading}
                    >
                      <i className="fal fa-plus"></i>
                      Create Microsoft Account
                    </button>
                  )}
                </PermissionsGate>
              </div>
              <p>{microsoftLog ? microsoftLog : ""}</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Account Status</label>
              <div className="form-group form-group-save-cancel">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
                  <button
                    // title="Yes"
                    className={formik.values.Status ? "btn btn-success rounded-circle btn-status-1" : "btn btn-danger rounded-circle btn-status-1"}
                    type="button"
                    disabled={(studentData && !studentData.microsot_id) || loading || officeLoading}
                    onClick={() => handleToggleStatus()}
                    //   onClick={() => { onChangeStatus(row.meeting_id, row.isRecordingChecked) }}
                  >
                    {formik.values.Status ? (
                      <div title="Yes">
                        {" "}
                        <i className="fal fa-check"></i> Yes{" "}
                      </div>
                    ) : (
                      <div title="No">
                        {" "}
                        <i className="fal fa-times"></i> No
                      </div>
                    )}
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>Send Via *</label>
            <div className="form-group-blk mb-3">
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-2">
                <input
                  type="checkbox"
                  title="Email"
                  className="custom-control-input"
                  id="EmailNotification"
                  name="select"
                  checked={formik.values.sentGroups.EmailNotification}
                  onChange={(e) =>
                    formik.setFieldValue("sentGroups.EmailNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="EmailNotification"
                >
                  Email Notification
                </label>
              </div>
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-2">
                <input
                  type="checkbox"
                  title="Push Notification"
                  className="custom-control-input"
                  id="PushNotification"
                  name="select"
                  checked={formik.values.sentGroups.PushNotification}
                  onChange={(e) =>
                    formik.setFieldValue("sentGroups.PushNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="PushNotification"
                >
                  Push Notification
                </label>
              </div>
            </div>
            {/* validation error msg */}
            {Object.keys(formik.values).map(key => {
              if (formik.touched[key] && formik.errors[key]) {
                if (key == "sentGroups") {
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key].EmailNotification}
                    </div>
                  )

                }
                return (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                )
              }
            })}
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <button className="btn btn-save btn-success rtimg-icon-btn" type="submit" title="Send Manual Notification">Send Manual Notification</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Office365Details;

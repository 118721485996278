import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { AddBrandTemplate, getBrandTemplate, getBrandTemplateBrand, getBrandTemplateCampus, getBrandTemplateStatus, getCurrencyDropDownList, updateBrandTemplate } from "../../../../services/BrandTemplateServices";
import axios from "../../../../utils/axios";
import { IMAGE_URL } from "../../../../utils/Constants";
import { AssignPageField, FileUploadField, FormField } from "../../../common/FormFields";
import AssignPagePopup from "./AssignPagePopup";
import { TrimText } from "../../../common/TrimText";
import PermissionsGate from "../../../../utils/permissionGate";



const BrandInformation = ({ setHeaderName }) => {
  const { subId, subType } = useParams();
  const history = useHistory();
  const [campusArr, setCampusArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [currencyArr, setCurrencyArr] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [assignedPage, setAssignedPage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusRes, campusRes, brandRes, currencyRes] = await Promise.all([
          getBrandTemplateStatus(),
          getBrandTemplateCampus(),
          getBrandTemplateBrand(),
          getCurrencyDropDownList()
        ]);
        const statusFilters = statusRes.data.statusFilters || [];
        const campusFilters = campusRes.data.campusLocationFilters || [];
        const brandFilters = brandRes.data.BrandFilters || [];
        const currencyFilters = currencyRes.data?.currency || [];
        setStatusArr(statusFilters);
        setBrandArr(brandFilters);
        setCampusArr(campusFilters);
        setCurrencyArr(currencyFilters);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getBrandTemplate(subId);
        res.data.data.campuslocation = res.data.data?.campuslocation?.map((el) => el.campusID)
        setBrandData(res.data?.data);
        setHeaderName(res.data?.data?.templateName)
        setAssignedPage({ PageTitle: res.data?.data?.termsAndConditionsPageName, PageID: res.data?.data?.termsAndConditionsPages })
        localStorage.setItem("headerName", res.data?.data?.templateName);
      } catch (error) {
        if (!axios?.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (subId) {
      fetchData();
    }
  }, [subId]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      templateName: brandData?.templateName || "",
      brandLinked: brandData?.brandLinked || "",
      status: +brandData?.status || "",
      campusID: brandData?.campuslocation || [],
      whatsappNo: brandData?.whatsappNo || "",
      termsAndConditionsPages: brandData?.termsAndConditionsPages || "",
      flagTermsAndConditions: brandData?.flagTermsAndConditions || "",
      primaryContactNo: brandData?.primaryContactNo || "",
      currency: brandData?.currency || "",
      primaryEmail: brandData?.primaryEmail || "",
      headerColor: brandData?.headerColor || "#007BFF",
      primaryColor: brandData?.primaryColor || "#007BFF",
      secondaryColor: brandData?.secondaryColor || "#007BFF",
      buttonColor: brandData?.buttonColor || "#007BFF",
      buttonHoverColor: brandData?.buttonHoverColor || "#007BFF",
      buttonActiveColor: brandData?.buttonActiveColor || "#007BFF",
      highlightColor: brandData?.highlightColor || "#007BFF",
      activeColor: brandData?.activeColor || "#007BFF",
      accentColorOne: brandData?.accentColorOne || "#007BFF",
      accentColorTwo: brandData?.accentColorTwo || "#007BFF",
      accentColorThree: brandData?.accentColorThree || "#007BFF",
      accentColorFour: brandData?.accentColorFour || "#007BFF",
      textColorOne: brandData?.textColorOne || "#007BFF",
      textColorTwo: brandData?.textColorTwo || "#007BFF",
      backgroundColorOne: brandData?.backgroundColorOne || "#007BFF",
      backgroundColorTwo: brandData?.backgroundColorTwo || "#007BFF",
      logo: brandData?.logo || "",
      favicon: brandData?.favicon || "",
      headerVideo: brandData?.headerVideo || "",
      defaultEmailTitle: brandData?.defaultEmailTitle || "", 
    },
    validationSchema: Yup.object().shape({
      templateName: Yup.string().required("Template Name is required").nullable().trim(),
      brandLinked: Yup.string().required("Linked Brand is required"),
      // status: Yup.string().required("Status is required"),
      campusID: Yup.array().min(1, "Campus Locations is required").required("Campus Locations is required"),
      whatsappNo: Yup.string().required("WhatsApp Number is required").nullable().trim(),
      primaryContactNo: Yup.string().required("Primary Contact Number is required").nullable().trim(),
      primaryEmail: Yup.string().email("Invalid email address").required("Primary Email Address is required"),
      logo: Yup.string().required("Logo is required"),
      favicon: Yup.string().required("Favicon is required"),
      headerVideo: Yup.string().required("Header Video is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
      Object.keys(trimmedValues).forEach((key) => {
        if (Array.isArray(trimmedValues[key])) {

          trimmedValues[key].forEach((val, idx) => {
            formData.append(`${key}[${idx}]`, val);
          })
        } else {
          formData.append(key, trimmedValues[key]);
        }
      });
      try {
        if (subType === "create") {
          await AddBrandTemplate(formData);
          Swal.fire("Success!", "Brand Template created successfully!", "success");
        } else if (subType === "update") {
          formData.append("brandID", subId)
          await updateBrandTemplate(formData);
          Swal.fire("Success!", "Brand Template updated successfully!", "success");
        }
        history.push("/websiteManagement/siteSetup/brand_Templates");
      } catch (error) {
        Swal.fire("Error!", `Failed to ${subType === "create" ? "create" : "update"} Brand Template`, "error");
        console.log(error)
      }
      setSubmitting(false);
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Brand Information</h4>
            </div>
          </div>

          <FormField field={{ name: "templateName", label: "Template Name" }} formik={formik} />
          <FormField field={{ name: "brandLinked", label: "Linked Brand" }} formik={formik} selectOptions={brandArr} />
          <FormField field={{ name: "status", label: "Status", required: false, info: true }} formik={formik} selectOptions={statusArr} />
          <div className="col-md-6 col-lg-3" title="Campus Locations">
            <label htmlFor="Campus_Locations">Campus Locations * <i className="fal fa-info-circle"></i></label>
            <div className="form-icon-group mb-4">
              <Select
                className={`form-control custom-select-box ${formik.touched.campusID && formik.errors.campusID && "is-invalid"}`}
                name="campusID"
                value={campusArr.filter((val) => formik.values.campusID.includes(val.value))}
                onChange={(value) => formik.setFieldValue("campusID", value ? value.map((v) => v.value) : [])}
                isClearable
                onBlur={formik.handleBlur}
                options={campusArr}
                maxMenuHeight={175}
                placeholder="Select"
                isMulti
              />
            </div>
          </div>
          <FormField field={{ name: "currency", label: "Currency", required: false }} formik={formik} selectOptions={currencyArr} />
          <AssignPageField name="termsAndConditionsPages" label="Terms & Conditions Page" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPage}
            setAssignedPage={setAssignedPage} />
          <div className="col-md-12">
            <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
          </div>

          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Contact Information</h4>
            </div>
          </div>

          <FormField field={{ name: "whatsappNo", label: "WhatsApp Number" }} formik={formik} />
          <FormField field={{ name: "primaryContactNo", label: "Primary Contact Number" }} formik={formik} />
          <FormField field={{ name: "primaryEmail", label: "Primary Email Address", type: "email" }} formik={formik} />
          <FormField field={{ name: "defaultEmailTitle", label: "Default From Email Title", required: false}} formik={formik} />

          <div className="col-md-12">
            <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
          </div>

          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Brand Colours</h4>
            </div>
          </div>

          {[
            { label: "Header Colour", name: "headerColor", color: true, required: false, type: "color", },
            { label: "Primary Colour", name: "primaryColor", color: true, required: false, type: "color" },
            { label: "Secondary Colour", name: "secondaryColor", color: true, required: false, type: "color" },
            { label: "Button Colour", name: "buttonColor", color: true, required: false, type: "color" },
            { label: "Button Hover Colour", name: "buttonHoverColor", color: true, required: false, type: "color" },
            { label: "Button Active Colour", name: "buttonActiveColor", color: true, required: false, type: "color" },
            { label: "Highlight Colour", name: "highlightColor", color: true, required: false, type: "color" },
            { label: "Active Colour", name: "activeColor", color: true, required: false, type: "color" },
            { label: "Accent Colour 1", name: "accentColorOne", color: true, required: false, type: "color" },
            { label: "Accent Colour 2", name: "accentColorTwo", color: true, required: false, type: "color" },
            { label: "Accent Colour 3", name: "accentColorThree", color: true, required: false, type: "color" },
            { label: "Accent Colour 4", name: "accentColorFour", color: true, required: false, type: "color" },
            { label: "Text Colour 1", name: "textColorOne", color: true, required: false, type: "color" },
            { label: "Text Colour 2", name: "textColorTwo", color: true, required: false, type: "color" },
            { label: "Background Colour 1", name: "backgroundColorOne", color: true, required: false, type: "color" },
            { label: "Background Colour 2", name: "backgroundColorTwo", color: true, required: false, type: "color" },
          ].map((field, index) => (
            <FormField key={index} field={field} formik={formik} />
          ))}

          <div className="col-md-12">
            <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
          </div>

          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Brand Assets</h4>
            </div>
          </div>

          <FileUploadField name="logo" label="Logo" formik={formik} accept=".png,.jpg,.jpeg" required={true} col_md={6} col_lg={3} />
          <FileUploadField name="favicon" label="Favicon" formik={formik} accept=".png,.jpg,.jpeg" required={true} col_md={6} col_lg={3} />
          <FileUploadField name="headerVideo" label="Header Video" formik={formik} accept="video/*" required={true} col_md={6} col_lg={3} />

        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate scopes={[subType == "create" ? 'wbssitebrandtmpadd' : 'wbssitebrandtmpedit']} RenderError={() => (
            <button className="btn btn-save btn-primary" disabled>
              <i className="fal fa-save"></i>
            </button>
          )}>

          <button className="btn btn-save btn-primary" disabled={formik.isSubmitting} type="submit" title="Save">
            {formik.isSubmitting ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )} Save
          </button>
            </PermissionsGate>
          {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              );
            }
          })}
        </div>
      </form>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopupBrandTemp"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-file"></i> Assign Terms & Conditions Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <AssignPagePopup formik={formik} assignedPage={assignedPage} setAssignedPage={setAssignedPage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandInformation;


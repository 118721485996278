import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import moment from 'moment';
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";

import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { useParams } from "react-router";

import PermissionsGate from "../../../../utils/permissionGate";
import { ExamsFinanceExportData, ExamsRegCenterExportList, ExamsRegExportData } from "../../../../services/ExternalExamServices";
import $ from "jquery";
import hasPermission from "../../../../utils/hasMultiplePermission";
import axios from "axios";

const races = {
  'asian': 'A', 
	'african': 'B', 
	'coloured': 'C', 
	'indian': 'I', 
	'white': 'W',
  'other':'O'
}

const ExportData = () => {
  const {id} = useParams();
  const history=useHistory();
  const [search, setSearch] = useState("");
  const [searchAvailable, setSearchAvailable] = useState("");
  const [userData, setUserData] = useState([]);
  const [availableCentre, setAvailableCentre] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeedview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async ()=>{
      try {
        const res = await ExamsRegCenterExportList(id, source.token);
        if(res.status==200){
          setAvailableCentre(res.data.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    
    getData();
    return () => {
      source.cancel('Component unmounted');    
    };
  }, [])
  
  // Export Sheet
  const FormatExportData = (exportData) =>{
    const examData = exportData.examData;
    const current_date = new Date()
    let header = '12801T'+examData?.trimester+moment().format('YYYY').slice(-1)+'E.TXT'+'AIE'.padEnd(30, ' ')+'0899992801'+'GAUTENG'.padEnd(40, ' ')+'08'+moment(new Date()).format("YYYYMMDD")+moment(examData?.period).format("YYYYMM")+'0204\n'
    const stData = exportData.data.filter((st) => (st?.student_type != "international-student"));
    let content = ''
    let entriesCount = 0;
    stData.map((item)=>{      
      const uniqueQualCodes = [];
      const got_codes = item?.ExportSubjects.map((sub)=> {
        if(sub?.qualificationCode && sub?.qualificationCode!=="" && (!uniqueQualCodes.includes(sub?.qualificationCode))){
          uniqueQualCodes.push(sub?.qualificationCode);
        }      
      });
      uniqueQualCodes.map((code) =>{
        content+='2'+(code ? code : "")?.toString().padStart(10, '0')+'00';
        content+=item?.last_name.padEnd(30, ' ');
        content+=item?.first_name.padEnd(30, ' ');
        content+=item?.second_name.padEnd(30, ' ');
        content+=item?.third_name.padEnd(30, ' ');
        if(item.ExportSubjects[0].qualificationCode===505083382){
          content+='5E';
        }else{
          content+='4E';
        }
        let rsa_id = '';
        if(item.student_type=='south-african-citizen'){
          rsa_id=item?.rsa_identity;
        }else{
          if(item.exam_id_number==''){
            rsa_id=item?.rsa_identity;
          }else{
            rsa_id=item?.exam_id_number;
          }
        }
        content+=rsa_id.trim().replace(' ', '').padEnd(13, '0');
        content+=item?.bday_year.toString().trim().padStart(4, '0');
        content+=item?.bday_month.toString().trim().padStart(2, '0');
        content+=item?.bday_day.toString().trim().padStart(2, '0');
        content+=item?.student.toString().padEnd(9, '0');
        content+='2801';
        content+=item?.gender[0].toUpperCase();
        content+='0000';
        if(item?.ExportSubjects[0]?.getSubjectsExport?.type=='full' || item?.ExportSubjects[0]?.getSubjectsExport?.type=='part' || item?.ExportSubjects[0]?.getSubjectsExport?.type=='fulltimecontact' || (item?.registrationsCentresNames?.name?.toString().toLowerCase().includes("midrand"))){
          content+="1";
        }else{
          content+="4";
        }
        content+=races[item?.race?.toString()] ? races[item?.race?.toString()] : ""
        content+="".padEnd(50, ' ');      
  
        let subject_code = '';
        item.ExportSubjects.map((subject)=>{
          if(subject?.qualificationCode===code){
            subject_code+="1"+subject?.getSubjectsExport?.code.padStart(8,'0')+"1";
          }
        })
        content+=subject_code.padEnd(200,'0');
        content+="E\r\n";
        entriesCount+=1;
      })
    })
    let footer = '92801T1'+current_date.getFullYear().toString().substr(-1)+'E.TXT'+'AIE'.padEnd(30, ' ')+'0899992801'+'GAUTENG'.padEnd(40, ' ')+'08'+moment(new Date()).format("YYYYMMDD")+moment(examData.period).format("YYYYMM")
    footer += entriesCount.toString().padStart(6,'0')
    const formatData = header+content+footer;
    return formatData;
  }

  // Export Term Marks
  const FormatTermData = (exportData) =>{
    const stData = exportData.data;
    const examData = exportData.examData;
    const replace = '1199995013';
    const find = [
      '0199990135',
      '0299995210',
      '0599995515',
      '0299995214',
      '0599995517',
      '0699995608',
      '0999995909',
      '0400000408',
      '0199995164',
      '0499990454',
      '0399990328',
      '0999995901',
      '0799995724',
      '1099995064',
      '1099995049',
      '1099995044',
      '1099995043'
    ];
    let last_digit_year = moment().format('YYYY').slice(-1);
    let date_of_creations = moment().format('YYYYMMDD');
    let center_code = (stData[0]?.registrationsCentresNames?.code).toString().padStart(10, '0');
    center_code = find.includes(center_code) ? replace : center_code;
    const last_digit_center_code = center_code.slice(-4);    
    let header = "1"+last_digit_center_code+"T"+examData.trimester+last_digit_year+"M.TXTAIE"+"".padEnd(23, ' ')+center_code+'08'+date_of_creations+moment(examData.period).format("YYYYMM")+'0204\n'

    let content = '';
    let count = 0;
    stData.map((item)=>{
      let common_content = '';
      common_content+='2';
      common_content+=item?.exam_id_number ? item?.exam_id_number?.toString().trim().padEnd(13, '0') : "undefined";
      common_content+=item?.student?.toString().padEnd(9, "0");
      common_content+=item?.registrationsCentresNames?.centre_number;
      common_content+=center_code;
      item.ExportSubjects.map((subject)=>{
        let sub_content = '';
        sub_content+=(subject?.qualificationCode ? subject?.qualificationCode : "").toString().padStart(10, 0);
        sub_content+='1';
        sub_content+=(subject?.getSubjectsExport?.code)?.toString().padStart(8, "0");        
        sub_content+=subject?.getSubjectsExport?.type==='full' ? 1 : '2';
        sub_content+='02';
        sub_content+= subject?.mark>0 ? subject?.mark.replace("%","").padStart(4,'0') : "0999";
        sub_content+="EA"
        content+=common_content+sub_content+"\r\n";
        count++;
      })
    })

    let footer = "9"+last_digit_center_code+"T"+examData?.trimester+last_digit_year+"M.TXT"+count.toString().padStart(6,"0")+"AIE".padEnd(20, " ")+center_code+'08'+date_of_creations+moment(examData?.period).format("YYYYMM")
    const formatData = header+content+footer;
    return formatData;
  }
  const handleExportData = (type) =>{    
    const parameter = {
      exam : id,
      center : userData.length ? userData.map((item=>(item.id))) : [],
      export : type=="sheet" ? 1 : 2,
      exportAll : false
    }
    if(type=="sheet"){
      setLoading1(true);
    }
    if(type=="term"){
      setLoading2(true);
    }
    ExamsRegExportData(parameter).then((res)=>{
      if(res.status===200){
        const respData = res.data;

        const element = document.createElement("a");
        let file;
        if(type=="sheet"){
          file = new Blob([FormatExportData(respData)], {type: 'text/plain'});
        }else{
          file = new Blob([FormatTermData(respData)], {type: 'text/plain'});
        }
        element.href = URL.createObjectURL(file);
        element.download = type==="sheet" ? "Export_sheet.txt" : "Export_term_mark.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

      } 
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading1(false);
      setLoading2(false);
    })
    
  }

  const handleFinanceExport = () =>{
    const param = {
      exam : id,
      center : userData.length ? userData.map((item=>(item.id))) : [],
      export : 1,
      exportAll : false
    }
    setLoading3(true);
    ExamsFinanceExportData(param).then((res)=>{
      if(res.status===200){
        const header = ["First Name",	"Second Name", "Last Name",	"Student Number",	"Subjects",	"Status"];
        const data = res.data.data.map((item)=>({
          "First Name":item.first_name,
          "Second Name":item.second_name,
          "Last Name":item.last_name,
          "Student Number":item.student_crm_id,
          "Subjects":item.name,
          "Status":item.status
        }));
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);
        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = "Exam_Finance_report";
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();
        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
      }
    }).catch((err)=>{console.log(err);}).finally(()=>{setLoading3(false);})
  }


  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const handleSearchAvailableFilter = (e) => {
    const value = e.target.value;
    setSearchAvailable(value);
  };

  // TODO Filtering

  // TODO Reset Filter

  const resetFilter = () => {
    setSearch("");
  };

  const resetAvailableFilter = () => {
    setSearchAvailable("");
  };
  const sortingCentre = (rowA, rowB) => {
    const name1 = rowA.name.toString();
    const name2 = rowB.name.toString();
    return name1.localeCompare(name2);
  };
  const sortingTotalstudents = (rowA, rowB) => {
    const name1 = rowA.total_student;
    const name2 = rowB.total_student;
    if (name1 > name2) {return 1} else {return -1}
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempStudent = allData.filter((item) => {
        let includes =
          item.students &&
          item.total_student.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCentre = allData.filter((item) => {
        let includes = item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempCentre];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (search.length) {
      return updatedData;
    } else {
      return userData;
    }
  };
  const dataToAvailable = () => {
    let updatedData = [];
    let allData = availableCentre;
    if (searchAvailable.length) {
      let tempStudent = allData.filter((item) => {
        let includes =
          item.total_student &&
          item.total_student.toString().includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCentre = allData.filter((item) => {
        let includes = item.name
          .toLowerCase()
          .includes(searchAvailable.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempCentre];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (searchAvailable.length) {
      return updatedData;
    } else {
      return availableCentre;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Centre",
      selector: "centre",
      sortable: true,
      sortFunction:sortingCentre,
      cell: (row) => (row.name ? 
        <Link
          title={row.name}
          className="as-text-blue curser feature-name"
          target="_blank"
          to={`/studentAdministration/externalExam/examCentres/edit/form/${row.id}`}
        >
          <span className="textLimit100">{row.name}</span> 
        </Link> : "NA"),
    },
    {
      name: "Students",
      selector: "students",
      sortable: true,
      sortFunction:sortingTotalstudents,
      cell: (row) => <p>{row.total_student ? row.total_student : "NA"}</p>,
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["umdelete"]} errorProps={{disabled:true}}>
              <button
                title="Delete"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const availableColumns = useMemo(() => [
    {
      name: "Centre",
      selector: "name",
      sortable: true,
      sortFunction:sortingCentre,
      cell: (row) => (row.name ? 
        <Link
          title={row.name}
          className="as-text-blue curser feature-name"
          onClick={()=>{$("#exportCentre").modal("hide");}}
          to={`/studentAdministration/externalExam/examCentres/edit/form/${row.id}`}
        >
          <span className="textLimit100">{row.name}</span> 
        </Link> : "-"),
    },
    {
      name: "Students",
      selector: "total_student",
      sortable: true,
      sortFunction:sortingTotalstudents,
      cell: (row) => row.total_student ? row.total_student : "-",
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Add"
              className="btn btn-primary rounded-circle"
              onClick={() => handleAdd(row, row.id)}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  // available centre add button

  const handleAdd = (data, id) => {
    const addData = availableCentre.filter((val) => {
      return val.id !== id;
    });
    setAvailableCentre(addData);
    setUserData((prev) => [...prev, data]);
  };

  const handleDelete = (row , id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let arr = userData
        arr.splice(arr.indexOf(row),1)
        setUserData(()=>[...arr])
        setAvailableCentre(() => [...availableCentre,row])
        Swal.fire("Deleted!", "Your Centre has been deleted.", "success");
      }
    });
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      {/* selected centres */}
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="filter-button-group">  
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>                  
                  <div className="files-export-group">
                    <button className="btn btn-primary mr-2" title="Export Sheet" onClick={() =>{handleExportData("sheet")}} disabled={userData.length <1}>
                      {loading1 ? <i className="fas fa-cog fa-spin"></i> :<i className="fal fa-file icon"></i>}Export Sheet
                    </button>
                    <button className="btn btn-primary mr-2" title="Export Term Marks" onClick={() =>{handleExportData("term")}} 
                    disabled={userData.length <1}
                    >
                      {loading2 ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file icon"></i>}Export Term Marks
                    </button>
                    <button className="btn btn-primary" title="Export Finance Report" 
                    onClick={() =>{handleFinanceExport()}} 
                    disabled={userData.length <1}
                    >
                      {loading3 ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file icon"></i>}Export Finance Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button
                      className="btn btn-primary"
                      title="Add Centre"
                      disabled
                    >
                      <i className="fal fa-plus"></i>Add Centre
                    </button>
                  )}
                  scopes={["eeedview"]}
                >
                    <button className="btn btn-primary" title="Add Centre" data-toggle="modal" data-target="#exportCentre">
                      <i className="fal fa-plus"></i>Add Centre
                    </button>
                </PermissionsGate>
              </div>
            </div>
          </div>

        </div>
       
        <DataTable
          data={dataToRender()}
          defaultSortField="ID"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
        />        
        {/*Available Centre */}
        <div
          className="topic-add-modal modal fade"
          id="exportCentre"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          >
          <div
            className="modal-dialog modal-dialog-centered available-assessment-modal"
            role="document"
          >
            <div className="modal-content">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Export Centre</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
              <div className="modal-body">
                <div className="custom-table-div filter-search-icon card-table-custom">
                  <div className="search-filter-div">
                    <div className="search-filter-div-left">
                      <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                          <div
                            id="assessment-table-main_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                              <div
                                id="assessment-table-main_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  <input
                                    type="search"
                                    className=""
                                    placeholder="Search"
                                    aria-controls="assessment-table-main"
                                    onChange={handleSearchAvailableFilter}
                                    value={searchAvailable}
                                  />
                                </label>
                                <div className="filter-eff filter-data-btn">
                                  <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                  </button>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="filter-button-group">
                            <div className="reset-btn-group">
                              <div className="button-reset dropdown-comman">
                                <button
                                  className="btn btn-primary"
                                  onClick={resetAvailableFilter}
                                  title="Reset"
                                >
                                  <i className="fal fa-redo"></i>Reset
                                </button>
                              </div>
                              {/* <div className="files-export-group">
                                <button
                                  type="button"
                                  className="btn btn-files"
                                  onClick={() => {
                                    exportAvailableData("xlsx", "AvailableCentre");
                                  }}
                                  title="Export spreadsheet"
                                >
                                  <i className="fal fa-file-excel icon"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-files"
                                  onClick={() => {
                                    exportAvailableData("csv", "AvailableCentre");
                                  }}
                                  title="Export CSV"
                                >
                                  <i className="fal fa-file-csv icon"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-files"
                                  onClick={() => {
                                    exportAvailableData("pdf", "AvailableCentre");
                                  }}
                                  title="Export PDF"
                                >
                                  <i className="fal fa-file-pdf icon"></i>
                                </button>
                              </div> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                
                  <DataTable
                    data={dataToAvailable()}
                    defaultSortField="name"
                    defaultSortAsc={true}
                    columns={availableColumns}
                    pagination={true}
                    noDataComponent={Str.noRecord}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default ExportData;

import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import HeaderInformation from "./HeaderInformation";
import AttachedPage from "./AttachedPage";
function HeaderTabs() {
    const history = useHistory();
    const { tab, subTab, subId="" } = useParams();
     const [headerName, setHeaderName] = useState(() => {
        return localStorage.getItem("headerName") || "";
    });;
    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div
                    className={
                        "sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")
                    }
                >
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 class="text-left-align landing-heading heading_color_dynamic">
                                                {subTab == "create" ? "Headers: " : "Headers: "}
                                                <span>{subTab == "create" ? "Open Day Header" : headerName}</span>
                                                {/* Headers: <span>Open Day Header</span> */}
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        title="Back"
                                                        className="btn btn-white-bordered"
                                                    >
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {subTab === "update" && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/header/header_information/${subTab}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "header_information" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/header/header_information/${subTab}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-info-circle"></i>
                                                    Header Information
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/header/attached_page/${subTab}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "attached_page" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/header/attached_page/${subTab}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-attached_page"></i>Attached Page
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/header/auditTrail/${subTab}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/header/auditTrail/${subTab}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "header_information" && <HeaderInformation setHeaderName={setHeaderName}/>}
                                            {tab == "attached_page" && <AttachedPage />}
                                            {tab == "auditTrail" && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderTabs;

import axiosInstance from "../utils/axios";

export const AddWebsite = async (data) => {
    console.log(data);
    return await axiosInstance().post('/insertWebsiteData', data)
}

export const updateWebsite = async (data , id) => {
    console.log(id);
    return await axiosInstance().post(`/updateWebsiteData?id=${id}`, data)
}

export const WebsiteBranch = async () => {
    return await axiosInstance().get(`/getBranchWebDropDownList`)
}

export const WebsiteStatus = async () => {
    return await axiosInstance().get(`/getStatusDropDownList`)
}

export const WebsiteTemplates = async () => {
    return await axiosInstance().get(`/getBrandTemplateList`)
}

export const GetWebsiteListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getWebsiteList`, values, {
        cancelToken: cancelToken
    });
};

export const GetWebsiteListsFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getWebsiteListDropdown`, {
        cancelToken: cancelToken
    });
};

export const DeleteWebsiteList = async (values) => {
    console.log(values);
    return await axiosInstance().post(`/deleteWebsiteData?id=${values.id}`, values);
};

export const GetWebsiteList = async (data) => {
    return await axiosInstance().post(`/getWebsiteList`, data);
};

export const GetWebsiteUpdateList = async (id) => {
    return await axiosInstance().get(`getWebsiteData?id=${id}`);
};

export const GetEmailList = async (values, cancelToken) => {
    return await axiosInstance().post(`/getEmailsList`,values,{
        cancelToken: cancelToken
    });
};
export const GetEmailListFilters = async (cancelToken) => {
    return await axiosInstance().get(`/getEmailsListFilters`, {
        cancelToken: cancelToken
    });
};
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { AddMenusItem, getMenuItemsColorDropdown, getMenuItemssub_menuDropdown, getMenuItemsTypeDropdown, getMenusItemsDropdownlist } from "../../../services/Menusserver";

const CreateMenuItems = (props) => {

    const [MenuDetails, setMenuDetails] = useState([]);
    const [menuColor, setmenuColor] = useState([]);
    const [MenuItemDetails, setMenuItemDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SubMenuItemDetails, setSubMenuItemDetails] = useState([]);
    const [iconName, setIconName] = useState("");
    const [checkbox, setCheckbox] = useState({
        MenuOverrideColor: false,
        isUseMenuIcon: false,
    });

    const id = Number(props.id)

    // get dropdown menu list

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await getMenusItemsDropdownlist()
                if (data.status === 200) {
                    setMenuDetails(data && data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const gettypeData = async () => {
            try {
                const data = await getMenuItemsTypeDropdown()
                if (data.status === 200) {
                    setMenuItemDetails(data && data?.data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const getsubMenuData = async () => {
            try {
                const data = await getMenuItemssub_menuDropdown(id)
                if (data.status === 200) {
                    setSubMenuItemDetails(data && data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const getColorData = async () => {
            try {
                const data = await getMenuItemsColorDropdown(id)
                if (data.status === 200) {
                    setmenuColor(data && data?.data?.result)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        getColorData()
        getsubMenuData()
        gettypeData()
        getData()
    }, []);

    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            formik.setFieldValue("MenuIcon", e.currentTarget.value);
            setIconName(file.name);
        }
    };

    const formik = useFormik({
        initialValues: {
            MenuItemID: '',
            MenuID: id,
            MenuItemAlias: '',
            MenuItemTypeID: '',
            MenuItemStatus: '',
            CategoryHeading: '',
            MenuItemCategory: '',
            MenuCategory: 0,
            SubMenuID: 0,
            MenuItemLabel: "",
            MenuItemLinkURL: "",
            MenuItemColor: "",
            MenuItemOverrideColor: checkbox.MenuOverrideColor ? 1 : 0,
            isUseMenuIcon: checkbox.isUseMenuIcon ? 1 : 0,
            MenuIcon: '',
            OpenInNewPage: 0,
            MenuItemOverrideCategory: 0,
            MenuItemPageID: 0
        },
        validationSchema: Yup.object().shape({
            MenuItemAlias: Yup.string().required("Menu Item Alias is required"),
        }),
        onSubmit: (values, action) => {
            setLoading(true);
            AddMenusItem(values)
                .then((response) => {
                    Swal.fire('Success!', 'Menu Items created successfully!', 'success');
                    action.resetForm();
                })
                .catch((error) => {
                    console.error('Error creating menus:', error);
                    Swal.fire('Error!', 'Failed to create menu Items', 'error');
                })
                .finally(() => setLoading(false));
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-12">
                    <div className="card card-profile-info-card course-details-tab-sec border-0">
                        <div className="course-info-sec">
                            <div className="edit-icon new-card-header">
                                <div className="card-header">
                                    Menu Information
                                </div>
                            </div>
                            <div className="card-body-inr card-body-info">
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-icon-group mb-4">
                                            <label>Menu Item Alias *</label>
                                            <input
                                                type="text"
                                                className={`form-control ${formik.errors.MenuItemAlias && formik.touched.MenuItemAlias && "is-invalid"}`}
                                                name="MenuItemAlias"
                                                title="MenuItemAlias"
                                                placeholder="Menu Item Alias"
                                                value={formik.values.MenuItemAlias}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.MenuItemAlias && formik.touched.MenuItemAlias && (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group-blk mb-4">
                                            <label>Menu Item Resource Type <i className="fal fa-info-circle"></i></label>
                                            <Select
                                                className={"form-control custom-select-box" + (formik.errors.MenuItemTypeID && formik.touched.MenuItemTypeID ? " is-invalid" : "")}
                                                name="MenuItemTypeID"
                                                value={MenuItemDetails?.find(item => item.value === formik.values.MenuItemTypeID)}
                                                onChange={(value) => {
                                                    if (value) {
                                                        formik.setFieldValue("MenuItemTypeID", value.value);
                                                    } else {
                                                        formik.setFieldValue("MenuItemTypeID", "");
                                                    }
                                                }}
                                                placeholder={"Select..."}
                                                onBlur={formik.handleBlur}
                                                options={MenuItemDetails}
                                                maxMenuHeight={175}
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group-blk mb-4">
                                            <label>Status <i className="fal fa-info-circle"></i></label>
                                            <Select
                                                className={"form-control custom-select-box" + (formik.errors.MenuItemStatus && formik.touched.MenuItemStatus ? " is-invalid" : "")}
                                                name="MenuItemStatus"
                                                value={MenuDetails?.menusItemStatus?.find(item => item.value === formik.values.MenuItemStatus)}
                                                onChange={(value) => {
                                                    if (value) {
                                                        formik.setFieldValue("MenuItemStatus", value.value);
                                                    } else {
                                                        formik.setFieldValue("MenuItemStatus", "");
                                                    }
                                                }}
                                                placeholder={formik.values.MenuItemStatus ? formik.values.MenuItemStatus : "Select..."}
                                                onBlur={formik.handleBlur}
                                                options={MenuDetails.menusItemStatus}
                                                maxMenuHeight={175}
                                                isClearable
                                            />

                                            {formik.errors.MenuItemStatus &&
                                                formik.touched.MenuItemStatus && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    {formik.values.MenuItemTypeID === 2 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-icon-group mb-4">
                                                <label>Menu Item Title *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.MenuItemLabel && formik.touched.MenuItemLabel && "is-invalid"}`}
                                                    name="MenuItemLabel"
                                                    title="MenuItemLabel"
                                                    placeholder="Menu Item Title"
                                                    value={formik.values.MenuItemLabel}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.MenuItemLabel && formik.touched.MenuItemLabel && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {formik.values.MenuItemTypeID === 2 &&
                                        <div className="col-md-6 col-lg-8">
                                            <div className="form-group-blk mb-4">
                                                <label>Menu Item URL <i className="fal fa-info-circle"></i></label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.MenuItemLinkURL && formik.touched.MenuItemLinkURL && "is-invalid"}`}
                                                    name="MenuItemLinkURL"
                                                    title="MenuItemLinkURL"
                                                    placeholder="Menu Item URL"
                                                    value={formik.values.MenuItemLinkURL}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.MenuItemLinkURL && formik.touched.MenuItemLinkURL && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 4 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-icon-group mb-4">
                                                <label>Heading*</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.CategoryHeading && formik.touched.CategoryHeading && "is-invalid"}`}
                                                    name="CategoryHeading"
                                                    title="Heading"
                                                    placeholder="Short Courses"
                                                    value={formik.values.CategoryHeading}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.CategoryHeading && formik.touched.CategoryHeading && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 6 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-icon-group mb-4">
                                                <label>Override Page Title</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.MenuItemOverridePageTitle && formik.touched.MenuItemOverridePageTitle && "is-invalid"}`}
                                                    name="MenuItemOverridePageTitle"
                                                    title="Override Page Title"
                                                    placeholder="Short Courses"
                                                    value={formik.values.MenuItemOverridePageTitle}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.MenuItemOverridePageTitle && formik.touched.MenuItemOverridePageTitle && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 6 &&
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-icon-group mb-4">
                                                <label>Override Page Title</label>
                                                <div className="form-group form-group-save-cancel">
                                                    <button
                                                        className="btn btn-save btn-success"
                                                        type="submit"
                                                        title="Save"
                                                        disabled={loading ? true : false}
                                                    >
                                                        {loading === false ? (
                                                            <i className="fal fa-plus"></i>
                                                        ) : (
                                                            <i className="fas fa-cog fa-spin"></i>
                                                        )}
                                                        Assign Page
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        formik.values.MenuItemTypeID === 3 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group-blk mb-4">
                                                <label>Available Sub-Menus* <i className="fal fa-info-circle"></i></label>
                                                <Select
                                                    className={"form-control custom-select-box" + (formik.errors.SubMenuID && formik.touched.SubMenuID ? "is-invalid" : "")}
                                                    name="SubMenuID"
                                                    value={SubMenuItemDetails?.find(item => item.value === formik.values.SubMenuID)}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            formik.setFieldValue("SubMenuID", value.value);
                                                        } else {
                                                            formik.setFieldValue("SubMenuID", "");
                                                        }
                                                    }}
                                                    placeholder={"Select..."}
                                                    onBlur={formik.handleBlur}
                                                    options={SubMenuItemDetails}
                                                    maxMenuHeight={175}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 1 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group-blk mb-4">
                                                <label>Available Categories <i className="fal fa-info-circle"></i></label>
                                                <Select
                                                    className={"form-control custom-select-box" + (formik.errors.MenuItemCategory && formik.touched.MenuItemCategory ? " is-invalid" : "")}
                                                    name="MenuItemCategory"
                                                    value={MenuDetails.availableCategory?.find(item => item.value === formik.values.MenuItemCategory)}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            formik.setFieldValue("MenuCategory", value.value);
                                                        } else {
                                                            formik.setFieldValue("MenuCategory", "");
                                                        }
                                                    }}
                                                    placeholder={"Select..."}
                                                    onBlur={formik.handleBlur}
                                                    options={MenuDetails.availableCategory}
                                                    maxMenuHeight={175}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 1 &&
                                        <div className="col-md-6 col-lg-8">
                                            <div className="form-group-blk mb-4">
                                                <label>Override Category Heading <i className="fal fa-info-circle"></i></label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.CategoryHeading && formik.touched.CategoryHeading && "is-invalid"}`}
                                                    name="MenuItemLinkURL"
                                                    title="MenuItemLinkURL"
                                                    placeholder="Menu Item URL"
                                                    value={formik.values.CategoryHeading}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.CategoryHeading && formik.touched.CategoryHeading && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {formik.values.MenuItemTypeID === 7 &&
                                        <p className="ml-3">
                                            <i className="fal fa-info-circle"></i> Note: Submenus can not be placed within a menu that uses columns
                                        </p>
                                    }

                                    {
                                        formik.values.MenuItemTypeID === 4 &&
                                        <p className="ml-3 mt-5">
                                            <i className="fal fa-info-circle"></i> Note: Submenus can not be placed within a menu that uses static headings
                                        </p>
                                    }
                                </div>

                                {formik.values.MenuItemTypeID !== 7 && formik.values.MenuItemTypeID !== 4 &&
                                    <>
                                        <hr />
                                        <div className="course-sec-gap course-resource-sec">
                                            <div className="edit-icon new-card-header">
                                                <div className="card-header">
                                                    <i className="fal fa-cog"></i>&nbsp;
                                                    Menu Item Settings
                                                </div>
                                            </div>

                                            <div className="card-body-inr">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-icon-group mb-4">
                                                            <label>Menu Item Colour *</label>
                                                            <Select
                                                                className={`form-control custom-select-box ${formik.errors.MenuItemColor && formik.touched.MenuItemColor && "is-invalid"}`}
                                                                name="MenuItemColor"
                                                                value={menuColor.filter(val => formik.values.MenuItemColor.includes(val.value.toString()))}
                                                                onChange={(value) => {
                                                                    if (value) {
                                                                        formik.setFieldValue("MenuItemColor", value.value);
                                                                    } else {
                                                                        formik.setFieldValue("MenuItemColor", "");
                                                                    }
                                                                }}
                                                                isClearable
                                                                onBlur={formik.handleBlur}
                                                                options={menuColor}
                                                                maxMenuHeight={175}
                                                                placeholder={"Accent 1"}
                                                            />
                                                            {formik.errors.MenuItemColor &&
                                                                formik.touched.MenuItemColor ? (
                                                                <span className="exclamation">
                                                                    <i className="fal fa-exclamation-circle"></i>{" "}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-3 d-flex align-items-center">
                                                        <label className="mb-0">
                                                            Override Colour Variable <i className="fal fa-info-circle"></i>
                                                        </label>
                                                        <div className="toggle-switch ml-2">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    name="MenuOverrideColor"
                                                                    checked={checkbox.MenuOverrideColor}
                                                                    onChange={(e) => setCheckbox({ ...checkbox, MenuOverrideColor: e.target.checked })}
                                                                />
                                                                <span className="slider slider-round"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-3 d-flex align-items-center">
                                                        <label className="mb-0">
                                                            Use Menu Item Icon <i className="fal fa-info-circle"></i>
                                                        </label>
                                                        <div className="toggle-switch ml-2">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    name="isUseMenuIcon"
                                                                    checked={checkbox.isUseMenuIcon}
                                                                    onChange={(e) => setCheckbox({ ...checkbox, isUseMenuIcon: e.target.checked })}
                                                                />
                                                                <span className="slider slider-round"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-group-blk mb-3">
                                                            <label>
                                                                Menu Icon * <i className="fal fa-info-circle"></i>
                                                            </label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-group atttach-file">
                                                                    <label
                                                                        className={
                                                                            formik.errors.MenuIcon && formik.touched.MenuIcon && "file-req is-invalid"
                                                                        }
                                                                    >
                                                                        <i className="fal fa-upload"></i>
                                                                        <span>Upload</span>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control form-control-aatch-file"
                                                                            onChange={(e) => handleFileChange(e)}
                                                                            name="MenuIcon"
                                                                            id="MenuIcon"
                                                                            accept=".png,.jpg,.jpeg"
                                                                        />
                                                                    </label>
                                                                </div>
                                                                {iconName && (
                                                                    <div className="frm-group d-flex align-items-center" style={{ marginLeft: 10 }}>
                                                                        <ul className="list-unstyled attached-file-ul m-0">
                                                                            <li>
                                                                                <a href={formik.values.MenuIcon} target="_blank">
                                                                                    <i className="fal fa-file"></i>
                                                                                    &nbsp;{iconName}
                                                                                </a>
                                                                            </li>
                                                                        </ul>

                                                                        <button
                                                                            className="btn btn-danger rounded-circle"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                formik.setFieldValue("MenuIcon", "");
                                                                                setIconName("");
                                                                            }}
                                                                        >
                                                                            <i className="fal fa-trash-alt"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="form-group form-group-save-cancel">
                            <button
                                className="btn btn-save btn-success"
                                type="submit"
                                title="Save"
                                disabled={loading ? true : false}
                            >
                                {loading === false ? (
                                    <i className="fal fa-save"></i>
                                ) : (
                                    <i className="fas fa-cog fa-spin"></i>
                                )}
                                Save
                            </button>
                        </div>

                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CreateMenuItems;

import axiosInstance from '../utils/axios';

export const GetCampusMgtList = async (data, cancelToken) => {
    return await axiosInstance().post("/getCampusClassesList",data,{cancelToken});
}



export const AddEditCampusClass = async (params, isEdit) => {
    let url = isEdit === true ? "/editCampusClasses" : "/addCampusClasses"
    return await axiosInstance().post(url, params);
}

export const GetCampusRoomsList = async (id) => {
    return await axiosInstance().get("/getCampusRoomsList?campus_id=" + id);
}

export const GetCampusRoomsListCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get("/getCampusRoomsList?campus_id=" + id, {cancelToken: cancelToken});
}

export const AddRoomToCampus = async (data) => {
    return await axiosInstance().post("/addCampusRooms", data);
}

export const DeleteRoomOfCampus = async (data) => {
    return await axiosInstance().post("/deleteCampusRooms", data);
}

export const GetCampusDetails = async (id) => {
    return await axiosInstance().get("/getCampusClassesDetail?id=" + id);
}

export const GetCampusDetailsCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get("/getCampusClassesDetail?id=" + id, {cancelToken: cancelToken});
}

export const GetCampusAuditTrails = async (id = null) => {
    return await axiosInstance().post("/getCampusRoomAuditTrail", id !== null && { action_id: id });
}

export const GetCampusAuditTrailsCancelToken = async (id = null, cancelToken) => {
    return await axiosInstance().post("/getCampusRoomAuditTrail", id !== null ?  { action_id: id } : {}, {cancelToken: cancelToken});
}
import axios from "axios";
import Swal from "sweetalert2";

export const download = async (fileUrl, fileName) => {
  if (fileUrl){
    // replace port if fileurl has a :<port number in with ""
    fileUrl = fileUrl.replace(/:\d+/, "");
  }
  Swal.close();
  Swal.fire({
   icon: "info",
   title: "Downloading...",
   timer: 2000,
   onOpen: function () {
     Swal.showLoading();
   },
   showConfirmButton: false
 }).then((result) => {
 });
  let flag = 0;
  await axios({
    url: fileUrl, 
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    console.log(response);
    if (response.status==200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); 
      document.body.appendChild(link);
      link.click();
      flag = 1;
      Swal.close();
      Swal.fire({
       icon: "success",
       title: "Downloading Finished",
       showConfirmButton: true
     }).then((result) => {
     });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error while downloading file",
      }).then((result) => {});
    }

  }).catch((error) => {   
    Swal.fire({
      icon: "error",
      title: "Error while downloading file",
    }).then((result) => {});
  });
  if (flag) return flag;
};

  // export function download(data, fileName, type="application/pdf") {
  //   //let data='https://www.myaie.ac/Library/Timetables/Timetable_Electrical_Engineering_FT_N3.pdf';
  //   // Create an invisible A element
  //   const a = document.createElement("a");
  //   a.style.display = "none";
  //   document.body.appendChild(a);
  //   //data:image/png;base64,
    
  //   // Set the HREF to a Blob representation of the data to be downloaded
  //   a.href = window.URL.createObjectURL(
  //     new Blob([data], { type })
  //   );
  //   //a.href = "data:application/pdf;base64,"+data;
  //   console.log(data,a.href,'tetxtt');
  //   a.setAttribute("download", fileName);
  //   a.setAttribute("target", "_target");
  //   a.click();
    
  //   // Cleanup
  //   window.URL.revokeObjectURL(a.href);
  //   document.body.removeChild(a);
  // }

// export function download(url, filename) {
//   fetch(url).then(function(t) {
//       return t.blob().then((b)=>{
//           var a = document.createElement("a");
//           a.href = URL.createObjectURL(b);
//           a.setAttribute("download", filename);
//           a.click();
//       }
//       );
//   });
//   }


// const downloadFileAuto = require("downloadify");
// export const download = async (data,name) => {  
// downloadFileAuto(data, "profile_banner").then(p => {
//     console.log(`File saved as ${p}`)
//     // => File saved as profile_banner.jpeg
// }).catch(console.error)
// }

// export const download = async (data,fileName,type="*/*") => {
//   const a = document.createElement("a");
//   a.style.display = "none";
//   document.body.appendChild(a);
//   a.href = window.URL.createObjectURL(
//     new Blob([data], { type })
//   );
//   a.setAttribute("download", fileName);
//   a.click();
//   window.URL.revokeObjectURL(a.href);
//   document.body.removeChild(a);
//   return true;
//  };

// export const download = async (urls,name) => {  
//   const url = 'https://unsplash.com/photos/AaEQmoufHLk/download?force=true'
//   const path = Path.resolve(__dirname, 'images', 'code.jpg')
//   const writer = Fs.createWriteStream(path)

//   const response = await Axios({
//     url,
//     method: 'GET',
//     responseType: 'stream'
//   })

//   response.data.pipe(writer)

//   return new Promise((resolve, reject) => {
//     writer.on('finish', resolve)
//     writer.on('error', reject)
//   })
// }

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";
import { DeleteCertificate, GetCommonContentReReg, MatricCertificate, StudentEduDetails, UpdateHighSchoolDetails } from "../../../../../services/StudentsSettingServices";
import { IMAGE_URL } from "../../../../../utils/Constants";
import { myCompare } from "../../../../common/MyCompare";
import PermissionsGate from "../../../../../utils/permissionGate";
function HighSchoolDetailBlock({ subjects, loading, metricCertificate, student, setDeleteRow, deleteRow }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState();
  const [certificateFile, setCertificateFile] = useState();
  const [certificateFileName, setCertificateFileName] = useState();
  const [showMore, setShowMore] = useState(false);

  const [yearArr, setYearArr] = useState([]);

  const tempStatusArr = [];

  useEffect(() => {
    GetCommonContentReReg()
      .then((res) => {
        setYearArr(res.data.getLastyears);
      })
      .catch((err) => console.log("err", err));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Year: student ? student.high_year : "",
      School: student ? student.high_name : "",
      MetricCert: metricCertificate ? metricCertificate : [],
      SubjectOne: subjects && subjects[0] && subjects[0].subject ? subjects[0].subject : "",
      SubjectTwo: subjects && subjects[1] && subjects[1].subject ? subjects[1].subject : "",
      SubjectThree: subjects && subjects[2] && subjects[2].subject ? subjects[2].subject : "",
      SubjectFour: subjects && subjects[3] && subjects[3].subject ? subjects[3].subject : "",
      SubjectFive: subjects && subjects[4] && subjects[4].subject ? subjects[4].subject : "",
      SubjectSix: subjects && subjects[5] && subjects[5].subject ? subjects[5].subject : "",
      SubjectSeven: subjects && subjects[6] && subjects[6].subject ? subjects[6].subject : "",
      PercentOne: subjects && subjects[0] && subjects[0].mark ? subjects[0].mark : "",
      PercentTwo: subjects && subjects[1] && subjects[1].mark ? subjects[1].mark : "",
      PercentThree: subjects && subjects[2] && subjects[2].mark ? subjects[2].mark : "",
      PercentFour: subjects && subjects[3] && subjects[3].mark ? subjects[3].mark : "",
      PercentFive: subjects && subjects[4] && subjects[4].mark ? subjects[4].mark : "",
      PercentSix: subjects && subjects[5] && subjects[5].mark ? subjects[5].mark : "",
      PercentSeven: subjects && subjects[6] && subjects[6].mark ? subjects[6].mark : "",
      SubjectIdOne: subjects && subjects[0] && subjects[0].id ? subjects[0].id : "",
      SubjectIdTwo: subjects && subjects[1] && subjects[1].id ? subjects[1].id : "",
      SubjectIdThree: subjects && subjects[2] && subjects[2].id ? subjects[2].id : "",
      SubjectIdFour: subjects && subjects[3] && subjects[3].id ? subjects[3].id : "",
      SubjectIdFive: subjects && subjects[4] && subjects[4].id ? subjects[4].id : "",
      SubjectIdSix: subjects && subjects[5] && subjects[5].id ? subjects[5].id : "",
      SubjectIdSeven: subjects && subjects[6] && subjects[6].id ? subjects[6].id : "",
    },
    validationSchema: Yup.object({
      Year: Yup.string().required("Year is required"),
      School: Yup.string().required("School is required").trim(),
      // MetricCert: Yup.array().required("Metric Certificate is required"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      //TODO FormData
      const formData = new FormData();
      formData.append("from_admin", 1);
      formData.append("student_id", id);
      formData.append("high_year", values.Year);
      formData.append("high_name", values.School);
      // formData.append("subject[e_6507]", id);
      // formData.append("subject_id[e_6507]", id);
      // formData.append("mark[e_6507]", id);
      formData.append("subject[1]", values.SubjectOne);
      formData.append("subject_id[1]", values.SubjectIdOne);
      formData.append("mark[1]", values.PercentOne);
      formData.append("subject[2]", values.SubjectTwo);
      formData.append("subject_id[2]", values.SubjectIdTwo);
      formData.append("mark[2]", values.PercentTwo);
      formData.append("subject[3]", values.SubjectThree);
      formData.append("subject_id[3]", values.SubjectIdThree);
      formData.append("mark[3]", values.PercentThree);
      formData.append("subject[4]", values.SubjectFour);
      formData.append("subject_id[4]", values.SubjectIdFour);
      formData.append("mark[4]", values.PercentFour);
      formData.append("subject[5]", values.SubjectFive);
      formData.append("subject_id[5]", values.SubjectIdFive);
      formData.append("mark[5]", values.PercentFive);
      formData.append("subject[6]", values.SubjectSix);
      formData.append("subject_id[6]", values.SubjectSix);
      formData.append("mark[6]", values.PercentSix);
      formData.append("subject[7]", values.SubjectSeven);
      formData.append("subject_id[7]", values.SubjectIdSeven);
      formData.append("mark[7]", values.PercentSeven);
      UpdateHighSchoolDetails(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setDisabled(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setDisabled(false);
          console.log("error :", err.response);
        });
    },
  });

  const handleDelete = (certId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "Deleting Record",
          showConfirmButton: false,
        });
        DeleteCertificate(id, certId, "metric_certificate")
          .then((res) => {
            Swal.fire("Deleted!", "File Deleted.", "success");
            setDeleteRow(!deleteRow);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              setDeleteRow(!deleteRow);
              Swal.fire("", `Unable To Delete.`, "warning");
            }
          });

        Swal.fire("Deleted!", "File has been deleted.", "success");
      }
    });
  };

  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">High School Details</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-md-6 col-lg-4" title="Year Matriculated">
            <label htmlFor="Year">Year Matriculated *</label>
            <div className="form-icon-group mb-4">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Year && formik.touched.Year ? " is-invalid" : "")}
                name="Year"
                value={yearArr.filter((item) => {
                  return item.value == formik.values.Year;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Year", value.value);
                  } else {
                    formik.setFieldValue("Year", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={yearArr.sort(myCompare).reverse()}
                maxMenuHeight={175}
                autoComplete="off"
                placeholder={formik.values.Year ? formik.values.Year : "Select"}
                isClearable
              />
              {formik.errors.Year && formik.touched.Year ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="School">School Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="School"
                className={"form-control" + (formik.errors.School && formik.touched.School ? " is-invalid" : "")}
                name="School"
                placeholder="Enter School Name"
                value={formik.values.School}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="School"
              />
              {formik.errors.School && formik.touched.School ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Matric Certificate *</label>
              <div className="form-group atttach-file m-0">
                <label className={formik.errors.MetricCert && formik.touched.MetricCert && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                    <input
                      type="file"
                      title={certificateFileName ? certificateFileName : "No file selected"}
                      className="form-control  form-control-aatch-file"
                      onChange={(e) => {
                        if (e.target.files.length) {
                          formik.setFieldValue("MetricCert", [
                            ...formik.values.MetricCert,
                            {
                              url: e.target.files[0].name,
                              student: id,
                              id: e.target.files[0].name,
                              type: "metric_certificate",
                            },
                          ]);
                          setCertificateFile(e.target.files[0]);
                          setCertificateFileName(e.target.files[0].name);
                          const formData = new FormData();
                          formData.append("student", id);
                          formData.append("type", "metric_certificate");
                          formData.append("from_admin", 1);
                          formData.append("file_input", e.target.files[0]);
                          MatricCertificate(formData)
                            .then((res) => {
                              Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "File Added successfully",
                              });
                              setDisabled(false);
                            })
                            .catch((err) => {
                              Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: err?.response?.data?.message,
                              });
                            });
                        }
                      }}
                    />
                  </PermissionsGate>
                </label>
              </div>
              <div>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    {formik.values.MetricCert.map((item, index) => {
                      if(!index || showMore) {
                      return (
                        <li key={item.id} className="">
                          <a href={(item.url.includes("http") ? "" : (IMAGE_URL + "/"))  + item.url.replace("/home/myaie/public_html/", "").replace("public", "")} target="_blank">
                            <i className="fal fa-file"></i>
                            &nbsp;{basename(item.url)}
                          </a>
                          <PermissionsGate errorProps={{ disabled: true }} scopes={["sededit"]}>
                            <button className="btn btn-danger" onClick={() => handleDelete(item.id)} type="button" title="Delete">
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </PermissionsGate>
                        </li>
                      );}
                    })}
                  {formik.values.MetricCert.length>1 ? <a href="#" className="show_more_btn" onClick={()=>setShowMore(!showMore)}>Show {!showMore ? "More" : "Less"}</a> : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-icon new-card-header">
          <div className="card-subheading">Subject Details</div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="SubjectOne">Subject </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectOne"
                className={"form-control" + (formik.errors.SubjectOne && formik.touched.SubjectOne ? " is-invalid" : "")}
                name="SubjectOne"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectOne}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectOne"
                title="Subject 1"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectTwo"
                className={"form-control" + (formik.errors.SubjectTwo && formik.touched.SubjectTwo ? " is-invalid" : "")}
                name="SubjectTwo"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectTwo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectTwo"
                title="Subject 2"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectThree"
                className={"form-control" + (formik.errors.SubjectThree && formik.touched.SubjectThree ? " is-invalid" : "")}
                name="SubjectThree"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectThree}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectThree"
                title="Subject 3"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectFour"
                className={"form-control" + (formik.errors.SubjectFour && formik.touched.SubjectFour ? " is-invalid" : "")}
                name="SubjectFour"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectFour}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectFour"
                title="Subject 4"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectFive"
                className={"form-control" + (formik.errors.SubjectFive && formik.touched.SubjectFive ? " is-invalid" : "")}
                name="SubjectFive"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectFive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectFive"
                title="Subject 5"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectSix"
                className={"form-control" + (formik.errors.SubjectSix && formik.touched.SubjectSix ? " is-invalid" : "")}
                name="SubjectSix"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectSix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectSix"
                title="Subject 6"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SubjectSeven"
                className={"form-control" + (formik.errors.SubjectSeven && formik.touched.SubjectSeven ? " is-invalid" : "")}
                name="SubjectSeven"
                placeholder="Enter Subject Name"
                value={formik.values.SubjectSeven}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="SubjectSeven"
                title="Subject 7"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Percent">%</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentOne"
                className={"form-control" + (formik.errors.PercentOne && formik.touched.PercentOne ? " is-invalid" : "")}
                name="PercentOne"
                placeholder="Enter Percent"
                value={formik.values.PercentOne}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentOne"
                title="Percentage 1"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentTwo"
                className={"form-control" + (formik.errors.PercentTwo && formik.touched.PercentTwo ? " is-invalid" : "")}
                name="PercentTwo"
                placeholder="Enter Percent"
                value={formik.values.PercentTwo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentTwo"
                title="Percentage 2"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentThree"
                className={"form-control" + (formik.errors.PercentThree && formik.touched.PercentThree ? " is-invalid" : "")}
                name="PercentThree"
                placeholder="Enter Percent"
                value={formik.values.PercentThree}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentThree"
                title="Percentage 3"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentFour"
                className={"form-control" + (formik.errors.PercentFour && formik.touched.PercentFour ? " is-invalid" : "")}
                name="PercentFour"
                placeholder="Enter Percent"
                value={formik.values.PercentFour}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentFour"
                title="Percentage 4"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentFive"
                className={"form-control" + (formik.errors.PercentFive && formik.touched.PercentFive ? " is-invalid" : "")}
                name="PercentFive"
                placeholder="Enter Percent"
                value={formik.values.PercentFive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentFive"
                title="Percentage 5"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentSix"
                className={"form-control" + (formik.errors.PercentSix && formik.touched.PercentSix ? " is-invalid" : "")}
                name="PercentSix"
                placeholder="Enter Percent"
                value={formik.values.PercentSix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentSix"
                title="Percentage 6"
              />
            </div>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PercentSeven"
                className={"form-control" + (formik.errors.PercentSeven && formik.touched.PercentSeven ? " is-invalid" : "")}
                name="PercentSeven"
                placeholder="Enter Percent"
                value={formik.values.PercentSeven}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                Year="PercentSeven"
                title="Percentage 7"
              />
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <button className="btn btn-save btn-success" type="submit" title="Save" Year="Update" disabled={disabled || !formik.dirty || isLoaded}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
          <button className="btn btn-close btn-danger" type="button" Year="Cancel" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Year && formik.errors.Year ? <div className="invalid-feedback d-block">{formik.errors.Year}</div> : null}
          {formik.touched.School && formik.errors.School ? <div className="invalid-feedback d-block">{formik.errors.School}</div> : null}
          {formik.touched.MetricCert && formik.errors.MetricCert ? <div className="invalid-feedback d-block">{formik.errors.ConfirmSchool}</div> : null}
        </div>
      </form>
    </div>
  );
}

export default HighSchoolDetailBlock;

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { GetPinnedMessages, UnPinUserMessage } from '../../../services/PostsAndFeedService';
import moment from 'moment';
import Swal from "sweetalert2";

const tempFilterData = [
    { text: "Assessment", color: "cat-dark-blue", icon: "fa-ballot-check" },
    { text: "Classes", color: "cat-green", icon: "fa-calendar" },
    { text: "Notifications", color: "cat-yellow", icon: "fa-bell" },
    { text: "Mentions", color: "cat-purple", icon: "fa-at" },
    { text: "Replies", color: "cat-blue", icon: "fa-reply" },
    { text: "Unread", color: "cat-red", icon: "fa-comment-exclamation" },
]

export default function FeedToolBar(props) {
    const history = useHistory();
    const { id } = useParams();
    const [refresh, setRefresh] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showPinned, setShowPinned] = useState(false);
    const [tempFilter, setTempFilter] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [pinnedMessages, setPinnedMessages] = useState([])

    useEffect(() =>{
        return (()=>{            
            props.setAssessmentFilter(0)
            props.setClassFilter(0)
            props.setNotificationFilter(0)
            props.setMentionFilter(0)
            props.setReplyFilter(0)
            props.setUnReadFilter(0)
        })
    },[])
    useEffect(() => {
        const data = tempFilterData.filter(item => !tempFilter.includes(item.text))
        if (data == 0) {
            setShowFilter(false);
        }
    }, [tempFilter])
    
    const handleFilter = (input) =>{
        const ls = [...tempFilter];
        const allSelected = [...selectedItem];
        ls.push(input.text);
        allSelected.push(input);
        setTempFilter([...ls]);
        setSelectedItem([...allSelected]); 
        if(input.text==="Assessment"){
            props.setAssessmentFilter(1)
        }
        if(input.text==="Classes"){
            props.setClassFilter(1)            
        }
        if(input.text==="Notifications"){
            props.setNotificationFilter(1)                   
        }
        if(input.text==="Mentions"){
            props.setMentionFilter(1)            
        }
        if(input.text==="Replies"){
            props.setReplyFilter(1)            
        }
        if(input.text==="Unread"){
            props.setUnReadFilter(1)            
        }  
        setShowFilter(false)      
    }
    
    const removeFilterItem = (input, index) =>{
        const ls = [...tempFilter];
        const allSelected = [...selectedItem];
        if(index === -1){
            index = allSelected.findIndex((item) => item.text===input.text);
        }
        ls.splice(index, 1);
        allSelected.splice(index, 1);
        setTempFilter([...ls]);
        setSelectedItem([...allSelected]);
        if(input.text==="Assessment"){
            props.setAssessmentFilter(0)
        }
        if(input.text==="Classes"){
            props.setClassFilter(0)            
        }
        if(input.text==="Notifications"){
            props.setNotificationFilter(0)                   
        }
        if(input.text==="Mentions"){
            props.setMentionFilter(0)            
        }
        if(input.text==="Replies"){
            props.setReplyFilter(0)            
        }
        if(input.text==="Unread"){
            props.setUnReadFilter(0)            
        } 
    }
    
    useEffect(()=>{
        if(showPinned){
            const payload = { 
                room_id: id, 
            }
            GetPinnedMessages(payload).then((res)=>{
                if(res.status === 200){
                    setPinnedMessages(res?.data)
                }
            })
        }
    },[showPinned, refresh, props.refreshPinnedMsg])

    const handleRemovePinnedMessage = (id) =>{   
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove it!",
        }).then((result) => {
        if (result.isConfirmed) {
            UnPinUserMessage({id}).then((res) =>{
                if(res.status === 200){
                    Swal.fire("Removed!", "Message Unpinned successfully.", "success");
                    setRefresh(!refresh)
                }
            }).catch((err)=>{
                console.log(err)

            });
        }
        });             
    }

    const handleViewPinnedMessage = (msg) =>{
        props.setMessageId(msg?.id);
    }

    return (
        <div className='feed-header'>
            <div className='feed-tool-bar'>
                <div className='search-bar-container'>
                    <div className='feed-search-bar news-room-search'>
                        <i class="fal fa-search left-icon"></i>
                        <input
                            className='feed-search-input form-control'
                            placeholder='Search'
                            name="search"
                            title="search"
                            value = {props?.feedSearch}
                            onChange={(e)=>{props.setFeedSearch(e?.target?.value)}}
                        />
                        <div className={'filter-item-container' + (selectedItem.length > 0 ? ' filled-items' : "")}>
                            {selectedItem.slice(0,1).map((item, idx) => (
                                <span className='feed-filter-item'>
                                    <i class={`cat ${item.color} fal ${item.icon} mr-1`}></i>
                                    {item.text}
                                    <button onClick={() => { removeFilterItem(item,idx) }} className='remove-filter-item'>
                                        <i className='fal fa-times feed-filter-bar-remove-icon'></i>
                                    </button>
                                </span>
                            ))}
                        </div>
                        <span  onClick={() => { setShowFilter(!showFilter) }}>
                            <i class="fal fa-filter right-icon curser" title='Filter'></i>
                            {selectedItem.length > 0 ? <small className='feed-filter-count'>{selectedItem.length}</small> : null}
                        </span>
                    </div>
                    <div className={'feed-filter-dropdown ' + (showFilter ? "show" : "")}>
                        <ul className='feed-filter-group'>
                            {selectedItem.map((item, idx) =>(
                                <li key={idx} className='feed-filter-item'>
                                    <i className={`cat ${item.color} fal ${item.icon}`}></i> {item.text} <i className='fal fa-times feed-filter-remove-icon' onClick={()=>{removeFilterItem(item, -1)}}></i>
                                </li>
                            ))}
                            {(tempFilterData.filter(item => !tempFilter.includes(item.text))).map((item) => (
                                <li onClick={() => { handleFilter(item) }} className='feed-filter-item'><i class={`cat ${item.color} fal ${item.icon}`}></i> {item.text}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='feed-tool-actions'>
                    <button
                        className='btn btn-white-bordered mr-2'
                        onClick={() => {props.setMessageId(0);}}
                        title="Show All Messages"
                    >
                        <i class="fal fa-eye"></i> Show All Messages
                    </button>
                    <button
                        className='btn btn-white-bordered mr-2'
                        onClick={() => { history.push(`/courseAdministration/coursesdetails/${id}/shared-files/show`) }}
                        title="Shared Files"
                    >
                        <i class="fal fa-folder-open"></i> Shared Files
                    </button>
                    <button
                        className={'btn btn-white-bordered ' + (showPinned ? " active" : "")}
                        title="Pinned Message"
                        onClick={() => { setShowPinned(!showPinned);props.setMessageId(0); }}
                    >
                        <i class="fal fa-thumbtack"></i>
                        Pinned Message
                    </button>
                </div>
            </div>
            {showPinned ? 
            pinnedMessages.length > 0 ?
            pinnedMessages.map((message, key) =>(
                <div className={`pinned-messages-block pinned-msg-container-${key}`} key={key} onClick={()=>{handleViewPinnedMessage(message)}}>
                    <div className='card pinned-msg-item'>
                        <div className='pinned-msg-left'title={message?.subject}>
                            <div className='pinned-msg-title'>
                                <span className='pinned-msg-title-head'>
                                    {message?.type == 2 ? 
                                    <>
                                    <i className="fal pin-icon fa-calendar"></i> <span>Pinned Class: </span> 
                                    </>
                                    : message?.type == 3 ?
                                    <>
                                    <i className="fal pin-icon fa-ballot-check"></i> <span>Pinned Assessment: </span> 
                                    </>
                                    : message?.type == 1 ?
                                    <>
                                    <i className="fal pin-icon fa-bell-exclamation"></i> <span>Pinned Notification: </span> 
                                    </>
                                    :<> 
                                    <i className="fal pin-icon fa-thumbtack"></i> <span>Pinned Message: </span> 
                                    </>} 
                                </span>
                                <span className='pinned-msg-title-content'>{message?.subject}</span> 
                            </div>
                        </div>
                        <div className='pinned-msg-right'>
                            <div className='pinned-msg-date'>
                                <i className="fal fa-calendar-alt"></i> {moment(message?.pin_date).format("DD MMM YY HH:mm")}
                            </div>
                            <div className="dropdown btn-dropdown-item">
                                <button title='More' className='pinned-msg-action dropdown-toggle' type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fal fa-ellipsis-v"></i>
                                </button>
                                <div className={`dropdown-menu pin-action-menu-${key}`} aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        <li className='curser pb-1' onClick={()=>{handleViewPinnedMessage(message)}}><i className="fal fa-comment"></i> View Message</li>
                                        <li className='curser' onClick={()=>{handleRemovePinnedMessage(message?.pinned_msg_id)}}><i className="fas fa-thumbtack"></i> Remove Pinned Message</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            ))
            :<p className='text-center'>No Pinned Messages</p>
            : null}
            {/* <div className='feed-msg-date or-line-post-ps'>
                <div className='or-line-post'>
                    <span className='post-by-time'>Wednesday, 22 Nov 22</span>
                </div>
            </div> */}
        </div>
    )
}
import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../utils/permissionGate";
import { RenderAssessmentType, RenderCourseType } from "../../../utils/CommonGroupingItem";
import { getGradeMixDropdown, getProgrameGradeDetails } from "../../../services/GradeSettingService";
import Str from "../../common/Str";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { handleTableScroll } from "../../../utils/commonFunction";
import Tablefilter from "../../common/Tablefilter";
import { GetGradeMixList } from "../../../services/GradeMixService";
import { GradeLetterTemplateList } from "../../../services/CourseService";
import $ from "jquery";
import Swal from "sweetalert2";
import axios from "axios";

const ProgrammeGradeMix = ({
  setTempCheckedAllData,
  tempCheckedAllData,
  setCheckedAllRows,
  checkedAllRows,
  courseId,
  setSelectedGradeMixTemplate,
  quizTotal,
  type
}) => {
  const history = useHistory()
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const { id, subtype } = useParams();
  const [search, setSearch] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1)
  const [sortOrder, setSortOrder] = useState("ASC")
  const [sortKey, setSortKey] = useState("name");
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [debouncedTerm, setDebouncedTerm] = useState("")
  const [assessmentIds, setAssessmentIds] = useState({ arr: [], checkObj: [] })
  const [linkedAssessmentDropdown, setLinkedAssessmentDropdown] = useState({ linkedAssessment: [], })

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
   if(subtype == "gradeMix") { 
    let response = hasPermission({
      scopes: ["pgmixview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const cancelTokenSources = [];

    const getGradeMixList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const apiData = {
        id: subtype == "gradeMix" ? id : courseId,
        type: subtype == "gradeMix" ? "programme" : "course",
        page,
        limit: perPage,
        key: sortKey,
        sort: sortOrder,
        exportStatus: subtype ? "true" : "false",
        search,
        viaAssessment: assessmentIds.arr
      };
  
      try {
        const res = await GetGradeMixList(apiData, source.token);
        if (res.status === 200) {
        let details = res.data?.data;
        let assessment_data = res?.data?.gradeMixList
        setAssessmentList(
          subtype ? assessment_data ?? [] : res.data.gradeMixList?.data ?? []
        );
        setTotalRows(subtype ? res.data?.courseAndAssessment?.length : res.data.gradeMixList.total)
        setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getGradeMixList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page, sortKey, sortOrder, search, id, perPage, assessmentIds]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        let data = {
          gradeMixIds: id,
          type: subtype == "gradeMix" ? "programme" : "course"
         }
        const res = await getGradeMixDropdown(data, dropdownSource.token);
        setLinkedAssessmentDropdown({...res.data, linkedAssessment: res?.data?.assessment ?  res?.data?.assessment : []})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const LinkedAssessmentCell = ({ row }) => {
    const [showAll, setShowAll] = useState(false);
    const maxItemsToShow = 2;
  
    const linkedAssessment = row?.getAssessmentlist || [];
    const visibleIds = showAll ? linkedAssessment : linkedAssessment.slice(0, maxItemsToShow);
  
    const toggleShowAll = () => {
      setShowAll(!showAll);
    };
  
    return (
      <div>
        {visibleIds.map((item, index) => (
          <div key={index}>
            <span title={item?.name ? item?.name : ""} className="overflow-ellipsis2 ">
          {item.name ? (
            <Link
              className="as-text-blue curser feature-name font-weight-bold"
              to={`/courseAdministration/coursesdetails/${item.intake}/assessments/open/assigned/Details/${item.assignment_id}`}
              onClick={() => {
                $("#grademixmodal").modal("hide");
              }}
            >
              <span className="textLimit100">{item?.name}</span>
            </Link>
          ) : (
            "-"
          )}
        </span>
        </div>
        ))}
        {linkedAssessment.length > maxItemsToShow && (
          <span className="see-more cursor-pointer as-text-blue" onClick={toggleShowAll}>
            {showAll ? 'See Less' : '...See More'}
          </span>
        )}
      </div>
    );
  };

  const handleSelect = async (e, row) => {
    const { checked, id, name, value } = e.target;
    const ls = [...checkedAllRows];
    let lsData = [...tempCheckedAllData];
    if (checked) {
      const data = {
        exportStatus: true,
      };
      GradeLetterTemplateList(data)
      .then(async (res) => {
        let templateGrdaeMixPassMarkArr = [];
        let tempObj = res.data.list_data?.find(
          (item) => item.id === row.grading_template_id
          );
          
          let templateGrdaeMixArray = tempObj?.gradeOptions?.length && tempObj?.gradeOptions.map(
            (item) => {
              templateGrdaeMixPassMarkArr.push({
                value: item?.id,
                label: item?.text,
              });
            }
            );
            ls.push(Number(id));
          lsData.push({...row, selectedTemplate: tempObj, templateOptions: templateGrdaeMixPassMarkArr});
          await setTempCheckedAllData([...lsData]);
          setCheckedAllRows([...ls]);
        })
        .catch((err) => {
          console.log(err);
          lsData.push({...row});
          ls.push(Number(id));
          setCheckedAllRows([...ls]);
          setTempCheckedAllData([...lsData]);
        });
      
    } else {
      const index = ls.indexOf(Number(id));
        ls.splice(index, 1);
          // const indexData = lsData?.indexOf((item) => item?.grade_id == row?.id);
          // lsData.splice(indexData, 1);
        lsData = lsData.filter(item => !(item?.grade_id == row?.id))
        await setTempCheckedAllData([...lsData]);
        setCheckedAllRows([...ls]);
    }
  }


  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <PermissionsGate scopes={['pgmixedit']} RenderError={() => <p>{row.name}</p>}>
           <span title={row?.name ? row.name : "-"} className="overflow-ellipsis2 ">
            {row.name ? (
              
              <Link
                className="as-text-blue curser feature-name"
                to={`/courseAdministration/gradeMix/${row?.qualification_id}/details/${row?.id}/open`}
                onClick={() => {
                  $("#grademixmodal").modal("hide");
                }}
              >
                <span className="textLimit100">{row?.name ? row.name : "-"}</span>
              </Link>
              
            ) : (
              "-"
            )}
          </span>
          </PermissionsGate>
        );
      },
    },
    {
        name: "Linked Assessment",
        selector: "id",
        sortField: "id",
        // sortable: true,
        cell: (row) => <LinkedAssessmentCell row={row} />,
    },
    {
      name: "SOR Name",
      selector: "sor_name",
      sortable: false,
      sortField: "sor_name",
      cell: (row) => (row?.sor_name ? row?.sor_name : "-"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {subtype == "gradeMix" ?<PermissionsGate
              scopes={["pgmixedit"]}
              RenderError={() => (
                <button className="btn btn-primary rounded-circle" title="Open" disabled>
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="mr-1"
                to={`/courseAdministration/gradeMix/${row?.qualification_id}/details/${row?.id}/open`}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate> : 
            (checkedAllRows.includes(row?.id) ? 
            <button
            type="button"
            className="btn btn-danger rounded-circle" title="Remove Grade Mix"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You want to unassign this grademix!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleSelect({target: { checked: false, id: row?.id }}, row)
                }
              }) 
            }}
            >
              <i className="fal fa-minus"></i>
            </button>
            : <button
            type="button"
            className="btn btn-primary rounded-circle" title="Add Grade Mix"
            onClick={() => {handleSelect({target: { checked: true, id: row?.id }}, row)}}
            >
              <i className="fal fa-plus"></i>
            </button>)
            }
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = {
      id: subtype == "gradeMix" ? id : courseId,
      type: subtype == "gradeMix" ? "programme" : "course",
      page,
      limit: perPage,
      key: sortKey,
      sort: sortOrder,
      exportStatus: "true",
      search,
      viaAssessment: assessmentIds.arr
    }
    GetGradeMixList(data)
      .then((res) => {
        let assessment_data = res.data.gradeMixList || [];
        const header = [
          "Name",
          "Linked Assessments",
          "SOR Name",
        ];
        const data = []
        assessment_data.map((gradeMix,index)=>{
          let gradeMixNames = {
            "Name": gradeMix.name ? gradeMix.name : "-",
            "Linked Assessments": "",
            "SOR Name": gradeMix.sor_name ? gradeMix.sor_name : "-"
          }
          data.push(gradeMixNames)
          gradeMix.getAssessmentlist.map((item,index)=>{
            let assessmentNames = {
              "Name": "",
              "Linked Assessments": item.name ? item.name : "-",
              "SOR Name": ""
            }
            data.push(assessmentNames)
          })
        })
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Name"],
              row["Linked Assessments"],
              row["SOR Name"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          return false;
        }
      }).catch((error) => {
        console.error("error :", error);
      });
  };

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("");
    setAssessmentIds({ arr: [], checkObj: [] })
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "AESC" : "DESC");
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };
    return (
      <div className="my-tickets-info-list Tickets-main-wrap">
          <div className={subtype != "gradeMix" ? "custom-table-div filter-search-icon card-table-custom course-table-filter-sec log-copy-table" : "custom-table-div filter-search-icon card card-table-custom log-copy-table"}>
            <div className="grade-setting-box">
              <div className="course-info-sec">
                <div className="edit-icon new-card-header d-block mb-0">
                  <div className="card-header">Grade Mix's {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div><br />
                  <div><i className="fal fa-info-circle mr-1"></i>The Grade mix module allows you to create and manage combinations of assessment grades . This will allow you to assign grade mix’s to assessments and manage their grade settings.</div>
                </div>
                <div className="card-body-inr card-body-info">
                  {/* <hr /> */}<p>&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={e => setDebouncedTerm(e.target.value)}
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                  <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                     filterName="Linked Assessments"
                     optionArr={linkedAssessmentDropdown.linkedAssessment}
                     state={assessmentIds}
                     setState={setAssessmentIds}
                     isSearchFilter={true}
                    />
                  </div>
                </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export spreadsheet"
                          onClick={() => {
                            exportData("xlsx", "Programme-Grade-settings");
                          }}
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export CSV"
                          onClick={() => {
                            exportData("csv", "Programme-Grade-settings");
                          }}
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export PDF"
                          onClick={() => {
                            exportData("pdf", "Programme-Grade-settings");
                          }}
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {subtype == "gradeMix" && <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-primary" title="Create New" disabled>
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )} scopes={["pgmixadd"]}>
                  <Link to={`/courseAdministration/Programmes/programme/open/${id}/gradeMix/add`}>
                    <button className="btn btn-primary" title="Create New">
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>

          </div>}
            </div>
            <DataTable
              columns={columns.map(item => item)}
              pagination={true}
              defaultSortField={sortKey}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              paginationDefaultPage={page}
              data={assessmentList}
              noDataComponent={Str.noRecord}
              onSort={handleSort}
              sortServer
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover={false}
              paginationPerPage={perPage}
              progressComponent={<SkeletonTicketList />}
              progressPending={loading}
            />
          </div>
      </div>    
      );
}

export default ProgrammeGradeMix

import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  ExamsNotRegistrationsList,
  ExamsNotRegistrationsListFilter,
  getFilterDetails,
  SendDraftQuickNotification,
  StudentQuickEmailNotification,
} from "../../../../../services/ExternalExamServices";

import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";

import Str from "../../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../../../utils/permissionGate";
import {ColorRender} from "../../../../systemadministration/usermanagement/CheckRole";
import { checkIfImageExists } from "../../../../../utils/commonFunction";
import { InitialRender } from "../../../../common/Helper";
import { FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT, IMAGE_URL } from "../../../../../utils/Constants";
import Hover from "../../../../common/Hover";
import { RenderExamRegistrationStatus, RenderProgrammeStudentStatus } from "../../../../../utils/CommonStatusItems";
import Tablefilter from "../../../../common/Tablefilter";
import { useFormik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import DatePicker from "react-datepicker";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import axios from "axios";

const NonRegistrationList = () => {
  const loc = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({arr: [], checkObj: {}});
  const [regStatus, setRegStatus] = useState({arr: [], checkObj: {}});
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [intakeYear, setIntakeYear] = useState([]);
  const [searchIntakeYear, setSearchIntakeYear] = useState({});
  const [intakeNumber, setIntakeNumber] = useState([]);
  const [searchIntakeNumber, setSearchIntakeNumber] = useState({});
  
  const [userData, setUserData] = useState([]);

  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("fullname");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [statusData, setStatusData] = useState([])
  const [regStatusData, setRegStatusData] = useState([])
  const [reasonData, setReasonData] = useState([])
  const [reason, setReason] = useState({ arr: [], checkObj: {} });
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [intakeArr, setIntakeArr] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [notificationFields, setNotificationFields] = useState({id: "",name: ""});
  const [disabled, setDisabled] = useState(false);
  const [attachmentName, setAttachmentName] = useState(false);
  const [attachmentFilePreview, setAttachmentFilePreview] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("")
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eenrview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateNonExamRegistrations"));
    if (storedFilters) {
      setStatus(storedFilters.status);
      setIntake(storedFilters.intake);
      setReason(storedFilters.reason);
      setRegStatus(storedFilters.regStatus);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      intake,
      reason,
      regStatus
    };
    localStorage.setItem("filterStateNonExamRegistrations", JSON.stringify(filterState));
  }, [  status, intake, reason, regStatus]);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () =>{
      setloading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);

      const exam = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exam: id,
        viaIntake: intake.arr,
        viaStatus:status.arr,
        viaDeclineReason:reason.arr,
        viaRegStatus:regStatus.arr,
      };

      try {
        const res = await ExamsNotRegistrationsList(exam, source.token);
        if(res.status==200){
          setUserData(res?.data?.data?.data);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }      
    }
    
    getData();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [page, perPage, sortkey, sortOrder, search, id,searchIntakeNumber,searchIntakeYear,intakeNumber,intakeYear,status,searchStatusCheck,intake, reason, regStatus]);

  useEffect(()=>{
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ExamsNotRegistrationsListFilter(id, filterSource.token);
        if(res.status==200){
          setStatusData(res.data.studentStatusFilter);
          setIntakeArr(res.data.yearsAndIntaksFilter);    
          setReasonData(res.data.reasonFilter);    
          setRegStatusData(res.data.regStatusFilter);    
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');    
    };

  },[])
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  
  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };


  // TODO Reset Filter

  const resetFilter = () => {
    setStatus({arr: [], checkObj: {}});
    setRegStatus({arr: [], checkObj: {}});
    setReason({arr: [], checkObj: {}});
    setSearchStatusCheck({});
    setIntakeNumber([]);
    setSearchIntakeNumber({});
    setIntakeYear([]);
    setIntake({ arr: [], checkObj: {} });
    setSearchIntakeYear({});
    setDebouncedTerm("")
    setSearch("");
  };

  const columns = useMemo(() => [
    {
      name: "Student Name",
      selector: "student",
      sortField: "fullname",
      sortable: true,
      cell: (row) => (
        <>
          {row.fullname ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender("Student")
                  }
                >
                  {row.photo &&
                  checkIfImageExists(
                    `${IMAGE_URL}/${row.photo.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    ).replaceAll("public/","")}`
                  ) ? (
                    <img
                      src={`${IMAGE_URL}/${row.photo.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      ).replaceAll("public/","")}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([
                      row.first_name.trim(),
                      row.last_name.trim(),
                    ])
                  )}
                  <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.first_name.trim()}
                    lastName={row.last_name.trim()}
                    photo={row.photo}
                    email={row.email}
                    mobile={row.mobile}
                    right={true}
                    role={"Student"}
                    status={row.status} 
                    activity_status={row.activity_status} 
                    showNumber={true} 
                    number={row.student_crm_id}
                  />
                </span>
                <PermissionsGate scopes={["eenredit"]} RenderError={() => (
                <p>{row?.fullname.trim() ? <span className="feature-exam_period"> <span className="textLimit100">{row.fullname}</span> </span> : "-"}</p>
              )}>
                <Link to={`/studentAdministration/students/open/${row?.id}/general`} className="as-text-blue curser" title={row?.fullname}>
                  <span className="textLimit100">{row.fullname}</span>
                </Link>
                </PermissionsGate>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Student Number",
      selector: "student_number",
      sortField: "student_number",
      sortable: true,
      cell: (row) => (row.student_number ? row.student_number : "-"),
    },
    {
      name: "Intake",
      selector: "sp_intake",
      sortField: "sp_intake",
      sortable: true,
      cell: (row) => (row.sp_intake ? row.sp_intake : "-"),
    },
    {
      name: "Registration Status",
      selector: "reg_status",
      sortField: "reg_status",
      sortable: true,
      cell: (row) => (row.reg_status ? RenderExamRegistrationStatus(row.reg_status).html : "N/A"),
    },
    {
      name: "Decline Reason",
      selector: "reg_status",
      sortField: "not_submit_reason",
      sortable: true,
      cell: (row) => (row.not_submit_reason && reasonData.length ? reasonData.find(item => item.value == row.not_submit_reason).label : "N/A"),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.status ? (
          RenderProgrammeStudentStatus(row.status).html
        ) : (
          <p className="cat">
            <i className="fas fa-circle mr-1"></i> -
          </p>
        ),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={["eenredit"]} RenderError={()=>(
                  <button className="btn btn-primary rounded-circle mr-1" title="Open" disabled>                    
                    <i className="fal fa-folder-open"></i>                 
                  </button>
                )}>
              <Link to={`/studentAdministration/students/open/${row?.id}/general`}>
                <button
                  title="Open"
                  className="btn btn-primary rounded-circle mr-1"
                  // onClick={() => handleEdit(row.id)}
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>
            <PermissionsGate scopes={["eenradd"]} RenderError={()=>(
                  <button className="btn btn-primary rounded-circle" title="Quick Notification" disabled>                    
                    <i className="fal fa-bell"></i>
                  </button>
                )}>
            <button
                  title="Quick Notification"
                  className="btn btn-primary rounded-circle"
                  onClick={() => handleNotification(row?.id,row?.fullname)}
                >
                  <i className="fal fa-bell"></i>
                </button>
                </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const handleNotification = (id,fullname) => {
    $("#newNotification").modal("show");
    setNotificationFields({id: id,name: fullname});
    setSelectedFile("");
    setEditorState("");
  };


  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = []

    const header = [
      "Student Name",
      "Student Number",
      "Intake",
      "Registration Status",
      "Decline Reason",
      "Status",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const exam = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exam: id,
      viaIntake: intake.arr,
      viaStatus:status.arr,
      viaDeclineReason:reason.arr,
      viaRegStatus:regStatus.arr,
      exportStatus:"true"
    };
    ExamsNotRegistrationsList(exam)
      .then((res) => {
        data=res.data.data;
        data = data?.map((row) => ({
          ...row,
          "Student Name": row?.fullname ? row?.fullname.trim() : "-",
          "Student Number": row?.student_number ? row?.student_number : "-",
          Intake: row?.sp_intake? row.sp_intake : "-",
          "Registration Status": row.reg_status ? row.reg_status : "-",
          "Decline Reason": row.not_submit_reason && reasonData.length ? reasonData.find(item => item.value == row.not_submit_reason).label : "N/A",
          Status: row.status ? row.status : "-",
        }));
        // console.log(typeof data);
        // console.log(typeof result);
    
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          // console.log(csvString);
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student Name"],
              row["Student Number"],
              row.Intake,
              row["Registration Status"],
              row["Decline Reason"],
              row.Status,
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
    
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });

   
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notificationTo: notificationFields.name,
      notificationToId: notificationFields.id,
      sentGroups:{
        EmailNotification: 0,
        PushNotification: 0,
      },
      SendWhen: 0,
      SendWhenDate: "",
      title: "",
      content: "",
      FileAttachment: "",
      IsDraft: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().trim("Name cannot include leading and trailing spaces").required("Title is required"),
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          })
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"]
        ]
      ),
      SendWhenDate: Yup.string()
      .when("SendWhen", {
        is: 2,
        then: Yup.string().required("Date And Time is required"),
      }),
      content: Yup.string()
      .test("required", "Content is required", (value) => {
        if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
          return false
        }
        if (!editorState) {
          return false
        }
        return true;
      })
      .trim()
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      // console.log("popup values->>>>>>",values,editorState);
      formData.append("student_id", values.notificationToId);
      formData.append("subject", values.title);
      formData.append("publish_date", values.SendWhen === 2 ? Math.floor(new Date(values.SendWhenDate).getTime()/1000) : "");
      formData.append("publish", values.SendWhen === 2 ? 0 : 1);
      formData.append("isEmailActive", values.sentGroups.EmailNotification ? 1 : 0);
      formData.append("isPushnotificationActive", values.sentGroups.PushNotification ? 1 : 0);
      formData.append("draft_notification", values.IsDraft);
      formData.append("usmsg", editorState);
      formData.append("attachment",values.FileAttachment);

      SendDraftQuickNotification(formData)
        .then((data) => {
          if (data.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            setDisabled(false);
            cancelQuickNotification();
          }
        })
        .catch((error) => {
          setDisabled(false);
          console.log("error ---> ", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data[0].message,
          });
        });
    },
  });
  function cancelQuickNotification() {
    $("#newNotification").modal("hide");
    formik.resetForm();
    setNotificationFields({ id: "", name: "" });
    setEditorState("");
    setSelectedFile("");
    setAttachmentFilePreview("");
    setAttachmentName(false);
  }
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
    <div className="my-tickets-info-list Tickets-main-wrap right-align-action">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={e => setDebouncedTerm(e.target.value)} 
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Student Status"
                      optionArr={statusData}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderProgrammeStudentStatus}
                    />
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={intakeArr}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName={"Decline Reason"}
                      optionArr={reasonData}
                      state={reason}
                      setState={setReason}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName={"Registration Status"}
                      optionArr={regStatusData}
                      state={regStatus}
                      setState={setRegStatus}
                      isOptionReversed={true}
                      renderLabelFunction={RenderExamRegistrationStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "NonRegistration");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "NonRegistration");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "NonRegistration");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            data={userData}
            defaultSortField="student"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
          />
      </div>
    </div>
      {/* popup modal */}
      <div
        className="overflow-auto topic-add-modal modal fade"
        id="newNotification"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-bell"></i> New Notification
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => cancelQuickNotification()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Notification To</label>
                          <input
                            type="text"
                            className={"form-control form-control-aatch-file"}
                            disabled
                            title="Notification To"
                            value={formik.values.notificationTo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Send Via *</label>
                          <div className="form-group-blk mb-3">
                            <label></label>
                            <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                              <input
                                type="checkbox"
                                title="Email"
                                className="custom-control-input"
                                id="EmailNotification"
                                name="select"
                                checked={formik.values.sentGroups.EmailNotification}
                                onChange={(e) =>
                                  formik.setFieldValue("sentGroups.EmailNotification", e.target.checked)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="EmailNotification"
                              >
                                Email
                              </label>
                            </div>
                            <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                              <input
                                type="checkbox"
                                title="Push Notification"
                                className="custom-control-input"
                                id="PushNotification"
                                name="select"
                                checked={formik.values.sentGroups.PushNotification}
                                onChange={(e) =>
                                  formik.setFieldValue("sentGroups.PushNotification", e.target.checked)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="PushNotification"
                              >
                                Push Notification
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Send When *</label>
                          <div className="form-icon-group" title="SendWhen">
                            <div className="custon_rd_new">
                              <div
                                class="custom-control custom-radio custom-control-inline"
                                title="Now"
                              >
                                <input
                                  type="radio"
                                  id="customRadioInline1"
                                  name="customRadioInline"
                                  class="custom-control-input"
                                  checked={formik.values.SendWhen == 0}
                                  onChange={() => formik.setFieldValue("SendWhen", 0)}
                                  onBlur={formik.handleBlur}
                                />
                                <label class="custom-control-label" for="customRadioInline1">
                                  Now
                                </label>
                              </div>
                              <div
                                class="custom-control custom-radio custom-control-inline"
                                title="Later"
                              >
                                <input
                                  type="radio"
                                  id="customRadioInline2"
                                  name="customRadioInline"
                                  class="custom-control-input"
                                  checked={!formik.values.SendWhen == 0}
                                  onChange={() => formik.setFieldValue("SendWhen", 2)}
                                  onBlur={formik.handleBlur}
                                />
                                <label class="custom-control-label" for="customRadioInline2">
                                  Later
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!formik.values.SendWhen == 0 && <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Date *</label>
                          <div className="form-group-blk mb-3">
                            <DatePicker
                              selected={formik.values.SendWhenDate}
                              onChange={(date) => {
                                formik.setFieldValue("SendWhenDate", date);
                              }}
                              filterTime={filterPassedTime}
                              className={
                                "form-control cursor-pointer" +
                                (formik.errors.SendWhenDate && formik.touched.SendWhenDate
                                  ? " is-invalid"
                                  : "")
                              }
                              title="Date"
                              onBlur={formik.handleBlur}
                              placeholderText="Enter Date"
                              showYearDropdown
                              showTimeSelect
                              scrollableYearDropdown
                              dateFormatCalendar="MMMM"
                              dateFormat={FORM_DATE_TIME_FORMAT}
                              timeFormat={FORM_TIME_FORMAT}
                              minDate={new Date()}
                            />
                          </div>
                          {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>}
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Title *</label>
                          <input
                            type="text"
                            title="Title"
                            name="title"
                            className={"form-control form-control-aatch-file" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")}
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Content *</label>
                          <HtmlInputEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            hideSign={true}
                            isCKEditor={true}
                            isInvalid={formik.touched.content && formik.errors.content}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment</label>
                          <div className="form-group atttach-file m-0 d-flex align-items-center">
                            <label
                              className={
                                formik.errors.FileAttachment &&
                                formik.touched.FileAttachment &&
                                "file-req is-invalid"
                              }
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                accept={"image/*, .docx, .doc, .xlsx, .pdf, .zip"}
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    formik.setFieldValue("FileAttachment",e.target.files[0]);
                                    setAttachmentName(e.target.files[0].name);
                                    setAttachmentFilePreview(URL.createObjectURL(e.target.files[0]));
                                  }
                                }}
                                placeholder="File"
                              />
                            </label>
                          </div>
                          {attachmentName && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      href={attachmentFilePreview}
                                      title={attachmentName}
                                      target="_blank">
                                      <i className="fal fa-file"></i>
                                      &nbsp;{attachmentName}
                                    </a>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setAttachmentFilePreview("");
                                        setAttachmentName(false);
                                      }}
                                    ><i className="fal fa-trash-alt"></i></button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-save btn-success"
                      type="submit"
                      title="Save Draft"
                      onClick={() => formik.setFieldValue("IsDraft", 1)}
                    >
                      {disabled && formik.values.IsDraft == 1 ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-save"></i>
                      )}
                      Save Draft
                    </button>
                    <button
                      type="submit"
                      title="Send"
                      className="btn btn-primary"
                      disabled={disabled}
                      onClick={() => formik.setFieldValue("IsDraft", 0)}
                    >
                      {disabled && formik.values.IsDraft == 0 ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fas fa-location-arrow"></i>
                      )}
                      Send
                    </button>
                    <button
                      type="button"
                      title="Close"
                      className="btn btn-danger"
                      onClick={() => cancelQuickNotification()}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {Object.keys(formik.values).map(key => {
                      if (formik.touched[key] && formik.errors[key]) {
                        if (key == "sentGroups") {
                          return (
                            <div className="invalid-feedback d-block">
                              {formik.errors[key].EmailNotification}
                            </div>
                          )

                        }
                        return (
                          <div className="invalid-feedback d-block">
                            {formik.errors[key]}
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonRegistrationList;

import React, { useEffect, useState, useCallback } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  getBrandTemplate,
  updateLaptopPricing
} from "../../../../services/BrandTemplateServices";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FileUploadField, FormField } from "../../../common/FormFields";
import axios from "axios";
import { GetNetsuiteItemsCancelToken } from "../../../../services/CourseService";

const LaptopPricing = () => {
  const { subId, subType } = useParams();
  const history = useHistory();
  const [brandData, setBrandData] = useState({});
  const [netsuiteList, setNetsuiteList] = useState([]);
  const [editorStates, setEditorStates] = useState({
    core_specifications: "",
    med_specifications: "",
    high_specifications: "",
    disclaimer_tc: ""
  });



  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken?.source();
      cancelTokenSources.push(source);
      try {
        const data = await GetNetsuiteItemsCancelToken(
          '',
          source.token
        );
        if (data.status === 200) {
          setNetsuiteList(
            data?.data?.data?.items
              ? data?.data?.data?.items?.map((el) => { return { "label": el?.displayname, "value": el?.id } })
              : []
          );
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log('error :', error);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('Component unmounted');
      });
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBrandTemplate(subId);
        const data = res.data?.getLaptopPricingData;
        setBrandData(data);
        setEditorStates({
          core_specifications: data?.core_specifications || "<p><p/>",
          med_specifications: data?.med_specifications || "<p><p/>",
          high_specifications: data?.high_specifications || "<p><p/>",
          disclaimer_tc: data?.disclaimer_tc || "<p><p/>"
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (subId) {
      fetchData();
    }
  }, [subId]);

  const generateInitialValues = (data) => ({
    core_laptop_model: data?.core_laptop_model || "",
    core_fee: data?.core_fee || 0,
    core_dep: data?.core_dep || 0,
    core_short_dec: data?.core_short_dec || "",
    core_specifications: data?.core_specifications || "",
    core_image: data?.core_image || "",
    med_laptop_model: data?.med_laptop_model || "",
    med_fee: data?.med_fee || 0,
    med_dep: data?.med_dep || 0,
    med_short_dec: data?.med_short_dec || "",
    med_specifications: data?.med_specifications || "",
    med_image: data?.med_image || "",
    high_laptop_model: data?.high_laptop_model || "",
    high_fee: data?.high_fee || 0,
    high_dep: data?.high_dep || 0,
    high_short_dec: data?.high_short_dec || "",
    high_specifications: data?.high_specifications || "",
    high_image: data?.high_image || "",
    disclaimer_tc: data?.disclaimer_tc || "",
    core_netsuite_product_id: data?.core_netsuite_product_id || "",
    med_netsuite_product_id: data?.med_netsuite_product_id || "",
    high_netsuite_product_id: data?.high_netsuite_product_id || "",
  });

  const validationSchema = Yup.object({
    core_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
    core_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
    core_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(9999, 'The maximum deposit is 9999')
      .required("Laptop Deposit is required"),
    core_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
    core_specifications: Yup.string().required("Specifications are required"),
    core_image: Yup.string().required("Image is required"),
    med_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
    med_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
    med_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(9999, 'The maximum deposit is 9999')
      .required("Laptop Deposit is required"),
    med_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
    med_specifications: Yup.string().required("Specifications are required"),
    med_image: Yup.string().required("Image is required"),
    high_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
    high_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
    high_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(9999, 'The maximum deposit is 9999')
      .required("Laptop Deposit is required"),
    high_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
    high_specifications: Yup.string().required("Specifications are required"),
    high_image: Yup.string().required("Image is required"),
    disclaimer_tc: Yup.string().required("Terms and Conditions are required"),
    core_netsuite_product_id: Yup.string().required("Core NetSuite Product is required"),
    med_netsuite_product_id: Yup.string().required("Medium NetSuite Product is required"),
    high_netsuite_product_id: Yup.string().required("High NetSuite Product is required"),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues(brandData),
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });

        if (subType === "update") {
          formData.append("brand_Id", subId);
          await updateLaptopPricing(formData);
          Swal.fire("Success!", "Laptop Pricing updated successfully!", "success");
        }
        history.push("/websiteManagement/siteSetup/brand_Templates");
      } catch (error) {
        Swal.fire("Error!", `Failed to update Laptop Pricing`, "error");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleEditorChange = (key, value) => {
    setEditorStates((prevStates) => ({
      ...prevStates,
      [key]: value
    }));
    formik.setFieldValue(key, value);
  };

  const renderSection = useCallback(
    (section, label, index) => (
      <>
        <div className="col-md-12">
          <div className="edit-icon new-card-header">
            <h4 className="card-header"><FontAwesomeIcon icon={faInfoCircle} color="var(--topbar-color)" /> {label} Specification Details</h4>
          </div>
        </div>
        <FormField field={{ name: `${section}_laptop_model`, label: "Laptop Model" }} formik={formik} col_md={3} col_lg={3} />
        <FormField field={{ name: `${section}_netsuite_product_id`, label: "NetSuite Product" }} selectOptions={netsuiteList} formik={formik} col_md={3} col_lg={3} />
        <FormField field={{ name: `${section}_fee`, label: "Laptop Fee", type: "number", sign: true }} formik={formik} col_md={3} col_lg={2} />
        <FormField field={{ name: `${section}_dep`, label: "Laptop Deposit", type: "number", sign: true }} formik={formik} col_md={3} col_lg={2} />
        <FileUploadField name={`${section}_image`} label="Image *" formik={formik} accept=".png,.jpg,.jpeg" col_md={3} col_lg={2} />
        <FormField field={{ name: `${section}_short_dec`, label: "Short Description" }} formik={formik} col_md={12} col_lg={12} />
        <div className="col-md-12">
          <label htmlFor={`${section}_specifications`}>Specifications *</label>
          <div className="form-icon-group mb-6 laptop-pricing">
            <HtmlInputEditor
              editorState={editorStates[`${section}_specifications`]}
              setEditorState={(value) => handleEditorChange(`${section}_specifications`, value)}
              isInValid={formik.errors[`${section}_specifications`]}
              isCKEditor={true}
              hideSign={true}
            />
          </div>
        </div>
      </>
    ),
    [formik, editorStates]
  );

  return (
    <div className="card card-body-inr">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            {renderSection("core", "Core", 1)}
            {renderSection("med", "Medium", 2)}
            {renderSection("high", "High", 3)}
            <div className="col-md-12">
              <label htmlFor={"disclaimer_tc"}>Disclaimer/Terms & Conditions*</label>
              <div className="form-icon-group mb-6 laptop-pricing">
                <HtmlInputEditor
                  editorState={editorStates.disclaimer_tc}
                  setEditorState={(value) => handleEditorChange("disclaimer_tc", value)}
                  isInValid={formik.errors.disclaimer_tc}
                  isCKEditor={true}
                  hideSign={true}
                />
              </div>
            </div>
          </div>
          <div className="form-group form-group-save-cancel">
            <button className="btn btn-save btn-success" disabled={formik.isSubmitting} type="submit" title="Save">
              {formik.isSubmitting ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )} Save
            </button>
            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                );
              }
            })}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default LaptopPricing;

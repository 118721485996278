import React, { useEffect, useState } from 'react';
import Select from "react-select";
import HtmlParser from 'react-html-parser';
import { GetCertificatesFonts } from '../../../../services/CertificateServices';

const RepositionModal = ({
  closeModal,
  dragPositions,
  onChange,
  onSave,
  onClose,
  editableIndex,
  originalValues = {},
  setPosition
}) => {

  const [fontFamilyOptions, setFontFamilyOptions] = useState([]);

  useEffect(() => {
    GetCertificatesFonts().then(res => {
      let arr = []
      res.data.forEach(item => {
        arr.push({
          ...item,
          slug: item.label
          // label: HtmlParser(`<span style='font-family: ${item.label};' title="${item.label}">${item.label}  </span> `)
        })
      })
      setFontFamilyOptions(arr)
    }).catch((error) => console.error("error :", error))
  }, [])
  
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const handleSave = () => {
    onSave(editableIndex);
  };

  const handleClose = async () => {
    setPosition({...originalValues[editableIndex], maxWidth: ''})
    onClose(editableIndex);
  };

  const handleFontChange = (e) => {
    // dragPositions.fontSize = '';
    onChange(e.target.value, true, false, editableIndex, false);
  };

  const handleMaxWidthChange = (e) => {
    onChange(e.target.value, false, false, editableIndex, false, true);
  };

  const handleFontFamilyChange = (value) => {
    onChange(value, false, false, editableIndex, true);
  };

  const handleColorChange = (e) => {
    onChange(e.target.value, false, true, editableIndex, false);
  };
  const customOptionLabel = ({ value, label, ...rest }) => {
    return (
      <div {...rest}>
        <span style={{fontFamily: label}} title={label}>{label}</span>
      </div>
    );
  };

  return (
    <div>
      {/* Removed the button for opening the modal */}
      <div className="reposition-modal-overlay sticky-save-footer" onClick={handleOverlayClick}>
        <div className="modal-content">
          <div className="modal-header modal-header-custom">
            <h5 className="modal-title">
              <i className="fal fa-edit"></i> Edit Elements
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="t p-30px">
            <div className="row">
              {/* Font Size Input */}
              <div className="col-md-2 col-lg-2">
                <label htmlFor="fontSize">Font Size:</label>
                <div className="form-icon-group">
                  <input
                    type="text"
                    id="fontSize"
                    className="form-control"
                    name="fontSize"
                    onChange={handleFontChange}
                    value={dragPositions.fontSize}
                    title="fontSize"
                  />
                </div>
              </div>

              {/* Font Family Input */}
              <div className="col-md-2 col-lg-2">
                <label htmlFor="fontFamily">Font Family:</label>
                <div className="">
                  <div className="form-icon-group mb-4 ">
                    <Select
                      formatOptionLabel={customOptionLabel}
                      className="form-control custom-select-box"
                      name="fontFamily"
                      value={fontFamilyOptions.filter((val) => {
                        return val.slug == (dragPositions.fontFamily?.slug ? dragPositions.fontFamily.slug : dragPositions.fontFamily)
                      })}
                      onChange={(selectedOption) => {
                        handleFontFamilyChange(selectedOption)
                      }}
                      menuPlacement="top"
                      isClearable
                      options={fontFamilyOptions}
                      maxMenuHeight={175}
                      placeholder={
                        dragPositions.fontFamily?.slug
                          ? dragPositions.fontFamily?.slug
                          : "Select"
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Max Width Input */}
              {editableIndex !== "certificate_date" ? <div className="col-md-2 col-lg-2">
                <label htmlFor="maxWidth">Max Width:</label>
                <div className="form-icon-group">
                  <input
                    type="number"
                    id="maxWidth"
                    className="form-control"
                    name="maxWidth"
                    onChange={handleMaxWidthChange}
                    value={dragPositions.maxWidth}
                    title="maxWidth"
                  />
                </div>
              </div> : ""}

              {/* Font Color Input */}
              <div className="col-md-2 col-lg-2">
                <label htmlFor="fontColor">Font Color:</label>
                <div className="form-icon-group">
                  <input
                    type="color"
                    id="fontColor"
                    className="form-control"
                    name="fontColor"
                    onChange={handleColorChange}
                    value={dragPositions.fontColor}
                    title="fontColor"
                  />
                </div>
              </div>

              {/* Position X Input */}
              <div className="col-md-2 col-lg-2">
                <label htmlFor="positionX">Position X:</label>
                <div className="form-icon-group">
                  <input
                    type="text"
                    id="positionX"
                    className="form-control"
                    name="positionX"
                    value={dragPositions.position_x}
                    disabled
                    title="positionX"
                  />
                </div>
              </div>

              {/* Position Y Input */}
              <div className="col-md-2 col-lg-2">
                <label htmlFor="positionY">Position Y:</label>
                <div className="form-icon-group">
                  <input
                    type="text"
                    id="positionY"
                    className="form-control"
                    name="positionY"
                    value={dragPositions.position_y}
                    disabled
                    title="positionY"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="col-md-2 col-lg-2">
                <div className="form-group form-group-save-cancel mt-4">
                  <button type="submit" className="btn btn-save btn-success" title="Save" onClick={handleSave}>
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                  <button type="reset" title="Cancel" className="btn btn-danger" data-dismiss="modal" onClick={handleClose}>
                    <i className="fal fa-times"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepositionModal;

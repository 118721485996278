/**
 * This is the entire logic and UI for repositioning the elements on the certificate template.
 * The Respositioning is the Parent component with ImageTemplate as the child component.
 * The ImageTemplate component is a wrapper for the CertificateGenerateContainer component.
 * The CertificateGenerateContainer component is where the draggable elements are located.
 * Repositing Modal is another component that is used in the CertificateGenerateContainer component.
 * Repositing Modal is reponsible for dynamic fontSize and font Color changes
 * The logic of these three componenets is slighlty complex as it uses a callback to pass values from the child to the parent component.
 * the entire Logic is well structured.
 * the handleSave function is the heart of this component
 * as it is responsible for saving the certificate template with the desired fontSize, fontColor and position of the elements.
 **/

import React, { useState, useEffect } from 'react';
import ImageTemplate from './Certificate Template Image/ImageTemplate';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  saveCertificateTemplate,
} from '../../../services/CertificateServices';
import { IMAGE_URL } from '../../../utils/Constants';

const Repositioning = ({ response }) => {
  const history = useHistory();
  const { id } = useParams();
  const [saveLoading, setSaveLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [imageContainerSize, setImageContainerSize] = useState({
    width: '',
    height: '',
  });
  const [showRepositioningElements, setShowRepositioningElements] = useState({
    ShowStudentName: null,
    ShowCourseName: null,
    ShowDate: null,
  });
  const [studentNamePosition, setStudentNamePosition] = useState({
    position_x: 200,
    position_y: 200,
    fontSize: '40pt',
    fontColor: '#000000',
    fontFamily: { slug: 'Courier' },
    maxWidth: '',
  });
  const [courseNamePosition, setCourseNamePosition] = useState({
    position_x: 200,
    position_y: 300,
    fontSize: '20pt',
    fontColor: '#000000',
    fontFamily: { slug: 'Courier' },
    maxWidth: '',
  });
  const [certificateDatePosition, setCertificateDatePosition] = useState({
    position_x: 350,
    position_y: 400,
    fontSize: '20pt',
    fontColor: '#000000',
    fontFamily: { slug: 'Courier' },
  });
  const [originalValues, setOriginalValues] = useState({
    student_name: {
      position_x: 200,
      position_y: 200,
      fontSize: '40pt',
      fontColor: '#000000',
      fontFamily: { slug: 'Courier' },
      maxWidth: '',
    },
    course_name: {
      position_x: 200,
      position_y: 300,
      fontSize: '20pt',
      fontColor: '#000000',
      fontFamily: { slug: 'Courier' },
      maxWidth: '',
    },
    certificate_date: {
      position_x: 350,
      position_y: 400,
      fontSize: '20pt',
      fontColor: '#000000',
      fontFamily: { slug: 'Courier' },
    }
  })

  // Fetching the certificate template data
  useEffect(() => {
    const fetchImageData = async () => {
      try {
        // const certificateTemplate = await getCertificateTemplateById(id);
        const {
          BackgroundImage,
          StylingDetails,
          Size,
          ShowCourseName,
          ShowStudentName,
          ShowDate,
        } = response;
        setBackgroundImage(IMAGE_URL + '/' + BackgroundImage.toString());

        setShowRepositioningElements({
          ShowStudentName: ShowStudentName,
          ShowCourseName: ShowCourseName,
          ShowDate: ShowDate,
        });
        if (StylingDetails && Object.keys(StylingDetails).length > 0) {
          const parsedBody = JSON.parse(StylingDetails);
          const {
            StudentNameDetails,
            CourseNameDetails,
            CertificateDateDetails,
          } = parsedBody;
          setOriginalValues({ student_name: StudentNameDetails, course_name: CourseNameDetails, certificate_date: CertificateDateDetails })
          setStudentNamePosition({
            position_x: StudentNameDetails.positionX,
            position_y: StudentNameDetails.positionY,
            fontSize: StudentNameDetails.fontSize,
            fontColor: StudentNameDetails.fontColor,
            fontFamily: StudentNameDetails.fontFamily,
            maxWidth: StudentNameDetails.maxWidth,
          });
          setCourseNamePosition({
            position_x: CourseNameDetails.positionX,
            position_y: CourseNameDetails.positionY,
            fontSize: CourseNameDetails.fontSize,
            fontColor: CourseNameDetails.fontColor,
            fontFamily: CourseNameDetails.fontFamily,
            maxWidth: CourseNameDetails.maxWidth,
          });
          setCertificateDatePosition({
            position_x: CertificateDateDetails.positionX,
            position_y: CertificateDateDetails.positionY,
            fontSize: CertificateDateDetails.fontSize,
            fontColor: CertificateDateDetails.fontColor,
            fontFamily: CertificateDateDetails.fontFamily,
          });
        } else if (Size === 'A4 Portrait') {
          setCertificateDatePosition({
            ...certificateDatePosition,
            position_x: 250
          })
        }
        if (Size === 'A4 Landscape') {
          setImageContainerSize({
            width: '842pt',
            height: '595pt',
          });
        } else if (Size === 'A4 Portrait') {
          setImageContainerSize({
            width: '595pt',
            height: '842pt',
          });
        }
      } catch (error) {
        console.error('Error fetching image data:', error);
      }
    };
    if (Object.keys(response).length) {
      fetchImageData();
    }
  }, [id, response]);

  // this function is responsible for handling the drag event
  // the elementRef is used to identify which element is being dragged
  // this function calaculates the position of the element that is being dragged
  // it performs callback to get the position of the elementRef from the CertificateGenerateContainer component
  const handleDrag = (e, ui, elementRef) => {
    if (elementRef === 'student_name') {
      const newPosition = studentNamePosition.position_y + ui.deltaY
      const minPosition = Number(studentNamePosition.fontSize.replace("pt", '')) / 2
      const maxPosition = Number(imageContainerSize.height.replace("pt", '')) - minPosition
      if (((newPosition < minPosition && Math.sign(ui.deltaY) === -1) || (newPosition > maxPosition && Math.sign(ui.deltaY) === 1))) {
        return
      }
      setStudentNamePosition({
        ...studentNamePosition,
        position_x: studentNamePosition.position_x + ui.deltaX,
        position_y: studentNamePosition.position_y + ui.deltaY,
      });
    } else if (elementRef === 'course_name') {
      const newPosition = courseNamePosition.position_y + ui.deltaY
      const minPosition = Number(courseNamePosition.fontSize.replace("pt", '')) / 2
      const maxPosition = Number(imageContainerSize.height.replace("pt", '')) - minPosition
      if (((newPosition < minPosition && Math.sign(ui.deltaY) === -1) || (newPosition > maxPosition && Math.sign(ui.deltaY) === 1))) {
        return
      }
      setCourseNamePosition({
        ...courseNamePosition,
        position_x: courseNamePosition.position_x + ui.deltaX,
        position_y: courseNamePosition.position_y + ui.deltaY,
      });
    } else if (elementRef === 'certificate_date') {
      const newPositionY = certificateDatePosition.position_y + ui.deltaY
      const minPositionY = Number(certificateDatePosition.fontSize.replace("pt", '')) / 2
      const maxPositionY = Number(imageContainerSize.height.replace("pt", '')) - minPositionY
      if (((newPositionY < minPositionY && Math.sign(ui.deltaY) === -1) || (newPositionY > maxPositionY && Math.sign(ui.deltaY) === 1))) {
        return
      }
      const newPositionX = certificateDatePosition.position_x + ui.deltaX
      const minPositionX = Number(certificateDatePosition.fontSize.replace("pt", '')) - 20
      const maxPositionX = Number(imageContainerSize.width.replace("pt", '')) - 100
      if (((newPositionX < minPositionX && Math.sign(ui.deltaX) === -1) || (newPositionX > maxPositionX && Math.sign(ui.deltaX) === 1))) {
        return
      }
      setCertificateDatePosition({
        ...certificateDatePosition,
        position_x: certificateDatePosition.position_x + ui.deltaX,
        position_y: certificateDatePosition.position_y + ui.deltaY,
      });
    }
  };

  // the handleChange function is responsible for handling the changes in fontSize and fontColor
  // it performs callback to three levels to RepositioningModal to get the desired fontSize and fontColor

  const handleChange = (
    value,
    isFontSizeChanged,
    isFontColorChanged,
    editableIndexValue,
    isFontFamilyChanged,
    isMaxWidthChanged
  ) => {
    if (isFontSizeChanged) {
      if (editableIndexValue === 'student_name') {
        setStudentNamePosition({
          ...studentNamePosition,
          fontSize: value,
        });
      } else if (editableIndexValue === 'course_name') {
        setCourseNamePosition({
          ...courseNamePosition,
          fontSize: value,
        });
      } else if (editableIndexValue === 'certificate_date') {
        setCertificateDatePosition({
          ...certificateDatePosition,
          fontSize: value,
        });
      }
    } else if (isFontColorChanged) {
      if (editableIndexValue === 'student_name') {
        setStudentNamePosition({
          ...studentNamePosition,
          fontColor: value,
        });
      } else if (editableIndexValue === 'course_name') {
        setCourseNamePosition({
          ...courseNamePosition,
          fontColor: value,
        });
      } else if (editableIndexValue === 'certificate_date') {
        setCertificateDatePosition({
          ...certificateDatePosition,
          fontColor: value,
        });
      }
    } else if (isFontFamilyChanged) {
      if (editableIndexValue === 'student_name') {
        setStudentNamePosition({
          ...studentNamePosition,
          fontFamily: value,
        });
      } else if (editableIndexValue === 'course_name') {
        setCourseNamePosition({
          ...courseNamePosition,
          fontFamily: value,
        });
      } else if (editableIndexValue === 'certificate_date') {
        setCertificateDatePosition({
          ...certificateDatePosition,
          fontFamily: value,
        });
      }
    } else if (isMaxWidthChanged) {
      if (editableIndexValue === 'student_name') {
        setStudentNamePosition({
          ...studentNamePosition,
          maxWidth: value,
        });
      } else if (editableIndexValue === 'course_name') {
        setCourseNamePosition({
          ...courseNamePosition,
          maxWidth: value,
        });
      }
    }
  };

  // this function is responsible for fetching the image from the server and converting it to a blob
  const fetchImageAndConvertToBlob = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blobImage = await response.blob();
      const file = new File([blobImage], 'image.png', { type: 'image/png' });
      return file;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };
  // this is the main function that is responsible for saving the certificate template
  // with desired fontSize, fontColor and position of the elements
  // we are sending only those elements that are being repositioned

  const handleSave = async () => {
    setSaveLoading(true)
    try {
      const StylingDetails = {
        StudentNameDetails: showRepositioningElements.ShowStudentName
          ? {
            positionX: studentNamePosition.position_x,
            positionY: studentNamePosition.position_y,
            fontSize: studentNamePosition.fontSize,
            fontColor: studentNamePosition.fontColor,
            fontFamily: { ...studentNamePosition.fontFamily, label: studentNamePosition.fontFamily.slug },
            maxWidth: studentNamePosition.maxWidth,
          }
          : {
            positionX: 200,
            positionY: 200,
            fontSize: '20pt',
            fontColor: '#000000',
          },
        CourseNameDetails: showRepositioningElements.ShowCourseName
          ? {
            positionX: courseNamePosition.position_x,
            positionY: courseNamePosition.position_y,
            fontSize: courseNamePosition.fontSize,
            fontColor: courseNamePosition.fontColor,
            fontFamily: { ...courseNamePosition.fontFamily, label: courseNamePosition.fontFamily.slug },
            maxWidth: courseNamePosition.maxWidth,
          }
          : {
            positionX: 200,
            positionY: 300,
            fontSize: '20pt',
            fontColor: '#000000',
          },
        CertificateDateDetails: showRepositioningElements.ShowDate
          ? {
            positionX: certificateDatePosition.position_x,
            positionY: certificateDatePosition.position_y,
            fontSize: certificateDatePosition.fontSize,
            fontColor: certificateDatePosition.fontColor,
            fontFamily: { ...certificateDatePosition.fontFamily, label: certificateDatePosition.fontFamily?.slug },
          }
          : {
            positionX: 300,
            positionY: 400,
            fontSize: '20pt',
            fontColor: '#000000',
          },
      };
      const formData = new FormData();
      formData.append('id', id);
      formData.append('StylingDetails', JSON.stringify(StylingDetails));
      await saveCertificateTemplate(formData);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Certificate template Saved successfully.',
      });
      setSaveLoading(false)
    } catch (error) {
      console.error('Error updating certificate template:', error);
      setSaveLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response.data.message,
      });
    }
  };


  return (
    <>
          <div className="image-template-instruction-container">
            <form
              encType="multipart/form-data"
              onSubmit={(e) => e.preventDefault()}
            >
              <p>
                <b>Reposition Elements</b>
              </p>
              <p>
                Drag & Drop elements to change where they are positioned on the
                certificate
              </p>
          <div className="form-group form-group-save-cancel">
            <button
              className="btn btn-save btn-success"
              type="button"
              title="Save"
              onClick={handleSave}
              disabled={saveLoading}
            >
              {saveLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              onClick={() => history.goBack()}
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>

              <div className="image-template-container">
                <ImageTemplate
                  imageURL={backgroundImage}
                  // size={imageData.size || 'A4 Landscape'}
                  onSave={handleSave}
                  containerSize={imageContainerSize}
                  onDrag={(e, ui, elementRef) => handleDrag(e, ui, elementRef)}
                  onChange={(
                    value,
                    isFontSizeChanged,
                    isFontColorChanged,
                    editableIndex,
                    isFontFamilyChanged,
                    isMaxWidthChanged
                  ) =>
                    handleChange(
                      value,
                      isFontSizeChanged,
                      isFontColorChanged,
                      editableIndex,
                      isFontFamilyChanged,
                      isMaxWidthChanged
                    )
                  }
                  studentNamePosition={studentNamePosition}
                  setStudentNamePosition={setStudentNamePosition}
                  courseNamePosition={courseNamePosition}
                  setCourseNamePosition={setCourseNamePosition}
                  certificateDatePosition={certificateDatePosition}
                  setCertificateDatePosition={setCertificateDatePosition}
                  originalValues={originalValues}
                  showRepositioningElements={showRepositioningElements}
                />
              </div>
            </form>
          </div>
    </>
  );
};

export default Repositioning;

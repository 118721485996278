import axiosInstance from "../utils/axios";

export const getCategoryList = async (values) => {
  return await axiosInstance().post(`/getCategoriesList`, values);
};
export const getCategoryListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getCategoriesList`, values, {
    cancelToken: cancelToken,
  });
};
export const getCategoryDetails = async (id) => {
  return await axiosInstance().get(`/getCategory?categoryID=${id}`);
};
export const addCategoryDetails = async (data) => {
  return await axiosInstance().post("/insertCategory", data);
};

export const updateCategoryDetails = async (data) => {
  return await axiosInstance().post(`/updateCategory`, data);
};
export const getCategoryFilters = async () => {
  return await axiosInstance().get(`/getCategorFilter`);
};
export const getCategoryDropDownList = async () => {
  return await axiosInstance().get(`/getHeaderDropDownList`);
};
export const getCategoryTypeDropDownList = async () => {
  return await axiosInstance().get(`/getCategoryTypeDropDownList`);
};
export const DeleteLayhoutCategory = async (id) => {
  return await axiosInstance().get(
    `deleteCategory?categoryID=${id}`
  );
};
export const getAssignedProgrammesListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(
    `/getAssignedorNotAssignedProgrammes`,
    values,
    {
      cancelToken: cancelToken,
    }
  );
};
export const getAssignedProgrammesList = async (values) => {
  return await axiosInstance().post(
    `/getAssignedorNotAssignedProgrammes`,
    values
  );
};
export const getAssignedProgrammesFiltersList = async (categoryID, assignedData) => {
  return await axiosInstance().get(
    `/getAssignedorNotAssignedProgrammesFilters?categoryID=${categoryID}&assignedData=${assignedData}`
  );
};


import React, { useState, useEffect, useCallback, useMemo } from "react";
// import ReactHtmlParser from "react-html-parser";
import _ from "underscore";
import FeedAttachments from "./FeedAttachments";
import "../../../assets/css/comment.css";
// import { GetCommentMessages } from "../../../services/PostsAndFeedService";
import {
  readCommentMessages,
  GetMentionedUserDetails,
} from "../../../services/QualificationService";
import { rollsWidget } from "./../../common/Helper";
// import HtmlInputEditorShow from "../common/HtmlInputEditorShow";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
  Entity,
  CompositeDecorator,
} from "draft-js";
import HtmlInputEditorShowComment from "../../common/HtmlInputEditorShowComment";
// import { getRandomInt } from "../../utils/commonFunction";
import Swal from "sweetalert2";
// import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
// import "filepond/dist/filepond.min.css";
// import { API_BASE } from "../../utils/Constants";
// import { getToken } from "../../utils/Auth";
import { useSelector } from "react-redux";
import { getRandomInt } from "../../../utils/commonFunction";
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import moment from "moment";
import HoverChat from "../../common/HoverChat";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import hasPermission from "../../../utils/hasMultiplePermission";
import ThumbsUpIcon from '../../../assets/images/Thumbsup.png'
import ThumbsDownIcon from '../../../assets/images/ThumbsDown.png'
import RedHeartIcon from '../../../assets/images/RedHeart.png'
import PoutingIcon from '../../../assets/images/Pouting.png'
import OpenFaceIcon from '../../../assets/images/OpenFace.png'
import GrinningIcon from '../../../assets/images/GrinningFace.png'
import FrowningIcon from '../../../assets/images/Frowning.png'
import HtmlParser from "react-html-parser";

// import { GetCommentMessages } from "../../../services/PostsAndFeedService";
// import { InitalRender, originalValues } from "../../common/Helper";

const FeedComments = (props) => {
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [showReply, setShowReply] = useState(false);
  const [data, setData] = useState([]);
  const [copyText, setCopyText] = useState(0);
  const [resStr, setResStr] = useState("");
  const [newStr, setNewStr] = useState("");
  const [unreadMsg, setUnreadMsg] = useState("");
  const [globalMention, setGlobalMention] = useState(false);
  const [globalImportant, setGlobalImportant] = useState(false);
  const [globalAttachment, setGlobalAttachment] = useState(false);
  const [globalMentionComment, setGlobalMentionComment] = useState(false);
  const [globalImportantComment, setGlobalImportantComment] = useState(false);
  const [globalAttachmentComment, setGlobalAttachmentComment] = useState(false);
  const [actionState, setActionState] = useState(0);
  const [editableArr, setEditableArr] = useState([]);
  const [importantArr, setImportantArr] = useState([{}]);
  const [editableAllArr, setEditableAllArr] = useState([]);
  const [users, setUsers] = useState(props.userData);
  const [newComments, setNewComments] = useState(0);
  const [reduceReq, setReduceReq] = useState(true);
  const [hoverId, setHoverId] = useState("");
  const user = useSelector((state) => state.user);
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("")

  const originalValues = useCallback((s) => {
    try {
      return decodeURIComponent(s);
    } catch (error) {
      
      return s;
    }
  }, []);

  const InitalRender = useMemo(() => (data) => {
    if (!data) {
      return 'NA';
    }

    const initials = data
      .split(" ")
      .filter(Boolean) // Removes empty strings from array
      .map(word => word[0].toUpperCase())
      .join("")
      .slice(0, 2);

    return initials || 'NA';
  }, []);

  const HandleSpan = useCallback((props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  });

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(compositeDecorator)
  );

  useEffect(() => {
    globalIconHandle(data);
  }, [data]);

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const copyEmailText = (text) => {
    setCopyText(1);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopyText(0);
    }, 1000);
  };

  useEffect(() => {
    setData(props.comments);
    let finalStr = "";
    let usersArr = [];
    let isUnreadMsg = false;
    let arrLen = props.comments.length;
    let newCount = 0;
    let allNewCount = 0;

    if (props.comments) {
      props.comments.map((i) => {
        if (i.user == null) {
          i.user = i.adminuser;
          i.user_id = i.admin_id;
          i.userType = "admin";
        }
        usersArr.push(i?.user?.fullname);
        if (
          !props?.readMessages?.comment_id ||
          i.id > props?.readMessages?.comment_id
        ) {
          isUnreadMsg = true;
        }
        if (arrLen == 1 && !props?.readMessages?.comment_id) {
          readComment(i.message_id, i.id);
        }
        if (props?.readMessages?.comment_id < i.id) {
          if (i.user_id !== user.id) {
            newCount += 1;
          }
          allNewCount += 1;
        }
        setNewComments(newCount > 0 ? newCount - 1 : 0);
      });
    }
    var set = new Set(usersArr);
    if (set.size == 1) {
      finalStr =
        usersArr.length -
        1 +
        (usersArr.length == 2 ? " reply from " : " replies from ") +
        usersArr[0];
    } else if (set.size == 2) {
      let revisedArr = Array.from(set);
      let tempUsers = revisedArr[0] + " and " + revisedArr[1];
      // finalStr = (usersArr.length - 1) + ' replies from ' + tempUsers;

      if (usersArr.length == 2) {
        finalStr = usersArr.length - 1 + " reply from " + revisedArr[0];
      } else {
        finalStr = usersArr.length - 1 + " replies from " + tempUsers;
      }
    } else if (set.size > 2) {
      let revisedArr = Array.from(set);
      let tempUsers = revisedArr[0];
      finalStr =
        usersArr.length -
        1 +
        " replies from " +
        tempUsers +
        " + " +
        " Others ";
    }
    setResStr(
      usersArr.length >
        // - newCount
        0
        ? finalStr
        : ""
    );
    setUnreadMsg(newCount > 1 ? true : false);
    // if (newCount == 1 && props.comments.length > 0) {
    // if (
    //   (newCount == 1 && props.comments.length > 0) ||
    //   (allNewCount > 0 &&
    //     props.comments[props.comments.length - 1].user_id == user.id)
    // ) {
    //   readComment(
    //     props.comments[props.comments.length - 1].message_id,
    //     props.comments[props.comments.length - 1].id
    //   );
    // }
  }, [props.comments, actionState]);

  useEffect(() => {
    if (props.teamObj) {
      let adminData = props.comments.map((itemArr) => {
        let finalobj = props.teamObj.find((item) => {
          return item.id == itemArr.admin_id;
        });
        // console.log(finalobj,"finalobj")
        if (itemArr.admin_id == null) {
          finalobj = { role: "student" };
        } else if (!finalobj) {
          finalobj = { role: itemArr.adminuser.job_title };
        }
        return { ...itemArr, roleKey: finalobj.role };
      });
      setData(adminData);
    }

  }, [props.comments, actionState]);

  const readComment = (msg, comment) => {
    readCommentMessages({
      message_id: msg,
      comment_id: comment,
      user_id: user.id,
    }).then((data) => {
    });
  };

  const expandComList = () => {
    let allData = data;
    let isUnreadMsg = false;
    let reduceCount = 0;
    allData.map((i) => {
      if (
        !props?.readMessages?.comment_id ||
        i.id > props?.readMessages?.comment_id
      ) {
        isUnreadMsg = true;
        reduceCount += 1;
      }
    });
    // if (isUnreadMsg) {
    //   let msg_id = allData[0].message_id;
    //   let comment_id = allData[allData.length - 1].id;
    //   // props.updateReadMessages(msg_id, comment_id)

    //   readComment(msg_id, comment_id);
    //   if (reduceReq) {
    //     props.reduceCount(reduceCount, props.room_id);
    //     setReduceReq(false);
    //   }
    //   setActionState(getRandomInt(5000));
    // }
  };

  const handleBtnMessage = (
    currentData,
    comment_user,
    login_user,
    important,
    item,
    user
  ) => {
    if (important) {
      return "important-tag";
    } else if (currentData && currentData?.includes(`@${user.Firstname} ${user.Lastname}`)) {
      return "my-tag-reply";
    } else if (comment_user == login_user) {
      return "other-reply";
    } else {
      return "";
    }
  };

  const userTagColor = (comment_user, login_user) => {
    if (comment_user == login_user) {
      return " my-tag ";
    } else {
      return " other-tag ";
    }
  };

  const showTagIcon = (
    currentData,
    comment_user,
    login_user,
    item,
    commentData,
    index
  ) => {
    if (currentData && currentData?.includes(`@${item.Firstname} ${item.Lastname}`)) {
      if (index < data.length - 1) {
        if (!globalMention) {
          setGlobalMention(true);
        }
      } else {
        if (!globalMentionComment) {
          setGlobalMentionComment(true);
        }
      }
      return " post-reaction-icon ";
    } else {
      return "";
    }
  };

  const isLiked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isDisliked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLoved = (login_user, loveArr) => {
    if (loveArr.length) {
      for (var i = 0; i < loveArr.length; i++) {
        if (loveArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLaughed = (login_user, laughArr) => {
    if (laughArr.length) {
      for (var i = 0; i < laughArr.length; i++) {
        if (laughArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isSurprised = (login_user, surpriseArr) => {
    if (surpriseArr.length) {
      for (var i = 0; i < surpriseArr.length; i++) {
        if (surpriseArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isSad = (login_user, sadArr) => {
    if (sadArr.length) {
      for (var i = 0; i < sadArr.length; i++) {
        if (sadArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isAngry = (login_user, angryArr) => {
    if (angryArr.length) {
      for (var i = 0; i < angryArr.length; i++) {
        if (angryArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const renderActionList = useCallback((usersArr = [], position) => {
    return usersArr.map((i, index) => {
      if (i.user == null) {
        i.user = i.adminuser;
        i.user_id = i.admin_id;
        i.userType = "admin";        
      }
      return (
        <li key={index}>
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender(i?.user?.role_name)
                }
              >
                {i?.user?.photo ? (
                  <img
                    src={`${i?.user?.photo.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    ).replace("public/","")}`}
                    alt="AIE"
                  />
                ) : (
                  InitalRender((originalValues(i?.user?.fullname)))
                )}

                <span className={`profile-box-2-status ${i?.user?.activity_status ? i?.user?.activity_status.toLowerCase()=="online" ? "Online" :i?.user?.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                </span>

                <HoverChat
                  fullName={i?.user?.fullname}
                  firstName={i?.user?.first_name}
                  lastName={i?.user?.last_name}
                  photo={i?.user?.photo}
                  email={i?.user?.email}
                  mobile={i?.user?.Mobile}
                  status={(i?.user?.status && i?.user?.id && !i?.user?.UserID) ? i?.user?.status : i?.user?.is_active}
                  activity_status={i?.user?.activity_status}
                  right={true}
                  showNumber={i?.user?.student_crm_id ? true : false} 
                  number={i?.user?.student_crm_id}
                />
              </span>            
            </div>
          </div>
          <div className="info-drop">
            <span title={i?.user?.first_name}>{i?.user?.first_name}</span>
            <a
              href="#!"
              title={`${
                isLiked(
                  i?.user?.id,
                  data[position] && data[position].likes
                    ? data[position].likes
                    : []
                )
                  ? "Like"
                  : isDisliked(
                      i?.user?.id,
                      data[position].dislikes ? data[position].dislikes : []
                    )
                  ? "Dislike"
                  : isLoved(
                      i?.user?.id,
                      data[position].love ? data[position].love : []
                    )
                  ? "Love"
                  : isLaughed(
                      i?.user?.id,
                      data[position].laugh ? data[position].laugh : []
                    )
                  ? "Laugh"
                  : isSurprised(
                      i?.user?.id,
                      data[position].surprised ? data[position].surprised : []
                    )
                  ? "Surprised"
                  : isSad(
                      i?.user?.id,
                      data[position].sad ? data[position].sad : []
                    )
                  ? "Sad"
                  : isAngry(
                      i?.user?.id,
                      data[position].angry ? data[position].angry : []
                    )
                  ? "Angry"
                  : "NA"
              }`}
            >              
              {isLiked(i?.user?.id, data[position].likes ? data[position].likes : [])
                ? <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                : isDisliked(
                    i?.user?.id,
                    data[position].dislikes ? data[position].dislikes : []
                  )
                ? <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                : isLoved(i?.user?.id, data[position].love ? data[position].love : [])
                ? <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                : isLaughed(
                    i?.user?.id,
                    data[position].laugh ? data[position].laugh : []
                  )
                ? <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                : isSurprised(
                    i?.user?.id,
                    data[position].surprised ? data[position].surprised : []
                  )
                ? <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                : isSad(i?.user?.id, data[position].sad ? data[position].sad : [])
                ? <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                : isAngry(
                    i?.user?.id,
                    data[position].angry ? data[position].angry : []
                  )
                ? <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                : null}
            </a>
          </div>
        </li>
      );
    });
  },[data]);

  const deleteConfirm = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete comment!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteHandle({
          comment_id: item.id,
          user_id: item.user_id,
          message_type: "deleted",
          message_id: null,
          table_type: "comment",
          deletedFiles: [],
        });
        // setActionState(getRandomInt(5000))
      }
    });
  };

  const editComment = (item) => {
    // console.log('the editable comment is ', item)
    setUserId(item.user_id);
    let data = item.admin_id ? "admin" : "student"
    setUserType(data)
    setTimeout(() => {
      props.setShowHideEdit([item.id]);
    }, 200);
    // setEditableArr([item.id])
    // setEditableAllArr((pre) => [...pre, item])
  };

  const sendClickHandle = (
    messageValue,
    comment_id,
    attachments,
    sizes,
    message_id
  ) => {
    let tempImportant = false;
    let tempData = data;
    let deletedFiles = [];
    let remainFiles = [];
    data.map((i) => {
      if (i.id == comment_id) {
        i.attachments.map((j) => {
          if (j.isDeleted) {
            deletedFiles.push(j.attachment);
          } else {
            remainFiles.push(j);
          }
        });
        tempImportant = i.important;
        attachments = [...remainFiles.map((x) => x.attachment), ...attachments];
        deletedFiles = deletedFiles;
        sizes = [...remainFiles.map((x) => (x.size ? x.size : "NA")), ...sizes];
      }
    });

    props.editHandle(
      messageValue,
      comment_id,
      tempImportant,
      attachments,
      sizes,
      deletedFiles,
      message_id
    );
    props.setShowHideEdit([]);
  };

  const importantHandle = (commentData, value) => {
    let tempArr = importantArr[0];
    tempArr[commentData.id] = value;
    setImportantArr((pre) => [tempArr]);

    let tempData = data;
    tempData.map((i) => {
      if (i.id == commentData.id) {
        i.important = value;
      }
    });
    globalIconHandle(tempData);

    setData(tempData);
  };

  const deletedFileHandle = (id, path) => {
    let tempData = data;
    tempData.map((i) => {
      if (i.id == id) {
        i.attachments.map((j) => {
          if (path != j.attachment) {
            if (!j.isDeleted) {
              j.isDeleted = false;
            }
          } else {
            j.isDeleted = true;
          }
        });
      }
    });
    setData((pre) => [...tempData]);
  };

  const globalIconHandle = (tempData) => {
    let tempImp = false;
    let tempAttach = false;
    let tempImpComment = false;
    let tempAttachComment = false;
    tempData.map((item, index) => {
      if (index < tempData.length - 1) {
        if (item.important) {
          if (!tempImp) {
            tempImp = true;
          }
        }
        if (item.attachments && item.attachments.length) {
          if (!tempAttach) {
            tempAttach = true;
          }
        }
      } else {
        if (item.important) {
          if (!tempImpComment) {
            tempImpComment = true;
          }
        }
        if (item.attachments && item.attachments.length) {
          if (!tempAttachComment) {
            tempAttachComment = true;
          }
        }
      }
      if (item.user == null) {
        item.user = item.adminuser;
        item.user_id = item.admin_id;
        item.userType = "admin";
      }
    });
    setGlobalImportant(tempImp);
    setGlobalAttachment(tempAttach);
    setGlobalImportantComment(tempImpComment);
    setGlobalAttachmentComment(tempAttachComment);
  };

  const handleReset = (item) => {
    if (!props.showHideEditArr.includes(item.id)) {
      props.resetEditor(true);
    }
  };


  const handleReplyToMessages = (msg) =>{
    props.showReplyEditor(msg);
  }

  return (
    <>
      <div
        className={
          "other-reply summary-main " +
          (newComments > 0
            ? " summary-sidebar-show"
            : " summary-sidebar-hide") +
          (props.comments.length == 1 ? " hide-comment-cnt " : "")
        }
      >
        <div
          className="comment-con"
          onClick={() => {
            setShowReply(!showReply);
            expandComList();
          }}
        >
          <i
            className={
              "fal " +
              (showReply ? "fa-angle-double-up" : "fa-angle-double-down")
            }
          ></i>{" "}
          {showReply ? (
            "Collapse all"
          ) : (
            <div
              className={`comment-con ${
                newComments > 0 ? " new-reply-font" : ""
              }`}
            >
              {newComments > 0 ? <p className="new-cmt-txt"> New</p> : null}{" "}
              {resStr}
            </div>
          )}
        </div>

        {!showReply &&
        (globalImportant || globalMention || globalAttachment) ? (
          <div className="row_icons_fas ls_box">
            {globalImportant ? (
              // || globalImportantComment
              <i
                className="fal fa-exclamation important-icon"
                title="Important"
              ></i>
            ) : null}
            {globalMention ? (
              // || globalMentionComment
              <div className=" post-reaction-icon " title="Tag">
                <i className="fal fa-at"></i>
              </div>
            ) : null}
            {globalAttachment ? (
              // || globalAttachmentComment
              <i
                className="fal fa-paperclip attachment-icon"
                title="Attachment"
              ></i>
            ) : null}
          </div>
        ) : null}
      </div>
      {data.map(function (item, index) {
        if (index < data.length - 1) {
          if (item.important) {
            if (!globalImportant) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalImportant(true);
              }
            }
          }
          if (item.attachments && item.attachments.length) {
            if (!globalAttachment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalAttachment(true);
              }
            }
          }
        } else {
          if (item.important) {
            if (!globalImportantComment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalImportantComment(true);
              }
            }
          }
          if (item.attachments && item.attachments.length) {
            if (!globalAttachmentComment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalAttachmentComment(true);
              }
            }
          }
        }

        const replyMessage = data.find(msg => msg.id == item.reply_to);

        if(!item) {
          return ""
        }
        // {props?.readMessages?.comment_id
        //   ? item.user_id !== user.id &&
        //     item.id > props?.readMessages?.comment_id
        //     ? "New"
        //     : null
        //   : item.user_id !== user.id
        //   ? "New"
        //   : null}
        return (
          <div
            className={"comment-box-block " + (index < props.comments.length - 1 && !showReply
                ? " hide-reply"
                : " show-reply likeUnlikeBox") +
                (props?.readMessages?.comment_id && item.user_id !== user.id && item.id > props?.readMessages?.comment_id ? " new-message-style " : "")
            }
            key={index}
            onClick={() => {
              handleReset(item);
            }}
          >
            {item.reply_to > 0 ? 
            <a href={`#comment-message-${item.reply_to}`} onClick={()=>{setShowReply(!showReply);expandComList();}}>
              <div className="reply-to-card reply-show-card">
                <div className="reply-to-header">
                  <span className="reply-to-head-l">
                    <p className="reply-to-user">{replyMessage?.user && replyMessage?.user?.fullname ? replyMessage?.user?.fullname : "Unknown User"}</p>
                    <span>{moment(replyMessage.created_at,"Do MMMM YYYY, h:mm:ss a").format("dddd, " + TABLE_DATE_TIME_FORMAT)}</span>
                  </span>
                  <span className="reply-to-head-r">
                    {rollsWidget(replyMessage?.roleKey)}                      
                  </span>
                </div>
                <div className="reply-to-content">                    
                  {HtmlParser(replyMessage?.comment)}
                </div>
              </div>
            </a>
             : null}
            <div
              id={`comment-message-${item.id}`}
              className={
                "card-reply  card-reply-box ps-by-card-header " +
                handleBtnMessage(
                  item.comment,
                  item.user_id,
                  props.user.id,
                  item.important,
                  item,
                  props.user
                ) 
                // " " +
                // (item.user_id === props.user.id ? " mycomment" : "")
              }
            >
              
              <div className="row_icons_fas ls_box desk-post-static">
                {item.important ? (
                  <i
                    className="fal fa-exclamation important-icon"
                    title="Important"
                  ></i>
                ) : null}
                {showTagIcon(
                  item.comment,
                  item.user_id,
                  props.user.id,
                  props.user,
                  item,
                  index
                ) != "" ? (
                  <div
                    className={showTagIcon(
                      item.comment,
                      item.user_id,
                      props.user.id,
                      props.user,
                      item,
                      index
                    )}
                  >
                    <i className="fal fa-at" title="Tag"></i>
                  </div>
                ) : null}

                {item.attachments && item.attachments.length ? (
                  <i
                    className="fal fa-paperclip attachment-icon"
                    title="Attachment"
                  ></i>
                ) : null}
              </div>
              <div className="post-reat-new">
                <div className="post-reat-head">
                  <div className="post-reat-icon">
                    <button
                      title="Like"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 1,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                    </button>
                    <button
                      title="Dislike"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 2,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                    </button>

                    <button
                      title="Love"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 3,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                    </button>
                    <button
                      title="Laugh"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 4,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                    </button>
                    <button
                      title="Surprised"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 5,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                    </button>
                    <button
                      title="Sad"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 6,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                    </button>
                    <button
                      title="Angry"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item?.id,
                          table_type: "comment",
                          comment_type: 7,
                          message_id: item?.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                    </button>
                  </div>
                  <div className="post-reat-action">
                    <a
                      className="feed-reaction-button"
                      onClick={() => handleReplyToMessages(item)}
                      title="Reply"
                      href={`#reply-input-editor-${props?.message_id}`}
                    >
                      <i className="far fa-reply-all"></i>
                    </a>
                    {((item.admin_id && props.user.id == item.user.id) || (hasPermission({ scopes: ["cchatempmsgedit"], permissions: givenPermsisions }) && item.admin_id)) && 
                      <button
                        title="Edit"
                        onClick={() => editComment(item)}                        
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    }
                    {((item.admin_id && props.user.id == item.user.id) || (hasPermission({ scopes: ["cchatempmsgdelete"], permissions: givenPermsisions }) && item.admin_id)) && 
                      <button
                        title="Delete"
                        onClick={() => deleteConfirm(item)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    }
                    {(hasPermission({ scopes: ["cchatstumsgedit"], permissions: givenPermsisions }) && (item.roleKey && item.roleKey == "student")) && 
                      <button
                        title="Edit"
                        onClick={() => editComment(item)}                        
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    }
                    {(hasPermission({ scopes: ["cchatstumsgdelete"], permissions: givenPermsisions }) && (item.roleKey && item.roleKey == "student")) && 
                      <button
                        title="Delete"
                        onClick={() => deleteConfirm(item)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    }                                        
                  </div>
                  <div className="post-reat-more">
                    <button 
                      title="More" 
                      onClick={() => {
                        props.setShowHideCOmm(
                          props.showHideCOmm ? "" : item.id
                        );
                      }}
                    >
                      <i
                        className="fas fa-ellipsis-h"                        
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  className={`post-reat-list ${
                    props.showHideCOmm == item.id ? "" : " d-none"
                  }`}
                >
                  <h4>
                    Reactions (
                    {(item && item.likes && item.likes.length
                      ? item.likes.length
                      : 0) +
                      (item && item.dislikes && item.dislikes.length
                        ? item.dislikes.length
                        : 0) +
                      (item && item.love && item.love.length
                        ? item.love.length
                        : 0) +
                      (item && item.laugh && item.laugh.length
                        ? item.laugh.length
                        : 0) +
                      (item && item.surprised && item.surprised.length
                        ? item.surprised.length
                        : 0) +
                      (item && item.sad && item.sad.length
                        ? item.sad.length
                        : 0) +
                      (item && item.angry && item.angry.length
                        ? item.angry.length
                        : 0)}
                    )
                  </h4>
                  <ul>
                    {renderActionList(item.likes, index)}
                    {renderActionList(item.dislikes, index)}
                    {renderActionList(item.love, index)}
                    {renderActionList(item.laugh, index)}
                    {renderActionList(item.surprised, index)}
                    {renderActionList(item.sad, index)}
                    {renderActionList(item.angry, index)}
                  </ul>
                </div>
              </div>              
              <div className="card-left assigned-title-blk">
                <div className="card-left-by-img online-status user-drop-down-img">
                  {item?.user?.photo ? (
                    <img
                      src={item?.user?.photo || null}
                      alt={item?.user?.fullname || null}
                    />
                  ) : (
                    InitalRender(item?.user?.fullname, 23)
                  )}
                  <span
                    className={
                      // "portal-status-icon " + (
                      //   item.user.id == user.id ? " online-u" :
                      //     (_.contains(props.online, item.user.id)
                      //       ? " online-u"
                      //       : " offline-u"))
                      `portal-status-icon ${
                        item.user && item.user.activity_status.toLowerCase() == "online"
                          ? "online-u"
                          : item.user && item.user.activity_status.toLowerCase() ==
                            "donotdisturb"
                          ? "donotdisturb-u"
                          : "offline-u"
                      }`
                    }
                  >
                    {" "}
                  </span>
                </div>
               {item.user && <HoverChat
                  fullName={item.user.fullname}
                  firstName={item.user.first_name}
                  lastName={item.user.last_name}
                  photo={item.user.photo}
                  email={item.user.email}
                  mobile={item.user.Mobile}
                  status={(item.user.status && item.user.id && !item.user.UserID) ? item.user.status : item.user.is_active}
                  activity_status={item.user.activity_status}
                  right={true}
                  showNumber={item.user.student_crm_id ? true : false} 
                  number={item.user.student_crm_id}
                />}
              </div>
              <div className="card-right">
                <div className="d-flex justify-content-between">
                  <h5 className="comment-con">
                    <div className="profile-title-box-main">
                      <div class="profile-title-box">
                        <a href="#">{item.user && item.user.fullname}</a>
                        <span className="post-by-time mr-2"> {moment(item.created_at, "Do MMMM YYYY, h:mm:ss a").format("dddd, " + TABLE_DATE_TIME_FORMAT)}</span>
                        {/* {props?.readMessages?.comment_id && item.user_id !== user.id && item.id > props?.readMessages?.comment_id ? 
                          <div className="new-comment mr-1  ">
                            New 
                          </div>
                        : null} */}
                        <small>
                          {item.roleKey ? rollsWidget(item.roleKey) : ""}
                        </small>
                      </div>

                      <div className="main-thums-mob mob-profile-right">
                        {/* 
                        {(item.likes && item.likes.length) ||
                        (item.dislikes && item.dislikes.length) ||
                        (item.love && item.love.length) ||
                        (item.laugh && item.laugh.length) ||
                        (item.surprised && item.surprised.length) ||
                        (item.sad && item.sad.length) ||
                        (item.angry && item.angry.length) ? (
                          <div className="post-reaction-blk">
                            {item.likes &&
                            item.likes.length &&
                            item.likes.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 1,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-thumbs-up"
                                  title="Like"
                                ></i>{" "}
                                <span>{item.likes.length}</span>
                                {/* <div className="like-list ">
                                  <ul>
                                    <li><i className="fas fa-thumbs-up" title="Like"></i> Like reaction</li>

                                    {renderActionList(item.likes, index)}
                                  </ul>
                                </div> *}
                              </a>
                            ) : null}
                            {item.dislikes &&
                            item.dislikes &&
                            item.dislikes.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 2,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-thumbs-down"
                                  title="Dislike"
                                ></i>{" "}
                                <span>
                                  {item &&
                                    item.dislikes &&
                                    item.dislikes.length}
                                </span>
                              </a>
                            ) : null}
                            {item.love.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 3,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-heart"
                                  title="Love"
                                ></i>{" "}
                                <span>{item.love.length}</span>
                              </a>
                            ) : null}
                            {item.laugh.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 4,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-laugh"
                                  title="Laugh"
                                ></i>{" "}
                                <span>{item.laugh.length}</span>
                              </a>
                            ) : null}
                            {item.surprised.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 5,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-surprise"
                                  title="Surprised"
                                ></i>{" "}
                                <span>{item.surprised.length}</span>
                              </a>
                            ) : null}
                            {item.sad.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 6,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-frown"
                                  title="Sad"
                                ></i>{" "}
                                <span>{item.sad.length}</span>
                              </a>
                            ) : null}
                            {item.angry.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: item.id,
                                      table_type: "comment",
                                      comment_type: 7,
                                      message_id: item.message_id,
                                    });
                                  }}
                                  className="fad fa-angry"
                                  title="Angry"
                                ></i>{" "}
                                <span>{item.angry.length}</span>
                              </a>
                            ) : null}
                          </div>
                        ) : null} */}
                        <div className="mob-profile-dotted">
                          <i class="fas fa-ellipsis-v"></i>
                        </div>
                        <div className="tag-point-list">
                          <div className="row_icons_fas ls_box mob-discuss-icons  mob-post-static">
                            {item.important ? (
                              <i
                                className="fal fa-exclamation important-icon"
                                title="Important"
                              ></i>
                            ) : null}
                            {showTagIcon(
                              item.comment,
                              item.user_id,
                              props.user.id,
                              props.user,
                              item,
                              index
                            ) != "" ? (
                              <div
                                className={showTagIcon(
                                  item.comment,
                                  item.user_id,
                                  props.user.id,
                                  props.user,
                                  item,
                                  index
                                )}
                              >
                                <i className="fal fa-at" title="Tag"></i>
                              </div>
                            ) : null}
                            {item.attachments && item.attachments.length ? (
                              <i
                                className="fal fa-paperclip attachment-icon"
                                title="Attachment"
                              ></i>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
                {/* <p>{ReactHtmlParser(item.comment)}</p> */}
                <HtmlInputEditorShowComment
                  data={item.comment}
                  tagClassName={userTagColor(item.user_id, props.user.id)}
                  setCurrentVideo={props.setCurrentVideo}
                  editableArr={props.showHideEditArr}
                  allData={item}
                  sendClick={sendClickHandle}
                  importantArr={importantArr}
                  setIsImportant={importantHandle}
                  isImportant={item.important}
                  // attachmentClick={handleAttachment}
                  // editorState={editorState}
                  // setEditorState={setEditorState}
                  // isInValid={editorState}
                  userData={props.userData}
                  deletedFileHandle={deletedFileHandle}
                />
                <div className="chat-reaction-bar comments-reaction-bar">
                  {item?.likes?.length ? (
                    <button className={"chat-message-reaction " + (isLiked(user.id, item.likes ? item.likes : []) ? "active" : "")} title="Like" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 1,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" /> <span>{item?.likes?.length}</span>
                    </button>
                  ) : null}
                  {item?.dislikes?.length ? (
                    <button className={"chat-message-reaction " + (isDisliked(user.id, item.dislikes ? item.dislikes : []) ? "active" : "")} title="Dislike" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 2,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" /> <span>{item?.dislikes?.length}</span>
                    </button>
                  ) : null}
                  {item?.love?.length ? (
                    <button className={"chat-message-reaction " + (isDisliked(user.id, item.love ? item.love : []) ? "active" : "")} title="Love" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 3,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" /> <span>{item?.love?.length}</span>
                    </button>
                  ) : null} 
                  {item?.laugh?.length ? (
                    <button className={"chat-message-reaction " + (isLaughed(user.id, item.laugh ? item.laugh : []) ? "active" : "")} title="Laugh" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 4,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" /> <span>{item?.laugh?.length}</span>
                    </button>
                  ) : null}
                  {item?.surprised?.length ? (
                    <button className={"chat-message-reaction " + (isSurprised(user.id, item.surprised ? item.surprised : []) ? "active" : "")} title="Surprised" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 5,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" /> <span>{item?.surprised?.length}</span>
                    </button>
                  ) : null}
                  {item?.sad?.length ? (
                    <button className={"chat-message-reaction " + (isSad(user.id, item.sad ? item.sad : []) ? "active" : "")} title="Sad" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 6,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" /> <span>{item?.sad?.length}</span>
                    </button>
                  ) : null} 
                  {item?.angry?.length ? (
                    <button className={"chat-message-reaction " + (isSad(user.id, item.angry ? item.angry : []) ? "active" : "")} title="Angry" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 7,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" /> <span>{item?.angry?.length}</span>
                    </button>
                  ) : null}                      
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default React.memo(FeedComments);

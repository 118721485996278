import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ListOfCourses from "../components/courses/ListOfCourses";
import QuestionBankTabs from "../components/courses/smartAssessment/questionBank/tabs";
import SmartAssessmentTabs from "../components/courses/smartAssessment/assessment/tabs";
import { useLocation } from "react-router-dom";
import AuditTrails from "../components/courses/AuditTrail";
import { useHistory, useParams } from "react-router-dom";
import { GlobalIconNames } from "../utils/GlobalIconNames";


const Courses = () => {
  const [QAview, setQAview] = useState(false);
  const { pathname } = useLocation();
  const currentPath = pathname.split("/");
  const { tab } = useParams();
  const history = useHistory();
  const courseTabs = ["allCourses", "In Progress", "upcoming", "past", "selfplaced"]
  
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                {currentPath.includes("courseAdministration") &&
                  currentPath.includes("courses") && (
                    <div className="my-tickets-info-list Tickets-main-wrap">
                      <div className="my-tickets-heading-blk flex-direction mb-2">
                        <div className="d-flex align-items-center ">
                          <div className="name-w-head d-flex align-items-center ">
                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                            Courses{QAview && (<>: <span>QA View</span></>)}
                            </h4>
                          </div>
                          <div className="text-md-right action2-blk">
                            <div className="ticket-view-btn d-flex align-items-center">
                              <div className="add-new-ticket-bx">
                                <button
                                  onClick={() => history.goBack()}
                                  title="Back"
                                  className="btn btn-white-bordered"
                                >
                                  <i className="fal fa-angle-left"></i>Back
                                </button>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                          <div className="add-ticket-blk button-reset dropdown-comman">
                            <div>
                              <button
                                className="btn btn-primary"
                                title={
                                  QAview ? "Standard View" : "QA View"
                                }
                                onClick={() => setQAview(!QAview)}
                              >
                                <i className="fal fa-eye"></i>{" "}
                                {QAview ? "Standard View" : "QA View"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div className="tabs-wrap">
                        <ul
                          className="nav nav-pills mb-4"
                          id="tabs"
                          role="tablist"
                        >
                          <li
                            className="nav-item"
                            onClick={() => {
                              history.push("/courseAdministration/courses/allCourses");
                            }}
                          >
                            <a
                              className={`nav-link cursor-pointer ${tab === "allCourses" ? "active" : ""
                                }`}
                              id="pills-tab1"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-tab12"
                              aria-selected="true"
                            >
                              <i className="fal fa-globe-africa"></i>All Courses
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => {
                              history.push("/courseAdministration/courses/In Progress");
                            }}
                          >
                            <a
                              className={`nav-link cursor-pointer ${tab === "In Progress" ? "active" : ""
                                }`}
                              id="pills-tab2"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-tab12"
                              aria-selected="true"
                            >
                              <i className="fal fa-calendar-alt"></i>In-Progress
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => {
                              history.push("/courseAdministration/courses/selfplaced");
                            }}
                          >
                            <a
                              className={`nav-link cursor-pointer ${tab === "selfplaced" ? "active" : ""
                                }`}
                              id="pills-tab2"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-tab12"
                              aria-selected="true"
                            >
                              <i className="fal fa-calendar-alt"></i>Self Paced
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => {
                              history.push("/courseAdministration/courses/upcoming");
                            }}
                          >
                            <a
                              className={`nav-link cursor-pointer ${tab === "upcoming" ? "active" : ""
                                }`}
                              id="pills-tab3"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-tab13"
                              aria-selected="true"
                            >
                              <i className="fal fa-arrow-circle-right"></i>Upcoming
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => {
                              history.push("/courseAdministration/courses/past");
                            }}
                          >
                            <a
                              className={`nav-link cursor-pointer ${tab === "past" ? "active" : ""
                                }`}
                              id="pills-tab1"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-tab11"
                              aria-selected="true"
                            >
                              <i className="fal fa-history"></i>Past
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/courseAdministration/courses/audit-trails`
                              )
                            }
                          >
                            <a
                              className={
                                "nav-link" +
                                (tab === "audit-trails" ? " active" : "")
                              }
                              id="pills-tab1"
                              data-toggle="pill"
                              href="#pills-tab11"
                              role="tab"
                              aria-controls="pills-tab1"
                              aria-selected="true"
                            >
                              {GlobalIconNames("audittrail").html}
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className={"tab-pane fade active show"}
                            id="pills-tab22"
                            role="tabpanel"
                            aria-labelledby="pills-tab2"
                          >
                            {(tab !== "audit-trails" && courseTabs.includes(tab)) && <ListOfCourses QAview={QAview} />}
                            {tab === "audit-trails" && <AuditTrails />}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {currentPath.includes("courseAdministration") &&
                  currentPath.includes("questionBank") && <QuestionBankTabs />}
                {currentPath.includes("courseAdministration") &&
                  currentPath.includes("quizzes") && <SmartAssessmentTabs />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;

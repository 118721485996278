import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../../components/common/Header";
import SideBar from "../../../components/common/SideBar";
import AuditTrail from "../../../components/StudentAdministration/Students/AuditTrail";
import LayoutForm from "./LayoutForm";
import MenuItem from "../layoutMenuItem/MenuItem";
// This will be shown in merge-all branch

function LayoutMenusCreate() {
    const history = useHistory();
    const { tab, id } = useParams();

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">Menus&nbsp;:&nbsp;<span>{tab === "update" ? "Update" : "Create New"}</span></h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab === "menuItems" || tab === "update" || tab === "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/menus/update/${id}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "update" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/menus/update/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-info-circle"></i>
                                                    Menu Settings
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/menus/menuItems/${id}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "menuItems" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/menus/menuItems/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-bars"></i> Menu Items
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layoutNavigation/menus/auditTrail`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layoutNavigation/menus/auditTrail`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "create" && <LayoutForm />}
                                            {tab == "update" && <LayoutForm id={id} />}
                                            {tab == "menuItems" && <MenuItem id={id} />}
                                            {tab == "auditTrail" && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LayoutMenusCreate;

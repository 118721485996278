import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { RenderMenuItemStatus, RendermenuitemStatus } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { GetMenusItemListsFilterCancelToken, getMenusItemsList } from "../../../services/Menusserver";

function AddMenuItems({ id , setMenuStructure , menuStructureData , setMenuStructureData }) {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [menuType, setMenuType] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [updateData, setUpdateData] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterData, setFilterData] = useState({ status: [], menuType: [] });


    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateMenuItemListMain"));
        if (storedFilters) {
            setStatus(storedFilters.status);
            setMenuType(storedFilters.menuType);
        }
    }, []);

    useEffect(() => {
        const filterState = {
            status,
            menuType
        };
        localStorage.setItem("filterStateMenuItemListMain", JSON.stringify(filterState));
    }, [status, menuType]);

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    // get list
    useEffect(() => {
        const cancelTokenSources = [];

        const getMenuItemList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const menuItemData = {
                page: page,
                perPage: perPage,
                key: sortkey,
                search: search,
                viaStatus: status.arr,
                viaType: menuType.arr,
                menu_id: Number(id),
                isAssigned: 0
            };

            try {
                const res = await getMenusItemsList(menuItemData, source.token);
                setMenuStructureData(res?.data?.data);
                setTotalRows(res.data?.total);
                setPerPage(res.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getMenuItemList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, status, menuType, searchStatusCheck, updateData, id]);

    // get dropdownlist
    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const res = await GetMenusItemListsFilterCancelToken(dropdownSource.token);
                setFilterData({
                    status: res?.data?.menusItemStatusFilters,
                    menuType: res?.data?.menusItemTypesFilters,
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();

        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, [deleterow]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setMenuType({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
    };

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handleAdd = (row) => {
        const newItem = {
            id: row.MenuItemID,
            title: row.MenuItemLabel,
            data: { ...row },
            className: "parent-node",
        };
        
        setMenuStructure(prevItems => [...prevItems, newItem]);
    
        const updatedMenuStructureData = menuStructureData.filter(data => data.MenuItemID !== row.MenuItemID);
        
        setMenuStructureData(updatedMenuStructureData);
    }

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === 'asc' ? 'asc' : 'desc');
    };

    const handleRedirection = (ID) => {
        if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
            return `/studentAdministration/students/open/${ID}/general`;
        } else if (
            hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/personal`;
        } else if (
            hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/education`;
        } else if (
            hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/account`;
        } else if (
            hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
        } else if (
            hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/assessments`;
        } else if (
            hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notifications`;
        } else if (
            hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/classAttendance`;
        } else if (
            hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/licenses`;
        } else if (
            hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/certificates`;
        } else if (
            hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/appeals`;
        } else if (
            hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/tickets`;
        } else if (
            hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notes`;
        } else {
            return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
        }
        // history.push(redirectLink)
    };

    const sortingName = (rowA, rowB) => {
        const name1 = rowA.Title.trim().toLowerCase();
        const name2 = rowB.Title.trim().toLowerCase();
        return name1.localeCompare(name2);
    };

    const columns = useMemo(() => [
        {
            name: "Title",
            selector: "Title",
            sortField: "Title",
            sortable: true,
            // minWidth: "190px",
            sortFunction: sortingName,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                        <Link
                            className="as-text-blue curser feature-name"
                            // to={handleRedirection(row.id)}
                            title={row.MenuItemLabel}
                        >
                            <span className="textLimit100">{row.MenuItemLabel}</span>
                        </Link>
                        {/* </PermissionsGate> */}
                    </div>
                </div>
            ),
        },
        {
            name: "Menu Item Type",
            selector: "Menu Item Type",
            sortField: "Menu Item Type",
            sortable: true,
            cell: (row) => (row.MenuItemType ?
                <div className="textLimit100 item-text-green" title={row.MenuItemType}>
                    {row.MenuItemType}
                </div>
                : "NA")
        },
        {
            name: "Status",
            selector: "MenuStatus",
            sortField: "MenuStatus",
            sortable: true,
            // maxWidth: "120px",
            // minWidth: "120px",
            cell: (row) => RendermenuitemStatus(row.MenuItemStatus).html,
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <button
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleAdd(row)}
                        >
                            <i className="fal fa-plus"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    console.log(menuStructureData);
    

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom program-table border-0">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={debouncedTerm}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={filterData?.status}
                                            state={status}
                                            setState={setStatus}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                            renderLabelFunction={RenderMenuItemStatus}
                                        />
                                    </div>
                                </div>
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Menu Item Type"
                                            optionArr={filterData?.menuType}
                                            state={menuType}
                                            setState={setMenuType}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                        // renderLabelFunction={RenderBranchList}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    paginationDefaultPage={page}
                    progressPending={loading}
                    data={menuStructureData}
                    progressComponent={<SkeletonTicketList />}
                    defaultSortField={sortkey}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    // noDataComponent={Str.noRecord}
                    onSort={handleSort}
                    sortServer
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    );
}

export default AddMenuItems;

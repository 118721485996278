import axiosInstance from "../utils/axios";

export const AddCourseLandingPages = async (data) => {
    return await axiosInstance().post('/insertCourseLandingPages', data)
}

// export const AddCampusResourse = async (data) => {
//     return await axiosInstance().post('/insertUploadResource', data)
// }

export const updateCourseLandingPages = async (data) => {
    return await axiosInstance().post(`/updateCourseLandingPages`, data)
}

export const getCourseLandingCategoryDropdown = async () => {
    return await axiosInstance().post(`/getCourseLandingPagesFilter`)
}

// export const getCampusColorList = async (values) => {
//     return await axiosInstance().post(`/getCampusColorList` , values)
// }

// export const getStaticPagesBrandList = async () => {
//     return await axiosInstance().get(`/getBrandTemplateList`)
// }

// export const getStaticPagesHeaderList = async () => {
//     return await axiosInstance().get(`/getHeaderDropDownListStatic`)
// }

// export const getCampusPagesStatusList = async () => {
//     return await axiosInstance().get(`/getPublishedStatusList`)
// }

// export const getStaticPagesPopupList = async () => {
//     return await axiosInstance().get(`/getPopUpListStatic`)
// }

export const GetCourseLandingPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getCourseLandingPagesList`, values, {
        cancelToken: cancelToken
    });
};

export const getCourseLandingPagesList = async (values) => {
    return await axiosInstance().post(`/getCourseLandingPagesList`, values);
};

export const DeleteCourseLandingPages = async (id) => {
    return await axiosInstance().delete(`/deleteCourseLandingPages?TrainingCourseLandingPageID=${id}`);
};

export const getassignedProgrammeFilters = async (id , ass) => {
    return await axiosInstance().get(`/assignedProgrammeFilters?TrainingCourseLandingPageID=${id}&isAssigned=${ass}`);
};

// export const GetCampusResourseList = async (data) => {
//     return await axiosInstance().post(`/getCampusResourceList`, data);
// };

export const GetCoursePageAssignedProgrammeList = async (values) => {
    return await axiosInstance().post(`/getAssignedProgrammeList`, values);
};

export const GetCourseLandingUpdateList = async (id) => {
    return await axiosInstance().get(`/getCourseLandingPages?TrainingCourseLandingPageID=${id}`);
};
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import AuditTrailInner from "./UserAuditTrailInner";
import CallLogs from "../SoftphoneManagement/CallLogs";
import PermissionsGate from "../../../utils/permissionGate";
import UserAuditTrailInner from "./UserAuditTrailInner";

function UserAuditTrail({ studentName }) {
  const history = useHistory();
  const { tab, id, type,subTab } = useParams();
  return (
    <div>
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li
            className="nav-item ml-1"
            onClick={() =>
              history.push(
                `/systemAdministration/userManagement/open/${id}/auditTrail`
              )
            }
          >
            <a
              className={`nav-link cursor-pointer ${
                subTab === "auditTrail" && type != "callLogs" ? "active" : ""
              }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-history"></i>
              Audit Trail
            </a>
          </li>
          <PermissionsGate scopes={['useraudittrialcalllogview']}
          RenderError={ () => null}
          >

          <li
            className="nav-item"
            onClick={() =>
              history.push(
                `/systemAdministration/userManagement/open/${id}/auditTrail/callLogs`
              )
            }
          >
            <a
              className={`nav-link cursor-pointer ${
                subTab == "auditTrail" && type == "callLogs" ? "active" : ""
              }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-phone-office"></i>
              Call Logs
            </a>
          </li>
              </PermissionsGate>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-tab11"
            role="tabpanel"
            aria-labelledby="pills-tab1"
          >
            {subTab == "auditTrail" && type != "callLogs" && (
              <UserAuditTrailInner />
            )}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab12"
            role="tabpanel"
            aria-labelledby="pills-tab2"
          >
            {subTab == "auditTrail" && type == "callLogs" && (
              <CallLogs onPage={"user"}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAuditTrail;

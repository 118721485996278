import React, { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import Swal from "sweetalert2";
import { TrimText } from "../../common/TrimText";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import { DeleteProgrameNotification, GetProgramsNotificationList, GetProgramsNotificationListCancelToken } from "../../../services/ProgrammeServices";
import moment from "moment";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import $ from "jquery";
import hasPermission from "../../../utils/hasMultiplePermission";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import HtmlParser from "react-html-parser";

function Notification() {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");

  const [deleterow, setDeleteRow] = useState(false);

  const [notificationList, setNotificationList] = useState([]);
  const [notificationData, setNotificationData] = useState({});

  const user = useSelector((state) => state.user);

  const [loading, setloading] = useState(true);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({ scopes: ["pnview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];
    const getNotificationList = async () => {
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      let res = await GetProgramsNotificationListCancelToken(id)
      if (res.status === 200) {

        setNotificationList(res.data.notifications);
        setloading(false);
      }
    }
    getNotificationList()
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const GetNotificationDetails = (row) => {
    setNotificationData(row);
  };

  const resetFilter = () => {
    setSearch("");
  };

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <>
            <i className="fal fa-envelope"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isSmsActive ? (
          <>
            <i className="fal fa-comment"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isPushnotificationActive ? <i className="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive ? "-" : ""}
      </>
    );
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = notificationList;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempRelatedTo = allData.filter((item) => {
        let includes = item?.qualifications?.course && item?.qualifications?.course?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSendVia = allData.filter((item) => {
        let includes = item?.userinfo?.log && item?.userinfo?.log?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempSendDate = allData.filter((item) => {
        let includes = item?.start && moment.unix(item?.start).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSubject = allData.filter((item) => {
        let includes = item?.subject && item?.subject?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempRelatedTo,
        ...tempSendVia,
        ...tempSendDate,
        ...tempSubject
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return notificationList;
    }
  };
  const sortingDate = (rowA, rowB) => {
    const name1 = rowA?.start.toString().length > 10 ? rowA?.start / 1000 : rowA?.start
    const name2 = rowB?.start.toString().length > 10 ? rowB?.start / 1000 : rowB?.start
    return new Date(name1) - new Date(name2);
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   // maxWidth: "80px",
    //   // minWidth: "80px",
    //   cell: (row) => (
    //     <div className="ticket-id">
    //         <span className="overflow-ellipsis2">
    //           <b>#{row.id}</b>
    //         </span>
    //     </div>
    //   ),
    // },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        !row.draft_notification ? (
          <p className="as-text-blue curser feature-name" onClick={() => GetNotificationDetails(row)} data-toggle="modal" data-target="#notifications">
            <span title={row.subject} className="textLimit100">{row.subject}</span>
          </p>
        ) : (
          <Link className="as-text-blue curser feature-name" to={`/courseAdministration/Programmes/programme/open/${id}/editNotification/${row.id}`}>
            <span className="textLimit100">{row.subject}</span>
          </Link>
        )),
    },
    // { name: "Related To", selector: "relatedTo", sortable: true, cell: (row) => TrimText(row.qualifications.course, 15) },
    {
      name: "Sent Via",
      selector: "sendVia",
      sortable: false,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            {row.draft_notification ? <div className="as-green-bg as-widget">{"Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          </span>
        </div>
      ),
    },
    {
      name: "Sent By",
      selector: "sendBy",
      sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <div className="ticket-id" title={row.userinfo && row.userinfo.log}>
          <span className="overflow-ellipsis2">{row.userinfo && row.userinfo.log}</span>
        </div>
      ),
    },
    {
      name: "Sent Date",
      selector: "start",
      sortable: true,
      sortFunction: sortingDate,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <div className="dateTime">
          <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT + ",")}</p>
          <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p>
        </div>
      ),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row.draft_notification ? (
              <>
                <PermissionsGate scopes={["pnview"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-primary rounded-circle" title="View" data-toggle="modal" data-target="#notifications" onClick={() => GetNotificationDetails(row)}>
                    <i className="fal fa-eye"></i>
                  </button>
                </PermissionsGate>
                <div className="dropdown btn-dropdown-item">
                  <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fal fa-ellipsis-h-alt"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="btn btn-primary rounded-circle" title="Open" to={`/courseAdministration/Programmes/programme/open/${id}/editNotification/${row.id}`}>
                      <i className="fal fa-folder-open"></i>
                    </Link>
                    {user?.role_name === "Admin" && (
                      <PermissionsGate scopes={["pndelete"]} errorProps={{ disabled: true }}>
                        <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </PermissionsGate>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <PermissionsGate scopes={["pnview"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-primary rounded-circle" title="View" data-toggle="modal" data-target="#notifications" onClick={() => GetNotificationDetails(row)}>
                    <i className="fal fa-eye"></i>
                  </button>
                </PermissionsGate>
                {user?.role_name === "Admin" && (
                  <PermissionsGate scopes={["pndelete"]} errorProps={{ disabled: true }}>
                    <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  </PermissionsGate>
                )}
              </>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    // console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgrameNotification(ID)
          .then((res) => {
            setDeleteRow(!deleterow);
            Swal.fire("Deleted!", "Notification has been deleted.", "success");
          })
          .catch((err) => {
            setDeleteRow(!deleterow);
            Swal.fire("Not Deleted!", "Somthing Went Wrong.", "warning");
            console.log("error :", err);
          });
      }
    });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Subject", "Related To", "Sent Via", "Sent By", "Sent Date"];

    data = data?.map((row) => ({
      ...row,
      // ID: row.id ? row.id : "",
      Subject: row?.subject,
      "Related To": row?.qualifications.course ? row?.qualifications.course : "-",
      "Sent Via": row?.draft_notification ? "Draft Saved On " + moment.unix(row?.start).format(TABLE_DATE_TIME_FORMAT) : ((row?.isEmailActive ? "Email " : "") + (row?.isPushnotificationActive ? " Push" : "")),
      "Sent By": !row?.userinfo ? "-" : row?.userinfo.log ? row?.userinfo.log : "-",
      "Sent Date": row?.start ? moment.unix(row?.start).format(TABLE_DATE_TIME_FORMAT) : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Subject, row["Related To"], row["Sent Via"], row["Sent By"], row["Sent Date"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "ProgrammesNotification");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "ProgrammesNotification");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "ProgrammesNotification");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>            
          </div>
          <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="topic-add-modal-content text-center font-weight-bold">
                      <i className="fas fa-bars"></i> Notification Details
                    </div>
                    <hr />
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Subject/Title : </span>
                                <span>{notificationData && notificationData.subject}</span>
                              </li>
                              <li>
                                <span className="title ">Received Date/Time : </span>
                                <span>{notificationData && notificationData.start && moment.unix(notificationData.start).format(TABLE_DATE_TIME_FORMAT)}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Related Qualification/Subject : </span>
                                <span>{notificationData && notificationData.qualifications && notificationData.qualifications.course}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12">
                          <div className="form-group-blk mb-3">
                              <span>{notificationData && notificationData.content ? HtmlParser(notificationData.content) : "NA"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <a href={!notificationData || !notificationData?.attachment ? "" : IMAGE_URL + "/" + (notificationData?.attachment).replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank" download>
                      <button className="btn btn-primary" disabled={notificationData?.attachment == "" || notificationData?.attachment == null || !notificationData?.attachment} title="Download Document">
                        <i className="fal fa-arrow-down"></i>
                        Download Document
                      </button>
                    </a>

                    <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <div className="search-filter-div-right">
            <div className="filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate RenderError={() =>
                  <button className="btn btn-primary" title="Create New" disabled>
                    <i className="fal fa-plus"></i>Create New
                  </button>} scopes={["pnadd"]}>
                  <Link to={`/courseAdministration/Programmes/programme/open/${id}/addNotification`} className="btn btn-primary" title="Create New">
                    <i className="fal fa-plus"></i>Create New
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="start"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
}

export default Notification;

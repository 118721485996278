import axiosInstance from "../utils/axios";

export const AddBrandTemplate = async (data) => {
  return await axiosInstance().post("/brandInsertData", data);
};

export const updateBrandTemplate = async (data) => {
  return await axiosInstance().post(`/updateBrandData`, data);
};
export const updateBrandTemplateHomePage = async (data) => {
  return await axiosInstance().post(`/updateHomePage`, data);
};
export const updateLaptopPricing = async (data) => {
  return await axiosInstance().post(`/updateLaptopPricing`, data);
};

export const BrandTemplateDropdown = async () => {
  return await axiosInstance().get(`/getBrandSettingDropdown`);
};

export const getBrandTemplateStatus = async () => {
  return await axiosInstance().get(`/getStatusDropDownList`);
};
export const getHeaderDropDownListStatic = async () => {
  return await axiosInstance().get(`/getHeaderDropDownListStatic`);
};
export const getBlockVariantTypeDropDown = async () => {
  return await axiosInstance().get(`/getBlockVariantTypeDropDown`);
};
export const getPopUpListStatic = async () => {
  return await axiosInstance().get(`/getPopUpListStatic`);
};

export const getHeaderDropdown = async () => {
  return await axiosInstance().get(`/getHeaderDropdown`);
};

export const getBrandTemplateCampus = async () => {
  return await axiosInstance().get(`/getCampusLocationDropdown`);
};
export const getBrandTemplateBrand = async () => {
  return await axiosInstance().get(`/getBrandDropdown`);
};
export const getCurrencyDropDownList = async () => {
  return await axiosInstance().get(`/getCurrencyDropDownList`);
};
export const getBrandTemplate = async (id) => {
  return await axiosInstance().get(`/getBarndData?brandID=${id}`);
};

export const GetBrandTemplateList = async (values) => {
  return await axiosInstance().post(`/getBrandSettingList`, values);
};
export const getPagesList = async (values) => {
  return await axiosInstance().post(`/getPagesList`, values);
};

export const getPagesFilters = async (values) => {
  return await axiosInstance().get(`/getPagesFilters`, values);
};
export const getPagesListWithToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getPagesList`, values, {
    cancelToken: cancelToken,
  });
};

export const GetBrandTemplateListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getBrandSettingList`, values, {
    cancelToken: cancelToken,
  });
};

export const DeleteBrandTemplate = async (values) => {
  return await axiosInstance().post(
    `/brandDeleteData?brandID=${values.id}`,
    values
  );
};

import axiosInstance from "../utils/axios";
export const emailPendingList = async (values, cancelToken) => {
  return await axiosInstance().get("/getEmailList?type=pending", {
    params: values,
    cancelToken
  });
};
export const emailGeneratedList = async (values, cancelToken) => {
  return await axiosInstance().get("/getEmailList?type=generated", {
    params: values,
    cancelToken
  });
};

export const emailStoppedList = async (values, cancelToken) => {
  return await axiosInstance().get("/getEmailList?type=stopped", {
    params: values,
    cancelToken
  });
};

export const emailList = async (values,cancelToken) => {
  return await axiosInstance().get("/getEmailTemplateList", {
    params: values,
    cancelToken
  });
};

export const getBulkNotification = async (values, cancelToken) => {
  return await axiosInstance().get("/getBulkNotification", {
    params: values,
    cancelToken
  });
};
export const emailListDetails = async (id) => {
  return await axiosInstance().get(`/getEmailTemplateDetails?id=${id}`);
};

export const emailListDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getEmailTemplateDetails?id=${id}`, {cancelToken: cancelToken});
};

export const getReceiverDetail = async (values) => {
  return await axiosInstance().post(`/getReceiverDetail`, values);
};

export const getReceiverDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getReceiverDetail`, values, {cancelToken: cancelToken});
};

export const emailDetailsEdit = async (values) => {
  return await axiosInstance().post(`/editEmailTemplate`, values);
};
export const changeStatusEmail = async (values) => {
  return await axiosInstance().post(`/changeStatusEmail`, values);
};
export const getIntakeList = async (values) => {
  return await axiosInstance().post(`/getIntakeList`, values);
};

export const GetIntakeListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getIntakeList`, values, {
    cancelToken: cancelToken
  });
};

export const getEmailDetails = async (id) => {
  return await axiosInstance().get(`/getEmailDetails?id=${id}`);
};

export const insertbulkNotification = async (values) => {
  return await axiosInstance().post(`/insertbulkNotification`, values);
};

export const insertIndividualNotification = async (values) => {
  return await axiosInstance().post(`/QuickNotification`, values);
};

export const UploadCSVNotification = async (values) => {
  return await axiosInstance().post(`/uploadCSVbulkNotification`, values);
}

export const updateBulkNotification = async (values,updateId) => {
  return await axiosInstance().put(`/updateBulkNotification/${updateId}`, values);
}

export const updateCSVBulkNotification = async (values,updateId) => {
  return await axiosInstance().put(`/updateCSVBulkNotification/${updateId}`, values);
}
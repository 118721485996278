export const MOBILE_REGEX =
/(([(]?[+]?[(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))[)]??[(]?[0-9]{1,3}[)]?([0-9]{3})([0-9]{3,4})/g;
export const NAME_REGEX = /^[A-Za-z0-9 '-]*$/;
// export const URL_REGEX =
// "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
export const URL_REGEX =
/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const VIMEO_REGEX =
'/(?:<iframe [^>]*src=")?((?:https?://)?(?:[w]+.)*vimeo.com(?:[/w:]*(?:/videos)?)?/([0-9]+)[^s]*)"?(?:[^>]*></iframe>)?(?:<p>.*</p>)?/g';
export const YOUTUBE_REGEX =
'/(?:(?:https?://)(?:www)?.?(?:youtu.?be)(?:.com)?/(?:.*[=/])*)([^= &?/\r\n]{8,11})/g';
export const EMAIL_REGEX = /^(?!www\.)(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})(?<!\.co)$/;
export const PASSWORD_REGEX =
/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const STUDENT_NUMBER_REGEX =
/^(?:\d{6}_\d{6}|\d{6})(?:\b|_)$/;
export const TABLE_DATE_FORMAT = 'DD MMM YY';
export const FORM_DATE_FORMAT = 'd MMM yy';
export const TABLE_DATE_TIME_FORMAT = 'DD MMM YY, HH:mm';
export const FORM_DATE_TIME_FORMAT = 'dd MMM yyyy, HH:mm';
export const TABLE_TIME_FORMAT = 'HH:mm';
export const FORM_TIME_FORMAT = 'HH:mm';
export const NETSUITE_DATE_TIME_FORMAT = 'DD-MM-YYYY h:mm A';
export const TABLE_ROWS_PER_PAGE = [10, 15, 20, 25, 30, 50, 100];
export const SP_PASS = 'aa*S4571';
export const DATE_FORMAT = 'DD MMM YYYY';

// //Staging
// export const API_BASE = 'https://aisuperadmin.devtechnosys.tech:17366/api/v1';
// export const zipURL = 'https://devadminapi.myaie.ac:1788';
// export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
// export const IMAGE_URL = 'https://aisuperadmin.devtechnosys.tech:17366';
// // export const IMAGE_URL = "https://myaie.ac";
// export const STUDENT_PORTAL_URL = 'https://student.myaie.ac';
// export const WS_URL = 'wss://devadminapi.myaie.ac:1788';
// export const APP_LINK = 'https://dev.admin.myaie.ac/';
// export const BASE_URL = 'http://myaie.ac';
// export const downloadURL = 'https://corona.myaie.ac/';
// export const DOMAIN_COLOR = 'aiesuperadmin.devtechnosys.tech';
// export const STUDENT_FILE_DOWNLOAD =
// 'https://aiesuperadmin.devtechnosys.tech:17144';
// export const STUDENT_FILES_DOWNLOAD =
// 'https://aisuperadmin.devtechnosys.tech/backend/public';
// export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

//Staging - Internal - Shivam
// export const API_BASE = 'https://stagingamiapi.myaie.ac:1701/api/v1';
// export const zipURL = 'https://stagingamiapi.myaie.ac:1701';
// export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
// export const IMAGE_URL = 'http://myaie.ac';
// export const STUDENT_PORTAL_URL = 'https://staging.student.myaie.ac';
// export const WS_URL = 'wss://stagingstudentapi.myaie.ac:17899';
// export const APP_LINK = 'https://staging.ami.myaie.ac/';
// export const BASE_URL = 'http://myaie.ac';
// export const downloadURL = 'https://corona.myaie.ac/';
// export const DOMAIN_COLOR = 'staging.ami.myaie.azc';
// export const STUDENT_FILE_DOWNLOAD = 'myaie.ac';
// export const STUDENT_FILES_DOWNLOAD = 'myaie.ac';
// export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

//Staging2 - Internal - Sahil
// export const API_BASE = 'https://stagingamiapi2.myaie.ac:1708/api/v1';
// export const zipURL = 'https://stagingamiapi2.myaie.ac:1708';
// export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
// export const IMAGE_URL = 'http://myaie.ac';
// export const STUDENT_PORTAL_URL = 'https://staging.student.myaie.ac';
// export const WS_URL = 'wss://stagingstudentapi.myaie.ac:17899';
// export const APP_LINK = 'https://staging2.myaie.ac/';
// export const BASE_URL = 'http://staging2.myaie.ac';
// export const downloadURL = 'https://corona.myaie.ac/';
// export const DOMAIN_COLOR = 'stagingamiapi.myaie.ac';
// export const STUDENT_FILE_DOWNLOAD = 'myaie.ac';
// export const STUDENT_FILES_DOWNLOAD = 'myaie.ac';
// export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

//Staging3 - Internal - Samrath
// export const API_BASE = 'https://stagingamiapi3.myaie.ac:1709/api/v1';
// export const zipURL = 'https://stagingamiapi3.myaie.ac:1709';
// export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
// export const IMAGE_URL = 'http://myaie.ac';
// export const STUDENT_PORTAL_URL = 'https://staging.student.myaie.ac';
// export const WS_URL = 'wss://stagingstudentapi.myaie.ac:17899';
// export const APP_LINK = 'https://staging3.myaie.ac/';
// export const BASE_URL = 'http://staging3.myaie.ac';
// export const downloadURL = 'https://corona.myaie.ac/';
// export const DOMAIN_COLOR = 'staging3.myaie.ac';
// export const STUDENT_FILE_DOWNLOAD = 'myaie.ac';
// export const STUDENT_FILES_DOWNLOAD = 'myaie.ac';
// export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

// Dev
export const API_BASE = 'https://devadminapi.myaie.ac:1788/api/v1';
export const zipURL = 'https://devadminapi.myaie.ac:1788';
export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
export const IMAGE_URL = 'https://myaie.ac';
export const STUDENT_PORTAL_URL = 'https://dev.myaie.ac';
export const WS_URL = 'wss://devstudentapi.myaie.ac:17896';
export const APP_LINK = 'https://dev.ami.myaie.ac/';
export const BASE_URL = 'http://myaie.ac';
export const downloadURL = 'https://corona.myaie.ac/';
export const DOMAIN_COLOR = 'dev.ami.myaie.ac';
export const STUDENT_FILE_DOWNLOAD = 'myaie.ac';
export const STUDENT_FILES_DOWNLOAD = 'myaie.ac';
export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

//Live
// export const API_BASE = 'https://adminapi.myaie.ac:17278/api/v1';
// export const zipURL = 'https://adminapi.myaie.ac:17278/';
// export const ROOT_URL_AWS = 'https://playback.myaie.ac/';
// export const IMAGE_URL = 'https://myaie.ac';
// export const STUDENT_PORTAL_URL = 'https://student.myaie.ac';
// export const WS_URL = 'wss://testapi.myaie.ac';
// export const APP_LINK = 'https://ami.myaie.ac/';
// export const BASE_URL = 'http://myaie.ac';
// export const downloadURL = 'https://corona.myaie.ac/';
// export const DOMAIN_COLOR = 'ami.myaie.ac';
// export const STUDENT_FILE_DOWNLOAD = 'myaie.ac';
// export const STUDENT_FILES_DOWNLOAD = 'myaie.ac';
// export const CHAT_FILES_DOWNLOAD = 'testapi.myaie.ac';

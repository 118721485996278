import { useFormikContext } from "formik";
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { GetQualificationOptions, GetSchoolList, GetSchoolListCancelToken, checkCheckboxRejectDetails } from "../../../../services/RegistrationService";
import { FORM_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import { addDays } from "date-fns";
import RenderPreviousValues from "./RenderPreviousValues";
import { TrimText } from "../../../common/TrimText";
import Swal from "sweetalert2";
import axios from "axios";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import {
  RenderLearningMethod,  
} from "../../../../utils/CommonGroupingItem";
import { RenderMatchStatus } from  "../../../../utils/CommonStatusItems";
import PermissionsGate from '../../../../utils/permissionGate';

function RegistrationDetails({ studentData, clickedCheck, salesPerson, allBrands, studentId, school, isAcademicDepartment, loadingData }) {
  const dropdownValues = useSelector((state) => state.registerDropdownValues);
  const [intakeArr, setIntakeArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [qualOption, setQualOption] = useState([]);
  const [search, setSearch] = useState("");
  const [courseMatchData, setCourseMatchData] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const [schoolValue, setSchoolValue] = useState("");
  const [status, setStatus] = useState("");
  const [studentStartDate, setStudentStartDate] = useState(new Date());
  const [studentEndDate, setStudentEndDate] = useState(new Date());
  const [schoolList, setSchoolList] = useState([]);
  const AcademicLearningMethod = useSelector((state) => state.registerDropdownValues.study_type_2);
  const ShortLearningMethod = useSelector((state) => state.registerDropdownValues.study_type);
  const [filterMatch, setFilterMatch] = useState({});
  function getValue(arrName, label) {
    let labelName = "";
    for (let item of dropdownValues[arrName]) {
      if (item.value.toString() == label.toString()) {
        labelName = item.label;
        break;
      }
    }

    if (labelName === "" && arrName === "re_registration_status") return label;
    return labelName;
  }

  const fixNetsuiteArr = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        value: item.id,
        label: item.displayname,
        departmentId: item.department,
      };
      tempArr.push(singleItem);
    });
    // console.log("new Array1", tempArr);
    setQualOption(oldVal => [...oldVal, ...tempArr]);
  };

  useEffect(() => {
    if (dropdownValues) {
      let tempIntakeArr = []
      dropdownValues.intake_year.map(year => {
        dropdownValues.intake_number.map(number => {
          tempIntakeArr.push({ value: `${year.value}/${number.label}`, label: `${year.value}-${number.label}` })
        })
      })
      setIntakeArr(tempIntakeArr)
    }
  }, [dropdownValues])
  useEffect(() => {
    // Filter courseMatchedInfo to where courseMatchedInfo.Name is LIKE search
    setCourseMatchData(studentData?.courseMatchInfo);
    if (search && search != "") {
      
      const filteredCourseMatchData = courseMatchData.filter(courseMatchedInfo => {
        courseMatchedInfo.Name.toLowerCase().includes(search.toLowerCase())
        const foundQualName = qualOption.find((option) => {return option.value == courseMatchedInfo.NetsuiteId;})?.label?.toLowerCase();
        return courseMatchedInfo.Name.includes(search.toLowerCase()) || foundQualName.includes(search.toLowerCase());
      });
      setCourseMatchData(filteredCourseMatchData);
    }
    return;
  },[search])

  useEffect(() => {
    console.log(searchStatusCheck);
    if (!searchStatusCheck || searchStatusCheck == {} || searchStatusCheck == undefined || Object.values(searchStatusCheck).every(value => !value)) {
      setCourseMatchData(studentData?.courseMatchInfo);
      return;
    }
  
    const filteredCourseMatchData = studentData?.courseMatchInfo?.filter(courseMatchedInfo => {
      const statusKey = courseMatchedInfo?.Status?.toLowerCase().replace(" ", "");
      return searchStatusCheck[statusKey];
    });
  
    setCourseMatchData(filteredCourseMatchData);
  }, [searchStatusCheck]);
  
  

  useEffect(() => {
    if (schoolId) {
      GetQualificationOptions(schoolId).then(res => {
        fixNetsuiteArr(res && res.data && res.data.data && res.data.data.items ? res.data.data.items : []);
      })
    }
  }, [schoolValue])

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const res = await GetSchoolListCancelToken(source.token)
        if (res.status == 200) {
          const respData = res.data.data;
          // Add "Academic" option to list of schools
          respData.push({ value: "academic", label: "Academic", netsuite_department_id: 14 });
          setSchoolList(respData);
          if(isAcademicDepartment){
            setSchoolId(14);
            setSchoolValue("academic");
          }else{
          respData.map((item) => {
            if (item.value === school) {
              setSchoolId(item.netsuite_department_id)
              setSchoolValue(item.value)
              return;
            }
          })
          // console.log(schoolId);
          // console.log(schoolValue);
        }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [school, isAcademicDepartment])

  useEffect(() => {
    if (studentData && salesPerson) {
      formik.setFieldValue("early_bird_discount", studentData?.re_reg_data?.early_bird_discount);
      let time = studentData?.re_reg_data?.created_date;
      if (!(studentData?.re_reg_data?.created_date.length > 10)) {
        time = (parseInt(studentData?.re_reg_data?.created_date) * 1000)
      }
      setStartDate(time);
      if (studentData?.re_reg_data && studentData?.re_reg_data.status) {
        setStatus(getValue("re_registration_status", studentData?.re_reg_data.status));
      }
      let studentStartTime = studentData?.re_reg_data?.start_date;
      if (studentStartTime && !(studentStartTime > 10)) {
        studentStartTime = (parseInt(studentStartTime) * 1000)
      }
      setStudentStartDate(studentStartTime);
      let studentEndTime = studentData?.re_reg_data?.end_date;
      if (studentEndTime && !(studentEndTime.length > 10)) {
        studentEndTime = (parseInt(studentEndTime) * 1000)
      }
      setStudentEndDate(studentEndTime);

    }    
    if(studentData.courseMatchInfo){
      setCourseMatchData(studentData.courseMatchInfo);
    }
  }, [studentData, salesPerson]);

  const formik = useFormikContext()

  const setDate = (date) => {
    setStartDate(date)
    formik.setFieldValue("submission_date", date)
  }
  const myNameFilter = (a, b) => {
    return a?.first_name?.toLowerCase() > b?.first_name?.toLowerCase() ? 1 : -1;
  };
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const handleStatusFilter = (e) => {
    let id = e.target.id;
    setSearchStatusCheck({
      ...searchStatusCheck,
      [id]: !searchStatusCheck[id],
    });
  };
  const resetFilter = () => {
    setSearch("");
    setFilterMatch([]);
    setSearchStatusCheck({});
  };
  const columns = useMemo(() => [
    {
      name: "Course",
      selector: "Name",
      sortable: true,
      wrap: true,
      sortFunction: myNameFilter,
      cell: (row) => (
        row.intakeId ? (<Link className="as-text-blue curser textLimit50" to={`/courseAdministration/coursesdetails/${row.intakeId}/detailCourses/show`} title={row.Name}>{row.Name}</Link>)
        :
        (
          <strong>{qualOption.find((option) => {return option.value == row.NetsuiteId;}) ? qualOption.find((option) => {return option.value == row.NetsuiteId;}).label : "Netsuite Product not Found"}</strong>
        )
      )}, 
      {
        name: "Status",
        selector: "Status",
        wrap: true,
        cell: (row) => RenderMatchStatus(row.Status).html,
      },
      {
        name: "Netsuite ID",
        selector: "NetsuiteId",
        sortable: false,
        wrap: false,
        cell: (row) => (<span className="textLimit100">{row.NetsuiteId}</span>),
      },  
      {
        name: "Intake",
        selector: "Intake",
        sortable: true,
        wrap: true,
        cell: (row) => (<span className="textLimit100">{row.Intake}</span>),
      },  
      {
        name: "Learning Method",
        selector: "Type",
        sortable: true,
        wrap: true,
        cell: (row) => RenderLearningMethod(row.Type).html,
      },      
      {
        name: "Actions",
        selector: "",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
          {row.intakeId ? (
          <PermissionsGate
          scopes={["cview"]}
          RenderError={() => (
            <button
            className="btn btn-primary rounded-circle"
            title="Open"
            disabled
            >
            <i className="fal fa-folder-open"></i>
            </button>
            )}
            >
            <Link
            to={`/courseAdministration/coursesdetails/${row.intakeId}/detailCourses/show`}
            className="btn btn-primary rounded-circle"
            title="Open"
            >
            <i className="fal fa-folder-open"></i>
            </Link>
            </PermissionsGate>)
            :
            (<div>
              <button
              className="btn btn-primary rounded-circle"
              title="Open"
              disabled
              >
              <i className="fal fa-folder-open"></i>
              </button>
            </div>)
            }
            </div>
            </div>
            ),
          }
        ], [qualOption]);        
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Registration Details</div>
      </div>
      {/* <form onSubmit={formik.handleSubmit}></form > */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label htmlFor="school">School *</label>
          <div className="form-icon-group mb-4" title='School'>
            <Select
              className={"form-control custom-select-box " + (formik.errors.school && formik.touched.school ? " is-invalid" : "")}
              name="school"
              value={schoolList.filter((item) => {
                return item.value == formik.values.school_id;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("school", value.label);
                  formik.setFieldValue("school_id", value.value);
                  setSchoolId(value.netsuite_department_id);
                  setSchoolValue(value.value)
                } else {
                  formik.setFieldValue("school", "");
                  formik.setFieldValue("school_id", "");
                  setSchoolId("");
                  setSchoolValue("")
                }
                formik.setFieldValue("qualification", "");
                formik.setFieldValue("netsuite_department_id", "");
                setQualOption([]);
              }}
              // isClearable
              onBlur={formik.handleBlur}
              options={schoolList}
              maxMenuHeight={175}
              // placeholder={formik.values.school ? formik.values.school : "Select"}
              placeholder={"Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.academy} previousValue={studentData?.RegStudentsPreviousDetails?.academy} type="dropdown" typeSupportingProps={{filterArray : schoolList}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="qualification">{ isAcademicDepartment ? "Product" : "Qualification"} {(status && (status == "Re-Registration" || status == "Preliminary Re-Registration")) && "*"}</label>
          <div className="form-icon-group mb-4" title="Qualification">
            <Select
              className={"form-control custom-select-box " + (formik.errors.qualification && formik.touched.qualification ? " is-invalid" : "")}
              name="qualification"
              value={qualOption.filter((item) => {
                return item.value.toString() == formik.values.qualification.toString();
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("qualification", value.value);
                  formik.setFieldValue("netsuite_department_id", value.departmentId);
                } else {
                  formik.setFieldValue("qualification", "");
                  formik.setFieldValue("netsuite_department_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={qualOption.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1?.localeCompare(name2) ? name1.localeCompare(name2) : 0
              })}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.qualification ? formik.values.qualification : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.qualification} previousValue={studentData?.RegStudentsPreviousDetails?.qualification} 
          type="dropdown" typeSupportingProps={{filterArray : qualOption, isQualification: true, oldSchoolValue: studentData?.RegStudentsPreviousDetails?.academy}} 
          />
        </div>
        <div className="col-md-6 col-lg-4 ">
          <label htmlFor="delivery_method">Course Type *</label>
          <div className="form-icon-group mb-4" title="Course Type">
            <Select
              className={"form-control custom-select-box " + (formik.errors.course_type && formik.touched.course_type ? " is-invalid" : "")}
              name="course_type"
              value={dropdownValues.type_course.filter((item) => {
                return item.value == formik.values.course_type;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("course_type", value.value);
                  if (value.value == "mentored" && formik.values.delivery_method == "selfplaced") {
                    formik.setFieldValue("delivery_method", "");
                    formik.setFieldValue("delivery_method_id", "");
                  }
                } else {
                  formik.setFieldValue("course_type", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.type_course}
              maxMenuHeight={175}
              placeholder={formik.values.course_type ? formik.values.course_type : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.course_type} previousValue={studentData?.RegStudentsPreviousDetails?.course_type} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.type_course}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="delivery_method">Learning Method *</label>
          <div className="form-icon-group mb-4" title="Learning Method">
            <Select
              className={"form-control custom-select-box " + (formik.errors.delivery_method && formik.touched.delivery_method ? " is-invalid" : "")}
              name="delivery_method"
              value={ShortLearningMethod.filter((item) => {
                return item.value == formik.values.delivery_method;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("delivery_method", value.value);
                  formik.setFieldValue("delivery_method_id", value.label);
                } else {
                  formik.setFieldValue("delivery_method", "");
                  formik.setFieldValue("delivery_method_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={formik.values.course_type === "shortcourse" ? ShortLearningMethod : AcademicLearningMethod}
              maxMenuHeight={175}
              placeholder={formik.values.delivery_method ? ShortLearningMethod.find((item) => (item.value == formik.values.delivery_method))?.label : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.delivery_methode} previousValue={studentData?.RegStudentsPreviousDetails?.delivery_methode} type="dropdown" typeSupportingProps={{filterArray : formik.values.course_type === "shortcourse" ? ShortLearningMethod : AcademicLearningMethod}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="campus">Campus *</label>
          <div className="form-icon-group mb-4" title="Campus">
            <Select
              className={"form-control custom-select-box " + (formik.errors.campus && formik.touched.campus ? " is-invalid" : "")}
              name="campus"
              value={dropdownValues.program_campus.filter((item) => {
                return item.value == formik.values.campus;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("campus", value.value);
                  formik.setFieldValue("campus_id", value.value);
                } else {
                  formik.setFieldValue("campus", "");
                  formik.setFieldValue("campus_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.program_campus.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1?.localeCompare(name2) ? name1?.localeCompare(name2) : 0
              })}
              maxMenuHeight={175}
              placeholder={formik.values.campus ? formik.values.campus : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.campus_selected} previousValue={studentData?.RegStudentsPreviousDetails?.campus_selected} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.program_campus, from :"asdf"}} />
        </div>
        {formik.values.course_type == "mentored" ?
          <div className="col-md-6 col-lg-4">
            <label htmlFor="intake">{formik.values.course_type === "shortcourse" ? "Intake" : "Intake *"} </label>
            <div className="form-icon-group mb-4" title="Intake">
              <Select
                className={"form-control custom-select-box " + (formik.errors.intake && formik.touched.intake ? " is-invalid" : "")}
                name="intake"
                value={intakeArr?.filter(val => val.value === formik.values.intake)}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("intake", value.value);
                  } else {
                    formik.setFieldValue("intake", "");
                  }
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={intakeArr}
                maxMenuHeight={175}
                placeholder={formik.values.intake ? formik.values.intake : "Select"}
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.intake_year && studentData?.re_reg_data?.intake_number ? `${studentData?.re_reg_data?.intake_year}/${studentData?.re_reg_data?.intake_number}` : ''} previousValue={studentData?.RegStudentsPreviousDetails?.intake_year && studentData?.RegStudentsPreviousDetails?.intake_number ? `${studentData?.RegStudentsPreviousDetails?.intake_year}/${studentData?.RegStudentsPreviousDetails?.intake_number}` : ''} type="dropdown" typeSupportingProps={{filterArray : intakeArr, from: "intake"}} />
          </div>
          : <></>}
        <div className="col-md-6 col-lg-4">
          <label htmlFor="submission_date">Submission Date</label>
          <div className="form-group-blk mb-3" title="Submission Date">
            <DatePicker
              selected={startDate}
              disabled
              onChange={setDate}
              className="form-control w-100"
              onChangeRaw={(e) => e.preventDefault()}
              dateFormat={FORM_DATE_FORMAT}
              title="Submission Date"
              onBlur={formik.handleBlur}
              placeholderText="Select Submission Date"
            />
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.created_date} previousValue={studentData?.RegStudentsPreviousDetails?.created_date} typeSupportingProps={{from: "date"}}  />
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="finance_option">Finance Option</label>
          <div className="form-icon-group mb-4" title="Finance Option">
            <Select
              className={"form-control custom-select-box " + (formik.errors.finance_option && formik.touched.finance_option ? " is-invalid" : "")}
              name="finance_option"
              value={dropdownValues.finance_option_chosen.filter((item) => {
                return item.value == formik.values.finance_option_id;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("finance_option", value.value);
                  formik.setFieldValue("finance_option_id", value.value);
                } else {
                  formik.setFieldValue("finance_option", "");
                  formik.setFieldValue("finance_option_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={dropdownValues.finance_option_chosen.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1?.localeCompare(name2) ? name1?.localeCompare(name2) : 0
              })}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.finance_option ? formik.values.finance_option : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.finance_option_chosen} previousValue={studentData?.RegStudentsPreviousDetails?.finance_option_chosen} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.finance_option_chosen}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="laptop">Laptop *</label>
          <div className="form-icon-group mb-4" title="Laptop">
            <Select
              className={"form-control custom-select-box " + (formik.errors.laptop && formik.touched.laptop ? " is-invalid" : "")}
              name="laptop"
              value={dropdownValues.laptop.filter((item) => {
                return item.value == formik.values.laptop;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("laptop", value.value);
                  formik.setFieldValue("laptop_id", value.value);
                } else {
                  formik.setFieldValue("laptop", "");
                  formik.setFieldValue("laptop_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={dropdownValues.laptop}
              maxMenuHeight={175}
              isClearable
              placeholder={formik.values.laptop ? formik.values.laptop : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.laptop} previousValue={studentData?.RegStudentsPreviousDetails?.laptop} type="dropdown" typeSupportingProps={{filterArray : dropdownValues.laptop}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="brand">Brand *</label>
          <div className="form-icon-group mb-4" title="Brand">
            <Select
              className={"form-control custom-select-box " + (formik.errors.brand && formik.touched.brand ? " is-invalid" : "")}
              name="brand"
              value={allBrands.filter((item) => {
                return item.value == formik.values.brand;
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("brand", value.value);
                  formik.setFieldValue("brand_id", value.value);
                } else {
                  formik.setFieldValue("brand", "");
                  formik.setFieldValue("brand_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={allBrands.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1?.localeCompare(name2) ? name1?.localeCompare(name2) : 0
              })}
              maxMenuHeight={175}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.brand_reg} previousValue={studentData?.RegStudentsPreviousDetails?.brand_reg} type="dropdown" typeSupportingProps={{filterArray : allBrands}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label htmlFor="sales_person">Sales Person</label>
          <div className="form-icon-group mb-4" title="Sales Person">
            <Select
              className={"form-control custom-select-box " + (formik.errors.sales_person && formik.touched.sales_person ? " is-invalid" : "")}
              name="sales_person"
              value={salesPerson.filter((item) => {
                return item.value == formik.values.sales_person_id
              })}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("sales_person", value.label);
                  formik.setFieldValue("sales_person_id", value.value);
                } else {
                  formik.setFieldValue("sales_person", "");
                  formik.setFieldValue("sales_person_id", "");
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={salesPerson.sort((a, b) => {
                let name1 = a.label;
                let name2 = b.label
                return name1?.localeCompare(name2) ? name1.localeCompare(name2) : 0
              })}
              maxMenuHeight={175}
              placeholder={formik.values.sales_person ? formik.values.sales_person : "Select"}
            />
          </div>
          <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.sale_person_id} previousValue={studentData?.RegStudentsPreviousDetails?.sale_person_id} type="dropdown" typeSupportingProps={{filterArray : salesPerson}} />
        </div>
        {(formik.values.course_type != "mentored" && (formik.values.course_type == "shortcourse" && formik.values.delivery_method != "selfplaced")) ?
          <>
            <div className="col-md-6 col-lg-4">
              <label htmlFor="sales_person">Start Date</label>
              <div className="form-group-blk mb-3" title="Start Date">
                <DatePicker
                  autoComplete="off"
                  selected={formik.values.start_date}
                  name="start_date"
                  onChange={(date) => {
                    setStudentStartDate(date)
                    formik.setFieldValue("start_date", date)
                  }}
                  className="form-control w-100"
                  onChangeRaw={(e) => e.preventDefault()}
                  dateFormat={FORM_DATE_FORMAT}
                  title="Start Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Select Start Date"
                />
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.start_date} previousValue={studentData?.RegStudentsPreviousDetails?.start_date} typeSupportingProps={{from : "date"}}/>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <label htmlFor="sales_person">End Date</label>
              <div className="form-group-blk mb-3" title="End Date">
                <DatePicker
                  selected={studentEndDate}
                  onChange={(date) => {
                    setStudentEndDate(date)
                    formik.setFieldValue("end_date", date)
                  }}
                  className="form-control w-100"
                  onChangeRaw={(e) => e.preventDefault()}
                  dateFormat={FORM_DATE_FORMAT}
                  title="End Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Select End Date"
                  disabled={!formik.values.start_date}
                  timeIntervals={15}
                  minDate={addDays(formik.values.start_date, 0)}
                  dateFormatCalendar="MMMM"
                />
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.end_date} previousValue={studentData?.RegStudentsPreviousDetails?.end_date} typeSupportingProps={{from: "date"}} />
              </div>
            </div>
          </> : null}
        {studentData && studentData?.re_reg_data && studentData?.re_reg_data.academy == "business" && studentData?.re_reg_data.status !== "Final-Status" &&
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentSelection">Student Selection *</label>
            <div className="form-icon-group mb-4">
              {/* <Field name="StudentSelection" placeholder="Student Selection" type="text" className={"form-control"} id="StudentSelection" title="Street Address" /> */}
              <textarea
                cols={5}
                type="text"
                id="StudentSelection"
                className={"form-control" + (formik.errors.StudentSelection && formik.touched.StudentSelection ? " is-invalid" : "")}
                name="StudentSelection"
                placeholder="Student Selection"
                value={formik.values.StudentSelection}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.re_reg_subjects} previousValue={studentData?.RegStudentsPreviousDetails?.re_reg_subjects} />
          </div>
        }
      </div>
      <hr />
      <div className="form-group form-group-save-cancel">
        {isAcademicDepartment ?          
            <div>
              <b className="matched-purple">Laptop Only Registration</b>
            </div>
          : studentData && studentData?.matched_program ?
          <div>
            {studentData?.matched_program.length
              ? (              
              <b>Matched Qualification / Course: {studentData?.matched_program.map((item, index) =>
                <Link
                  className="as-text-blue curser "
                  to={formik.values.course_type == "mentored" ? `/courseAdministration/Programmes/programme/open/${item.programId}/details` : `/courseAdministration/coursesdetails/${item.programId}/detailCourses/show`}
                  key={index}
                >
                  {item.course}{(studentData?.matched_program.length - 1) !== index ? ", " : ""}
                </Link>)}</b>)
              : <b className="matched-red">Not Matched</b>
            }
          </div>
          :  ""
          
        }        
      </div>
      { studentData?.courseMatchInfo && studentData?.courseMatchInfo.length > 0 && (
        
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
              <div className="card-header">Matched Courses</div>
                <div className="search-filter-div">
                </div>
                <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
                <div className="search-filter-div">
                  <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div
                          id="assessment-table-main_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                            <div
                              id="assessment-table-main_filter"
                              className="dataTables_filter"
                            >       
                            <label>
                            <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={search}
                            />
                            </label>
                          </div>
                          </div>
                          </div>
                          <div>
                          <div className="filter-button-group">
                            <div className="filter-scroll">
                              <div className={`filter-scroll-inner  filter-custom-new`}>
                                <div className="assessment-table-filter">
                                  <div className="btn-type-filter dropdown-comman">
                                    <div className="dropdown multiselect">
                                      <button
                                        className={`btn btn-default dropdown-toggle ${searchStatusCheck.length ? "btn-selected" : ""
                                          }`}
                                        type="button"
                                        id="dropdownMenu1"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        title="Matched"
                                      >
                                        <span>
                                          Matched <i className="fal fa-angle-down"></i>
                                        </span>
                                      </button>
                                      <form className="dropdown-menu remove-list-style">
                                        <ul aria-labelledby="dropdownMenu1">
                                        <li className="item-text-white">
                                            <input
                                              type="checkbox"
                                              id="matched"
                                              value="Matched"
                                              onClick={handleStatusFilter}
                                              checked={searchStatusCheck.matched}
                                            />
                                            <label htmlFor="matched">
                                              <span className="as-green-bg as-widget">
                                                Matched
                                              </span>
                                            </label>
                                          </li>
                                          <li className="item-text-white">
                                            <input
                                              type="checkbox"
                                              id="notmatched"
                                              value="notmatched"
                                              onClick={handleStatusFilter}
                                              checked={searchStatusCheck.notmatched}
                                            />
                                            <label htmlFor="notmatched">
                                              <span className="as-red-bg as-widget">
                                                UNMATCHED
                                              </span>
                                            </label>
                                          </li>
                                          <li className="item-text-white">
                                            <input
                                              type="checkbox"
                                              id="excluded"
                                              value="Excluded"
                                              onClick={handleStatusFilter}
                                              checked={searchStatusCheck.excluded}
                                            />
                                            <label htmlFor="excluded">
                                              <span className="as-purple-bg as-widget">
                                                Excluded
                                              </span>
                                            </label>
                                          </li>
                                        </ul>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <div className="reset-btn-group">
                              <div className="button-reset dropdown-comman">
                                <button
                                  className="btn btn-primary"
                                  title="Reset"
                                  type="button"
                                  onClick={resetFilter}
                                >
                                  <i className="fal fa-redo"></i>Reset
                                </button>
                              </div> 
                          </div>    
                          </div>          
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            <DataTable
              data={courseMatchData}
              defaultSortField="Name"
              defaultSortAsc={true}
              columns={columns}
              pagination={true}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              progressComponent={<SkeletonTicketList />}
              progressPending={loadingData}
            />
          </div>
        )}
        <hr />
      </div>
  );
}

export default RegistrationDetails;

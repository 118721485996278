import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { LayoutHeaderStatus, RenderQuizTopic, RenderWebsiteBrandTemplate } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { getPagesFilters, getPagesList, getPagesListWithToken } from "../../../services/BrandTemplateServices";
import { RenderHeaderTypeMethod } from "../../../utils/CommonGroupingItem";


function AssignPagePopup({ formik, assignedPage, setAssignedPage }) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [brand, setBrand] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [updateData, setUpdateData] = useState(true);
  const [headerData, setHeaderData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("PageTitle");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    status: [],
    brand: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateAssiginPageTem"));
    if (storedFilters) {
      setStatus(storedFilters.status);
      setBrand(storedFilters.brand);
      setFilterData(storedFilters.filterData);
    }
  }, []);

  useEffect(() => {
    const filterState = {
      status,
      brand,
      filterData,
    };
    localStorage.setItem("filterStateAssiginPageTem", JSON.stringify(filterState));
  }, [status, brand, filterData]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    const cancelTokenSources = [];
    const getPageList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const pageData = {
        page: page,
        perPage: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: false,
        viaStatus: status?.arr,
        viaBrand: brand?.arr,
      };

      try {
        const res = await getPagesListWithToken(pageData, source.token);
        setHeaderData(res?.data?.Data?.data);
        setTotalRows(res.data?.Data?.total);
        setPerPage(res.data?.Data?.perPage);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getPageList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    status,
    brand,
    searchStatusCheck,
    updateData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPagesFilters();
        setFilterData({
          ...res.data,
          status: res.data.getStatus ? res.data.getStatus : [],
          brand: res.data.getBrandtemplate ? res.data.getBrandtemplate : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };



  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "PageTitle",
      sortField: "PageTitle",
      sortable: true,
      cell: (row) => (
        <Link
          title={row.PageTitle}
          className="as-text-blue curser feature-name"
          to={`/website_management/pages/staticpages/table/update/${row.PageID}`}
          onClick={() => {
            $("#assignPagePopupBrandTemp").modal("hide");
          }}
        >
          <span className="textLimit100">
            {row.PageTitle ? row.PageTitle : ""}{" "}
          </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.status?.toString()
          ? LayoutHeaderStatus(row.status)?.html
          : "-",
    },
    {
      name: "Brand Templates",
      selector: "brandTemplete",
      sortField: "brandTemplete",
      sortable: false,
      cell: (row) => {
        if (row.brandTemplete.length) {
          return (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {row?.brandTemplete.map((d, i) => (
                  <React.Fragment key={i}>
                    {RenderWebsiteBrandTemplate(d?.templateName)?.html}
                  </React.Fragment>
                ))}
              </div>
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Type",
      selector: "pageTypeLabel",
      sortField: "pageTypeLabel",
      sortable: true,
      cell: (row) => row?.pageTypeLabel ? RenderHeaderTypeMethod(row.pageTypeLabel, "IsDefault").html : "-"
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {formik.values.termsAndConditionsPages != row.PageID ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  formik.setFieldValue("termsAndConditionsPages", row.PageID);
                  setAssignedPage({ ...row });
                  // $("#assignPagePopupBrandTemp").modal("hide");
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  formik.setFieldValue("termsAndConditionsPages", "");
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Name", "Status", "Brand Templates"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const headerData = {
      page: page,
      perPage: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: true,
      viaStatus: status.arr,
      viaBrand: status.arr,
    };

    getPagesList(headerData)
      .then((res) => {
        data = res.data.Data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.PageTitle || "",
          Status: row?.status ? LayoutHeaderStatus(row.status)?.text : "-",
          "Brand Templates":
            row?.brandTemplete?.map((d, i) => d?.templateName).join(",") || "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.PageTitle,
              LayoutHeaderStatus(row.status)?.text || "-",
              row?.brandTemplete?.map((d, i) => d?.templateName).join(",") || "-",
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <>
      <div className="custom-table-div filter-search-icon card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData?.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={LayoutHeaderStatus}
                    />
                  </div>
                </div>
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Brand Template"
                      optionArr={filterData?.brand}
                      state={brand}
                      setState={setBrand}
                      renderLabelFunction={RenderQuizTopic}
                      uniqueId="brand"
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Brand_Templates_list");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Brand_Templates_list");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Brand_Templates_list");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          data={headerData}
          progressComponent={<SkeletonTicketList />}
          defaultSortField={sortkey}
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          // noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          isInsidePopUp={true}
        />
      </div>
      <div className="form-group form-group-save-cancel">
        <button
          className="btn btn-save btn-success"
          data-dismiss="modal"
          aria-label="Close"
          title="Save"
        >
          <i className="fal fa-save"></i>
          Save
        </button>
        <button
          className="btn btn-close btn-danger"
          type="button"
          title="Cancel"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="fal fa-times"></i>
          Cancel
        </button>
      </div>
    </>
  );
}
export default AssignPagePopup;

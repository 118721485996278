import React, { useEffect, useState } from "react";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import { TrimText } from "../../common/TrimText";
import { RenderQuizAttemptResultStatus } from "../../../utils/CommonStatusItems";
import Hover from "../../common/Hover";
import { IMAGE_URL, STUDENT_FILE_DOWNLOAD, TABLE_ROWS_PER_PAGE, downloadURL } from "../../../utils/Constants";
import hasPermission from "../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../utils/permissionGate";
import {
  assessmentExemptionToggle,
  getCourseAssessment,
  getCourseGradebook,
  updateCousesScore,
} from "../../../services/GradeSettingService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import GradeBookSaveBar from "../../common/GradeBookSaveBar";
import ExcelJS from 'exceljs';
import { RenderLearningMethodGradebook } from "../../../utils/CommonGroupingItem";
import JSZip from 'jszip';

let options = [
  { label: "Red", value: "0" },
  { label: "Yellow", value: "49" },
  { label: "Green", value: "100" },
];

const stdIds = [];

function CourseGradeBook(props) {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState({ list: false, heading: false });
  const [assessmentHeader, setAssessmentHeader] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [percentageView, setPercentageView] = useState(false);
  const [paginationState, setPaginationState] = useState({
    from: 0,
    to: 0,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
  });
  const [tableState, setTableState] = useState({ page: 1, limit: 10 });
  const [errorMessage, setErrorMessage] = useState("");
  const [tooltipValues, setTooltipValues] = useState();
  const [oldValue, setOldValue] = useState();
  const [tableWidth, setTableWidth] = useState(0);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [isCourseEdited, setIsCourseEdited] = useState(0)
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["cagbview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    if (props.gradingStandard == 2) {
      history.push("/noaccess")
    }
  }, [props])

  useEffect(() => {
    window.addEventListener("swallActive", function (e) {
      checkbtnValue(e.detail);
    });

    return () => {
      window.removeEventListener("swallActive", function (e) {
        checkbtnValue(e.detail);
      });
    };
  }, []);

  const shouldBlockNavigation = updateValueArr.length;

  const handleNavigation = (location, page, states) => {
    if (shouldBlockNavigation) {
      if (Swal.isVisible()) {
        return false;
      }
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, Leave",
        confirmButtonText: "Yes, Save Changes",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              const values = {
                intake_id: id,
                assessment: updateValueArr,
              };
              updateCousesScore(values)
                .then((res) => {
                  setUpdateValueArr([]);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated Successfully",
                  }).then(() => {
                    if (location) {
                      // window.location.assign(location.pathname); // Redirect to the desired location
                      history.push(location.pathname)
                    } else if (!location && page) {
                      if (page == "exempted") {
                        handleExemption(...states)
                      } else {
                        setTableState((prevState) => ({
                          ...prevState,
                          page:
                            page === "firstPage"
                              ? 1
                              : page === "prevPage"
                                ? paginationState.currentPage - 1
                                : page === "nextPage"
                                  ? +paginationState.currentPage + 1
                                  : paginationState.lastPage,
                        }));
                      }
                    }
                  });
                })
                .catch((err) => {
                  Swal.close(); // Close the "Saving changes..." popup if there was an error
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                  });
                });
            },
          });
        } else {
          setUpdateValueArr([]);
          if (location) {
            // window.location.assign(location.pathname); // Redirect to the desired location
            history.push(location.pathname)
          } else if (!location && page) {
            if (page == "exempted") {
              handleExemption(...states)
            } else {
              setTableState((prevState) => ({
                ...prevState,
                page:
                  page === "firstPage"
                    ? 1
                    : page === "prevPage"
                      ? paginationState.currentPage - 1
                      : page === "nextPage"
                        ? +paginationState.currentPage + 1
                        : paginationState.lastPage,
              }));
            }
          }
        }
      });

      return false; // Prevent the default navigation behavior
    } else if (page == "exempted") {
      handleExemption(...states)
    }
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        // event.returnValue = ''; // This is necessary for Chrome/Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, heading: true }));
    getCourseAssessment(id)
      .then((res) => {
        setAssessmentHeader(res.data?.assessment);
        setLoading((prevState) => ({ ...prevState, heading: false }));
      })
      .catch((err) => {
        console.error("error", err);
        setLoading((prevState) => ({ ...prevState, heading: false }));
      });
  }, []);

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, list: true }));
    const Values = {
      intake_id: id,
      key: "",
      sort: "DESC",
      exportStatus: "false",
      search: search,
      page: tableState.page,
      limit: tableState.limit,
    };
    getCourseGradebook(Values)
      .then((res) => {
        let pagination = res?.data?.pagination;
        setIsCourseEdited(res?.data?.getCourse?.allUpdatedCourse)
        setUserData(res?.data?.result.map(item => ({ ...item, isDis: true, isSupplementary: item.assessmentArr.some((supplement) => supplement.isSupplementaryForThisStudent && !supplement.isSickForThisStudent) })));
        setPaginationState({
          from: pagination?.from,
          to: pagination?.to,
          total: pagination?.total,
          perPage: pagination?.per_page,
          currentPage: pagination?.current_page,
          lastPage: pagination?.last_page,
        });
        setTooltipValues(res?.data?.getCourse);
        setLoading((prevState) => ({ ...prevState, list: false }));
      })
      .catch((err) => {
        console.error(err);
        setLoading((prevState) => ({ ...prevState, list: false }));
      });
  }, [updateData, id, tableState, search]);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setDebouncedTerm("");
  };

  const handleExemption = (assId, stdId, val, gradeType, grade) => {
    // let data = {
    //   id: assId,
    //   student_id: stdId,
    //   grant_exemption: val,
    // };
    // assessmentExemptionToggle(data).then((res) => {
    //   setUpdateData(!updateData);
    //   Swal.fire("Updated!", res.data.message, "success");
    // });

    let updateArray = [...updateValueArr];

    const existingIndex = updateArray.findIndex(
      (item) => item.assessmnet_id === assId && item.student_id === stdId
    );

    if (existingIndex !== -1) {
      
      let obj = {
        ...updateArray[existingIndex],
        student_id: stdId,
        grade_type: gradeType,
        // grade: grade ? grade : 0,
        grant_exemption: val
      };
      if(val == 0) {
        obj.grade = grade ? grade : 0
      }
      updateArray[existingIndex] = obj
    } else {
      updateArray.push({
        student_id: stdId,
        assessmnet_id: assId,
        grade_type: gradeType,
        grade: grade ? grade : 0,
        grant_exemption: val
      });
    }

    let updateUserData = userData
    let indexOfUpdatedArr = updateArray.findIndex(item => item.student_id == stdId && item.assessmnet_id == assId)
    let index = updateUserData.findIndex((item)=> item.student_id == stdId)
    if (index !== -1){
      let assIndex = updateUserData[index].assessmentArr.findIndex((ass)=> ass.assessment_id == assId)
      if(assIndex !== -1){
        updateUserData[index].assessmentArr[assIndex].grant_exemption = val
        if (indexOfUpdatedArr !== -1) {
          if (gradeType == 1 || gradeType == 2) {
            updateUserData[index].assessmentArr[assIndex].grade = updateArray[indexOfUpdatedArr].grade
          } else {
            updateUserData[index].assessmentArr[assIndex].ass_mark = updateArray[indexOfUpdatedArr].grade
          }
        } else {
          if (gradeType == 1 || gradeType == 2) {
            updateUserData[index].assessmentArr[assIndex].grade = grade
          } else {
            updateUserData[index].assessmentArr[assIndex].ass_mark = grade
          }
        }
      }
    }

    setUserData([...updateUserData])
    setUpdateValueArr([...updateArray])
  };

  const handleUpdateMarks = (stdId, assId, gradeType, grade, maxMark) => {
    if (grade === oldValue) {
      setOldValue();
      return;
    }
    if (
      (grade > maxMark && gradeType == "3") ||
      (gradeType == "0" && grade > maxMark)
    ) {
      setErrorMessage("Sorry you can't put mark more then Total mark");
      return;
    }
    setErrorMessage("");

    // for showing loading effect update below state
    let updateArray = updateValueArr;

    const existingIndex = updateArray.findIndex(
      (item) => item.assessmnet_id === assId && item.student_id === stdId
    );

    if (existingIndex !== -1) {
      updateArray[existingIndex] = {
        ...updateArray[existingIndex],
        student_id: stdId,
        grade_type: gradeType,
        grade: grade ? grade : 0,
      };
    } else {
      updateArray.push({
        student_id: stdId,
        assessmnet_id: assId,
        grade_type: gradeType,
        grade: grade ? grade : 0,
      });
    }

    let tempArr = userData;
    let isdisabledcheck = tempArr.map((item) => {
      if (item.student_id === stdId) {
        return { ...item, isDis: false };
      }
      return { ...item };
    });
    setUserData(isdisabledcheck);
    setUpdateValueArr(updateArray);
  };

  const handleDownloadMarkerFiles = async (student_id, assessment_id) => {
    const student = userData.find((student) => student.student_id === student_id);

    if (!student) {
      alert('Student not found.');
      return;
    }

    const assessment = student.assessmentArr.find((ass) => ass.assessment_id === assessment_id);

    if (!assessment) {
      alert('Assessment not found for the selected student.');
      return;
    }

    if (assessment.return && assessment.return.length > 0) {
      if (assessment.return.length === 1) {
        // Only one file, download it directly
        const markerFile = assessment.return[0];
        try {
          const response = await fetch(`${downloadURL + STUDENT_FILE_DOWNLOAD}/${markerFile.replace("public/", "").replaceAll("/home/myaie/public_html/", "")}`);
          const blob = await response.blob();

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = markerFile.split('/').pop();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('Error downloading marker file:', error);
        }
      } else {
        // Multiple files, create a zip
        const zip = new JSZip();
        const downloadPromises = assessment.return.map(markerFile =>
          fetch(`${downloadURL + STUDENT_FILE_DOWNLOAD}/${markerFile.replace("public/", "").replaceAll("/home/myaie/public_html/", "")}`)
            .then(response => response.blob())
            .then(blob => {
              const fileName = markerFile.split('/').pop();
              zip.file(fileName, blob);
            })
        );

        try {
          await Promise.all(downloadPromises);
          const content = await zip.generateAsync({ type: 'blob' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(content);
          downloadLink.download = `${student.full_name}_MarkerFiles.zip`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('Error downloading marker files:', error);
        }
      }
    } else {
      alert('Marker files not found for the selected assessment.');
    }
  };

  const exportData = (fileType, fileName) => {
    const Values = {
      intake_id: id,
      key: "",
      sort: "DESC",
      exportStatus: "false",
      search: search,
      page: tableState.page,
      limit: tableState.limit,
    };
    getCourseGradebook(Values)
      .then((res) => {
        const respData = res?.data?.result;
        const header = [
          "Student Name",
          "Student Number",
          "Assessment Name",
          "Assessment Grade",
          "Assessment Result",
          "Course Grade",
          "Course Result",
        ];
        const data = [];
        respData.map((item) => {
          let dataItem = {
            "Student Name": `${item.full_name}`,
            "Student Number": item?.student_num
              ? item?.student_num
              : item?.student_crm_id,
            "Assessment Name": "",
            "Assessment Grade": "",
            "Assessment Result": "",
            "Course Grade": percentageView
              ? (item?.scoreRecieved && item?.scoreRecieved != "N/A") ||
                item?.scoreRecieved === 0
                ? typeof item?.scoreRecieved == "string" &&
                  item?.scoreRecieved.includes("%")
                  ? Math.round(
                    parseFloat(item?.scoreRecieved.replace("%", ""))
                  ).toString() + "%"
                  : typeof item?.scoreRecieved == "string" &&
                    !item?.scoreRecieved.includes("%")
                    ? Math.round(parseFloat(item?.scoreRecieved)).toString() + "%"
                    : item?.scoreRecieved
                      ? item?.scoreRecieved
                      : "N/A"
                : "N/A"
              : item.grading_type != 3
                ? typeof item?.gradeText == "string" &&
                  item?.gradeText.includes("%")
                  ? Math.round(
                    parseFloat(item?.gradeText.replace("%", ""))
                  ).toString() + "%"
                  : item?.gradeText
                    ? item?.gradeText
                    : "N/A"
                : item?.getAssessmentCalculate_sum_ass_mark +
                " / " +
                item.getAssessmentCalculate_sum_ass_total_mark,
            "Course Result":
              item?.pass_status == "N/A"
                ? "N/A"
                : RenderQuizAttemptResultStatus(item?.pass_status).text,
          };
          data.push(dataItem);
          item.assessmentArr &&
            item.assessmentArr.map((row, index) => {
              let subDataItem = {
                "Student Name": "",
                "Student Number": "",
                "Assessment Name": assessmentHeader[index]?.name,
                "Assessment Grade": row?.grant_exemption
                  ? "Exempted"
                  : percentageView
                    ? row?.ass_percentage + "%"
                    : row?.grading_type == "0"
                      ? row?.ass_percentage + "%"
                      : row?.grading_type == "3"
                        ? `${row?.ass_mark}/${row?.ass_total_mark}`
                        : row?.gradeText,
                "Assessment Result":
                  row?.assessmentMarkingStatus?.toString() !==
                    "NOT SUBMITTED" && row.ass_total_mark
                    ? RenderQuizAttemptResultStatus(row.pass_status).text
                    : "N/A",
                "Course Grade": "",
                "Course Result": "",
              };
              data.push(subDataItem);
            });
        });
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student Name"],
              row["Student Number"],
              row["Assessment Name"],
              row["Assessment Grade"],
              row["Assessment result"],
              row["Course Grade"],
              row["Course Result"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => console.error(err));
  };

  const exportXlsxData = async (fileName, courseName) => {
    try {
      const Values = {
        intake_id: id,
        key: "",
        sort: "DESC",
        exportStatus: "false",
        search: search,
        page: tableState.page,
        limit: tableState.limit,
      };

      const res = await getCourseGradebook(Values);
      const respData = res?.data?.result;
      let learning_method = res?.data?.getCourse?.type
      const header = [
        "Name",
        "Surname",
        "ID Number",
        "Student Number",
        "Study Method",
        ...assessmentHeader.map((assessment) => assessment?.name),
        "Final Mark",
      ];

      const data = respData.flatMap((item) => {
        const rowData = [
          item.first_name,
          item.last_name,
          item?.number ? item?.number : " ",
          item?.student_crm_id ? item?.student_crm_id : item?.student_num ? item?.student_num : " ",
          learning_method ? RenderLearningMethodGradebook(learning_method).text : " ",
          ...assessmentHeader.map((assessment) => {
            const assessmentData = item.assessmentArr.find(
              (row) => row.assessment_id === assessment?.id
            );
            if (assessmentData) {
              return assessmentData?.grant_exemption ? "Exempted"
                : percentageView
                  ? assessmentData?.ass_percentage + "%"
                  : assessmentData?.grading_type == "0"
                    ? assessmentData?.ass_percentage + "%"
                    : assessmentData?.grading_type == "3"
                      ? `${assessmentData?.ass_mark}/${assessmentData?.ass_total_mark}`
                      : assessmentData?.gradeText;
            }
            return "";
          }),
          percentageView
            ? (item?.scoreRecieved && item?.scoreRecieved != "N/A") ||
              item?.scoreRecieved === 0
              ? typeof item?.scoreRecieved == "string" &&
                item?.scoreRecieved.includes("%")
                ? Math.round(
                  parseFloat(item?.scoreRecieved.replace("%", ""))
                ).toString() + "%"
                : typeof item?.scoreRecieved == "string" &&
                  !item?.scoreRecieved.includes("%")
                  ? Math.round(parseFloat(item?.scoreRecieved)).toString() + "%"
                  : item?.scoreRecieved
                    ? item?.scoreRecieved
                    : "N/A"
              : "N/A"
            : item.grading_type != 3
              ? typeof item?.gradeText == "string" &&
                item?.gradeText.includes("%")
                ? Math.round(
                  parseFloat(item?.gradeText.replace("%", ""))
                ).toString() + "%"
                : item?.gradeText
                  ? item?.gradeText
                  : "N/A"
              : item?.getAssessmentCalculate_sum_ass_mark +
              " / " +
              item.getAssessmentCalculate_sum_ass_total_mark,
        ];
        return [rowData];
      });


      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('Excel Data');

      // const courseNameColumn = ws.getColumn(1);
      // courseNameColumn.width = courseName.length + 2;

      // Set border for the entire worksheet
      ws.views = [
        {
          state: 'frozen',
          ySplit: 1,
          xSplit: 1
        },
      ];
      ws.properties.showGridLines = true;

      const columnStyles = [];
      header.forEach((col) => {
        const style = {};
        if (['Name', 'Surname', 'ID Number', 'Student Number', 'Study Method'].includes(col)) {
          style.font = { bold: true };
          style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDBDBDB' }, // Light gray background color
          };
          style.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };
        } else if (col === 'Final Mark') {
          style.font = { color: { argb: "FFFFFF" } };
          style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF7030A0' }, // Purple background color
          };
          style.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },    // Black top border
            bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black bottom border
            left: { style: 'thin', color: { argb: 'FF000000' } },   // Black left border
            right: { style: 'thin', color: { argb: 'FF000000' } }   // Black right border
          };
        } else {
          style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDBDBDB' }, // Light gray background color
          };
          style.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };
        }
        columnStyles.push(style);
      });
      const staticRow = ws.addRow([courseName, '', '', '', '']);
      staticRow.height = 16;
      staticRow.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF66CCFF' } };
      staticRow.getCell(1).font = { bold: true };
      staticRow.getCell(1).alignment = { horizontal: 'center', vertical: "bottom" };
      staticRow.getCell(1).border = {};
      staticRow.getCell(5).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF203764' } };

      ws.mergeCells(`A${staticRow.number}:D${staticRow.number}`);

      // for (let colIndex = 3; colIndex <= header.length; colIndex++) {
      //   const cell = staticRow.getCell(colIndex);
      //   cell.value = '';
      //   cell.fill = { type: 'pattern', pattern: 'none' }; // Clear fill color
      //   cell.border = {}; // Clear border
      // }

      const headerRow = ws.addRow(header);
      headerRow.eachCell((cell, colNumber) => {
        if (columnStyles[colNumber - 1].font) {
          cell.font = columnStyles[colNumber - 1].font;
        }
        cell.fill = columnStyles[colNumber - 1].fill;
        cell.border = columnStyles[colNumber - 1].border;
      });

      data.forEach((row) => {
        const dataRow = ws.addRow(row);
        dataRow.eachCell((cell, colNumber) => {
          cell.border = columnStyles[colNumber - 1].border;
        });
      });

      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();

      Swal.close();
    } catch (err) {
      console.log(err);
    }
  };

  const slider = document.querySelector(".table-book-mid");

  useEffect(() => {
    if (slider) {
      let mouseDown = false;
      let startX, scrollLeft;

      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function (event) {
        mouseDown = false;
      };

      slider.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    }
  }, [slider]);

  const handleResize = () => {
    let pageWidth = document.querySelector(
      ".my-tickets-info-list"
    )?.offsetWidth;
    setTableWidth(pageWidth);
  };

  useEffect(() => {
    var resizeRequested = false;
    handleResize();
    window.addEventListener(
      "resize",
      function () {
        if (!resizeRequested) {
          resizeRequested = true;
          window.requestAnimationFrame(function () {
            setTableWidth(
              document.querySelector(".my-tickets-info-list")?.clientWidth
            );
            resizeRequested = false;
          });
        }
      },
      100
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWidth = () => {
    const leftTableWidth =
      document.querySelector(".table-book-left").clientWidth;
    const midTableWidth = document.querySelector(".grade-acc-in").clientWidth;
    const rightTableWidth =
      document.querySelector(".table-book-right").clientWidth;
    let maxAllowedWidth = tableWidth - leftTableWidth - rightTableWidth;
    let lastTableClass =
      document.querySelector(".table-book-right").firstChild.className;
    let firstTableClass =
      document.querySelector(".table-book-left").firstChild.className;
    if (maxAllowedWidth < midTableWidth) {
      document.querySelector(".table-book-mid").style.overflowY = "auto";
      document.querySelector(".table-book-right").style.maxWidth =
        "fit-content";
      if (!lastTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-right"
        ).firstChild.className = `${lastTableClass} border-shadow-custom-table`;
      }
      if (!firstTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-left"
        ).firstChild.className = `${firstTableClass} border-shadow-custom-table`;
      }
    } else {
      document.querySelector(".table-book-mid").style.overflowY = "unset";
      document.querySelector(".table-book-right").style.maxWidth = "unset";
      document.querySelector(".table-book-right").firstChild.className =
        lastTableClass.replaceAll("border-shadow-custom-table", "");
      document.querySelector(".table-book-left").firstChild.className =
        firstTableClass.replaceAll("border-shadow-custom-table", "");
    }
  };
  let firstLoaded = true;
  useEffect(() => {
    if (userData?.length && tableWidth) {
      if (firstLoaded) {
        setTimeout(() => {
          handleWidth();
          firstLoaded = false;
        }, 500);
      } else {
        handleWidth();
      }
    }
  }, [userData, tableWidth]);

  const handleSaveGrades = (studentID) => {
    Swal.fire({
      title: "Updating",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    setSaveGradesLoading(true);
    const values = {
      intake_id: id,
      assessment: updateValueArr,
      student_id: studentID,
    };
    if (!studentID) {
      delete values.student_id;
    }
    updateCousesScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        if (studentID) {
          const filteredArr = updateValueArr.filter(
            (item) => item.student_id !== studentID
          );
          setUpdateValueArr(filteredArr);
        } else {
          setUpdateValueArr([]);
        }
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  const handleSaveSingleGrade = (stdId) => {
    let otherIds = stdIds.filter((item) => item !== stdId);
    if (otherIds.length) {
      Swal.fire({
        title: "Are You Sure?",
        text: "You have unsaved data in other students",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <div class="custom-swal-button">
            <p>You have unsaved data in other students!</p>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "saveAll", updatedArr: ${JSON.stringify(
          updateValueArr
        )}})' >Save All</button>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "save", stdId: ${stdId}, updatedArr: ${JSON.stringify(
          updateValueArr
        )}})'>Save</button>
            <button class="swal2-cancel swal2-styled" onclick="triggerSwall({type: 'cancel'})" style="background-color: rgb(221, 51, 51);">Cancel</button>
          </div>`,
      });
    } else {
      handleSaveGrades(stdId, updateValueArr);
    }
  };

  const checkbtnValue = (values) => {
    if (values.type === "saveAll") {
      handleSaveGrades(undefined, values.updatedArr);
    } else if (values.type === "save") {
      handleSaveGrades(values.stdId, values.updatedArr);
    }
    Swal.close();
  };

  return (
    <>
      <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />
      <div className="my-tickets-info-list Tickets-main-wrap course-gradebook">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportXlsxData(
                          `${props.courseName} Grade Book`,
                          props.courseName
                        );
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", `${props.courseName} Grade Book`);
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", `${props.courseName} Grade Book`);
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <div>
                    <button
                      className="btn btn-primary"
                      title={
                        percentageView ? "Standard View" : "Percentage View"
                      }
                      onClick={() => setPercentageView(!percentageView)}
                    >
                      <i className="fal fa-eye"></i>{" "}
                      {percentageView ? "Standard View" : "Percentage View"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading.heading || loading.list ? (
            <SkeletonTicketList />
          ) : userData?.length ? (
            <div className="table-book-responsive">
              <div className="table-book row m-0">
                <div className={"table-book-left "}>
                  <table
                    className={
                      "table-book-main " +
                      (percentageView
                        ? assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table"
                        : assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th>Student</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="assigned-title-block-new">
                              <div className="assigned-title-itm">
                                <span
                                  className={
                                    "assigned-title-blk  name-icon cat-dark-red " +
                                    ColorRender(item.role_name)
                                  }
                                >
                                  {item.picture_me ? (
                                    <img
                                      src={`${IMAGE_URL}/${item.picture_me
                                        .replace("public/", "")
                                        .replaceAll(
                                          "/home/myaie/public_html/",
                                          ""
                                        )}`}
                                      alt="AIE"
                                    />
                                  ) : (
                                    InitialRender([
                                      item.full_name.split(" ")[0],
                                      item.full_name.split(" ").length
                                        ? item.full_name.split(" ")[1]
                                        : "",
                                    ])
                                  )}
                                  <span
                                    className={`profile-box-2-status ${item.activity_status
                                        ? item.activity_status.toLowerCase() ==
                                          "online"
                                          ? "Online"
                                          : item.activity_status.toLowerCase() ==
                                            "away"
                                            ? "Away"
                                            : "Offline"
                                        : "Offline"
                                      }`}
                                  >
                                    <i className="fas fa-circle"></i>
                                  </span>
                                  <Hover
                                    firstName={item.full_name.split(" ")[0]}
                                    lastName={
                                      item.full_name.split(" ").length
                                        ? item.full_name.split(" ")[1]
                                        : ""
                                    }
                                    photo={item.picture_me}
                                    email={item.email}
                                    mobile={item.mobile}
                                    status={item.status}
                                    activity_status={item.activity_status}
                                    showNumber={true}
                                    number={item.student_crm_id}
                                    right={true}
                                  />
                                </span>
                                <PermissionsGate
                                  scopes={["casvview"]}
                                  RenderError={() => (
                                    <p title={item.full_name}>
                                      {item.full_name}
                                    </p>
                                  )}
                                >
                                  <Link
                                    className="as-text-blue curser "
                                    to={`/courseAdministration/coursesdetail/${id}/students/grades/${item.student_id}?courseName=${props?.courseName}&stdName=${item?.full_name}`}
                                  >
                                    <span className="" title={item.full_name}>
                                      {item.full_name}
                                    </span>
                                  </Link>
                                </PermissionsGate>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    "table-book-mid " +
                    (percentageView
                      ? assessmentHeader?.length < 9
                        ? "less-items"
                        : "overflow-auto"
                      : assessmentHeader?.length < 9
                        ? "less-items"
                        : "overflow-auto")
                  }
                >
                  <div className="grade-acc">
                    <div className={"grade-acc-in active"}>
                      <table className="table-book-main table-book-main-contetn">
                        <thead>
                          <tr>
                            {assessmentHeader?.length
                              ? assessmentHeader.map((item, index) => (
                                <th key={index}>
                                  <div className="tb-book-tit">
                                    <Link
                                      className="curser"
                                      to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/Details/${item?.id}`}
                                      title={item?.name}
                                    >
                                      <p>
                                        {TrimText(item?.name, 20)}
                                        {userData[0]?.assessmentArr[index]
                                          ?.pass_required ? (
                                          <span
                                            href="#"
                                            className=" grade-info-red"
                                            data-toggle="tooltip"
                                            title={"Pass Required"}
                                          >
                                            <i className="fal fa-exclamation-circle"></i>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </Link>
                                  </div>
                                </th>
                              ))
                              : ""}
                          </tr>
                        </thead>
                        <tbody>
                          {userData.map((item, i) => (
                            <tr key={i}>
                              {item.assessmentArr.map((ass, index) => {
                                return (
                                  <td
                                    key={index}
                                    className={
                                      ass?.grant_exemption ? `exempted-td ${(item.isSupplementary && !ass.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}` : (ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent) ? "supplement-td" : ""
                                    }
                                  >
                                    {ass?.grant_exemption ? (
                                      <>
                                        <div
                                          className={`as-widget-text grade-info-plus mt-2 curser ${(item.isSupplementary && !ass.isSupplementaryForThisStudent) ? "supplementry-pointer-events" : ""}`}
                                          title="Remove from Exemption"
                                          onClick={() =>
                                            handleExemption(
                                              ass?.assessment_id,
                                              item?.student_id,
                                              ass?.grant_exemption ? 0 : 1,
                                              ass?.grading_type,
                                              percentageView
                                              ? ass.ass_percentage
                                              : ass.grading_type === 0 || ass.grading_type === 3
                                              ? ass.ass_mark
                                              : ass.grading_type === 2
                                              ? ass.grade
                                              : ass.grading_type === 1
                                              ? ass?.grade == 0 ? "0" : ass?.grade > 0 && ass?.grade <= 49 ? '49' : "100" : ""
                                            )
                                            // handleExemption(
                                            //   ass?.assessment_id,
                                            //   item?.student_id,
                                            //   ass?.grant_exemption ? 0 : 1
                                            // )
                                          }
                                        >
                                          {ass?.grant_exemption ? (
                                            <i className="fal fa-user-plus"></i>
                                          ) : (
                                            <i className="fal fa-user-plus"></i>
                                          )}
                                          <div
                                            title="Exempted"
                                            className="exempted-text"
                                          >
                                            Exempted{" "}
                                            <i className="fal fa-info-circle"></i>
                                          </div>
                                        </div>
                                      </>
                                    ) : (ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent) ? (
                                      <div className="as-widget-text mt-2 supplement-margin">N/A</div>
                                    ) : (ass?.ass_type == 9 && !ass.isAssignedSickStudent) ? (
                                      <div className={`as-widget-text mt-2 supplement-margin ${(item.isSupplementary && !ass.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</div>
                                    ) : (
                                      <div className={`d-flex ${(item.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent  ? "supplementry-opacity" : ""}`}>
                                        <div className="marks-number-info">
                                          <div className="marks-input-text">
                                            {percentageView ? (
                                              <div className="resultstatus_flex">
                                                <div className="form-icon-group status-select-form">
                                                  <input
                                                    className={
                                                      ass.ass_percentage == "0"
                                                        ? "input-red"
                                                        : ""
                                                    }
                                                    type="number"
                                                    value={ass.ass_percentage}
                                                    disabled
                                                  />{" "}
                                                  %
                                                </div>
                                              </div>
                                            ) : ass.grading_type === 0 ? (
                                              <div className={`resultstatus_flex`}>
                                                <div className="form-icon-group status-select-form percentage-box">
                                                  <PermissionsGate
                                                    scopes={["cagbedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <input
                                                      className={
                                                        ass.ass_percentage ==
                                                          "0"
                                                          ? "input-red percentage-input"
                                                          : "percentage-input"
                                                      }
                                                      type="number"
                                                      // title={
                                                      //   ass.ass_percentage
                                                      // }
                                                      defaultValue={
                                                        ass.ass_mark
                                                      }
                                                      onBlur={(e) => {
                                                        stdIds.push(
                                                          item.student_id
                                                        );
                                                        handleUpdateMarks(
                                                          item.student_id,
                                                          ass.assessment_id,
                                                          0,
                                                          e?.target?.value,
                                                          ass.ass_total_mark
                                                        );
                                                      }}
                                                      disabled={
                                                        ass.lock_grades == "1" || ass.quiz_id || (item.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                      }
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </PermissionsGate>
                                                  {
                                                    <span className="ml-1">
                                                      {/* % */}
                                                      <span>/ {ass.ass_total_mark}</span>
                                                    </span>
                                                  }
                                                  <span className="percentage">{`| ${ass.ass_percentage}`}%</span>
                                                </div>
                                              </div>
                                            ) : ass.grading_type === 1 ? (
                                              <div className="form-icon-group marks-input-text">
                                                <PermissionsGate
                                                  scopes={["cagbedit"]}
                                                  errorProps={{
                                                    disabled: true,
                                                  }}
                                                >
                                                  <select
                                                    className={
                                                      "mid-scroll-custom-table-select " +
                                                      (ass.ass_percentage == "0"
                                                        ? "percentage-select-red"
                                                        : "")
                                                    }
                                                    defaultValue={ass?.grade == 0 ? "0" : ass?.grade > 0 && ass?.grade <= 49 ? '49' : "100"}
                                                    disabled={
                                                      ass.lock_grades == "1" || ass.quiz_id || (item.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                    }
                                                    onChange={(e) => {
                                                      stdIds.push(
                                                        item.student_id
                                                      );
                                                      handleUpdateMarks(
                                                        item.student_id,
                                                        ass.assessment_id,
                                                        1,
                                                        e?.target?.value,
                                                        ass.ass_total_mark
                                                      );
                                                    }}
                                                    onMouseUp={(e) =>
                                                      setOldValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    {options?.length
                                                      ? options.map(
                                                        (opt, index) => (
                                                          <option
                                                            key={index}
                                                            value={opt.value}
                                                          >
                                                            {opt.label}
                                                          </option>
                                                        )
                                                      )
                                                      : ""}
                                                  </select>
                                                </PermissionsGate>
                                              </div>
                                            ) : ass.grading_type === 2 ? (
                                              <div className="form-icon-group marks-input-text">
                                                <PermissionsGate
                                                  scopes={["cagbedit"]}
                                                  errorProps={{
                                                    disabled: true,
                                                  }}
                                                >
                                                  <select
                                                    className={
                                                      "mid-scroll-custom-table-select " +
                                                      (ass.ass_percentage == "0"
                                                        ? "percentage-select-red"
                                                        : "")
                                                    }
                                                    defaultValue={ass?.grade}
                                                    disabled={
                                                      ass.lock_grades == "1" || ass.quiz_id || (item.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                    }
                                                    onChange={(e) => {
                                                      stdIds.push(
                                                        item.student_id
                                                      );
                                                      handleUpdateMarks(
                                                        item.student_id,
                                                        ass.assessment_id,
                                                        2,
                                                        e?.target?.value,
                                                        ass.ass_total_mark
                                                      );
                                                    }}
                                                    onMouseUp={(e) =>
                                                      setOldValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    {ass?.letterGrades &&
                                                      ass?.letterGrades?.length
                                                      ? ass?.letterGrades.map(
                                                        (opt, index) => (
                                                          <option
                                                            key={index}
                                                            value={opt.value}
                                                          >
                                                            {opt.label}
                                                          </option>
                                                        )
                                                      )
                                                      : ""}
                                                  </select>
                                                </PermissionsGate>
                                              </div>
                                            ) : (
                                              <div className="resultstatus_flex">
                                                <div className="form-icon-group status-select-form">
                                                  <PermissionsGate
                                                    scopes={["cagbedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <input
                                                      className={
                                                        ass.ass_mark == "0"
                                                          ? "input-red"
                                                          : ""
                                                      }
                                                      type="number"
                                                      disabled={
                                                        ass.lock_grades == "1" || ass.quiz_id || (item.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                      }
                                                      defaultValue={
                                                        ass.ass_mark
                                                      }
                                                      onBlur={(e) => {
                                                        stdIds.push(
                                                          item.student_id
                                                        );
                                                        handleUpdateMarks(
                                                          item.student_id,
                                                          ass.assessment_id,
                                                          3,
                                                          e?.target?.value,
                                                          ass.ass_total_mark
                                                        );
                                                      }}
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </PermissionsGate>
                                                  <span className="ml-1 min-w-40">
                                                    / {ass.ass_total_mark}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          {ass?.grant_exemption ? (
                                            ""
                                          ) : (
                                            <div className="grade-info-ic-box">
                                              {ass?.assessmentMarkingStatus?.toString() ===
                                                "notsubmitted" ||
                                                ass?.assessmentMarkingStatus?.toString() ===
                                                "NOT SUBMITTED" ? (
                                                <div
                                                  className="grade-info-ic grade-info-red"
                                                  title="Not Submitted"
                                                >
                                                  <i className="fal fa-file-times"></i>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {!ass?.mark_edited ||
                                                ass?.mark_edited == "0" ? (
                                                ""
                                              ) : (
                                                <div
                                                  className="grade-info-ic grade-info-orange"
                                                  title="Mark Edited"
                                                >
                                                  <i className="fal fa-edit"></i>
                                                </div>
                                              )}
                                              {ass?.assessmentStatus?.toString() ===
                                                "OPEN" ? (
                                                <div
                                                  className="grade-info-ic grade-info-green"
                                                  title="Open/Extension"
                                                >
                                                  <i className="fal fa-calendar-day"></i>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {ass?.assessmentStatus?.toString() ===
                                                "PENDING" ? (
                                                <div
                                                  className="grade-info-ic grade-info-blue"
                                                  title="Upcoming Assessment"
                                                >
                                                  <i className="fal fa-calendar-alt"></i>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="as-widget-outer text-center ml-3">
                                          {ass.return && ass.return.length > 0 && (
                                            <i className="fal fa-file-download mr-1" style={{ color: 'blue', cursor: 'pointer' }}
                                              onClick={() => handleDownloadMarkerFiles(item.student_id, ass.assessment_id)}
                                              title="Download marker files"
                                            ></i>
                                          )}
                                          {ass?.assessmentMarkingStatus?.toString() !==
                                            "NOT SUBMITTED" &&
                                            ass.ass_total_mark
                                            ? RenderQuizAttemptResultStatus(
                                              ass.pass_status
                                            ).html
                                            : ""}
                                          <PermissionsGate
                                            scopes={["coursegge"]}
                                            RenderError={() => (
                                              <div
                                                className="as-widget-text grade-info-red mt-2"
                                                title="Grant Exemption"
                                              >
                                                {ass?.grant_exemption ? (
                                                  <i className="fal fa-user-times"></i>
                                                ) : (
                                                  <i className="fal fa-user-times"></i>
                                                )}
                                              </div>
                                            )}
                                          >
                                            <div
                                               className={`as-widget-text grade-info-red mt-2 curser ${item.isSupplementary ? "supplementry-opacity" : ""} ${(item.isSupplementary && !ass.isSupplementaryForThisStudent) || item.isSupplementary ? "supplementry-pointer-events" : ""}`}
                                              title="Grant Exemption"
                                              onClick={() =>
                                                handleExemption(
                                                  ass?.assessment_id,
                                                  item?.student_id,
                                                  ass?.grant_exemption ? 0 : 1,
                                                  ass?.grading_type,
                                                  percentageView
                                                  ? ass.ass_percentage
                                                  : ass.grading_type === 0 || ass.grading_type === 3
                                                  ? ass.ass_mark
                                                  : ass.grading_type === 2
                                                  ? ass.grade
                                                  : ass.grading_type === 1
                                                  ? ass?.grade == 0 ? "0" : ass?.grade > 0 && ass?.grade <= 49 ? '49' : "100" : ""
                                                )
                                                // handleExemption(
                                                //   ass?.assessment_id,
                                                //   item?.student_id,
                                                //   ass?.grant_exemption ? 0 : 1
                                                // )
                                              }
                                            >
                                              {ass?.grant_exemption ? (
                                                <i className="fal fa-user-times"></i>
                                              ) : (
                                                <i className="fal fa-user-times"></i>
                                              )}
                                            </div>
                                          </PermissionsGate>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "table-book-right " +
                    (percentageView
                      ? assessmentHeader?.length < 9
                        ? "many-items"
                        : "max-width-fit-content"
                      : assessmentHeader?.length < 9
                        ? "many-items"
                        : "max-width-fit-content")
                  }
                >
                  <table
                    className={
                      "table-book-main text-center " +
                      (percentageView
                        ? assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table"
                        : assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th className="second-last-cell">
                          <span className="right-border-grade"></span>
                          <div className="tb-book-tit">
                            Course Grade
                            <span
                              className=" grade-info-red"
                              data-toggle="tooltip"
                              title={
                                tooltipValues.passReqAssessment.length
                                  ? `Required pass mark on ${tooltipValues.passReqAssessment.toString()} and a final grade above ${tooltipValues?.requiredPassMarkforCourse
                                  }`
                                  : `Requires a final grade above ${tooltipValues?.requiredPassMarkforCourse}`
                              }
                            >
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="tb-book-tit text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className={
                                "right-border-grade " +
                                (userData.length - 1 == index
                                  ? "last-cell-border"
                                  : "")
                              }
                            ></span>
                            {item?.pass_status == "N/A" &&
                              (item?.scoreRecieved != "N/A" ||
                                !item?.gradeText) ? (
                              <div className="as-widget-outer text-center">
                                N/A
                              </div>
                            ) : (
                              <div className="as-widget-outer text-center">
                                {item?.pass_status == "N/A"
                                  ? "N/A"
                                  : RenderQuizAttemptResultStatus(
                                    item?.pass_status
                                  ).html}
                                <div className="as-widget-text">
                                  {percentageView
                                    ? (item?.scoreRecieved &&
                                      item?.scoreRecieved != "N/A") ||
                                      item?.scoreRecieved === 0
                                      ? typeof item?.scoreRecieved ==
                                        "string" &&
                                        item?.scoreRecieved.includes("%")
                                        ? Math.round(
                                          parseFloat(
                                            item?.scoreRecieved.replace(
                                              "%",
                                              ""
                                            )
                                          )
                                        ).toString() + "%"
                                        : typeof item?.scoreRecieved ==
                                          "string" &&
                                          !item?.scoreRecieved.includes("%")
                                          ? Math.round(
                                            parseFloat(item?.scoreRecieved)
                                          ).toString() + "%"
                                          : typeof item?.scoreRecieved ===
                                            "number"
                                            ? item?.scoreRecieved + "%"
                                            : item?.scoreRecieved
                                              ? item?.scoreRecieved
                                              : "N/A"
                                      : "N/A"
                                    : item.grading_type != 3
                                      ? typeof item?.gradeText == "string" &&
                                        item?.gradeText.includes("%")
                                        ? Math.round(
                                          parseFloat(
                                            item?.gradeText.replace("%", "")
                                          )
                                        ).toString() + "%"
                                        : typeof item?.gradeText === "number"
                                          ? item?.gradeText + "%"
                                          : item?.gradeText
                                            ? item?.gradeText
                                            : "N/A"
                                      : item?.getAssessmentCalculate_sum_ass_mark +
                                      " / " +
                                      item.getAssessmentCalculate_sum_ass_total_mark}
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="justify-content-end d-flex calendar-cart-item-inner">
                            <div className="assessment-08 btn-dropdown-grp">
                              <div className="as-buttons d-flex">
                                <PermissionsGate
                                  scopes={["casvview"]}
                                  RenderError={() => (
                                    <button
                                      disabled
                                      className="btn btn-primary rounded-circle"
                                      title="Open"
                                    >
                                      <i className="fal fa-folder-open"></i>
                                    </button>
                                  )}
                                >
                                  <Link
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    to={`/courseAdministration/coursesdetail/${id}/students/grades/${item.student_id}?courseName=${props?.courseName}&stdName=${item?.full_name}`}
                                    target="_blank"
                                  >
                                    <i className="fal fa-folder-open"></i>
                                  </Link>
                                </PermissionsGate>
                                <PermissionsGate
                                  scopes={["casvview"]}
                                  RenderError={() => (
                                    <button
                                      disabled
                                      className="btn btn-primary rounded-circle"
                                      title="Save Grades"
                                    >
                                      <i className="fal fa-save"></i>
                                    </button>
                                  )}
                                >
                                  <button
                                    className="btn btn-primary rounded-circle"
                                    title="Save Grades"
                                    disabled={item?.isDis}
                                    onClick={
                                      () =>
                                        handleSaveSingleGrade(item?.student_id)
                                      // handleSaveGrades(item?.student_id)
                                    }
                                  >
                                    <i className="fal fa-save"></i>
                                  </button>
                                </PermissionsGate>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="custom-table-msg">{Str.noRecord}</div>
          )}
          <nav className="pagination-nav">
            <div>
              {errorMessage ? (
                <div className="invalid-feedback d-block">{errorMessage}</div>
              ) : (
                ""
              )}
            </div>
            <div className="second-child">
              <span>Rows per page:&nbsp;</span>
              <div className="pagination-select">
                <select
                  defaultValue={tableState?.limit}
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setTableState((prevState) => ({
                      ...prevState,
                      limit: value ? value : 10,
                      page: 1,
                    }));
                  }}
                >
                  {TABLE_ROWS_PER_PAGE.map((page, index) => (
                    <option key={index}>{page}</option>
                  ))}
                </select>
              </div>
              <span className="mx-3">
                {paginationState.from}-
                {paginationState.to > paginationState.total
                  ? paginationState.total
                  : paginationState.to}{" "}
                of {paginationState.total}
              </span>
              <div className="pagination-btns">
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "firstPage");
                    } else {
                      setTableState((prevState) => ({ ...prevState, page: 1 }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "prevPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.currentPage - 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "nextPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: +paginationState.currentPage + 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "lastPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.lastPage,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </div>
        {userData?.length && (updateValueArr?.length || isCourseEdited) ? <GradeBookSaveBar handleClick={() => handleSaveGrades()} disabled={errorMessage} saveGradesLoading={saveGradesLoading} /> : <></>}
      </div>
    </>
  );
}

export default CourseGradeBook;

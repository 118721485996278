import axiosInstance from "../utils/axios";

export const AddGradeLetterTemplate = async (values) => {
  return await axiosInstance().post(`/addGradeLetterTemplate`, values);
};

export const EditGradeLetterTemplate = async (values) => {
  return await axiosInstance().post(`/updateGradeTemplate`, values);
};

export const GradeLetterTemplateList = async (values) => {
  return await axiosInstance().get(`/getGradeLetterTemplateList`, { params: values });
};

export const GradeLetterTemplateListCancelToken = async (values,cancelToken) => {
  return await axiosInstance().get(`/getGradeLetterTemplateList`, { params: values, cancelToken: cancelToken });
};

export const GradeLetterTemplateDetail = async (values) => {
  return await axiosInstance().get(`/getGradeLetterTemplateDetail`, { params: values });
};

export const GradeLetterTemplateDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getGradeLetterTemplateDetail`, { params: values, cancelToken: cancelToken });
};

export const GradeLetterTemplateListFilters = async () => {
  return await axiosInstance().get(`/gradeTemplateListFilters`);
};

export const GradeLetterTemplateListFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/gradeTemplateListFilters`, {
    cancelToken: cancelToken
  });
};

export const GradeLetterTypes = async (cancelToken) => {
return await axiosInstance().get(`/getGradingTypes`, {cancelToken: cancelToken});
};

export const GradeLetterAggregationMethods = async (cancelToken) => {
  return await axiosInstance().get(`/getAggregationMethods`, {cancelToken: cancelToken});
};

export const GradingMethods = async (cancelToken) => {
  return await axiosInstance().get(`/getGradingMethods`, {cancelToken: cancelToken});
};

export const getGradeDetails = async (data) => {
  return await axiosInstance().post(`/getGradeDetails`, data);
};

export const updateGradeDetails = async (data) => {
  return await axiosInstance().post(`/updateGradeDetails`, data);
};

export const updateProgrameGradeDetails = async (data) => {
  return await axiosInstance().post(`/updateProgrammeGradeDetails`, data);
};

export const updateProgrammeGradeMixDetails = async (data) => {
  return await axiosInstance().post(`/updateProgrammeGradeMixDetails`, data);
};

export const getProgrameGradeDetails = async (data) => {
  return await axiosInstance().post(`/getProgrammeGradeDetails`, data);
};

export const getProgrameGradeMixDetails = async (data) => {
  return await axiosInstance().post(`/gradeMixProgrammeSettingList`, data);
};

export const getGradeMixDropdown = async (data, cancelToken) => {
  return await axiosInstance().post(`/getGradeMixDropdown`, data, {cancelToken: cancelToken});
}

export const getProgrameGradeDetailsCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post(`/getProgrammeGradeDetails`, data, {
    cancelToken
  });
};

export const getStudentGradeDetails = async (id) => {
  // return await axiosInstance().get(`/getStudentGradeDetails?id=${id}`);
  return await axiosInstance().get(`/getStudentGradeDetailsSc?id=${id}`);
};

export const getStudentGradeDetailsCancelToken = async (id, cancelToken) => {
  // return await axiosInstance().get(`/getStudentGradeDetails?id=${id}`);
  return await axiosInstance().get(`/getStudentGradeDetailsSc?id=${id}`, {cancelToken});
};

export const getStudentGradebookAndGrademixCancelToken = async (id, cancelToken) => {
  // return await axiosInstance().get(`/getStudentGradeDetails?id=${id}`);
  return await axiosInstance().get(`/getStudentGradebookAndGrademix?id=${id}&exportStatus=true`, {cancelToken});
};


export const getStudentProgrammeGradeDetails = async (id, subId) => {
  // return await axiosInstance().get(`/getProgrammeGradeBookDetails?id=${id}&student_id=${subId}`);
  return await axiosInstance().get(`/getProgrammeGradeBookDetailsSc?id=${id}&student_id=${subId}`);
};

export const getStudentProgrammeGradeDetailsCancelToken = async (id, subId, cancelToken) => {
  return await axiosInstance().get(`/getProgrammeGradeBookDetailsSc?id=${id}&student_id=${subId}`, {cancelToken: cancelToken});
};
export const processProgrammeGraduates = async (data) => {
  return await axiosInstance().post(`/processProgrammeGraduates`, {"PId": data});
}
export const getProgrammeGradeStudentList = async ({id,page,limit,search,exportStatus,key,sort,filteredIds,forPdf}) => {
  // return await axiosInstance().get(`/getProgrammeGradeStudentList?id=${id}&limit=${limit}&page=${page}&search=${search}&exportStatus=${exportStatus}`);
  return await axiosInstance().get(`/getProgrammeGradeStudentListSc?id=${id}&limit=${limit}&page=${page}&search=${search}&exportStatus=${exportStatus}&filteredIds=${filteredIds}&forPdf=${forPdf}`);
};

export const getProgrameGradeMixGradebook = async ({id,page,limit,search,exportStatus,key,sort,filteredIds,forPdf,student_id}) => {
  // return await axiosInstance().get(`/getProgrammeGradeStudentList?id=${id}&limit=${limit}&page=${page}&search=${search}&exportStatus=${exportStatus}`);
  return await axiosInstance().get(`/getProgrameGradeMixGradebook?id=${id}&limit=${limit}&page=${page}&search=${search}&exportStatus=${exportStatus}&filteredIds=${filteredIds}&forPdf=${forPdf}&student_id=${student_id}`);
};

export const getProgrameGradeMixGradebookCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getProgrameGradeMixGradebook`,{params: values, cancelToken: cancelToken});
};

export const updateAssessmentGradeSettings = async (values) => {
  return await axiosInstance().post(`/updateAssessmentGradeSettings`, values);
};

export const lockGradeProgrammeToggle = async (id, lock_grades) => {
  return await axiosInstance().get(`/lockGradeProgrammeToggle?id=${id}&lock_grades=${lock_grades}`,);
};

export const lockGradeProgrammeGrademixToggle = async (id, lock_grades, grade_id) => {
  return await axiosInstance().get(`/lockGradeProgrammeGrademixToggle?id=${id}&lock_grades=${lock_grades}${grade_id ? `&grade_id=${grade_id}` : ""}`,);
};

export const getStudentGradeViaCourse = async (data) => {
  // return await axiosInstance().post(`/getStudentGradeViaCourse`, data);
  return await axiosInstance().post(`/getStudentGradeViaCourseSc`, data);
};

export const assessmentExemptionToggle = async (data) => {
  return await axiosInstance().post(`/assessmentExemptionToggle`, data);
};

export const exemptionToggle = async (data) => {
  return await axiosInstance().post(`/exemptionToggle`, data);
};

export const getCourseGradebook = async (data) => {
  // return await axiosInstance().post(`/getCourseGradebook`, data);
  return await axiosInstance().post(`/getCourseGradebookSc`, data);
};

export const getCourseAssessment = async (intake_id) => {
  return await axiosInstance().get(`/getCourseAssessment?intake_id=${intake_id}`);
};

export const courseAssessmentMarkUpdate = async (data) => {
  return await axiosInstance().post(`/courseAssessmentMarkUpdate`, data);
};

export const getGradeOfStudentViaCourse = async (data) => {
  // return await axiosInstance().post(`/getGradeOfStudentViaCourse`, data);
  return await axiosInstance().post(`/getGradeOfStudentViaCourseSc`, data);
};

export const getProgramGradebookCoursesList = async (id) => {
  return await axiosInstance().get(`/getProgramGradebookCoursesList?id=${id}`,);
};

export const getGradeMixOfPrograme = async (id, cancelToken) => {
  return await axiosInstance().get(`/getGradeMixOfPrograme?id=${id}`, {cancelToken: cancelToken});
}

export const getProgramGradebookCoursesListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getProgramGradebookCoursesList`, {params: {id}, cancelToken});
};

export const updateProgameScore = async (data) => {
  return await axiosInstance().post(`/updateProgameScore`, data);
};

export const updateProgrammeScore = async (data) => {
  return await axiosInstance().post(`/updateGrademixProgrameScore`, data);
};

export const updateCousesScore = async (data) => {
  return await axiosInstance().post(`/updateCousesScore`, data);
};

export const deleteGradeTemplate = async (data) => {
  return await axiosInstance().post(`/deleteGradeTemplate`, data);
};

export const getGradeLetterAuditTrial = async (data) => {
  return await axiosInstance().post(`/getGradeLetterAuditTrial`, data);
};

export const GetGradeLetterAuditTrialCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getGradeLetterAuditTrial`, values, {
    cancelToken: cancelToken
  });
};

export const getGradeLetterAuditTrialDropdown = async (params) => {
  return await axiosInstance().get(`/getGradeLetterAuditTrialDropdown`, { params });
};

export const GetGradeLetterAuditTrialDropdownCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getProgramAuditTrialDropdown`, { params, cancelToken: cancelToken });
};
 
export const getStudentBrand = async (id) => {
  return await axiosInstance().post(`/getStudentBrand`, id );
};

export const getStudentBrandCancelToken = async (id, cancelToken) => {
  return await axiosInstance().post(`/getStudentBrand`, id, {cancelToken: cancelToken} );
};
import React, { useEffect, useState } from "react";
import { GetListOfRole, GetUserDetail, UpdateUserDetail, GetListOfUser } from "../../../services/UserService";
import { ProfileImageDetailService, RemoveProfileImage } from "../../../services/ProfileService";
import { useFormik } from "formik";
import { MOBILE_REGEX, NAME_REGEX, IMAGE_URL, EMAIL_REGEX } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import { useParams } from "react-router-dom";

const AboutYouEditUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState();
  const [roles, setRoles] = useState();
  const [userList, setUserListData] = useState([]);
  useEffect(() => {
    GetUserDetail({ UserID: id })
      .then((res) => {
        setUserData(res.data);
        setImage(res?.data?.picture_me);
      })
      .catch((err) => console.log(err));
    GetListOfRole()
      .then((res) => {
        let x = {
          value: "",
          label: "",
        };
        x = res.data.map((data) => {
          return { ...x, value: `${data.role_id}`, label: `${data.role_name}` };
        });

        setRoles(x);
      })
      .catch((err) => console.log(err));
    GetListOfUser().then((res) => {
      let x = {
        value: "",
        label: "",
      };
      // console.log("data", x);
      x = res.data.map((data) => {
        return { ...x, value: `${data.UserID}`, label: `${data.Firstname + " " + data.Lastname}` };
      });
      setUserListData(x);
    });
  }, []);

  const [image, setImage] = useState();

  const handleProfileImage = (e) => {
    if (e.target.files.length) {
      // setImage(URL.createObjectURL(e.target.files[0]));
      const formInputs = new FormData();
      formInputs.append("photo", e.target.files[0]);
      formInputs.append("UserID", id);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileImageDetailService(formInputs)
        .then((res) => {
          Swal.close();
          // dispatch(setImage(res.data.picture_me));
          setImage(res.data.picture_me);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {});
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => {});
        });
    }
  };

  const removeProfileImage = () => {
    RemoveProfileImage({ UserID: id })
      .then((res) => {
        Swal.close();
        // dispatch(setImage(""));
        setImage("");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Removed successfully",
        }).then((result) => {});
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {});
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nTitle: userData?.nTitle ? userData?.nTitle.trim() : "",
      Firstname: userData?.Firstname,
      Lastname: userData?.Lastname,
      Email: userData?.Email,
      Mobile: userData?.Mobile,
      role_id: userData?.role_id,
      role_name: userData?.role_name,
      job_title: userData?.job_title,
      is_active: userData?.is_active,
      Status: userData?.is_active ? "Active" : "Inactive",
      //   Password: userData?.Password,
      Company: userData?.Company ? userData?.Company : "",
      Location: userData?.Location ? userData?.Location : "",
      Birth_year: userData?.Birth_year ? userData?.Birth_year : "",
      Birth_month: userData?.Birth_month ? userData?.Birth_month : "",
      Birth_day: userData?.Birth_day ? userData?.Birth_day : "",
      WorkNumber: userData?.WorkNumber ? userData?.WorkNumber : "",
      about_me: userData?.about_me ? userData.about_me : "",
      Report_to: userData?.Report_to ? userData.Report_to : "",
      Report_to_Name: userData?.report_user_Firstname ? userData.report_user_Firstname + " " + userData.report_user_Lastname : "",
    },
    validationSchema: Yup.object({
      nTitle: Yup.string().required("Title is required "),
      Firstname: Yup.string().matches(NAME_REGEX, "Please enter valid first name").trim("The first name cannot include leading and trailing spaces").required("First Name is required"),
      Lastname: Yup.string().matches(NAME_REGEX, "Please enter valid last name").trim("The last name cannot include leading and trailing spaces").required("Last Name is required"),
      Mobile: Yup.string()
        .matches(MOBILE_REGEX, "Phone number should be 0-9 of 10 digits with country code")
        // .required("Mobile is required")
        .min(10, "Phone number should be 0-9 of 10 digits with country code")
        .max(16, "Work number should be 0-9 of 10 digits with country code")
        .nullable(),
      role_id: Yup.string().required("Role is required"),
      // is_active: Yup.string().required("Status is required"),
      Email: Yup.string().email("Invalid Email address").required("Email is required").matches(EMAIL_REGEX, "Please Enter a Valid Email"),
      about_me: Yup.string().nullable(),
      job_title: Yup.string().trim("The job title cannot include leading and trailing spaces").nullable(),
      //   Password: Yup.string()
      //     .required("Password is required")
      //     .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      Company: Yup.string().required("Company is required"),
      Location: Yup.string(),
      Birth_year: Yup.string(),
      Birth_month: Yup.string(),
      Birth_day: Yup.string(),
      WorkNumber: Yup.string()
        .matches(MOBILE_REGEX, "Work number should be 0-9 of 10 digits with country code")
        .min(10, "Phone number should be 0-9 of 10 digits with country code")
        .max(16, "Work number should be 0-9 of 10 digits with country code")
        .nullable(),
      Report_to: Yup.string(),
      // address: Yup.string(),
    }),
    onSubmit: (values) => {
      values = { ...values, UserID: id };
      // console.log("first", values);

      UpdateUserDetail(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
        });
    },
  });

  const initials = userData?.Firstname[0] + userData?.Lastname[0];
  return (
    <div className="card pt-3 pl-5 pr-5 card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="profile-box-2 mb-4">
          <article>
            <figure>
              {/* {userData?.picture_me ? <img src={`${IMAGE_URL}/${userData?.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : initials} */}
              {image ? <img src={`${IMAGE_URL}/${image.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : initials}
                
              <div className="upload-drop-down-wrap">
                <div className="dropdown">
                  <button title="Profile Picture" className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fal fa-ellipsis-v"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <ul className="list-unstyled">
                      <li title="Upload Profile Picture">
                        <div className="upload-image-option">
                          <input type="file" name="avtar" accept="image/*" onChange={handleProfileImage} />
                          <i className="fal fa-camera"></i>Upload Profile Picture
                        </div>
                      </li>
                      <li title="Remove Profile Picture">
                        <button type="button" onClick={removeProfileImage}>
                          <i className="fal fa-trash-alt"></i>Remove Profile Picture
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </figure>
          </article>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-icon-group mb-4" title="Title">
              <Select
                className={"form-control custom-select-box" + (formik.errors.nTitle && formik.touched.nTitle ? " is-invalid" : "")}
                name="nTitle"
                value={formik.values.nTitle || ""}
                onChange={(value) => formik.setFieldValue("nTitle", value.value)}
                onBlur={formik.handleBlur}
                options={Title}
                maxMenuHeight={175}
                placeholder={formik.values.nTitle ? formik.values.nTitle : "Title"}
              />
            </div>
            <div className="form-icon-group mb-4">

              <input
                type="text"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                title="Mobile"
                placeholder="Enter mobile number"
                value={formik.values.Mobile ? formik.values.Mobile.replace(" ", "") : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>

            <div className="form-icon-group mb-4">
              <textarea
                className={"form-control" + (formik.errors.about_me && formik.touched.about_me ? " is-invalid" : "")}
                placeholder="About Me"
                title="About Me"
                name="about_me"
                rows="8"
                value={formik.values.about_me}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.about_me && formik.touched.about_me ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-icon-group mb-4">
              {/* <input type="email" className="form-control" placeholder="Name" title="Name" name="" value="" /> */}
              <input
                type="text"
                className={"form-control" + (formik.errors.Firstname && formik.touched.Firstname ? " is-invalid" : "")}
                name="Firstname"
                placeholder="Name"
                title="Name"
                value={formik.values.Firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Firstname && formik.touched.Firstname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>

            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={"form-control" + (formik.errors.WorkNumber && formik.touched.WorkNumber ? " is-invalid" : "")}
                placeholder="Work Number"
                title="Work Number"
                name="WorkNumber"
                value={formik.values.WorkNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.WorkNumber && formik.touched.WorkNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>

            <div className="form-icon-group mb-4" title="Company">
              <Select
                className={"form-control custom-select-box" + (formik.errors.Company && formik.touched.Company ? " is-invalid" : "")}
                name="Company"
                value={formik.values.Company || ""}
                onChange={(value) => formik.setFieldValue("Company", value.value)}
                onBlur={formik.handleBlur}
                options={Company}
                maxMenuHeight={175}
                placeholder={formik.values.Company ? formik.values.Company : "Search"}
              />

              {formik.errors.Company && formik.touched.Company ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>

            <div className="form-icon-group mb-4">

              <input
                type="text"
                className={"form-control" + (formik.errors.job_title && formik.touched.job_title ? " is-invalid" : "")}
                name="job_title"
                placeholder="Job title"
                title="Job Title"
                value={formik.values.job_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.job_title && formik.touched.job_title ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <div className="form-icon-group mb-4" title="Role">
              {/* <Select
                className="form-control custom-select-box"
                name="Location"
                value={formik.values.Location || ""}
                onChange={(value) => formik.setFieldValue("Location", value.value)}
                onBlur={formik.handleBlur}
                options={Location}
                maxMenuHeight={175}
                placeholder={formik.values.Location ? formik.values.Location : "Location"}
              /> */}

              <Select
                className="form-control custom-select-box"
                name="role_id"
                value={formik.values.role_id || ""}
                onChange={(value) => {
                  formik.setFieldValue("role_id", value.value);
                  formik.setFieldValue("role_name", value.label);
                }}
                onBlur={formik.handleBlur}
                options={roles}
                maxMenuHeight={175}
                placeholder={formik.values.role_name ? formik.values.role_name : "Role"}
              />

              {/* <select
                className={"form-control select" + (formik.errors.role_id && formik.touched.role_id ? " is-invalid" : "")}
                value={formik.values.role_id}
                name="role_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Role</option>
                {roles &&
                  roles.map((role, i) => (
                    <option key={i} value={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
              </select> */}
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-icon-group mb-4">

              <input
                type="text"
                className={"form-control" + (formik.errors.Lastname && formik.touched.Lastname ? " is-invalid" : "")}
                name="Lastname"
                placeholder="Surname"
                title="Surname"
                value={formik.values.Lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Lastname && formik.touched.Lastname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>

            <div className="form-icon-group mb-4">
              <div className="form-grp" title="Date of Birth">
                <Select
                  className="form-control custom-select-box"
                  name="Birth_year"
                  value={formik.values.Birth_year || ""}
                  onChange={(value) => formik.setFieldValue("Birth_year", value.value)}
                  onBlur={formik.handleBlur}
                  options={Year}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_year ? formik.values.Birth_year : "Year"}
                />

                <Select
                  className="form-control custom-select-box"
                  name="Birth_month"
                  value={formik.values.Birth_month || ""}
                  onChange={(value) => formik.setFieldValue("Birth_month", value.value)}
                  onBlur={formik.handleBlur}
                  options={Month}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_month ? formik.values.Birth_month : "Month"}
                />

                <Select
                  className="form-control custom-select-box"
                  name="Birth_day"
                  value={formik.values.Birth_day || ""}
                  onChange={(value) => formik.setFieldValue("Birth_day", value.value)}
                  onBlur={formik.handleBlur}
                  options={Day}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_day ? formik.values.Birth_day : "Day"}
                />
              </div>
            </div>

            <div className="form-icon-group mb-4" title="Reports To">
              <Select
                className="form-control custom-select-box"
                name="Report_to"
                value={formik.values.Report_to || ""}
                onChange={(value) => {
                  formik.setFieldValue("Report_to", value.value);
                  formik.setFieldValue("Report_to_Name", value.label);
                }}
                onBlur={formik.handleBlur}
                options={userList}
                maxMenuHeight={175}
                placeholder={formik.values.Report_to_Name ? formik.values.Report_to_Name : "Search"}
              />
              {/* <input
                type="text"
                className="form-control"
                placeholder="Reports To"
                title="Reports To"
                name="Report_to"
                value={formik.values.Report_to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
              {formik.errors.Report_to && formik.touched.Report_to ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <div className="form-icon-group mb-4" title="Location">
              <Select
                className="form-control custom-select-box"
                name="Location"
                value={formik.values.Location || ""}
                onChange={(value) => formik.setFieldValue("Location", value.value)}
                onBlur={formik.handleBlur}
                options={Location}
                maxMenuHeight={175}
                placeholder={formik.values.Location ? formik.values.Location : "Location"}
              />
            </div>
            <div className="form-icon-group mb-4" title="Status">
              <Select
                className="form-control custom-select-box"
                name="Status"
                value={formik.values.is_active || ""}
                onChange={(value) => {
                  formik.setFieldValue("is_active", value.value);
                  formik.setFieldValue("Status", value.label);
                }}
                onBlur={formik.handleBlur}
                options={Active}
                maxMenuHeight={175}
                placeholder={formik.values.Status ? formik.values.Status : "Status"}
              />
            </div>
          </div>
        </div>

        <div className="form-group form-group-save-cancel ml-new">
          <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty && formik.isValid)}>
            <i className="fal fa-check"></i>
            Save
          </button>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.nTitle && formik.errors.nTitle ? <div className="invalid-feedback d-block">{formik.errors.nTitle}</div> : null}
          {formik.touched.Firstname && formik.errors.Firstname ? <div className="invalid-feedback d-block">{formik.errors.Firstname}</div> : null}
          {formik.touched.Lastname && formik.errors.Lastname ? <div className="invalid-feedback d-block">{formik.errors.Lastname}</div> : null}
          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Mobile && formik.errors.Mobile ? <div className="invalid-feedback d-block">{formik.errors.Mobile}</div> : null}
          {formik.touched.role_id && formik.errors.role_id ? <div className="invalid-feedback d-block">{formik.errors.role_id}</div> : null}
          {formik.touched.job_title && formik.errors.job_title ? <div className="invalid-feedback d-block">{formik.errors.job_title}</div> : null}
          {formik.touched.Company && formik.errors.Company ? <div className="invalid-feedback d-block">{formik.errors.Company}</div> : null}
          {formik.touched.Location && formik.errors.Location ? <div className="invalid-feedback d-block">{formik.errors.Location}</div> : null}
          {formik.touched.Report_to && formik.errors.Report_to ? <div className="invalid-feedback d-block">{formik.errors.Report_to}</div> : null}
          {formik.touched.about_me && formik.errors.about_me ? <div className="invalid-feedback d-block">{formik.errors.about_me}</div> : null}
          {formik.touched.WorkNumber && formik.errors.WorkNumber ? <div className="invalid-feedback d-block">{formik.errors.WorkNumber}</div> : null}
        </div>
      </form>
    </div>
  );
};

export default AboutYouEditUser;

{
  /* <div className={`card-body `}> */
}
{
  /* <div className="edit-icon">
                      <button className="edit-profile-icon" onClick={() => setEdit(!edit)} title={edit ? "Edit" : "Cancel"}>
                        <i className="fal fa-edit"></i>
                      </button>
                    </div> */
}
{
  /* {edit ? (
                      <div className="card-body-inr">
                        <div className="profile-box-2 ml-new mb-4 mt-2">
                          <article>
                            <figure>{userData?.picture_me ? <img src={`${IMAGE_URL}/${userData?.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : initials}</figure>
                          </article>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Title</label>
                              <div className="label-selected form-control">{userData?.title ? userData?.title : "NA"}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>First Name</label>
                              <div className="label-selected form-control">{userData?.Firstname}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Last Name</label>
                              <div className="label-selected form-control">{userData?.Lastname}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Mobile Number</label>
                              <div className="label-selected form-control">{userData?.Mobile ? userData?.Mobile : "NA"}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Email</label>
                              <div className="label-selected form-control">{userData?.Email ? userData?.Email : "NA"}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Location</label>
                              <div className="label-selected form-control">{userData?.Location ? userData?.Location : "NA"}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Job Title</label>
                              <div className="label-selected form-control">{userData?.job_title ? userData?.job_title : "NA"}</div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Status</label>
                              <div className="label-selected form-control">{userData?.is_active ? "Active" : "Inactive"}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Role</label>
                              <div className="label-selected form-control">{OldToNew(userData?.role_name)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Company</label>
                              <div className="label-selected form-control">{userData?.Company ? userData?.Company : "NA"}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group form-group-blk">
                              <label>Password</label>
                              <div className="label-selected form-control">***********</div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */
}
{
  /* </div> */
}

{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <select
                className={"form-control" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")}
                value={formik.values.title}
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
             
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <input
                type="text"
                className={"form-control" + (formik.errors.Firstname && formik.touched.Firstname ? " is-invalid" : "")}
                name="Firstname"
                value={formik.values.Firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Firstname && formik.touched.Firstname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <input
                type="text"
                className={"form-control" + (formik.errors.Lastname && formik.touched.Lastname ? " is-invalid" : "")}
                name="Lastname"
                value={formik.values.Lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Lastname && formik.touched.Lastname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div> */
}
{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
            
              <input
                type="email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Email"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
          
              <input
                type="text"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                placeholder="Mobile"
                value={formik.values.Mobile ? formik.values.Mobile.replace(" ", "") : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <select
                className={"form-control" + (formik.errors.Location && formik.touched.Location ? " is-invalid" : "")}
                value={formik.values.Location}
                name="Location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="Midrand">Midrand</option>
                <option value="Cape Town">Cape Town</option>
                <option value="Johannesburg">Johannesburg</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
          
              <input
                type="text"
                className={"form-control" + (formik.errors.job_title && formik.touched.job_title ? " is-invalid" : "")}
                name="job_title"
                placeholder="Job title"
                value={formik.values.job_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.job_title && formik.touched.job_title ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <select
                className={"form-control" + (formik.errors.is_active && formik.touched.is_active ? " is-invalid" : "")}
                value={formik.values.is_active}
                name="is_active"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
               
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <select
                className={"form-control" + (formik.errors.role_id && formik.touched.role_id ? " is-invalid" : "")}
                value={formik.values.role_id}
                name="role_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                
                {roles &&
                  roles.map((role, i) => (
                    <option key={i} value={role}>
                      {role}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <select
                className={"form-control" + (formik.errors.Company && formik.touched.Company ? " is-invalid" : "")}
                value={formik.values.Company}
                name="Company"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="Academic Institute of Excellence (AIE)">Academic Institute of Excellence (AIE)</option>
                <option value="Greenside Design Center (GDC)">Greenside Design Center (GDC)</option>
                <option value="Learnfast">Learnfast</option>
                <option value="Cadco">Cadco</option>
              </select>
            </div>
          </div>
          <div className="col">
            
          </div>
          <div className="col"></div>
        </div> */
}

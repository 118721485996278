// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  const cookieToken = document.cookie.match(new RegExp(`(^| )${'phone_token'}=([^;]+)`))?.at(2)
  const phoneUser = document.cookie.match(new RegExp(`(^| )${'phone_user'}=([^;]+)`))?.at(2)
  if (cookieToken) {
    localStorage.setItem("token", cookieToken)
    localStorage.setItem("user", phoneUser)
  }
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  document.cookie = ''
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

// set the token and user from the session storage
export const setUserSettings = (settings) => {
  localStorage.setItem("settings", JSON.stringify(settings));
};

// return the user data from the session storage
export const getUserSettings = () => {
  let settings = localStorage.getItem("settings");
  if (settings) return JSON.parse(settings);
  else return null;
};

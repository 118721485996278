import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link, useHistory } from "react-router-dom";
import AuditTrail from "../components/systemadministration/CampusManagement/AuditTrail";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import CampusList from "../components/systemadministration/CampusManagement/CampusList";
import CampusDetails from "../components/systemadministration/CampusManagement/CampusDetails";
import { TrimText } from "../components/common/TrimText";
import RoomList from "../components/systemadministration/CampusManagement/RoomList";
import { GlobalIconNames } from "../utils/GlobalIconNames";
import CallLogs from "../components/systemadministration/SoftphoneManagement/CallLogs";
import AuditTrails from "../components/systemadministration/SoftphoneManagement/AuditTrails";
import hasPermission from "../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

const SoftphoneManagement = () => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();
    const { tab } = useParams();
    const search = useLocation().search;
    const classId = new URLSearchParams(search).get("id");
    const campusName = new URLSearchParams(search).get("name");
    const givenPermsisions = useSelector((state) => state.givenPermission);
    useEffect(() => {
        if (tab !== '') {
            setActiveTab(tab);
        } else {
            setActiveTab('settings');
        }
    }, [tab]);

    useEffect(
        () => {
            let response = hasPermission({
                scopes: ["softphonecalllogview"],
                permissions: givenPermsisions,
              });
              if (!response) {
                history.push("/noaccess");
              }
        },[]
    )

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {((tab == "callLogs" || tab == "auditTrail") && !campusName) ? "Softphone " : tab == "addCampus" ? "Add " : "Campus: "} <span title={campusName}>{((tab == "callLogs" || tab == "auditTrail") && !campusName) ? "Management" : tab == "addCampus" ? "Campus" : ((tab == "campusDetails" || tab == "auditTrail" || tab == "rooms") && campusName) ? TrimText(campusName, 60) : ""}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        title="Back"
                                                        className="btn btn-white-bordered"
                                                    >
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {tab !== "addCampus" &&
                                        <ul className="nav nav-pills mb-2" id="tabs" role="tablist">
                                            <li className="nav-item" title="Call Logs" onClick={() => history.push(classId === null ? "/administration/softphoneManagement/callLogs" : `/administration/softphoneManagement/campusDetails?id=${classId}&name=${campusName}`)}>
                                                <Link className={`nav-link ${activeTab === 'callLogs' || activeTab === 'campusDetails' ? 'active' : ''}`} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                    {classId === null && <><i className="fal fa-phone-office"></i>Call Logs</>}
                                                    {classId !== null && GlobalIconNames("Details").html}
                                                </Link>
                                            </li>
                                            

                                            
                                        </ul>}

                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "callLogs" && <CallLogs onPage={"softphone"}/>}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SoftphoneManagement;

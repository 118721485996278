import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { AddStudents } from "../../../services/StudentsSettingServices";
import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX, STUDENT_NUMBER_REGEX } from "../../../utils/Constants";
import { AllBrandList } from "../../../services/RegistrationService";
import hasPermission from "../../../utils/hasMultiplePermission";
import Select from "react-select";

function StudentForm() {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  
  useEffect(() => {
    let response = hasPermission({ scopes: ["sadd"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
    AllBrandList().then((res)=>{
      let respData = res.data.allBrandList;
      respData = respData.map((item)=>({label : item.brand_name, value : item.id}));
      setAllBrands([...respData]);
    }).catch((err)=>{
      console.log(err)
    })
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirstName: "",
      SecondName: "",
      LastName: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      StudentNumber: "",
      brand_id: ""
    },
    validationSchema: Yup.object({
      FirstName: Yup.string().strict(true).matches(NAME_REGEX, "Please enter valid first name").required("First Name is required").trim("Leading and ending trail not allowed in first name"),
      SecondName: Yup.string().strict(true).matches(NAME_REGEX, "Please enter valid second name").trim("Leading and ending trail not allowed in second name"),
      Email: Yup.string().strict(true).email("Please Enter a Valid Email").required("Email is required").trim("Leading and ending trail not allowed in email").matches(EMAIL_REGEX, "Please Enter a Valid Email"),
      LastName: Yup.string().strict(true).matches(NAME_REGEX, "Please enter valid last name").required("Last Name is required").trim("Leading and ending trail not allowed in last name"),
      Password: Yup.string().strict(true).required("Password is required").trim("Leading and ending trail not allowed in password").matches(PASSWORD_REGEX, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      StudentNumber: Yup.string().strict(true).matches(STUDENT_NUMBER_REGEX, "Invalid Student Number").required("Student Number is required"),
      ConfirmPassword: Yup.string().strict(true)
        .required("Confirm Password is required").trim("Leading and ending trail not allowed in confirm password")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
      brand_id : Yup.string().required("Brand is required")
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("first_name", values?.FirstName);
      formData.append("second_name", values?.SecondName);
      formData.append("last_name", values?.LastName);
      formData.append("email", values?.Email);
      formData.append("password", values?.Password);
      formData.append("student_crm_id", values?.StudentNumber);
      formData.append("brand_id", values?.brand_id);
      AddStudents(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          history.push("/studentAdministration/students/table")
          setDisabled(false);
          resetForm()
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err.response.data[0].message}`,
          });
          setDisabled(false);
          console.log("err", err.response);
        });
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <label htmlFor="FirstName">First Name *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="FirstName"
                    id="FirstName"
                    className={"form-control" + (formik.errors.FirstName && formik.touched.FirstName ? " is-invalid" : "")}
                    name="FirstName"
                    placeholder="Enter First Name"
                    value={formik.values.FirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="FirstName"
                  />
                  {formik.errors.FirstName && formik.touched.FirstName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <label htmlFor="LastName">Last Name *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.LastName && formik.touched.LastName ? " is-invalid" : "")}
                    name="LastName"
                    placeholder="Enter Last Name"
                    title="Last Name"
                    id="LastName"
                    value={formik.values.LastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.LastName && formik.touched.LastName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
              <label htmlFor="student_crm_id">Student Number *</label>
              <div className="form-icon-group mb-4" title="LastName">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.StudentNumber && formik.touched.StudentNumber
                      ? " is-invalid"
                      : "")
                  }
                  name="StudentNumber"
                  id="StudentNumber"
                  placeholder="Student Number"
                  title="Student Number"
                  value={formik.values.StudentNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              </div>
              <div className="col-md-6 col-lg-3 ">
                <label htmlFor="brand">Brand *</label>
                <div className="form-icon-group mb-4" title="Brand">
                  <Select
                    className={"form-control custom-select-box " + (formik.errors.brand_id && formik.touched.brand_id ? " is-invalid" : "")}
                    name="brand"
                    value={allBrands.filter((item) => {
                      return item.value == formik.values.brand_id;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("brand_id", value.value);
                      } else {
                        formik.setFieldValue("brand_id", "");
                      }
                    }}
                    isClearable
                    onBlur={formik.handleBlur}
                    options={allBrands.sort((a, b) => {
                      let name1 = a.label;
                      let name2 = b.label
                      return name1.localeCompare(name2)
                    })}
                    maxMenuHeight={175}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <label htmlFor="Email">Email *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="Email"
                    className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                    name="Email"
                    id="Email"
                    placeholder="Enter Email address"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Email"
                  />
                  {formik.errors.Email && formik.touched.Email ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <label htmlFor="Password">Password *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="password"
                    className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                    name="Password"
                    id="Password"
                    placeholder="Enter Password"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Password"
                  />
                  {formik.errors.Password && formik.touched.Password ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <label htmlFor="ConfirmPassword">Confirm Password *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="password"
                    className={"form-control" + (formik.errors.ConfirmPassword && formik.touched.ConfirmPassword ? " is-invalid" : "")}
                    name="ConfirmPassword"
                    id="ConfirmPassword"
                    placeholder="Enter Confirm Password"
                    value={formik.values.ConfirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Confirm Password"
                  />
                  {formik.errors.ConfirmPassword && formik.touched.ConfirmPassword ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
              </button>
              <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
                <i className="fal fa-times"></i>
                Cancel
              </button>
              {Object.keys(formik.values).map(key => {
                  if (formik.touched[key] && formik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                      </div>
                    )
                  }
                })}
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default StudentForm;

import React from "react";
import { downloadURL, zipURL } from "../../utils/Constants";
import Swal from "sweetalert2";
import { download } from "../../utils/FileDownload";
export const RenderAttachList = (props) => {
  const downloadFile = (filePath, fileName) => {
    // if filepath does not include downloadURL, add downloadURL to beginning
    if (filePath && !filePath.includes(downloadURL)) {
      //rplace  https:// or http:// from filepath if it has it with ""
      filePath = filePath.replace("https://", "").replace("http://", "");
      filePath = downloadURL + filePath;
    }
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    
    let fileURL = filePath;
    download(fileURL, fileName)
    
  }
  
  const openFile = (filePath) => {
    if (!filePath?.includes(downloadURL)) {
        filePath = filePath?.replace(downloadURL, "");
        //add https:// to start of filepath if it is not there
        
    }
    window.open(filePath, "_blank"); // Open file in a new tab
  };
  const fetchIcon = (path) => {
    let ext = path.split('.')
    switch (ext[ext.length - 1]) {
      case 'docx':
      return 'fal fa-file-word';
      break;
      case 'zip':
      return 'fal fa-file-archive';
      break;
      case 'pdf':
      return 'fal fa-file-pdf';
      break;
      case 'xlsx':
      return 'fal fa-file-excel';
      break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      return 'fal fa-file-image';
      break;
      case 'txt':
      return 'fal fa-file-alt';
      break;
      case 'pptx':
      return 'fal fa-file-powerpoint';
      break;
      default:
      return 'fal fa-file';
    }
  }
  
  return (<div className="uploded-files-item feed-pad">
    <div className="uploded-files-item-txt curser"
    data-toggle="tooltip"
    data-placement="right"
    title={props?.fileName}
    onClick={() => openFile(props?.filePath, props?.fileName)}>
    <figure>
    <i className={fetchIcon(props?.filePath)}></i>
    </figure>
    <figcaption >
    <h4 className="oneline-ellipsis font12">{props?.fileName}</h4>
    <p className="font12 hold-show">{props?.size}</p>
    </figcaption>
    </div>
    
    {props.showClose ?
      <button onClick={() => { props.handleRemoveFile(props) }} type="button" className="btn file-remove-btn"><i className="fal fa-times hold-show"></i></button>
      : // remove http:// or https:// from props?.filepath if applciable
      <button type="button"  onClick={(e) => {e.stopPropagation(); downloadFile(props?.filePath, props?.fileName);}} className="btn file-remove-btn rm-box-shadow"><i className="fal fa-download"></i></button>
    }
    </div>
  )
}
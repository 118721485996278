import React from 'react'
import { useParams } from 'react-router-dom';
import Pending from './Appeal/Pending';
import Approved from './Appeal/Approved';
import Declined from './Appeal/Declined';

function ListofAppeals({filterIntake,globalReset,filterLearningMethod,filterSchool,filterLecturer}) {
  const { type } = useParams();

  const componentMap = {
    pending: <Pending globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterSchool={filterSchool} filterLecturer={filterLecturer}/>,
    approved: <Approved globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterSchool={filterSchool} filterLecturer={filterLecturer}/>,
    declined: <Declined globalReset={globalReset} filterIntake={filterIntake} filterLearningMethod={filterLearningMethod} filterSchool={filterSchool} filterLecturer={filterLecturer}/>
  };

  return componentMap[type]
}

export default ListofAppeals

import React, { createRef, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import { API_BASE, STUDENT_FILE_DOWNLOAD, IMAGE_URL, downloadURL } from "../../../utils/Constants";
import { getToken } from "../../../utils/Auth";
import { getFormattedTime, InitialRender } from "../../common/Helper";
import { TrimText } from "../../common/TrimText";
import ReactHtmlParser from "react-html-parser";
import Hover from "../../common/Hover";
import { checkIfImageExists } from "../../../utils/commonFunction";
import axios from "axios";

const Comments = (props) => {
  const { ticketData, ticketThread } = props;
  const filePondRef = createRef();
  const btnAttachment = createRef();
  const scroll_down_bottom = useRef();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [InputMessage, setInputMessage] = useState("");

  const [currentimage, setcurrentimage] = useState("");

  const user = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleAttachment = () => {
    btnAttachment.current.click();
  };

  const handleDragEnter = (event) => {
    setShowFilePond(true);
    // event.stopPropogation()
    // event.preventDefault()
  };

  const handleUploadedFiles = (response) => {
    setAttachments((attachments) => [...attachments, response]);
  };

  const handleFiles = async (event) => {
    const ufiles = await event.target.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        if (filePondRef.current) {
          filePondRef.current
            .addFile(ufiles[0])
            .then((file) => {
              // window.scrollTo(0, scroll_down_bottom.current.offsetTop);

              // alert(scroll_down_bottom.current.offsetTop);
            })
            .catch((error) => console.log(error));
        }
      }
    }
  };

  const handleFilePondInit = () => { };

  const handleSendMessage = () => {
    let messagepost = {
      message: InputMessage,
      files: attachments,
      // taggedUsers,
      id_ticket: props.ticketData.id_ticket,
    };

    props.handleTicketThreadList(messagepost);

    setInputMessage("");
    setFiles([]);
    setAttachments([]);
    setShowFilePond(false);
  };

  const downloadAs = (url, name) => {
    axios.get(url, {
      responseType: "blob"
    })
      .then(response => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  const downloadFile = async (absolutePath, fileName, index) => { };

// function autosize(e){
//   let textAreaElem = e.target
//   let scrollHeight = textAreaElem.scrollHeight
//   let offsetHeight = textAreaElem.offsetHeight
//   if (scrollHeight > offsetHeight) {
//     textAreaElem.style = `height: ${scrollHeight}px !important; overflow: hidden;`
//   // } else {
//   //   textAreaElem.style = `height: auto !important;`
//   }
//   // scrollHeight
//   // offsetHeight
//   console.log("elssss", textAreaElem.rows)
// }

function autosize() {
  var text = document.querySelectorAll('#messageTyping');

  text.forEach(function(elem) {
    elem.setAttribute('rows', 1);
    var kk = resize(elem);
  });

  text.forEach(function(elem) {
    elem.addEventListener('input', function() {
      var k = resize(elem);
    });
  });

  function resize(elem) {
    elem.style.height = 'auto';
    // elem.style.height = elem.scrollHeight + 'px';
    elem.style = `height: ${elem.scrollHeight}px !important;`
    return elem.scrollHeight;
  }
}

  return (
    <div className="comment-main ac-white-card p-0">
      <div className="comments-card-body">
        <div className="comment-conversation-list-wrap">
      <div className="">
        <label className="card-header comments-card-header lb-black d-block">Message</label>
        <div className="card-header comments-card-sub-header pb-4 resizable"> 
          {props.ticketData.campus_name && <p>Campus: {props.ticketData.campus_name} </p>}
          {ReactHtmlParser(props.message)}
        </div>
      </div>
      <div className="card-header comments-card-header">Comments</div>
          <ul className="comment-conversation-list list-unstyled">
            {ticketThread &&
              ticketThread.length > 0 &&
              ticketThread.map((thread, index) => {
                return (
                  <React.Fragment key={index}>
                    {thread.if_sender_cms_user > 0 ? (
                      <li
                        className="comment-user-current"
                        key={thread.id_sender}
                      >
                        <div className="comment-user-bx">
                          <div className="comment-user-img comment-box-pro assigned-title-blk">
                            <div className="comment-box-pro-item">
                            <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                              <span className="assigned-title-blk name-icon">
                                {thread.picture_me ? <><img
                                className="user-image"
                                  src={`${IMAGE_URL}/${thread.picture_me.replaceAll(
                                    "/home/myaie/public_html/",
                                    ""
                                  ).replaceAll(
                                    "public/",
                                    ""
                                  )}`}
                                  alt="AIE"
                                  onError={(e)=> {
                                    e.target.style.height = "0px"
                                    e.target.style.width = "0px"
                                  }}
                                />  </> : thread?.userFullName ? InitialRender([
                                  thread?.userFullName.split(" ")[0],
                                  thread?.userFullName.split(" ")[1],
                                ]
                                ) : InitialRender(["N", "A",])}
                                <span className={`profile-box-2-status ${thread.user_activity_status ? thread.user_activity_status.toLowerCase() == "online" ? "Online" : thread.user_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                  <i className="fas fa-circle"></i>
                                </span>
                                <Hover
                                  firstName={thread?.userFullName ? thread?.userFullName.split(" ")[0] : "N"}
                                  lastName={thread?.userFullName ? thread?.userFullName.split(" ")[1] : "A"}
                                  photo={thread.picture_me}
                                  email={thread.userEmail}
                                  mobile={thread.mobile}
                                  right={false}
                                  role={"User"}
                                  status={thread?.user_status}
                                  activity_status={thread?.user_activity_status}
                                />
                              </span>
                            </div>
                            </div>
                            </div>
                          </div>

                          <div className="comment-user-detail">
                            {thread.attachments.length > 0
                              ? thread.attachments.map((file, indKey) => {
                                if (file.fileType == "download") {
                                  return (
                                    <div
                                      className="Attachment-area"
                                      key={indKey}
                                    >
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                      Attached File :
                                      <a className="as-text-blue curser" onClick={()=>{downloadAs(downloadURL + (!file.attachment.includes("http") ? STUDENT_FILE_DOWNLOAD + "/" : "")+file.attachment.replace(
                                          "/home/myaie/public_html/",
                                          ""
                                        ).replace("public/","").replace(/(^\w+:|^)\/\//, ''),file.originalName)}}>{file.originalName}</a>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      key={indKey}
                                      onClick={() =>
                                        downloadFile(
                                          file.attachment,
                                          file.originalName,
                                          1
                                        )
                                      }
                                      className="attached-media-comment Attachment-area"
                                    >
                                      <img
                                        className="curser"
                                        onClick={() => {
                                          setcurrentimage(file.attachment);
                                        }}
                                        src={(!file.attachment.includes("https://myaie.ac") ? IMAGE_URL + "/" : "") +file.attachment.replace(
                                          "/home/myaie/public_html/",
                                          ""
                                        ).replace("public/","")}
                                        alt="AIE"
                                      />
                                      <div className={`image-popup-block ${currentimage != '' ? 'active' : ''}`}>
                                        <div className="image-popup flexible-modal">
                                          <button type="button" onClick={() => { setcurrentimage('') }} className="close-icon"><i className="fas fa-times hold-show"></i></button>
                                          <div className="image-popup-img">
                                            <img src={(!currentimage.includes("https://myaie.ac") ? IMAGE_URL + "/" : "") + (currentimage.replace("/home/myaie/public_html/", "").replace("public/", "").replace("public_html", ""))} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                            {thread.message && thread.message !== "" ? (
                              <div className="posted-comment">
                                {thread.message}
                              </div>
                            ) : null}
                            <small className="date">
                              {" "}
                              {getFormattedTime(thread.send_date)}
                            </small>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li className="comment-user-other" key={thread.id_sender}>
                        <div className="comment-user-bx">
                          <div className="comment-user-img comment-box-pro assigned-title-blk">
                            <div className="comment-box-pro-item">
                            <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                              <span className="assigned-title-blk  name-icon">
                                {thread.picture_me ? (
                                  <><img
                                  className="user-image"
                                    src={`${IMAGE_URL}/${thread.picture_me.replace(
                                      "/home/myaie/public_html/",
                                      ""
                                    ).replaceAll(
                                      "public/",
                                      ""
                                    )}`}
                                    alt="AIE"
                                    onError={(e)=> {
                                      e.target.style.height = "0px"
                                      e.target.style.width = "0px"
                                    }}
                                  /></>
                                ) : thread?.userFullName ? InitialRender([
                                  thread?.userFullName.split(" ")[0],
                                  thread?.userFullName.split(" ")[1],
                                ]) : InitialRender(["N", "A",])}
                              <span className={`profile-box-2-status ${thread.student_activity_status ? thread.student_activity_status.toLowerCase() == "online" ? "Online" : thread.student_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                              </span>
                              <Hover
                              firstName={thread?.userFullName ? thread?.userFullName.split(" ")[0] : "N"}
                              lastName={thread?.userFullName ? thread?.userFullName.split(" ")[1] : "A"}
                              photo={thread.picture_me}
                              email={thread.userEmail}
                              mobile={thread.mobile}
                              right={false}
                              role={"User"}
                              status={thread.student_status} 
                              activity_status={thread.student_activity_status} 
                              showNumber={true} 
                              number={thread.student_crm_id}
                            />
                              </span>
                            </div>
                            </div>
                            </div>
                            {/* <div className="assigned-title-info">
                                  <article>
                                    <figure className="bgBlue">
                                      {thread.picture_me && checkIfImageExists(`${IMAGE_URL}/${thread.picture_me.replace("/home/myaie/public_html/","")}`) ? <img src={`${IMAGE_URL}/${thread.picture_me.replace("/home/myaie/public_html/","")}`}  alt="AIE" /> : InitialRender([thread?.userFullName, thread?.userFullName ])}
                                     
                                      
                                    </figure>
                                    <figcaption>
                                      <h4>{TrimText(thread.userFullName, 23)}</h4>
                                      <p>
                                        <a className="hover-color-blue" onClick={() => navigator.clipboard.writeText(thread.userEmail)}>
                                          <i className="fal fa-envelope"></i> {thread.userEmail ? thread.userEmail : "NA"}
                                        </a>
                                      </p>
                                    </figcaption>
                                  </article>
                                </div> */}
                          </div>

                          <div className="comment-user-detail bg-transparent">
                            <div className="mb-1">{thread?.userFullName}</div>
                            {thread.attachments.length > 0
                              ? thread.attachments.map((file, ind) => {
                                if (file.fileType == "download") {
                                  return (
                                    <div
                                      className="Attachment-area"
                                      key={ind}
                                    >
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                      Attached File :
                                      <a className="as-text-blue curser" onClick={()=>{downloadAs(downloadURL + (!file.attachment.includes("http") ? STUDENT_FILE_DOWNLOAD + "/" : "")+file.attachment.replace(
                                          "/home/myaie/public_html/",
                                          ""
                                        ).replace("public/","").replace(/(^\w+:|^)\/\//, ''),file.originalName)}}>{file.originalName}</a>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      onClick={() =>
                                        downloadFile(
                                          file.attachment,
                                          file.originalName,
                                          1
                                        )
                                      }
                                      className="attached-media-comment Attachment-area"
                                    >
                                      <img
                                        className="curser"
                                        onClick={() => {
                                          setcurrentimage(file.attachment);
                                        }}
                                        src={file.attachment}
                                      />
                                      <div className={`image-popup-block ${currentimage != '' ? 'active' : ''}`}>
                                        <div className="image-popup flexible-modal">
                                          <button type="button" onClick={() => { setcurrentimage('') }} className="close-icon"><i className="fas fa-times hold-show"></i></button>
                                          <div className="image-popup-img">
                                            <img src={(!currentimage.includes("https://myaie.ac") ? IMAGE_URL + "/" : "") + (currentimage.replace("/home/myaie/public_html/", "").replace("public/", "").replace("public_html", ""))} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                            {thread.message && thread.message !== "" ? (
                              <div className="posted-comment">
                                {thread.read_at ? (
                                  <>{thread.message}</>
                                ) : (
                                  <b>{thread.message}</b>
                                )}
                              </div>
                            ) : null}
                            <small className="date">
                              {getFormattedTime(thread.send_date)}
                            </small>
                          </div>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
          </ul>
        </div>
      </div>
      {ticketData && ticketData.status !== "Closed" ? (
        <>
          <div className="card-footer comments-card-footer">
            <div
              className={
                ticketData && ticketData.status == "Closed"
                  ? "comment-area-control-full"
                  : "comment-area-control"
              }
            >
              <textarea style={{ cursor: props.cursorLoading ? "wait" : "default" }} 
                className="form-control comment-form-control ticket-message"
                // id="messageTyping"
                placeholder="Start typing..."
                onChange={handleInputChange}
                onKeyDown={autosize}
                // rows={1}
                value={InputMessage}
                onDragEnter={handleDragEnter}
                // onDragLeave={handleDragExit}
                disabled={
                  ticketData && ticketData.status == "Closed" ? true : false
                }
              />
            </div>
            <div className="comment-area-actions">
              <button
              style={{ cursor: props.cursorLoading ? "wait" : "default" }} 
                type="button"
                title="Add Attachment"
                className="btn btn-submit-arrow no-shadow"
                disabled={
                  ticketData && ticketData.status == "Closed" ? true : false
                }
                onClick={handleAttachment}
              >
                <i className="fal fa-paperclip"></i>
              </button>
              <div>
                <input
                style={{ cursor: props.cursorLoading ? "wait" : "default" }} 
                  disabled={
                    ticketData && ticketData.status == "Closed" ? true : false
                  }
                  type="file"
                  ref={btnAttachment}
                  style={{ display: "none" }}
                  onChange={(e) => handleFiles(e)}
                  showalerts={"false"}
                />
              </div>
              {!InputMessage && !files.length ? (
                <button
                style={{ cursor: props.cursorLoading ? "wait" : "default" }} 
                  type="button"
                  title="Send Message"
                  className="btn btn-submit-arrow no-shadow"
                  onClick={() => handleSendMessage()}
                  disabled={true}
                >
                  <i className="fal fa-paper-plane"></i>
                </button>
              ) : (
                <button
                style={{ cursor: props.cursorLoading ? "wait" : "default" }} 
                  type="button"
                  title="Send Message"
                  className="btn btn-submit-arrow no-shadow"
                  onClick={() => handleSendMessage()}
                  disabled={loading ? true : false}
                >
                  <i className="fal fa-paper-plane"></i>
                </button>
              )}
            </div>
          </div>
        </>
      ) : null}

      <div
        ref={scroll_down_bottom}
        className={!showFilePond ? "hideFilePond" : "showFilePond"}
      >
        <FilePond
          name="post"
          allowMultiple={true}
          maxFiles={3}
          ref={filePondRef}
          files={files}
          onupdatefiles={setFiles}
          beforeRemoveFile={(f) => setRemoveFile(f.file.name)}
          init={handleFilePondInit}
          onaddfile={(err, fileItem) => {
            setShowFilePond(true);
          }}
          server={{
            url: API_BASE,
            process: {
              url: "/addTicketAttachments",
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
              ondata: (formData) => {
                setLoading(true);
                return formData;
              },
              onload: (response) => {
                handleUploadedFiles(response);
                setLoading(false);
              },
            },
            revert: {
              url: `/removeFeedAttachments/fileName`,
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
              ondata: (formData) => {
                return formData;
              },

              onload: (response) => {
                setRemoveFile(null);
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Comments;
